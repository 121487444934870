import { useEffect, useState } from 'react';
import utils from '../../utils';
import { redirect, useLocation, useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import "./action.css";
import FieldBox from '../../base/utils/fieldbox';
import Utils from '../../utils';
import api from '../../api';
import { Loader } from '../../base/utils/loader';
import constants from '../../constants';

export const EditWarehouse = (props) => {
    const [pageStatus, setPageStatus] = useState("initial");
    const navigate = useNavigate();
    // const [currentTab, setCurrentTab] = useState(props.subsection.viewTabs ? props.subsection.viewTabs[0] : {});
    const [isLoading, setIsLoading] = useState(false);
    // const [fields, setFields] = useState({gender : "male"});
    const [error, setError] = useState({});

    const [citiesList, setCitiesList] = useState([]);
    const [fleetsList, setFleetsList] = useState([]);

    let { currentTab, id } = useParams();
    const {state} = useLocation();
    const [fields, setFields] = useState(state ? 
        {
            warehouse_name : state.data.warehouse_name,
            email : state.data.email,
            phoneNumber : state.data.phone_number,
            address1 : state.data.address ? state.data.address.address_line1 : "",
            address2 : state.data.address ? state.data.address.address_line2 : "",
            city : state.data.address ? state.data.address.city : "",
            stateName : state.data.address ? state.data.address.state.toUpperCase() : "",
            pincode : state.data.address ? state.data.address.postal_code : "",
            fleet_id : state.data.fleet_id,
        }
        : 
        {
            warehouse_name : "", 
            email : "",
            phoneNumber : "",
            address1 : "",
            address2 : "",
            city : "",
            stateName : "",
            pincode : "",
            fleet_id : "",
        });
    // const [data, setData] = useState(state.data);

    const errors = {
        fleet_id : "Please select fleet",
        warehouse_name : "Please enter a name", 
        email : "Please enter a valid email address",
        phoneNumber : "Please enter a valid phone number",
        address1 : "Please enter address",
        address2 : "Please enter address",
        city : "Please enter a city",
        stateName : "Please enter a state",
        pincode : "Please enter a valid pincode",
    }


    let isChecked = false;

    useEffect(() => {
        console.log("gettingRole", constants.getRole())
        if (isChecked) return;
        isChecked = true;
        
        if(fields.stateName && fields.stateName.length > 0){
            fetchDataCitiesList(fields.stateName);
        }
        fetchFleetsList();
        
      }, []);

    // input_data = {
    //     "updated_at": now,
    //     "joining_date": datetime.datetime.strptime(str(request.data.get('joining_date')), "%d/%m/%Y"),
    //     "gender": str(request.data.get('gender')),
    //     "dob": str(request.data.get('dob')),
    //     "address": {
    //         "country": str(request.data.get('country')),
    //         "state": str(request.data.get('state')),
    //         "address_line1": str(request.data.get('address_line1')),
    //         "address_line2": str(request.data.get('address_line2')),
    //         "city": str(request.data.get('city')),
    //         "postal_code": str(request.data.get('postal_code'))
    //     },
    //     "location": str(request.data.get('city')),
    //     "city_id": int(request.data.get('city_id')),
    //     "employer": {
    //         "company_name": str(request.data.get('company_name')),
    //         "location": str(request.data.get('company_location')),
    //         "employee_id": str(request.data.get('employee_id'))
    //     },
    //     "service_center_id": int(request.data.get('service_center_id'))
    // }

    // console.log("idEditSB", id, currentTab, data, props.actionType);

    
    function handleFieldState(key, value) {
        switch (key) {
            case "test":
            case "company_logo":
            case "fleet_image":
            case "license":
            case "photo":
                break;
            case "stateName":
                setFields({...fields, [key] : value, city : ""});
                validateField(key, value);
                fetchDataCitiesList(value);
                break;
            case "email":
                setFields({...fields, [key] : value.trim().toLowerCase()});
                validateField(key, value.trim().toLowerCase());
                break;
            default:
                setFields({...fields, [key] : value});
                validateField(key, value);
                break;
        }
    }

    function validateField(key, value){
        switch (key) {
            case "email":
                if(!Utils.validateEmail(value)) {
                    console.log("erroring1", key);
                    error[key] = errors[key];setError(error);return false;
                }else{
                    delete error[key];setError(error);return true;
                }
                break;
            case "pincode":
                if(!Utils.validatePinCode(value)) {
                    console.log("erroring2", key);
                    error[key] = errors[key];setError(error);return false;
                }else{
                    delete error[key];setError(error);return true;
                }
                break;
            case "phoneNumber":
                if(!Utils.validatePhoneNumber(value)) {
                    console.log("erroring3", key);
                    error[key] = errors[key];setError(error);return false;
                }else{
                    delete error[key];setError(error);return true;
                }
                break;
            case "fleet_id":
                if(constants.getRole() != "fleet_superadmin"){
                    if(!value || value.length == 0) {
                        console.log("erroring", key);
                        error[key] = errors[key];setError(error);return false;
                    }else{
                        delete error[key];setError(error);return true;
                    }    
                }else{
                    return true;
                }
                break;
            case "company_logo":
            case "fleet_image":
            case "license":
            case "photo":
                return true;
                break;
            default:
                if(!value || value.length == 0) {
                    console.log("erroring", key);
                    error[key] = errors[key];setError(error);return false;
                }else{
                    delete error[key];setError(error);return true;
                }
                break;
        }
    }

    function validateAllFields(){
        let isValid = true;
        
        Object.keys(fields).forEach((key, index) => {
            console.log("validate", key);
            if(!validateField(key, fields[key])){
                console.log("invalid", key, fields[key]);
                isValid = false;
            }
        });
        return isValid;
    }


    function handleButtonClick(buttonType, data){
        if(buttonType == "save"){
            console.log("here");
            if (validateAllFields()){
                console.log("here1");
                let request_data = {
                    // fleet_id : constants.getFleetID(),
                    warehouse_name : fields.warehouse_name,
                    email : fields.email,
                    phone_number : fields.phoneNumber,
                    country : "India",
                    state : fields.stateName,
                    address_line1 : fields.address1,
                    address_line2 : fields.address2,
                    city : fields.city,
                    postal_code : fields.pincode,
                }
                if(state && state.data.id){
                    request_data["warehouse_id"] = state.data.id;
                }
                if(localStorage.getItem("oem_id")){
                    request_data["oem_id"] = localStorage.getItem("oem_id");
                }
                if (constants.getRole() != "fleet_superadmin"){
                    request_data["fleet_id"] = fields.fleet_id;
                }else{
                    request_data["fleet_id"] = constants.getFleetID();
                }
                // var fieldData = Object.assign({}, that.state.fieldData);
                // delete fieldData.image_file;
                setIsLoading(true);
                // let files = [fields.company_logo, fields.fleet_image];
                api.saveWarehouse(request_data).then(function (response) {console.log(response);setIsLoading(false);navigate("/dashboard/warehouses")}).catch(function (error) {console.log(error);}).finally(function () {});
            }
        }
    }

    return (<>
        {isLoading ? 
            <div className="loader spacer border-box flex_horizontal center_vertical center_horizontal"><Loader /></div> : 
            
        
        
        <div className="action full_width full_height border-box flex_vertical center_horizontal start_vertical">
            <div className="one full_width border-box flex_horizontal center_horizontal center_vertical">
                <div className="left border-box flex_horizontal center_horizontal center_vertical">
                    <div className="border-box flex_horizontal center_vertical center_horizontal shadow-box">
                        {props.actionType == "add" ? "" : props.data.warehouse_name}
                    </div>
                </div>
                <div className="right border-box flex_vertical start_horizontal end_vertical">
                    <div className="title weight600">{props.actionType == "add" ? "Add new Warehouse" : (props.data.warehouse_name)}</div>
                    <div style={{height : "10px"}}/>
                    <div className="subtitle weight300">{props.actionType == "add" ? "" : props.data.email}</div>
                    <div style={{height : "30px"}}/>
                </div>
            </div>
            <div className="two full_width border-box flex_horizontal center_horizontal start_vertical bgcolorGray200">
                <div className="inner full_width border-box flex_horizontal center_horizontal start_vertical">
                    <div className="left border-box flex_vertical start_horizontal start_vertical">
                        <div className="title weight600">Warehouse details</div>
                        <div className="subtitle colorGray500span">Update Warehouse details</div>
                    </div>
                    <div className="right border-box flex_vertical start_horizontal start_vertical">
                        <div className="inner full_width border-box flex_vertical start_horizontal start_vertical bgcolorSix50 shadow-box">
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="warehouse_name" title="Warehouse Name" value={fields.warehouse_name} size="small" mandatory={true}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.warehouse_name} />  
                                </div>
                                {constants.getRole() != "fleet_superadmin" ?
                                <>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="fleet_id" title="Fleet" value={fields.fleet_id}  mandatory={true}
                                        selectedValue={fields.fleet_id} onSetFieldState={handleFieldState} 
                                        type="select" size="small" errorDisplay="yes" errorText={error.fleet_id}
                                        choices={[...[{value:"", title:"Select a Fleet"}], ...fleetsList.map(o => ({value:o.id, title:o.name}))]
                                                .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
                                </div>
                                </> 
                                : <></>}
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="email" title="Email" value={fields.email} size="small" mandatory={true}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.email} />  
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="phoneNumber" title="Phone Number" value={fields.phoneNumber} size="small" mandatory={true}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.phoneNumber} />  
                                </div>
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="address1" title="Head Office Address Line 1" value={fields.address1} size="small" mandatory={true}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.address1} />  
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="address2" title="Address Line 2" value={fields.address2} size="small" mandatory={true}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.address2} />  
                                </div>
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="stateName" title="State" value={fields.stateName}  mandatory={true}
                                        selectedValue={fields.stateName} onSetFieldState={handleFieldState} 
                                        type="select" size="small" errorDisplay="yes" errorText={error.stateName}
                                        choices={[...[{value:"", title:"Select a state"}], ...utils.getStatesIndia().map(o => ({value:o, title:o}))]
                                                .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="city" title="City" value={fields.city}  mandatory={true}
                                        selectedValue={fields.city} onSetFieldState={handleFieldState} 
                                        type="select" size="small" errorDisplay="yes" errorText={error.city}
                                        choices={[...[{value:"", title:"Select a city"}], ...citiesList.filter((item)=> item.state.toLowerCase() == fields.stateName.toLowerCase()).map(o => ({value:o.name, title:o.name}))]
                                                .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="pincode" title="Pin Code" value={fields.pincode} size="small" mandatory={true}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.pincode} />  
                                </div>
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="spacer"></div>
                                <div className="fieldbuttonbox border-box">
                                    <button className="bttn small solid one" onClick={(e) => handleButtonClick("save")}>Save</button>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </div>
        }
        </>
    );

    function fetchDataCitiesList(stateName){
        if(stateName && stateName.length > 0){
            // setIsLoading(true);
            api.getCities(stateName).then(function (response) {console.log("responsedata", response.data.data); setCitiesList(response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
        }else{
            setCitiesList([]);
        }
    }

    function fetchFleetsList(){
        // setIsLoading(true);
        api.getFleets({offset : 0, limit : 200}).then(function (response) {console.log("responsedata", response.data.data); setFleetsList(response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
    }
}
