import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import utils from '../../utils';
import constants from '../../constants';
import { Row } from '../Row';
import './userdetails.css';
import { Rating1 } from '../../base/utils/rating1';
import { UserDetailsAbout } from './userdetailsabout';
import api from '../../api';
import { UserDetailsServiceHistory } from './userdetailsservicehistory';
import { Loader } from '../../base/utils/loader';
import { FleetDetailsAbout } from './fleetdetailsabout';
import { OemDetailsAbout } from './oemdetailsabout';

const tabData = [{key : "servicehistory", title : "Service History"}, {key : "about", title : "About"}];

export const OemDetails = (props) => {
    const navigate = useNavigate();
    // const [selectedTab, setSelectedTab] = useState(tabData[0]);
    const [isLoading, setIsLoading] = useState(true);
    const [serviceData, setServiceData] = useState([]);
    // const [warehouseData, setWarehouseData] = useState([]);

    let oem = props.data;
    let isChecked = false;

    // useEffect(() => {
    //     if (isChecked) return;
    //     isChecked = true;
        
    //     fetchData();
        
    //   }, []);

    // useEffect(() => {
    //     if(isChecked) return;
    //     isChecked = true;

    //     fetchData();
    // }, [props.data]);

    console.log("props.data", props.data);

    function handleButtonClick(buttonType, data){
        switch(buttonType) {
        //    case "tab":
        //       setSelectedTab(data);
        //       break;
            case "edit":
                navigate("/dashboard/action/oems/oems/edit/" + props.data.id, {state : {data : props.data}});
                break;
        }
    }

    // let tabs = tabData.length > 0 ? tabData.map((tab, index) => {
    //     return (
    //         <div 
    //             className={tab.key === selectedTab.key ? "tab active border-box flex_horizontal center_vertical center_horizontal cursor-pointer" : "tab border-box flex_horizontal center_vertical center_horizontal cursor-pointer"} 
    //             key={index} onClick={(e) => handleButtonClick("tab", tab)}>{tab.title}</div>
    //     );
    //   }) : <></>;
    
      return(
        <div className="userdetails full_width full_height border-box flex_vertical center_horizontal start_vertical">
            {oem ? 
            <>
            <div className="one full_width border-box flex_horizontal center_horizontal center_vertical">
                <div className="left border-box flex_horizontal center_horizontal center_vertical">
                    <div className="border-box flex_horizontal center_vertical center_horizontal shadow-box">
                        {utils.getInitialsFromName(oem.name)}
                    </div>
                </div>
                <div className="right border-box flex_vertical start_horizontal end_vertical">
                    <div className="full_width border-box flex_horizontal center_vertical space_between_horizontal">
                        <div className="title weight600">{oem.name}</div>
                        {constants.isAdmin() ? <>
                            <button className="bttn small solid one" onClick={(e) => handleButtonClick("edit")}>Edit</button>
                        </> : <></>}
                    </div>
                    <div style={{height : "30px"}}/>
                    <div className="subtitle weight300">{oem.location}</div>
                    {/* <div style={{height : "10px"}}/>
                    <div className="rating"><Rating1 value={props.data.rating ? props.data.rating : 0} /></div>
                    <div style={{height : "50px"}}/>
                    <div className="tabs border-box flex_horizontal center_vertical start_horizontal">
                        {tabs}
                    </div> */}
                </div>
            </div>
            <div className="two full_width border-box flex_horizontal center_horizontal center_vertical">
                <div className="left border-box flex_horizontal center_horizontal center_vertical">
                    
                </div>
                <div className="right border-box flex_vertical start_horizontal start_vertical">
                    {/* <OemDetailsAbout data={props.data} extraData={warehouseData} /> */}
                    {/* {
                        {
                            "servicehistory" : <UserDetailsServiceHistory data={props.data} extraData={serviceData} />,
                            "about" : <UserDetailsAbout data={props.data} extraData={aboutData} />
                        }[selectedTab.key]
                    } */}
                </div>
            </div>
            </>
            : <div className="loader spacer border-box flex_horizontal center_vertical center_horizontal"><Loader /></div>}
        </div>
      );


    //   function fetchData(){
    //     console.log("fetchData", props.data)
    //     if(fleet){
    //         api.getWarehouses({fleet_id : fleet.id}).then(function (response1) {
    //             console.log("response1", response1.data.data)
    //             setWarehouseData(response1.data.data);
    //             // api.getBMS({vehicle_id : vehicle.id, type : "recent"}).then(function (response2) {
    //             //     console.log("response2", response2.data.data)
    //             //     setAboutData(response2.data.data);
    //             // }).catch(function (error) {console.log(error);}).finally(function () {});
    //         }).catch(function (error) {console.log(error);}).finally(function () {});
    //     }
    //     }
}
