import './topbar.css';
import Topbarauth from './topbar_auth';
import Topbarunauth from './topbar_unauth';

export default function Topbar(props) {
    let content;
	if (props.type === "unauth") {
		content = <Topbarunauth />;
	}else if (props.type === "auth") {
        content = <Topbarauth />;
    }else{
		content = <></>;
	}
	return (
		<div className="topbarbase border-box">
			{content}
		</div>
	);
}
