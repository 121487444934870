import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import utils from '../../utils';
import constants from '../../constants';
import { Row } from '../Row';
import './userdetails.css';
import { Rating1 } from '../../base/utils/rating1';
import api from '../../api';
import { Loader } from '../../base/utils/loader';
import { ServiceDetailsServiceDetails } from './servicedetaillsservicedetails';
import { ServiceDetailsAbout } from './servicedetailsabout';
import { Dialog } from '../../base/utils/dialog';
import { ServiceDetailsB2BServiceDetails } from './servicedetaillsb2bservicedetails';
import { ServiceDetailsB2BAbout } from './servicedetailsb2babout';

const tabData = [{key : "servicehistory", title : "Details"}, {key : "about", title : "About"}];

export const ServiceDetailsB2B = (props) => {
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState(tabData[0]);
    const [isLoading, setIsLoading] = useState(true);
    // const [serviceData, setServiceData] = useState([]);
    // const [aboutData, setAboutData] = useState({});
    const [isDialogShowing, setIsDialogShowing] = useState(false);
    const [dialogContent, setDialogContent] = useState(<></>);
    const [serviceBuddy, setServiceBuddy] = useState([]);

    let vehicle = props.data.vehicles ? (props.data.vehicles.length == 0 ? null : props.data.vehicles[props.data.vehicles.length - 1]) : null;
    console.log("data_check", Object.keys(props.data).length !== 0);
    let isChecked = false;

    useEffect(() => {
        console.log("data_check1", Object.keys(props.data).length !== 0);
        if (Object.keys(props.data).length !== 0 && isChecked) return;
        isChecked = true;
        
        // fetchData();
        
      }, []);

    useEffect(() => {
        if(isChecked) return;
        isChecked = true;

        // fetchData();
    }, [props.data]);

    console.log("props.data", props.data);

    function handleButtonClick(buttonType, data){
        switch(buttonType) {
           case "tab":
              setSelectedTab(data);
              break;
            case "edit":
                navigate("/dashboard/action/customers/customers/edit/" + props.data.id, {state : {data : props.data}});
                break;
            case "assign_sb":
                console.log("active");
                setDialogContent( 
                    <Dialog title ="Assign Service Buddy"
                        text="Please choose a Service Buddy"
                        buttonText = "Assign"
                        buttonActionKey = "confirm_assign_sb" fields={true} choices={
                            [...[{value:"", title:"Select a service buddy"}], 
                                ...serviceBuddy.map(o => ({value:o.user_id, title:o && o.sb_users && o.sb_users.name ? o.sb_users.name :""}))]}
                        fieldTitle = "Service Buddy"
                        buttonClassNames = {props.data.active ? "solid three" : "solid one" }
                        handleDialogButtonClick={handleDialogButtonClick} />);
                    setIsDialogShowing(true);
                    break;
            case "delete":
                console.log("delete");
                setDialogContent( 
                    <Dialog title = "Delete Order" 
                        text="Are you certain that you want to delete the order?"
                        buttonText = "Delete"
                        buttonActionKey = "confirm_delete"
                        buttonClassNames = "solid three"
                        handleDialogButtonClick={handleDialogButtonClick} />);
                    setIsDialogShowing(true);
                console.log("delete1", isDialogShowing, dialogContent);
                    break;
        }
    }

    function handleDialogButtonClick(buttonType, data){
        switch(buttonType) {
            case "cancel":
                // setDialogContent(<></>);
                // setIsDialogShowing(false);
                break;
            case "confirm_assign_sb":
                console.log("confirm_assign_sb", data);
                // api.autoAssignBuddy({...popupConfig.data, service_buddy_id : parseInt(fields)}).then(function (response) {setIsLoading(false); closeClick()}).catch(function (error) {console.log(error);setIsLoading(false);})
                api.autoAssignBuddy({...props.data, service_buddy_id : parseInt(data.reason)}).then(function (response) {
                    if(response.data.status){
                        // navigate("/dashboard/customers");
                    }else{
                        console.log("error", response.data);
                    }
                }).catch(function (error) {console.log(error);}).finally(function () {});
                break;
            case "confirm_delete":
                console.log("confirm_delete");
                api.deleteOrder({order_id : props.data.order_id}).then(function (response) {
                    if(response.data.status){
                        if(constants.getRole() == "fleet_superadmin"){
                            navigate("/dashboard/services_b2b");
                        }else{
                            navigate("/dashboard/services");
                        }
                    }else{
                        console.log("error", response.data);
                    }
                }).catch(function (error) {console.log(error);}).finally(function () {});
                break;
        }
        setDialogContent(<></>);
        setIsDialogShowing(false);
    }

    let tabs = tabData.length > 0 ? tabData.map((tab, index) => {
        return (
            <div 
                className={tab.key === selectedTab.key ? "tab active border-box flex_horizontal center_vertical center_horizontal cursor-pointer" : "tab border-box flex_horizontal center_vertical center_horizontal cursor-pointer"} 
                key={index} onClick={(e) => handleButtonClick("tab", tab)}>{tab.title}</div>
        );
      }) : <></>;
    
      return(
        <div className="userdetails full_width full_height border-box flex_vertical center_horizontal start_vertical">
            {Object.keys(props.data).length !== 0 ? 
            <>
            <div className="one full_width border-box flex_horizontal center_horizontal center_vertical">
                <div className="left border-box flex_horizontal center_horizontal center_vertical">
                    <div className="border-box flex_horizontal center_vertical center_horizontal shadow-box">
                        {utils.getInitialsFromName(props.data.user.name)}
                    </div>
                </div>
                <div className="right border-box flex_vertical start_horizontal end_vertical">
                    <div className="full_width border-box flex_horizontal center_vertical space_between_horizontal">
                        <div className="title weight600">{props.data.order_id}</div>
                        <div className='spacer' />
                        {/* {constants.isAdmin() && props.data.status == "pending" && props.data.oem_info && !props.data.oem_info.auto_assign ? <>
                            <button className="bttn small solid one" onClick={(e) => handleButtonClick("assign_sb")}>Assign Service Buddy</button>
                        </> : <></>} */}
                        {constants.isAdmin() && props.data.status != "deleted" && props.data.status != "completed" ? <>
                            <button className="bttn small solid one" onClick={(e) => handleButtonClick("delete")}>Delete</button>
                        </> : <></>}
                    </div>
                    <div style={{height : "30px"}}/>
                    <div className="subtitle weight300">Rating : <span className="weight600">{props.data.rating ? props.data.rating : "NA"}</span></div>
                    <div style={{height : "10px"}}/>
                    <div className="rating"><Rating1 value={props.data.rating ? parseInt(props.data.rating) : 0} /></div>
                    <div style={{height : "50px"}}/>
                    <div className="tabs border-box flex_horizontal center_vertical start_horizontal">
                        {tabs}
                    </div>
                </div>
            </div>
            <div className="two full_width border-box flex_horizontal center_horizontal center_vertical">
                <div className="left border-box flex_horizontal center_horizontal center_vertical">
                    
                </div>
                <div className="right border-box flex_vertical start_horizontal start_vertical" style={{overflowY : "auto", overflowX : "hidden"}}>
                    {
                        {
                            "servicehistory" : <ServiceDetailsB2BServiceDetails data={props.data} />,
                            "about" : <ServiceDetailsB2BAbout data={props.data} />
                        }[selectedTab.key]
                    }
                </div>
            </div>
            {isDialogShowing ? dialogContent : <></>}
            </>
            : <div className="loader spacer border-box flex_horizontal center_vertical center_horizontal"><Loader /></div>}
        </div>
      );


    //   const fetchServiceBuddyList = (stateName) =>{
    //     const params = {'oem_id':popupConfig.data.oem_info.id};
    //     api.getServiceBuddies({...params, is_online : 1}).then(function (response) {setServiceBuddy(response.data.data);}).catch(function (error) {console.log(error);})
    // }
    // useEffect(() => {
    //     if(popupConfig.show && popupConfig.data && popupConfig.data.oem_info && popupConfig.data.oem_info.id && popupConfig.data.oem_info.id && serviceBuddy.length == 0)
    //         fetchServiceBuddyList();
    // },[popupConfig])

    // function fetchData(){
    //     if(props.data.oem_info.id){
    //         const params = {'oem_id':props.data.oem_info.id};
    //         api.getServiceBuddies({...params, is_online : 1}).then(function (response) {setServiceBuddy(response.data.data);}).catch(function (error) {console.log(error);})    
    //     }
    // }
}
