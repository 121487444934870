import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import utils from '../utils';
import constants from '../constants';
import { Row } from './Row';
import { AttendanceHeader } from './AttendanceHeader';

export const AttendanceData = (props) => {
    const navigate = useNavigate(); 

    console.log("daysinmonthrowData", props);

    let days = {};
    console.log("daysinmonthData", props.paramsFilter);
    if(props.paramsFilter.month && props.paramsFilter.year){
      days = constants.getAllDaysInMonth(parseInt(props.paramsFilter.month)-1, parseInt(props.paramsFilter.year));
    }else{
      let currentDate = new Date();
      days = constants.getAllDaysInMonth(currentDate.getMonth(), currentDate.getFullYear());
    }
    

    let rows = props.data.map((row) => 
        <div className="row border-box flex_horizontal center_vertical start_horizontal">
            <div className="column1 border-box flex_horizontal center_vertical start_horizontal weight600">
                {row.sb_users.name}
            </div>
            {Object.keys(days).map((index, key) => 
                <div className="column2 border-box flex_horizontal center_vertical center_horizontal weight600">
                    {row.attendance.filter(o => o.date == constants.get_DateStringX3(days[key])).length != 0 ? 
                        (row.attendance.filter(o => o.date == constants.get_DateStringX3(days[key]))[0].present != null ? (row.attendance.filter(o => o.date == constants.get_DateStringX3(days[key]))[0].present == null ? "-" : (row.attendance.filter(o => o.date == constants.get_DateStringX3(days[key]))[0].present == "NA" ? "NA" : (row.attendance.filter(o => o.date == constants.get_DateStringX3(days[key]))[0].present ? "P" : "A"))) : "-") : 
                        "-"}
                        {/* {((index+1) <= row.attendance.length)+"_"+row.attendance.length}
                        {/* {row.attendance.length} */}
                </div>)
            }
            {/* {row.attendance && row.attendance.map((obj) => 
              <div className="column2 border-box flex_horizontal center_vertical center_horizontal weight600">
                  hello
              </div>)
            } */}
        </div>
    );

      return(
        <>
        <AttendanceHeader paramsFilter={props.paramsFilter} />
        <div className="attendancedata border-box">
            {rows}
        </div>
        </>
      );
}
