import React from 'react';
import constants from './constants';

const DYNAMIC_SKU_TYPEs = [
    {value : "seat_cover", title : "Seat Covers"}
]

const SEAT_COVER_PRICING = [
    {
        styles : ["AL", "AM", "AN", "AO"], 
        compatibility_group_ids : [1003, 1001, 2190, 1074, 1040, 1163, 2219, 2191, 1016, 1091, 1077, 1082, 1084, 1137, 1035, 1081, 2001, 2184, 1017, 1000, 1011, 1022, 1201, 1020, 1027, 1028, 1042, 1080, 2189, 2188, 1026, 1031, 1036, 1159, 2010, 1959, 1632, 1675, 1793, 1633, 1707, 1015, 1249, 1078, 1043, 1075, 1083, 1218, 1023, 1006, 1143, 1244, 1954, 1982, 1164, 2186, 1007, 1044, 1963, 1634, 1882, 1853, 1922, 1712, 1885, 1720, 1722, 1770, 1869, 1158, 1308, 1296, 1876, 2078, 1324, 1988],
        mrp_mfg : 3174, mrp_retail : 3899
    },
    {
        styles : ["AL", "AM", "AN", "AO"], 
        compatibility_group_ids : [1165, 1048, 1221, 1038, 1037, 1277, 2002, 2215, 1469, 1320, 1990, 1991, 1889, 1916, 1798, 1761, 2220, 2059, 2061, 1646, 1258, 1156, 1152, 1119, 1973, 2080, 1635, 1748, 1842, 1643, 1843, 1838, 1895, 1867, 1865, 1638, 1714, 1826, 1650, 1135, 1131, 1124, 1245, 1247, 1970, 1731, 1732, 1801, 1344, 1701, 2082, 1144, 1284, 1305, 1299, 1309, 1592, 1122, 1754, 2203, 76173, 76174, 76175, 76176, 76178, 76179, 76180, 76181],
        mrp_mfg : 3584, mrp_retail : 4399
    },
    {
        styles : ["AL", "AM", "AN", "AO"], 
        compatibility_group_ids : [1162, 1004, 1032, 1204, 1051, 2187, 1009, 1029, 1073, 1002, 1012, 1236, 1079, 1041, 1018, 1024, 1008, 1180, 1295, 1965, 1962, 1359, 1303, 1419, 1307, 2009, 1964, 1961, 1993, 1395, 1716, 1744, 1809, 1709, 1688, 1715, 1631, 1676, 1118, 1121, 1196, 2210, 2193, 2192, 1297, 1136, 1126, 1179, 1198, 1155, 1983, 1127, 1984, 2204, 1776, 1799, 2086, 2077, 1705, 1837, 2065, 1802, 2056, 2057, 1233, 1958, 1971, 1298, 1966, 1046, 1014, 1216, 1045, 1047, 1620, 1730, 1800, 1702, 1789, 1769, 1684, 1446, 1342, 1345, 1375, 1397, 1358, 1416, 1466, 1539, 1611, 1555, 1613, 2141, 1265, 1214, 1213, 1125, 1101, 1314, 1956, 76162, 76163, 76164, 76165, 76166, 76167, 76168, 76169, 76170, 76171, 76172, 76205, 76206, 76207, 76209, 76210, 76211, 76212],
        mrp_mfg : 4147, mrp_retail : 5099
    },
    {
        styles : ["AL", "AM", "AN", "AO"], 
        compatibility_group_ids : [1660, 2007, 1401, 1569, 2016, 1380, 1366, 1264, 1192, 1957, 1737, 1736, 2073, 2072, 1323, 1486, 1319, 2008, 1132, 1157, 1289, 1133, 1981, 1197, 1134, 1287, 1195, 1749, 1656, 1648, 1691, 1723, 1868, 1813, 1752, 1812, 2075, 1671, 1742, 1781, 2096, 1782, 1783, 1704, 1740, 1836, 1822, 1672, 1765, 1627, 1919, 1893, 1741, 2095, 1817, 1914, 1907, 1890, 2216, 1067, 1066, 1315, 1071, 1120, 1110, 1805, 2091, 1777, 2064, 76134, 76135, 76136, 76137, 76138, 76139, 76140, 76141, 76142, 76143, 76144, 76145, 76146, 76147, 76148, 76149, 76150, 76151, 76152, 76153],
        mrp_mfg : 4563, mrp_retail : 5599
    },
    {
        styles : ["AL", "AM", "AN", "AO"], 
        compatibility_group_ids : [1200, 1010, 1019, 1183, 1199, 1168, 1076, 2185, 1005, 1871, 1657, 2070, 1795, 1690, 1816, 1654, 2069, 1367, 1447, 1476, 1373, 1339, 1561, 1607, 2205, 1430, 1336, 1379, 1475, 1513, 1790, 1792, 2066, 2031, 2052, 2196, 1738, 2083, 1231, 1112, 1212, 1246, 1960, 1123, 1154, 1641, 1424, 1429, 1474, 1376, 1346, 1428, 1370, 1778, 1619, 2090, 1164, 2186],
        mrp_mfg : 5670, mrp_retail : 6899
    },
    {
        styles : ["AI", "AJ", "AK"], 
        compatibility_group_ids : [1003, 1001, 2190, 1074, 1040, 1163, 2219, 2191, 1016, 1091, 1077, 1082, 1084, 1137, 1035, 1081, 2001, 2184, 1017, 1000, 1011, 1022, 1201, 1020, 1027, 1028, 1042, 1080, 2189, 2188, 1026, 1031, 1036, 1159, 2010, 1959, 1632, 1675, 1793, 1633, 1707, 1015, 1249, 1078, 1043, 1075, 1083, 1218, 1023, 1006, 1143, 1244, 1954, 1982, 1164, 2186, 1007, 1044, 1963, 1634, 1882, 1853, 1922, 1712, 1885, 1720, 1722, 1770, 1869, 1158, 1308, 1296, 1876, 2078, 1324, 1988],
        mrp_mfg : 4631, mrp_retail : 6569
    },
    {
        styles : ["AI", "AJ", "AK"], 
        compatibility_group_ids : [1165, 1048, 1221, 1038, 1037, 1277, 2002, 2215, 1469, 1320, 1990, 1991, 1889, 1916, 1798, 1761, 2220, 2059, 2061, 1646, 1258, 1156, 1152, 1119, 1973, 2080, 1635, 1748, 1842, 1643, 1843, 1838, 1895, 1867, 1865, 1638, 1714, 1826, 1650, 1135, 1131, 1124, 1245, 1247, 1970, 1731, 1732, 1801, 1344, 1701, 2082, 1144, 1284, 1305, 1299, 1309, 1592, 1122, 1754, 2203, 76173, 76174, 76175, 76176, 76178, 76179, 76180, 76181],
        mrp_mfg : 4940, mrp_retail : 6999
    },
    {
        styles : ["AI", "AJ", "AK"], 
        compatibility_group_ids : [1162, 1004, 1032, 1204, 1051, 2187, 1009, 1029, 1073, 1002, 1012, 1236, 1079, 1041, 1018, 1024, 1008, 1180, 1295, 1965, 1962, 1359, 1303, 1419, 1307, 2009, 1964, 1961, 1993, 1395, 1716, 1744, 1809, 1709, 1688, 1715, 1631, 1676, 1118, 1121, 1196, 2210, 2193, 2192, 1297, 1136, 1126, 1179, 1198, 1155, 1983, 1127, 1984, 2204, 1776, 1799, 2086, 2077, 1705, 1837, 2065, 1802, 2056, 2057, 1233, 1958, 1971, 1298, 1966, 1046, 1014, 1216, 1045, 1047, 1620, 1730, 1800, 1702, 1789, 1769, 1684, 1446, 1342, 1345, 1375, 1397, 1358, 1416, 1466, 1539, 1611, 1555, 1613, 2141, 1265, 1214, 1213, 1125, 1101, 1314, 1956, 76162, 76163, 76164, 76165, 76166, 76167, 76168, 76169, 76170, 76171, 76172, 76205, 76206, 76207, 76209, 76210, 76211, 76212],
        mrp_mfg : 5381, mrp_retail : 7699
    },
    {
        styles : ["AI", "AJ", "AK"], 
        compatibility_group_ids : [1660, 2007, 1401, 1569, 2016, 1380, 1366, 1264, 1192, 1957, 1737, 1736, 2073, 2072, 1323, 1486, 1319, 2008, 1132, 1157, 1289, 1133, 1981, 1197, 1134, 1287, 1195, 1749, 1656, 1648, 1691, 1723, 1868, 1813, 1752, 1812, 2075, 1671, 1742, 1781, 2096, 1782, 1783, 1704, 1740, 1836, 1822, 1672, 1765, 1627, 1919, 1893, 1741, 2095, 1817, 1914, 1907, 1890, 2216, 1067, 1066, 1315, 1071, 1120, 1110, 1805, 2091, 1777, 2064, 76134, 76135, 76136, 76137, 76138, 76139, 76140, 76141, 76142, 76143, 76144, 76145, 76146, 76147, 76148, 76149, 76150, 76151, 76152, 76153],
        mrp_mfg : 5805, mrp_retail : 8299
    },
    {
        styles : ["AI", "AJ", "AK"], 
        compatibility_group_ids : [1200, 1010, 1019, 1183, 1199, 1168, 1076, 2185, 1005, 1871, 1657, 2070, 1795, 1690, 1816, 1654, 2069, 1367, 1447, 1476, 1373, 1339, 1561, 1607, 2205, 1430, 1336, 1379, 1475, 1513, 1790, 1792, 2066, 2031, 2052, 2196, 1738, 2083, 1231, 1112, 1212, 1246, 1960, 1123, 1154, 1641, 1424, 1429, 1474, 1376, 1346, 1428, 1370, 1778, 1619, 2090, 1164, 2186],
        mrp_mfg : 7415, mrp_retail : 10499
    },
    {
        styles : ["AB", "AC", "AD", "AE", "AF", "AG", "AH"], 
        compatibility_group_ids : [1003, 1001, 2190, 1074, 1040, 1163, 2219, 2191, 1016, 1091, 1077, 1082, 1084, 1137, 1035, 1081, 2001, 2184, 1017, 1000, 1011, 1022, 1201, 1020, 1027, 1028, 1042, 1080, 2189, 2188, 1026, 1031, 1036, 1159, 2010, 1959, 1632, 1675, 1793, 1633, 1707, 1015, 1249, 1078, 1043, 1075, 1083, 1218, 1023, 1006, 1143, 1244, 1954, 1982, 1164, 2186, 1007, 1044, 1963, 1634, 1882, 1853, 1922, 1712, 1885, 1720, 1722, 1770, 1869, 1158, 1308, 1296, 1876, 2078, 1324, 1988],
        mrp_mfg : 3799, mrp_retail : 5399
    },
    {
        styles : ["AB", "AC", "AD", "AE", "AF", "AG", "AH"], 
        compatibility_group_ids : [1165, 1048, 1221, 1038, 1037, 1277, 2002, 2215, 1469, 1320, 1990, 1991, 1889, 1916, 1798, 1761, 2220, 2059, 2061, 1646, 1258, 1156, 1152, 1119, 1973, 2080, 1635, 1748, 1842, 1643, 1843, 1838, 1895, 1867, 1865, 1638, 1714, 1826, 1650, 1135, 1131, 1124, 1245, 1247, 1970, 1731, 1732, 1801, 1344, 1701, 2082, 1144, 1284, 1305, 1299, 1309, 1592, 1122, 1754, 2203, 76173, 76174, 76175, 76176, 76178, 76179, 76180, 76181],
        mrp_mfg : 4108, mrp_retail : 5899
    },
    {
        styles : ["AB", "AC", "AD", "AE", "AF", "AG", "AH"], 
        compatibility_group_ids : [1162, 1004, 1032, 1204, 1051, 2187, 1009, 1029, 1073, 1002, 1012, 1236, 1079, 1041, 1018, 1024, 1008, 1180, 1295, 1965, 1962, 1359, 1303, 1419, 1307, 2009, 1964, 1961, 1993, 1395, 1716, 1744, 1809, 1709, 1688, 1715, 1631, 1676, 1118, 1121, 1196, 2210, 2193, 2192, 1297, 1136, 1126, 1179, 1198, 1155, 1983, 1127, 1984, 2204, 1776, 1799, 2086, 2077, 1705, 1837, 2065, 1802, 2056, 2057, 1233, 1958, 1971, 1298, 1966, 1046, 1014, 1216, 1045, 1047, 1620, 1730, 1800, 1702, 1789, 1769, 1684, 1446, 1342, 1345, 1375, 1397, 1358, 1416, 1466, 1539, 1611, 1555, 1613, 2141, 1265, 1214, 1213, 1125, 1101, 1314, 1956, 76162, 76163, 76164, 76165, 76166, 76167, 76168, 76169, 76170, 76171, 76172, 76205, 76206, 76207, 76209, 76210, 76211, 76212],
        mrp_mfg : 4549, mrp_retail : 6449
    },
    {
        styles : ["AB", "AC", "AD", "AE", "AF", "AG", "AH"], 
        compatibility_group_ids : [1660, 2007, 1401, 1569, 2016, 1380, 1366, 1264, 1192, 1957, 1737, 1736, 2073, 2072, 1323, 1486, 1319, 2008, 1132, 1157, 1289, 1133, 1981, 1197, 1134, 1287, 1195, 1749, 1656, 1648, 1691, 1723, 1868, 1813, 1752, 1812, 2075, 1671, 1742, 1781, 2096, 1782, 1783, 1704, 1740, 1836, 1822, 1672, 1765, 1627, 1919, 1893, 1741, 2095, 1817, 1914, 1907, 1890, 2216, 1067, 1066, 1315, 1071, 1120, 1110, 1805, 2091, 1777, 2064, 76134, 76135, 76136, 76137, 76138, 76139, 76140, 76141, 76142, 76143, 76144, 76145, 76146, 76147, 76148, 76149, 76150, 76151, 76152, 76153],
        mrp_mfg : 4973, mrp_retail : 7049
    },
    {
        styles : ["AB", "AC", "AD", "AE", "AF", "AG", "AH"], 
        compatibility_group_ids : [1200, 1010, 1019, 1183, 1199, 1168, 1076, 2185, 1005, 1871, 1657, 2070, 1795, 1690, 1816, 1654, 2069, 1367, 1447, 1476, 1373, 1339, 1561, 1607, 2205, 1430, 1336, 1379, 1475, 1513, 1790, 1792, 2066, 2031, 2052, 2196, 1738, 2083, 1231, 1112, 1212, 1246, 1960, 1123, 1154, 1641, 1424, 1429, 1474, 1376, 1346, 1428, 1370, 1778, 1619, 2090, 1164, 2186],
        mrp_mfg : 6583, mrp_retail : 9349
    },
];

const SEAT_COVER_STYLES = [
    {code : "AA", title : "None"},
    {code : "AB", title : "Fusion_Nevada"},
    {code : "AC", title : "Fusion_Racer Pro"},
    {code : "AD", title : "Fusion_Rodeo"},
    {code : "AE", title : "Fusion_Sportster"},
    {code : "AF", title : "Fusion_Street Racer"},
    {code : "AG", title : "Fusion_Adonis"},
    {code : "AH", title : "Fusion_Os"},
    {code : "AI", title : "Blyss_Paradise"},
    {code : "AJ", title : "Blyss_Solace"},
    {code : "AK", title : "Blyss_Os"},
    {code : "AL", title : "Prima_Bandit"},
    {code : "AM", title : "Prima_Maze"},
    {code : "AN", title : "Prima_Trails"},
    {code : "AO", title : "Prima_Os"},
]

const SEAT_COVER_COLORS = [
    {code_major : "Z", code_minor : "0", code_t1 : "Z", code_t2 : "0", is_major : true, is_minor : true, is_t1 : true, is_t2 : true, title : "NONE"},
    {code_major : "A", code_minor : "1", code_t1 : "A", code_t2 : "1", is_major : true, is_minor : true, is_t1 : true, is_t2 : true, title : "A TAN"},
    {code_major : "B", code_minor : "2", code_t1 : "B", code_t2 : "2", is_major : true, is_minor : true, is_t1 : true, is_t2 : true, title : "B RED"},
    {code_major : "C", code_minor : "3", code_t1 : "C", code_t2 : "3", is_major : true, is_minor : true, is_t1 : true, is_t2 : true, title : "B SCOTCH"},
    {code_major : "D", code_minor : "4", code_t1 : "D", code_t2 : "4", is_major : true, is_minor : true, is_t1 : true, is_t2 : true, title : "BLACK"},
    {code_major : "E", code_minor : "5", code_t1 : "E", code_t2 : "5", is_major : true, is_minor : true, is_t1 : true, is_t2 : true, title : "BLUE"},
    {code_major : "F", code_minor : "6", code_t1 : "F", code_t2 : "6", is_major : true, is_minor : true, is_t1 : true, is_t2 : true, title : "CHOCO"},
    {code_major : "G", code_minor : "7", code_t1 : "G", code_t2 : "7", is_major : true, is_minor : true, is_t1 : true, is_t2 : true, title : "COFFEE"},
    {code_major : "H", code_minor : "8", code_t1 : "H", code_t2 : "8", is_major : true, is_minor : true, is_t1 : true, is_t2 : true, title : "D TAN"},
    {code_major : "I", code_minor : "9", code_t1 : "I", code_t2 : "9", is_major : true, is_minor : true, is_t1 : true, is_t2 : true, title : "H BEIGE"},
    {code_major : "J", code_minor : "10", code_t1 : "J", code_t2 : "10", is_major : true, is_minor : true, is_t1 : true, is_t2 : true, title : "MAROON"},
    {code_major : "K", code_minor : "11", code_t1 : "K", code_t2 : "11", is_major : true, is_minor : true, is_t1 : true, is_t2 : true, title : "N GRAY"},
    {code_major : "L", code_minor : "12", code_t1 : "L", code_t2 : "12", is_major : true, is_minor : true, is_t1 : true, is_t2 : true, title : "R BEIGE"},
    {code_major : "M", code_minor : "13", code_t1 : "M", code_t2 : "13", is_major : true, is_minor : true, is_t1 : true, is_t2 : true, title : "SILVER"},
    {code_major : "N", code_minor : "14", code_t1 : "N", code_t2 : "14", is_major : true, is_minor : true, is_t1 : true, is_t2 : true, title : "X TAN"},

    {code_major : "O", code_minor : "15", code_t1 : "O", code_t2 : "15", is_major : true, is_minor : true, is_t1 : true, is_t2 : true, title : "C GREY"},
    {code_major : "P", code_minor : "16", code_t1 : "P", code_t2 : "16", is_major : true, is_minor : true, is_t1 : false, is_t2 : false, title : "C GREY PERFO"},
    {code_major : "Q", code_minor : "17", code_t1 : "Q", code_t2 : "17", is_major : true, is_minor : true, is_t1 : true, is_t2 : true, title : "BLK PERFO"},
    {code_major : "R", code_minor : "18", code_t1 : "R", code_t2 : "18", is_major : false, is_minor : false, is_t1 : true, is_t2 : true, title : "BLK STAR"},
    {code_major : "S", code_minor : "19", code_t1 : "S", code_t2 : "19", is_major : true, is_minor : true, is_t1 : false, is_t2 : false, title : "BLK STAR PERFO"},
    {code_major : "T", code_minor : "20", code_t1 : "T", code_t2 : "20", is_major : true, is_minor : true, is_t1 : false, is_t2 : false, title : "B SCOTCH PERFO"},
    {code_major : "U", code_minor : "21", code_t1 : "U", code_t2 : "21", is_major : true, is_minor : true, is_t1 : true, is_t2 : true, title : "B BLUE"},
    {code_major : "V", code_minor : "22", code_t1 : "V", code_t2 : "22", is_major : true, is_minor : true, is_t1 : false, is_t2 : false, title : "N GREY PERFO"},
    {code_major : "W", code_minor : "23", code_t1 : "W", code_t2 : "23", is_major : true, is_minor : true, is_t1 : true, is_t2 : true, title : "RED"},
]

const SEAT_COVER_ARMRESTS = [
    {front : "yes", rear : "yes", code : "A"},
    {front : "yes", rear : "no", code : "B"},
    {front : "no", rear : "yes", code : "C"},
    {front : "no", rear : "no", code : "D"},
]

const SEAT_COVER_HEADRESTS = [
    {front : "IHR", rear : "IHR", code : "A"},
    {front : "IHR", rear : "NIHR", code : "B"},
    {front : "NIHR", rear : "IHR", code : "C"},
    {front : "NIHR", rear : "NIHR", code : "D"},
]

const SEAT_COVER_ATTRS = [
    {split_rear : "DB", airbags : "yes", code : "A"},
    {split_rear : "DB", airbags : "no", code : "B"},
    {split_rear : "SB", airbags : "yes", code : "C"},
    {split_rear : "SB", airbags : "no", code : "D"},
]

const NS_UC_STATUSES_ORDER = {
    "cancelled" : 11, 
    "pending" : 1, 
    "picked" : 2,
    "packed" : 3, 
    "rtd" : 4, 
    "manifested" : 5, 
    "dispatched" : 6, 
    "shipment_pickup_complete" : 7, 
    "out_for_delivery" : 8, 
    "in_transit" : 9, 
    "delivered" : 10, 
    "returned" : 11
}

const NS_UC_STATUSES = {
    "cancelled" : "Cancelled", 
    "pending" : "Processing", 
    "picked" : "Picked",
    "packed" : "Packed", 
    "rtd" : "Ready to dispatch", 
    "manifested" : "Manifested", 
    "dispatched" : "Dispatched", 
    "shipment_pickup_complete" : "Shipment picked up", 
    "out_for_delivery" : "Out for delivery", 
    "in_transit" : "In transit", 
    "delivered" : "Delivered", 
    "returned" : "Returned"
}

const faqs = [
    {question : "Can I make a Non-Tax invoice?", answer : "Yes, absolutely. You can select the kind of invoice you wish to generate."},
    {question : "Can I discount the MRP of an item at the time of billing?", answer : "Yes. You can enter discount value for each item separately."},
    {question : "Can I add additional charges and additional discounts at the time of the billing?", answer : "Yes. You can enter a additional charges and extar discounts over the invoice value."},
    {question : "Can I edit the MRP of an item at the time of billing?", answer : "Yes. You can enter a new value for the MRP of each item while billing."},
    // {question : "Can I record a payment by cheque against an invoice?", answer : "Yes, absolutely. You can select the kind of invoice you wish to generate."},
    {question : "Can I record multiple payments against an invoice?", answer : "Yes. You can record multiple payments against the invoice as long as there is a balance payable."},
    {question : "Can I file a sales return?", answer : "Yes, absolutely. You can file a sales return for any items in the invoice."},
    {question : "Does my inventory get adjusted at the time of billing?", answer : "Yes. The invoice quantity for each item is automatically deducted from the inventory."},
    {question : "Can I bulk-update my catalogue?", answer : "Yes, absolutely. You can upload your catalogue in Excel/CSV format as well as edit your catalogue by items."},
    {question : "Can I bulk-upload inventory?", answer : "Yes. You can upload your inventory in Excel/CSV format as well as edit the inventory by items."},
    {question : "Are the details of my customers saved?", answer : "Yes. Every time you generate an invoice, the details of the party being billed are saved."},
]

const landing_data = [
    {
        'title' : "Inventory Management", 
        'subtitles' : [
            "Manage your catalogue with a single click", 
            "Track your inventory real-time", 
            "Download real-time reports"
        ],
        'image' : "landing_two_inventory.png"
    },
    {
        'title' : "Invoice & Billing", 
        'subtitles' : [
            "Create Tax & Non-Tax invoices", 
            "Real-time management of sales", 
            "Automatic adjustment of inventory post invoicing"
        ],
        'image' : "landing_two_invoicing.png"
    },
    {
        'title' : "Order Management", 
        'subtitles' : [
            "Real-time record of all the orders", 
            "Eliminate the need to go through books repeatedly"
        ],
        'image' : "landing_two_orders.png"
    },
    // {
    //     'title' : "Dropshipping", 
    //     'subtitles' : [
    //         "Coming soon!"
    //     ]
    // },
];

const STATES_INDIA = ["ANDAMAN AND NICOBAR ISLANDS",
"ANDHRA PRADESH",
"ARUNACHAL PRADESH",
"ASSAM",
"BIHAR",
"CHANDIGARH",
"CHATTISGARH",
"DADRA AND NAGAR HAVELI AND DAMAN AND DIU",
"DELHI",
"GOA",
"GUJARAT",
"HARYANA",
"HIMACHAL PRADESH",
"JAMMU AND KASHMIR",
"JHARKHAND",
"KARNATAKA",
"KERALA",
"LADAKH",
"LAKSHADWEEP",
"MADHYA PRADESH",
"MAHARASHTRA",
"MANIPUR",
"MEGHALAYA",
"MIZORAM",
"NAGALAND",
"ODISHA",
"PUDUCHERRY",
"PUNJAB",
"RAJASTHAN",
"SIKKIM",
"TAMIL NADU",
"TELANGANA",
"TRIPURA",
"UTTAR PRADESH",
"UTTARAKHAND",
"WEST BENGAL"];

const FLEET_CATEGORIES = ["2Wheeler",
"3Wheeler"];

const FINAL_STATUS1 = "boxed";
const FINAL_STATUS1s = ["boxed", "manifested", "dispatched", "delivered", "return_initiated"]
const FINAL_STATUS1s_FOR_RETURN = ["boxed", "manifested", "dispatched", "delivered"]
const FINAL_STATUS1s_FOR_RETURN1 = ["boxed", "manifested", "dispatched", "delivered", "return_initiated", "dpsp_rto"]
const FINAL_STATUSES_ES = ["boxed", "manifested", "dispatched", "delivered", "return_initiated"]
const NON_CANCELLABLE_STATUS = ["boxed", "manifested", "dispatched", "delivered", "cancelled", "rto", "dpsp_crto", "return_initiated", "returned", "picklist_generated","dpsp_boxed","dpsp_manifested"]
const NON_CANCELLABLE_STATUS_ES = ["boxed", "manifested", "dispatched", "delivered", "cancelled", "rto", "return_initiated", "returned", "picklist_generated", "bounced"]

const years = [2020, 2021, 2022];
const months = [{number : 1, title : "January", titleShort : "Jan"},
                {number : 2, title : "February", titleShort : "Feb"},
                {number : 3, title : "March", titleShort : "Mar"},
                {number : 4, title : "April", titleShort : "Apr"},
                {number : 5, title : "May", titleShort : "May"},
                {number : 6, title : "June", titleShort : "Jun"},
                {number : 7, title : "July", titleShort : "Jul"},
                {number : 8, title : "August", titleShort : "Aug"},
                {number : 9, title : "September", titleShort : "Sep"},
                {number : 10, title : "October", titleShort : "Oct"},
                {number : 11, title : "November", titleShort : "Nov"},
                {number : 12, title : "December", titleShort : "Dec"},
                ];

const user_roles = {
    sales: {title : "Sales Executive"},
    procurement: {title : "Procurement Executive"},
    procurement_admin: {title : "Procurement Admin"},
    ops: {title : "Operations Executive"},
    acc_ops: {title : "Accessories Operations Executive"},
    warehouse_manager: {title : "Warehouse Manager"},
    warehouse_inbound: {title : "Warehouse Inbound Executive"},
    warehouse_outbound: {title : "Warehouse Outbound Executive"},
    finance: {title : "Finance Executive"},
    admin: {title : "Admin"},
    ops_admin: {title : "Operations Admin"},
    acc_ops_admin: {title : "Accessories Operations Admin"},
};



// const id_patterns = {
//     sales_orders : {patterns : ["SO-XXXXXXXX-XXXXX"], separator : "-", keys : {startsWith : "SO"}, separator_indices : [2, 11]},
//     back_orders : {patterns : ["SO-XXXXXXXX-XXXXX"], separator : "-", keys : {startsWith : "SO"}, separator_indices : [2, 11]},
//     forward_orders : {patterns : ["SO-XXXXXXXX-XXXXX"], separator : "-", keys : {startsWith : "SO"}, separator_indices : [2, 11]},
//     purchos : {patterns : ["GMPO/XXXXXXXX/XXXXX/XXXX/XX"], separator : "/", keys : {startsWith : "GMPO"}, separator_indices : [4, 13, 19, 24]},
//     rfqs : {patterns : ["GM_RFQ_XXXXXXXXX"], separator : "_", keys : {startsWith : "GMRFQ"}, separator_indices : [2, 6]},
//     picklists : {patterns : ["GMPL-XXXXXXXX-XXXXX"], separator : "-", keys : {startsWith : "GMPL"}, separator_indices : [4, 13]},
//     grns : {patterns : ["GMGRN/XXXXXXXX/XXXXX/XXXX/XX"], separator : "/", keys : {startsWith : "GMGRN"}, separator_indices : [5, 14]},
//     wh_transfer_orders : {patterns : ["WHTO-XXXXXXXX-XXXXX"], separator : "-", keys : {startsWith : "WHTO"}, separator_indices : [4, 13]},
//     vendor_payment_requests : {patterns : ["GM-VPR-XXXXXXXX-XXXXX"], separator : "-", keys : {startsWith : "GMVPR"}, separator_indices : [2, 6, 15]},
// }
const id_patterns = {
    sales_orders : [{separator : "-", keys : {startsWith : "SO"}, separator_indices : [2, 11]}],
    sales_orders_acc : [{separator : "-", keys : {startsWith : "SO"}, separator_indices : [2, 11]}],
    back_orders : [{separator : "-", keys : {startsWith : "SO"}, separator_indices : [2, 11]}],
    forward_orders : [{separator : "-", keys : {startsWith : "SO"}, separator_indices : [2, 11]}],
    purchos : [{separator : "/", keys : {startsWith : "GMPO"}, separator_indices : [4, 13, 19, 24]},
                {separator : "_", keys : {startsWith : "GMRFQ"}, separator_indices : [2, 6]}],
    manufos_acc : [{separator : "/", keys : {startsWith : "GMMO/ACC"}, separator_indices : [8, 17, 23, 28]}],
    rfqs : [{separator : "_", keys : {startsWith : "GMRFQ"}, separator_indices : [2, 6]}],
    picklists : [{separator : "-", keys : {startsWith : "GMPL"}, separator_indices : [4, 13]}],
    grns : [{separator : "/", keys : {startsWith : "GMGRN"}, separator_indices : [5, 14]}],
    wh_transfer_orders : [{separator : "-", keys : {startsWith : "WHTO"}, separator_indices : [4, 13]}],
    vendor_payment_requests : [{separator : "-", keys : {startsWith : "GMVPR"}, separator_indices : [2, 6, 15]}],
}

const so_order_statuses = [
    {"status" : "rfq_generated", "title" : "RFQ Generated"}, 
    {"status" : "rfq_cancelled", "title" : "RFQ Cancelled"}, 
    {"status" : "created", "title" : "Created"},
    {"status" : "scanned", "title" : "Scanned"},
    {"status" : "transferred_to_warehouse", "title" : "TTP/TTW"},
    {"status" : "transferred_to_warehouse_manually", "title" : "TTW Manually"},
    {"status" : "po_created", "title" : "PO Created"},
    {"status" : "asn_created", "title" : "ASN Created"},
    {"status" : "grn_created", "title" : "GRN Created"},
    {"status" : "grn_rejected", "title" : "GRN Rejected"},
    {"status" : "cancelled", "title" : "Cancelled"},
    {"status" : "order_cancelled", "title" : "Order Cancelled"},
    {"status" : "cancel_order", "title" : "Order Cancelled"},
    {"status" : "putaway", "title" : "Put Away"},
    {"status" : "picklist_generated", "title" : "Picklist Generated"},
    {"status" : "generate_picklist", "title" : "Generate Picklist"},
    {"status" : "to_created", "title" : "TO Created"},
    {"status" : "tax_bill", "title" : "Tax Invoice"},
    {"status" : "advance", "title" : "Advance"},
    {"status" : "approved", "title" : "Approved"},
    {"status" : "rejected", "title" : "Rejected"},
    {"status" : "partially_paid", "title" : "Partially Paid"},
    {"status" : "paid", "title" : "Paid"},
    {"status" : "unpaid", "title" : "Unpaid"},
    {"status" : "fully_paid", "title" : "Fully Paid"},
    {"status" : "overpaid", "title" : "Overpaid"},
    {"status" : "payment_processed", "title" : "Payment Processed"},
    {"status" : "processed", "title" : "Processed"},
    {"status" : "reversed", "title" : "Reversed"},
    {"status" : "payment_reversed", "title" : "Payment Reversed"},
    {"status" : "reversal", "title" : "Reversal"},
    {"status" : "processing", "title" : "Processing"},
    {"status" : "payment_initiated", "title" : "Payment Initiated"},
    {"status" : "tax_invoice_uploaded", "title" : "Tax Invoice Uploaded"},
    {"status" : "po_invoice_uploaded", "title" : "PO Invoice Uploaded"},
    {"status" : "mo_invoice_uploaded", "title" : "MO Invoice Uploaded"},
    {"status" : "po_received", "title" : "PO Received"},
    {"status" : "boxed", "title" : "Boxed"},
    {"status" : "dpsp_boxed", "title" : "DPSP Boxed"},
    {"status" : "dpsp_manifested", "title" : "DPSP Manifested"},
    {"status" : "rto", "title" : "RTO"},
    {"status" : "dpsp_rto", "title" : "DPSP RTO"},
    {"status" : "dpsp_crto", "title" : "DPSP cRTO"},
    {"status" : "returned", "title" : "Returned"},
    {"status" : "rejected", "title" : "Rejected"},
    {"status" : "short", "title" : "Short"},
    {"status" : "returned_rejected", "title" : "Returned Rejected"},
    {"status" : "returned_short", "title" : "Returned Short"},
    {"status" : "boxing_pna", "title" : "Boxing PNA"},
    {"status" : "closed", "title" : "Closed"},
    {"status" : "manifested", "title" : "Manifested"},
    {"status" : "dispatched", "title" : "Dispatched"},
    {"status" : "delivered", "title" : "Delivered"},
    {"status" : "in_progress", "title" : "In Progress"},
    {"status" : "error", "title" : "Error"},
    {"status" : "bin_transfer", "title" : "Bin Transfer"},
    {"status" : "rfq_generate", "title" : "RFQ Generate"},
    {"status" : "rfq2_generate", "title" : "RFQ2 Generate"},
    {"status" : "putaway_by_excel_create", "title" : "PutAway By Excel/CSV Create"},
    {"status" : "po_by_excel_create", "title" : "PO By Excel/CSV Create"},
    {"status" : "po_create", "title" : "PO Create"},
    {"status" : "po_by_products_create", "title" : "Global PO Create"},
    {"status" : "inventory_by_excel_upload", "title" : "Inventory By Excel/CSV Upload"},
    {"status" : "catalogue_by_excel_upload", "title" : "Catalogue By Excel/CSV Upload"},
    {"status" : "distributor_order_by_excel_create", "title" : "Distributor Order By Excel/CSV Upload"},
    {"status" : "bulk_sales_order_transfer_create", "title" : "Bulk Sales Order Transfer"},
    {"status" : "sales_order_transfer_create", "title" : "Sales Order Transfer"},
    {"status" : "sales_order_edit_for_alt_parts", "title" : "Sales Order Edit for Alt Parts"},
    {"status" : "sales_order_transfer_to_warehouse_manually", "title" : "Force Freeze"},
    {"status" : "rfq_cancel_by_items", "title" : "RFQ Cancel By Items"},
    {"status" : "tax_invoice", "title" : "Tax Invoice"},
    {"status" : "non_tax_invoice", "title" : "Non-Tax Invoice"},
    {"status" : "dpsp_sales_order_transfer", "title" : "Transfer Sales Orders for Dropshipping"},
    {"status" : "so_created", "title" : "SO Created"},
    {"status" : "client_dropship", "title" : "Client Dropship"},
    {"status" : "client_dropship_acc_custom", "title" : "Acc Custom Client Dropship"},
    {"status" : "return_initiated", "title" : "Return Initiated"},
    {"status" : "return_dispatched", "title" : "Return Dispatched"},
];

const item_statuses = [
    {"status" : "rfq_generated", "title" : "RFQ Generated"}, 
    {"status" : "so_created", "title" : "SO Created"},
    {"status" : "po_created", "title" : "PO Created"},
    {"status" : "asn_created", "title" : "ASN Created"},
    {"status" : "grn_created", "title" : "GRN Created"},
    {"status" : "grn_rejected", "title" : "GRN Rejected"},
    {"status" : "back", "title" : "In Back Order"},
    {"status" : "forward", "title" : "In Forward Order"},
    {"status" : "ttdpsp", "title" : "Transferred to Dropshipping"},
    {"status" : "putaway", "title" : "Put Away"},
    {"status" : "to_created", "title" : "TO Created"},
    {"status" : "boxed", "title" : "Boxed"},
    {"status" : "dpsp_boxed", "title" : "DPSP Boxed"},
    {"status" : "dpsp_manifested", "title" : "DPSP Manifested"},
    {"status" : "rto", "title" : "RTO"},
    {"status" : "dpsp_rto", "title" : "DPSP RTO"},
    {"status" : "dpsp_crto", "title" : "DPSP cRTO"},
    {"status" : "returned", "title" : "Returned"},
    {"status" : "rejected", "title" : "Rejected"},
    {"status" : "short", "title" : "Short"},
    {"status" : "returned_rejected", "title" : "Returned Rejected"},
    {"status" : "returned_short", "title" : "Returned Short"},
    {"status" : "picklist_generated", "title" : "Picklist Generated"},
    {"status" : "manifested", "title" : "Manifested"},
    {"status" : "dispatched", "title" : "Dispatched"},
    {"status" : "delivered", "title" : "Delivered"},
    {"status" : "undelivered", "title" : "Undelivered"},
    {"status" : "order_cancelled", "title" : "Order Cancelled"},
    {"status" : "cancel_order", "title" : "Order Cancelled"},
    {"status" : "cancelled", "title" : "Cancelled"},
    {"status" : "in_transit", "title" : "In Transit"},
    {"status" : "failed_attempt", "title" : "Failed Attempt"},
    {"status" : "rto_initiated", "title" : "RTO Initiated"},
    {"status" : "rto_in_transit", "title" : "RTO In Transit"},
    {"status" : "rto_complete", "title" : "RTO Complete"},
    {"status" : "lost", "title" : "Lost"},
    {"status" : "bounced", "title" : "Bounced"},
    {"status" : "return_initiated", "title" : "Return Initiated"},
    {"status" : "return_dispatched", "title" : "Return Dispatched"},
];

const tabGroupData = [
    {tag :"global", title : "Global"}, 
    {tag :"finance", title : "Finance"}, 
    {tag :"warehouse", title : "Warehouse"}, 
    {tag :"orders", title : "Orders"}, 
    {tag : "procurement", title : "Procurement"}, 
    {tag : "outbound", title : "OutBound"}, 
    {tag : "inbound", title : "InBound"}, 
    {tag : "dropshipping", title : "Dropshipping"}, 
];

const tabData = [
    {tag:"dashboard" , title : "Dashboard", middlePanelTitle : "Dashboard", type : "2", "group" : "global",
        roles : ["sales", "procurement", "procurement_admin", "warehouse_manager", "warehouse_inbound", "warehouse_outbound", "warehouse_admin", "admin", "finance", "admin", "ops_admin", "ops", "acc_ops", "acc_ops_admin"]}, 
    {tag:"periodic_tasks" , title : "Periodic Tasks", middlePanelTitle : "Periodic Tasks", type : "3", "group" : "global", 
        roles : ["admin", "warehouse_manager", "procurement_admin", "ops_admin", "acc_ops_admin"]}, 
    {tag:"actionables" , report : true, title : "Actionables", middlePanelTitle : "Actionables", type : "3", "group" : "global", 
        roles : ["admin", "warehouse_outbound"]}, 
    // {tag:"actionables_gen" , title : "Gen Actionables", middlePanelTitle : "Generation Actionables", tabTitle : "GEN Actnbls", type : "3", "group" : "global", 
    //     roles : ["admin"]}, 
    {tag:"grns_incomplete" , title : "Ongoing GRNs", tabTitle : "OnG GRNs", middlePanelTitle : "Ongoing GRN List", type : "3", "group" : "global", 
        roles : ["admin", "warehouse_inbound"]}, 
    {tag:"putaways_incomplete" , title : "Ongoing PutAways", tabTitle : "OnG PutAways", middlePanelTitle : "Ongoing PutAway List", type : "3", "group" : "global", 
        roles : ["admin", "warehouse_inbound", "warehouse_manager"]}, 
    {tag:"putaways_incomplete_by_sales_return" , title : "Ongng PutAways By Sales Return", tabTitle : "OnG PAs By SR", middlePanelTitle : "Ongoing PutAway By Sales Return List", type : "3", "group" : "global", 
        roles : ["admin", "warehouse_inbound", "warehouse_manager"]}, 
    {tag:"boxings_incomplete" , title : "Ongoing Boxings", tabTitle : "OnG Boxings", middlePanelTitle : "Ongoing Boxing List", type : "3", "group" : "global", 
        roles : ["admin", "warehouse_outbound"]}, 
    {tag:"warehouses" , title : "Warehouses", middlePanelTitle : "Warehouses", type : "3", "group" : "warehouse", 
        roles : ["admin", "warehouse_manager"]}, 
    {tag:"inventory" , report : true, title : "Inventory", middlePanelTitle : "Inventory", type : "3", "group" : "warehouse", 
        roles : ["admin", "warehouse_manager"]}, 
    {tag:"inventory_history" , title : "Inventory History", middlePanelTitle : "Inventory History", tabTitle : "INVY History", type : "3", "group" : "warehouse", 
        roles : ["admin", "warehouse_manager"]}, 
    {tag:"wh_layout" , title : "Warehouse Layout", middlePanelTitle : "WH Layout", tabTitle : "WH Layout", type : "3", "group" : "warehouse", 
        roles : ["admin", "warehouse_manager"]}, 
    {tag:"wh_transfer_orders" , title : "Inventory Transfers", middlePanelTitle : "Warehouse Inventory Transfers", tabTitle : "INVY Transfers", type : "3", "group" : "warehouse", 
        roles : ["admin", "warehouse_manager"]}, 
    {tag:"generate_bulk_sales_order_transfer" , title : "Bulk Sales Order Transfer", tabTitle : "Bulk SO Trnsfr", middlePanelTitle : "Bulk Sales Order Transfer Between Warehouses", type : "3", "group" : "warehouse", 
        roles : ["admin", "warehouse_manager"]}, 
    {tag:"orders" , title : "Orders", middlePanelTitle : "Order List", type : "3", "group" : "orders", 
        roles : ["admin", "procurement_admin", "warehouse_inbound"]}, 
    {tag:"sales_orders" , report : true, title : "Sales Orders", middlePanelTitle : "Sales Order List", type : "3", "group" : "orders", 
        roles : ["admin", "procurement_admin", "warehouse_inbound", "warehouse_outbound", "warehouse_manager"]}, 
    {tag:"back_orders" , report : true, title : "Back Orders", middlePanelTitle : "Back Order List", type : "3", "group" : "orders", 
        roles : ["admin", "procurement_admin"]}, 
    {tag:"forward_orders" , report : true, title : "Forward Orders", middlePanelTitle : "Forward Order List", tabTitle : "Fwd Orders", type : "3", "group" : "orders", 
        roles : ["admin", "procurement_admin", "warehouse_inbound"]}, 
    {tag:"orders_eg" , title : "EG Orders", middlePanelTitle : "EasyGarage Order List", type : "3", "group" : "orders", 
        roles : ["admin"]},
    {tag:"orders_distributor" , title : "Dstrbtr Orders", middlePanelTitle : "Distributor Order List", type : "3", "group" : "orders", 
        roles : ["admin"]},
    {tag:"orders_acc" , title : "Acc Orders", middlePanelTitle : "Accessories Order List", type : "3", "group" : "orders", 
        roles : ["admin", "procurement_admin", "warehouse_inbound"]}, 
    {tag:"sales_orders_acc" , title : "Acc Sales Orders", middlePanelTitle : "Accessories Sales Order List", tabTitle : "Acc SOs", type : "3", "group" : "orders", 
        roles : ["admin", "procurement_admin", "warehouse_inbound"]}, 
    {tag:"back_orders_acc" , title : "Acc Back Orders", middlePanelTitle : "Accessories Back Order List", tabTitle : "Acc BOs", type : "3", "group" : "orders", 
        roles : ["admin", "procurement_admin"]}, 
    {tag:"forward_orders_acc" , title : "Acc Forward Orders", middlePanelTitle : "Accessories Forward Order List", tabTitle : "Acc FOs", type : "3", "group" : "orders", 
        roles : ["admin", "procurement_admin", "warehouse_inbound"]}, 

    {tag:"skus" , title : "SKUs", middlePanelTitle : "SKU List", type : "3", "group" : "warehouse", 
        roles : ["admin", "warehouse_manager"]}, 
    {tag:"skus_dynamic" , title : "Dynamic SKUs", middlePanelTitle : "Dynamic SKU List", type : "3", "group" : "warehouse", 
        roles : ["admin", "warehouse_manager"]}, 
    {tag:"vendors" , title : "Vendors", middlePanelTitle : "Vendor List", type : "3", "group" : "procurement", 
        roles : ["admin", "finance", "procurement_admin"]}, 
    // {tag:"vendors2" , title : "Vendors2", middlePanelTitle : "Vendor2 List", type : "3", "group" : "procurement", 
    //     roles : ["admin", "ops", "ops_admin", "procurement_admin"]}, 
    {tag:"acc_vendors" , title : "Acc Vendors", middlePanelTitle : "Accessories Vendor List", type : "3", "group" : "procurement", 
        roles : ["admin", "finance", "procurement_admin"]}, 
    {tag:"distributors" , title : "Distributors", middlePanelTitle : "Distributor List", type : "3", "group" : "procurement", 
        roles : ["admin", "finance", "procurement_admin"]}, 
    // {tag:"bids" , title : "Bids", middlePanelTitle : "Bid List", type : "3", "group" : "procurement", 
    //     roles : ["admin", "ops", "ops_admin"]}, 
    {tag:"transfer_for_dpsp" , title : "Transfer for Dropshipping", tabTitle : "Trnsfr fr Drpshpng", middlePanelTitle : "Transfer for Dropshipping - Select Back Orders", type : "3", "group" : "dropshipping", 
        roles : ["admin", "procurement_admin"]}, 
    {tag:"generate_rfq" , title : "Generate RFQ", tabTitle : "RFQ +", middlePanelTitle : "Generate RFQ - Select Back Orders", type : "3", "group" : "procurement", 
        roles : ["admin", "procurement_admin"]}, 
    // {tag:"generate_rfq2" , title : "Generate RFQ2", tabTitle : "RFQ2 +", middlePanelTitle : "Generate RFQ2 - Select Back Orders", type : "3", "group" : "procurement", 
    //     roles : ["admin", "procurement_admin"]}, 
    {tag:"generate_rfq_acc" , title : "Generate Acc RFQ", tabTitle : "Acc RFQ +", middlePanelTitle : "Generate Accessories RFQ - Select Accessories Back Orders", type : "3", "group" : "procurement", 
        roles : ["admin", "procurement_admin"]}, 
    {tag:"rfqs" , report : true, title : "RFQs", tabTitle : "RFQs", middlePanelTitle : "RFQ List", type : "3", "group" : "procurement", 
        roles : ["admin", "procurement_admin"]}, 
    {tag:"rfqs_acc" , title : "Acc RFQs", tabTitle : "Acc RFQs", middlePanelTitle : "Accessories RFQ List", type : "3", "group" : "procurement", 
        roles : ["admin", "procurement_admin"]}, 
    {tag:"rfqs_acc_global" , title : "Acc Global RFQs", tabTitle : "Acc Global RFQs", middlePanelTitle : "Accessories Global RFQ List", type : "3", "group" : "procurement", 
        roles : ["admin", "procurement_admin"]}, 
    {tag:"manufos_acc" , report : true, title : "Acc Manufacturing Orders", tabTitle : "ACC MOs", middlePanelTitle : "Accessories Manufacturing Order List", type : "3", "group" : "procurement", 
        roles : ["admin", "procurement_admin", "warehouse_inbound"]}, 
    {tag:"purchos" , report : true, title : "Purchase Orders", tabTitle : "PurchOs", middlePanelTitle : "Purchase Order List", type : "3", "group" : "procurement", 
        roles : ["admin", "procurement_admin", "warehouse_inbound"]}, 
    {tag:"purchos_acc" , title : "Acc Purchase Orders", tabTitle : "Acc POs", middlePanelTitle : "Accessories Purchase Order List", type : "3", "group" : "procurement", 
        roles : ["admin", "procurement_admin", "warehouse_inbound"]}, 
    {tag:"purchos_acc_global" , title : "Acc Global Purchase Orders", tabTitle : "Acc Global POs", middlePanelTitle : "Accessories Global Purchase Order List", type : "3", "group" : "procurement", 
        roles : ["admin", "procurement_admin", "warehouse_inbound"]}, 
    {tag:"grns" , report : true, title : "GRNs", tabTitle : "GRNs", middlePanelTitle : "GRN List", type : "3", "group" : "procurement", 
        roles : ["admin", "procurement_admin", "warehouse_inbound"]}, 
    {tag:"putaway_batches" , report : true, title : "PutAway Batches", tabTitle : "PutAway Bs", middlePanelTitle : "PutAway Batch List", type : "3", "group" : "warehouse", 
        roles : ["admin", "warehouse_manager", "warehouse_outbound"]}, 
    // {tag:"putaway" , title : "Put Away", tabTitle : "Put Away", middlePanelTitle : "Put Away", type : "3"}, 
    {tag:"generate_picklist" , title : "Generate PickList", tabTitle : "PickList +", middlePanelTitle : "Generate PickList - Select Sales Orders", type : "3", "group" : "outbound", 
        roles : ["admin", "warehouse_inbound", "warehouse_outbound", "warehouse_manager"]}, 
    {tag:"picklists" , report : true, title : "PickLists", tabTitle : "PickLists", middlePanelTitle : "PickLists", type : "3", "group" : "outbound", 
        roles : ["admin", "warehouse_outbound", "warehouse_manager"]}, 
    {tag:"boxing_batches" , report : true, title : "Boxing Batches", tabTitle : "Boxing Bs", middlePanelTitle : "Boxing Batch List", type : "3", "group" : "outbound", 
        roles : ["admin", "warehouse_outbound"]}, 
    {tag:"vendor_payment_requests" , report : true, title : "Payment Requests", tabTitle : "Pay Requests", middlePanelTitle : "Vendor Payment Requests", type : "3", "group" : "finance", 
        roles : ["admin", "finance", "ops", "ops_admin", "procurement_admin"]}, 
    {tag:"vendor_payment_requests_acc" , report : true, title : "Acc Payment Requests", tabTitle : "Acc Pay Reqs", middlePanelTitle : "Accessories Vendor Payment Requests", type : "3", "group" : "finance", 
        roles : ["admin", "finance", "acc_ops", "acc_ops_admin"]}, 
    {tag:"debit_notes" , report : true, title : "Debit Notes", tabTitle : "Debit Notes", middlePanelTitle : "Debit Notes", type : "3", "group" : "finance", 
        roles : ["admin", "finance", "procurement_admin"]}, 
    {tag:"generate_sales_return" , title : "Generate Sales Return", tabTitle : "Sales Return +", middlePanelTitle : "Generate Sales Return - Select Invoices", type : "3", "group" : "inbound", 
        roles : ["admin", "warehouse_manager"]}, 
    {tag:"generate_sales_return_tarone" , title : "Generate Sales Return for TarOne", tabTitle : "Sales Return TO +", middlePanelTitle : "Generate Sales Return for TarOne - Select Invoices", type : "3", "group" : "inbound", 
        roles : ["admin", "warehouse_manager"]}, 
    {tag:"box_invoices" , title : "Invoices", tabTitle : "Invoices", middlePanelTitle : "Invoices", type : "3", "group" : "outbound", 
        roles : ["admin", "warehouse_manager"]}, 
    {tag:"reverse_pickups" , report : true, title : "Reverse Pickups", tabTitle : "Reverse Pickups", middlePanelTitle : "Reverse Pickup List", type : "3", "group" : "inbound", 
        roles : ["admin", "warehouse_manager"]}, 
    {tag:"sales_returns" , report : true, title : "Sales Returns", tabTitle : "Sales Returns", middlePanelTitle : "Sales Return List", type : "3", "group" : "inbound", 
        roles : ["admin", "warehouse_manager"]}, 
    {tag:"purchase_returns" , title : "Purchase Returns", tabTitle : "PO Returns", middlePanelTitle : "Purchase Return List", type : "3", "group" : "inbound", 
        roles : ["admin", "warehouse_inbound", "procurement_admin"]}, 
    {tag:"boxing_batches_es" , report : true, title : "Boxing Batches", tabTitle : "DPSP Bx Bs", middlePanelTitle : "Dropshipping Boxing Batch List", type : "3", "group" : "dropshipping", 
        roles : ["admin", "procurement_admin"]}, 
    {tag:"orders_es" , report : true, title : "Orders", tabTitle : "DPSP Orders", middlePanelTitle : "Dropshipping Order List", type : "3", "group" : "dropshipping", 
        roles : ["admin", "procurement_admin"]}, 
    {tag:"sales_returns_es" , report : true, title : "Sales Returns", tabTitle : "DPSP Sales Returns", middlePanelTitle : "Dropshipping Sales Return List", type : "3", "group" : "dropshipping", 
        roles : ["admin", "procurement_admin"]}, 
    {tag:"inventory_es" , report : true, title : "Partner Inventory", tabTitle : "Partner Inventory", middlePanelTitle : "Dropshipping Partner Inventory", type : "3", "group" : "dropshipping", 
        roles : ["admin", "procurement_admin"]}, 
];

const rowData = {
    "actionables" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}},
        {"key" : "type", "header" : "Type", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "ref_id", "header" : "Ref ID", style : {width : "90px"}},
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "orders_list", "header" : "Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
    ],
    "periodic_tasks" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}},
        {"key" : "type", "header" : "Type", style : {width : "200px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        // {"key" : "type", "header" : "Type", style : {width : "180px"}},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "error_data", "header" : "Error Details", style : {width : "90px"}, "type" : "boolean"},
        // {"key" : "ref_id", "header" : "Ref ID", style : {width : "120px"}},
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        // {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "error_data", "header" : "Error Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "periodic_task_error_details"},
        {"key" : "error_data.type", "header" : "Error Type", style : {width : "120px"}},
        {"key" : "error_data.text", "header" : "Error Desc", style : {width : "120px"}},
        // {"key" : "orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "orders_list", "header" : "Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "updated_at", "header" : "Updated at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "history", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_history_items"},
    ],
    "skus" : [
        {"key" : "sku_code", "header" : "SKU Code", style : {width : "120px"}},
        {"key" : "product_id", "header" : "Product ID", style : {width : "120px"}},
        {"key" : "title", "header" : "Title", style : {width : "250px"}},
        {"key" : "sku_category", "header" : "Category", style : {width : "120px"}},
        {"key" : "sku_brand", "header" : "Brand", style : {width : "150px"}},
        {"key" : "inventory", "header" : "Stock", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "qty"},
        {"key" : "sku_code", "header" : "BarCode", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "barcode"},
        {"key" : "mrp", "header" : "MRP", style : {width : "70px"}},
        {"key" : "price", "header" : "Price", style : {width : "70px"}},
        {"key" : "alt_parts", "header" : "Alternative Parts", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "alt_parts", "header" : "Alternative Parts", style : {width : "70px"}, "panel" : "right", "type" : "custom", "type1" : "alt_parts_product_ids", "extra_key" : "product_id"},
        {"key" : "image_url", "header" : "Image", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "image"},
    ],
    "skus_dynamic" : [
        {"key" : "sku_code", "header" : "SKU Code", style : {width : "120px"}},
        {"key" : "product_id", "header" : "Product ID", style : {width : "120px"}},
        {"key" : "title", "header" : "Title", style : {width : "250px"}},
        {"key" : "sku_category", "header" : "Category", style : {width : "120px"}},
        {"key" : "sku_brand", "header" : "Brand", style : {width : "150px"}},
        {"key" : "inventory", "header" : "Stock", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "qty"},
        {"key" : "sku_code", "header" : "BarCode", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "barcode"},
        {"key" : "mrp", "header" : "MRP", style : {width : "70px"}},
        {"key" : "price", "header" : "Price", style : {width : "70px"}},
        {"key" : "alt_parts", "header" : "Alternative Parts", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "alt_parts", "header" : "Alternative Parts", style : {width : "70px"}, "panel" : "right", "type" : "custom", "type1" : "alt_parts_product_ids", "extra_key" : "product_id"},
        {"key" : "image_url", "header" : "Image", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "image"},
    ],
    "warehouses" : [
        {"key" : "code", "header" : "Code", style : {width : "80px"}},
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "address.city", "header" : "City", style : {width : "90px"}},
        {"key" : "address.state", "header" : "State", style : {width : "90px"}},
        {"key" : "address.pincode", "header" : "PIN Code", style : {width : "70px"}},
        {"key" : "finance.bank_name", "header" : "Bank", style : {width : "150px"}},
        {"key" : "finance.bank_account", "header" : "Account No.", style : {width : "100px"}},
        {"key" : "finance.gstin", "header" : "GSTIN", style : {width : "150px"}},
        {"key" : "address.full_address", "header" : "Address", style : {width : "400px"}},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
    ],
    "inventory" : [
        {"key" : "part.product_id", "header" : "Product ID", style : {width : "120px"}},
        {"key" : "part.sku_code", "header" : "SKU Code", style : {width : "120px"}},
        {"key" : "qty", "header" : "Quantity", style : {width : "70px"}},
        {"key" : "frozen_stock", "header" : "Frozen Qty", style : {width : "70px"}},
        {"key" : "location", "header" : "Location", style : {width : "90px"}},
        {"key" : "warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "part.title", "header" : "Title", style : {width : "250px"}},
        {"key" : "part.sku_category", "header" : "Category", style : {width : "120px"}},
        {"key" : "part.sku_brand", "header" : "Brand", style : {width : "150px"}},
        {"key" : "sku_code", "header" : "BarCode", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "barcode"},
        {"key" : "part.mrp", "header" : "MRP", style : {width : "70px"}},
        {"key" : "part.price", "header" : "Price", style : {width : "70px"}},
        {"key" : "alt_parts", "header" : "Alternative Parts", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "alt_parts", "header" : "Alternative Parts", style : {width : "70px"}, "panel" : "right", "type" : "custom", "type1" : "alt_parts_product_ids", "extra_key" : "product_id"},
        {"key" : "part.image_url", "header" : "Image", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "image"},
    ],
    "inventory_history" : [
        {"key" : "part.product_id", "header" : "Product ID", style : {width : "120px"}},
        {"key" : "part.sku_code", "header" : "SKU Code", style : {width : "120px"}},
        {"key" : "txn_type", "header" : "TXN Type", style : {width : "70px"}},
        {"key" : "qty_original", "header" : "Original Qty", style : {width : "70px"}},
        {"key" : "qty_final", "header" : "Final Qty", style : {width : "70px"}},
        {"key" : "qty_change", "header" : "Qty Change", style : {width : "70px"}},
        {"key" : "location", "header" : "Location", style : {width : "90px"}},
        {"key" : "warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "part.title", "header" : "Title", style : {width : "250px"}},
        {"key" : "part.sku_category", "header" : "Category", style : {width : "120px"}},
        {"key" : "part.sku_brand", "header" : "Brand", style : {width : "150px"}},
        {"key" : "part.mrp", "header" : "MRP", style : {width : "70px"}},
        {"key" : "part.price", "header" : "Price", style : {width : "70px"}},
        {"key" : "part.image_url", "header" : "Image", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "image"},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
    ],
    "wh_transfer_orders" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "to_id", "header" : "TO ID", style : {width : "150px"}},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "warehouse_from", "header" : "WH From", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "warehouse_to", "header" : "WH To", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},        
        {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
    "wh_layout" : [
        {"key" : "code", "header" : "Warehouse", style : {width : "80px"}},
        {"key" : "location", "header" : "Location", style : {width : "90px"}},
        {"key" : "location_type", "header" : "Location type", style : {width : "90px"}},
        {"key" : "zone", "header" : "Zone", style : {width : "90px"}},
        // {"key" : "coordinates", "header" : "Coordinates", style : {width : "100px"}},
        {"key" : "is_available", "header" : "Availability", style : {width : "70px"}, "type" : "boolean"},
        {"key" : "location", "header" : "BarCode", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "barcode"},
        // {"key" : "ffmt_constant", "header" : "BarCode", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "image", "constant_value" : "", "variable_values" : []},
    ],
    "orders" : [
        {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        {"key" : "order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "order_status", "header" : "Status", style : {width : "90px"}},
        {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        {"key" : "user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        {"key" : "created_by", "header" : "User ID", style : {width : "100px"}},
        {"key" : "order_lines", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
    "sales_orders" : [
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        {"key" : "created_by", "header" : "User ID", style : {width : "100px"}},
        {"key" : "user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "user_details.shipping_city", "header" : "City", style : {width : "90px"}},
        {"key" : "order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "sales_order.items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "sales_order.items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "sales_order.items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "sales_order.items", "header" : "DPSP Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "ttdpsp"},
        // {"key" : "sales_order.warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        
        {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        {"key" : "sales_order.invoice_number", "header" : "Invoice No.", style : {width : "120px"}},
        
        {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "_id.$oid", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "remote", "type1" : "sales_order_history"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
    "back_orders" : [
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        {"key" : "created_by", "header" : "User ID", style : {width : "100px"}},
        {"key" : "user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "user_details.shipping_city", "header" : "City", style : {width : "90px"}},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique_with_filter", "unique_key" : "product_id", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "sales_order.items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count_with_filter", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "sales_order.items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "sales_order.items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        
        {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id", "filter_key" : "status", "filter_key_value" : "back"},
        
        {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "filter_key" : "status", "filter_key_value" : "back", "unique_key" : "product_id"},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
    "forward_orders" : [
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        {"key" : "created_by", "header" : "User ID", style : {width : "100px"}},
        {"key" : "user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "user_details.shipping_city", "header" : "City", style : {width : "90px"}},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique_with_filter", "unique_key" : "product_id", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "sales_order.items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count_with_filter", "filter_key" : "status", "filter_key_value" : "forward"},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "sales_order.items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "sales_order.items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        
        {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id", "filter_key" : "status", "filter_key_value" : "forward"},
        
        {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "filter_key" : "status", "filter_key_value" : "forward", "unique_key" : "product_id"},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "orders_eg" : [
        {"key" : "order_id", "header" : "Order ID", style : {width : "120px"}},
        // {"key" : "created_by", "header" : "User ID", style : {width : "100px"}},
        {"key" : "status", "header" : "Status", style : {width : "120px"}},
        {"key" : "client_customer_details.name", "header" : "Customer", style : {width : "120px"}},
        // {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "client_customer_details.shipping_address.city", "header" : "City", style : {width : "90px"}},
        {"key" : "line_items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "line_items", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        
        // {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        // {"key" : "items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        // {"key" : "items", "header" : "DPSP Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "ttdpsp"},
        // {"key" : "sales_order.warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        // {"key" : "items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        
        // {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        // {"key" : "sales_invoices", "header" : "Sales Invoices", style : {width : "120px"}, "type" : "custom", "type1" : "sales_invoices_numbers_es"},
        
        {"key" : "client_customer_details.mobile", "header" : "Phone", style : {width : "90px"}},
        
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},




        {"key" : "spares_order.sales_order.so_order_id", "header" : "Spares Sales Order ID", style : {width : "120px"}},
        {"key" : "spares_order.stockone_order_id", "header" : "Spares Order ID", style : {width : "100px"}},
        {"key" : "spares_order.order_lines", "header" : "Spares Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "spares_order.order_lines", "header" : "Spares Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        // {"key" : "sales_order.items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "spares_order.sales_order.items", "header" : "Spares BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "spares_order.sales_order.items", "header" : "Spares FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "spares_order.sales_order.items", "header" : "Spares DPSP Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "ttdpsp"},
        // {"key" : "sales_order.warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "spares_order.sales_order.items", "header" : "Spares Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        
        {"key" : "spares_order.sales_order.invoice_number", "header" : "Spares Invoice No.", style : {width : "120px"}},
        
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "spares_order.sales_order.items", "header" : "Spares Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        // {"key" : "_id.$oid", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "remote", "type1" : "sales_order_history"},




        {"key" : "_id.$oid", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "remote", "type1" : "sales_order_history"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "orders_distributor" : [
        {"key" : "order_id", "header" : "Order ID", style : {width : "120px"}},
        // {"key" : "created_by", "header" : "User ID", style : {width : "100px"}},
        {"key" : "status", "header" : "Status", style : {width : "120px"}},
        {"key" : "customer_details.name", "header" : "Customer", style : {width : "120px"}},
        // {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        // {"key" : "client_customer_details.shipping_address.city", "header" : "City", style : {width : "90px"}},
        {"key" : "line_items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "line_items", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},

        // {"key" : "items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        // {"key" : "sales_order.items", "header" : "DPSP Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "ttdpsp"},
        // {"key" : "sales_order.warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        // {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        // {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        
        
        
        
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        
        // {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        // {"key" : "items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        // {"key" : "items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        // {"key" : "items", "header" : "DPSP Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "ttdpsp"},
        // {"key" : "sales_order.warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        // {"key" : "items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        
        // {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        // {"key" : "sales_invoices", "header" : "Sales Invoices", style : {width : "120px"}, "type" : "custom", "type1" : "sales_invoices_numbers_es"},
        
        {"key" : "customer_details.mobile", "header" : "Phone", style : {width : "90px"}},
        
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},

        // {"key" : "_id.$oid", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "remote", "type1" : "sales_order_history"},




        {"key" : "_id.$oid", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "remote", "type1" : "sales_order_history"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
    "orders_acc" : [
        {"key" : "order_id", "header" : "Order ID", style : {width : "100px"}},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        // {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        {"key" : "order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        // {"key" : "order_status", "header" : "Status", style : {width : "90px"}},
        {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        {"key" : "user_details.name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "user_details.mobile", "header" : "Phone", style : {width : "90px"}},
        {"key" : "user_details.user_id", "header" : "User ID", style : {width : "100px"}},
        {"key" : "dsp_tracking_details", "header" : "Tracking Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "acc_tracking_details"},
        {"key" : "order_lines", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
    "sales_orders_acc" : [
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "order_id", "header" : "Order ID", style : {width : "100px"}},
        {"key" : "user_details.name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "delivery_address.city", "header" : "City", style : {width : "90px"}},
        {"key" : "order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        // {"key" : "order_status", "header" : "Status", style : {width : "90px"}},
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "sales_order.items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "sales_order.items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "sales_order.items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "sales_order.items", "header" : "DPSP Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "ttdpsp"},
        
        // {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        // {"key" : "sales_order.warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        // {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        {"key" : "sales_order.invoice_number", "header" : "Invoice No.", style : {width : "120px"}},
        {"key" : "user_details.mobile", "header" : "Phone", style : {width : "90px"}},
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "_id.$oid", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "remote", "type1" : "sales_order_history"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
    "back_orders_acc" : [
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "order_id", "header" : "Order ID", style : {width : "100px"}},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        // {"key" : "order_status", "header" : "Status", style : {width : "90px"}},
        {"key" : "sales_order.items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "sales_order.items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        //IMP
        {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        // {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        
        {"key" : "sales_order.items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique_with_filter", "unique_key" : "product_id", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "sales_order.items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count_with_filter", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "user_details.name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "user_details.mobile", "header" : "Phone", style : {width : "90px"}},
        {"key" : "user_details.user_id", "header" : "User ID", style : {width : "100px"}},
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "filter_key" : "status", "filter_key_value" : "back", "unique_key" : "product_id"},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
    "forward_orders_acc" : [
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "order_id", "header" : "Order ID", style : {width : "100px"}},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        // {"key" : "order_status", "header" : "Status", style : {width : "90px"}},
        {"key" : "sales_order.items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "sales_order.items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        //IMP
        {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        // {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        
        {"key" : "sales_order.items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique_with_filter", "unique_key" : "product_id", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "sales_order.items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count_with_filter", "filter_key" : "status", "filter_key_value" : "forward"},
        // {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "user_details.name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "user_details.mobile", "header" : "Phone", style : {width : "90px"}},
        {"key" : "user_details.user_id", "header" : "User ID", style : {width : "100px"}},
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "filter_key" : "status", "filter_key_value" : "forward", "unique_key" : "product_id"},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],

    "distributors" : [
        {"key" : "code", "header" : "Code", style : {width : "90px"}},
        {"key" : "name", "header" : "Name", style : {width : "180px"}},
        {"key" : "contact_name", "header" : "Contact Name", style : {width : "180px"}},
        // {"key" : "brands", "header" : "Brands", style : {width : "150px"}, "type" : "array_string"},
        
        // {"key" : "gst_structure", "header" : "GST Structure", style : {width : "70px"}},
        {"key" : "type", "header" : "Type", style : {width : "90px"}},
        {"key" : "mobile", "header" : "Mobile", style : {width : "90px"}},
        {"key" : "gst_no", "header" : "GST Number", style : {width : "150px"}},
        // {"key" : "franchise", "header" : "Franchise", style : {width : "50px"}},
        {"key" : "shipping_city", "header" : "Shp City", style : {width : "90px"}},
        {"key" : "shipping_state", "header" : "Shp State", style : {width : "90px"}},
        {"key" : "shipping_pincode", "header" : "Shp PIN", style : {width : "70px"}},
        {"key" : "billing_city", "header" : "Blg City", style : {width : "90px"}},
        {"key" : "billing_state", "header" : "Blg State", style : {width : "90px"}},
        {"key" : "billing_pincode", "header" : "Blg PIN", style : {width : "70px"}},

        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
    "vendors" : [
        {"key" : "code", "header" : "Code", style : {width : "90px"}},
        {"key" : "name", "header" : "Name", style : {width : "180px"}},
        {"key" : "brands", "header" : "Brands", style : {width : "150px"}, "type" : "array_string"},
        {"key" : "city", "header" : "City", style : {width : "90px"}},
        {"key" : "state", "header" : "State", style : {width : "90px"}},
        {"key" : "pincode", "header" : "PIN Code", style : {width : "70px"}},
        {"key" : "dropship_details", "header" : "Dropshipping Status", style : {width : "80px"}, "type" : "custom", "type1" : "dropship_vendor_status"},
        {"key" : "dropship_details", "header" : "Order Bounce Time", style : {width : "80px"}, "type" : "custom", "type1" : "dropship_bounce_time"},
        {"key" : "dropship_details", "header" : "Daily Order Limit", style : {width : "80px"}, "type" : "custom", "type1" : "dropship_daily_order_limit"},
        {"key" : "dropship_details", "header" : "Payment Cycle", style : {width : "80px"}, "type" : "custom", "type1" : "dropship_payment_cycle"},
        {"key" : "gst_structure", "header" : "GST Structure", style : {width : "70px"}},
        {"key" : "vendor_type", "header" : "Vendor Type", style : {width : "90px"}},
        {"key" : "mobile", "header" : "Mobile", style : {width : "90px"}},
        {"key" : "razorpayx_fund_id", "header" : "RazorPayX Fund ID", style : {width : "120px"}},
        {"key" : "gst_no", "header" : "GST Number", style : {width : "150px"}},
        {"key" : "gst_vendor_type", "header" : "GST Vendor Type", style : {width : "90px"}},
        {"key" : "franchise", "header" : "Franchise", style : {width : "50px"}},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "vendors2" : [
        {"key" : "name", "header" : "Name", style : {width : "180px"}},
        {"key" : "owner_name", "header" : "Owner Name", style : {width : "120px"}},
        {"key" : "brands", "header" : "Brands", style : {width : "150px"}, "type" : "array_string"},
        {"key" : "discount", "header" : "Discount", style : {width : "70px"}},
        {"key" : "address", "header" : "Address", style : {width : "180px"}},
        {"key" : "gstin", "header" : "GST Number", style : {width : "150px"}},
        {"key" : "aadhar", "header" : "Aadhar No", style : {width : "120px"}},
        {"key" : "city", "header" : "City", style : {width : "90px"}},
        {"key" : "pincode", "header" : "PIN Code", style : {width : "70px"}},
        {"key" : "coordinates", "header" : "Coordinates", style : {width : "90px"}, "type" : "array_string"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "acc_vendors" : [
        {"key" : "code", "header" : "Code", style : {width : "90px"}},
        {"key" : "name", "header" : "Name", style : {width : "180px"}},
        {"key" : "brands", "header" : "Brands", style : {width : "150px"}, "type" : "array_string"},
        {"key" : "products", "header" : "Products", style : {width : "150px"}, "type" : "array_string"},
        {"key" : "dropship_details", "header" : "Dropshipping Status", style : {width : "80px"}, "type" : "custom", "type1" : "dropship_vendor_status"},
        {"key" : "dropship_details", "header" : "Order Bounce Time", style : {width : "80px"}, "type" : "custom", "type1" : "dropship_bounce_time"},
        {"key" : "dropship_details", "header" : "Daily Order Limit", style : {width : "80px"}, "type" : "custom", "type1" : "dropship_daily_order_limit"},
        {"key" : "dropship_details", "header" : "Payment Cycle", style : {width : "80px"}, "type" : "custom", "type1" : "dropship_payment_cycle"},
        {"key" : "razorpayx_fund_id", "header" : "RazorPayX Fund ID", style : {width : "120px"}},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
    //   "bids" : [
    //     {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
    //     // {"key" : "is_processed", "header" : "Processed", style : {width : "80px"}, "type" : "boolean"},
    //     {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count"},
    //     {"key" : "items", "header" : "Qty", style : {width : "80px"}, "type" : "array_key_sum", "key_sum" : "quantity"},
    //     {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus"},
    //     {"key" : "vendors", "header" : "Vendors", style : {width : "70px"}, "type" : "list_count"},
    //     {"key" : "vendors", "header" : "Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors2"},
    //     {"key" : "accept_responses", "header" : "Accept Responses", style : {width : "70px"}, "type" : "list_count"},
    //     {"key" : "status", "header" : "Status", style : {width : "80px"}, "type" : "boolean"},
    //     {"key" : "delivery_address.address_1", "header" : "Delivery Address", style : {width : "350px"}},
    //     {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
    //     {"key" : "type", "header" : "Type", style : {width : "80px"}},
    //     {"key" : "accept_responses", "header" : "Accept Responses", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vom_accept_responses"},
    //     {"key" : "status", "header" : "Status", style : {width : "90px"}, "panel" : "right", "type" : "custom", "type1" : "vom_status"},
    //     {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
    //     {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
    //   ],
    //   "putaway" : [
    //     {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
    //     {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
    //     {"key" : "grn_id", "header" : "GRN ID", style : {width : "250px"}},
    //     {"key" : "po_id", "header" : "PO ID", style : {width : "250px"}},
    //     {"key" : "po.po_id", "header" : "PO ID", style : {width : "180px"}},
    //     {"key" : "po.rfq.id", "header" : "RFQ ID", style : {width : "120px"}},
    //     {"key" : "po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
    //     {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
    //     {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
    //     // {"key" : "rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
    //     {"key" : "po.vendor_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors"},
    //     {"key" : "po.vendor_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor"},
    //     {"key" : "po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
    //     {"key" : "po.rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
    //     {"key" : "po.rfq.orders_list", "header" : "RFQ Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
    //     {"key" : "po.rfq.vendors", "header" : "Vendors", style : {width : "70px"}, "type" : "list_count"},
    //     {"key" : "po.rfq.vendors_list", "header" : "RFQ Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors"},
        
    //     {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
    //     {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},        
        
    //     // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
    //     {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
    //     {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
    //   ],
      "generate_picklist" : [
        {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "sales_order.items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "sales_order.items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "sales_order.items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        {"key" : "order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        {"key" : "user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "generate_bulk_sales_order_transfer" : [
        {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        // {"key" : "sales_order.invoices.invoice_number", "header" : "Invoice Number", style : {width : "120px"}},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        // {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "sales_order.items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "sales_order.items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        {"key" : "order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        {"key" : "user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "box_invoices" : [
        // {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "sales_order.invoices.invoice_number", "header" : "Invoice Number", style : {width : "120px"}},
        {"key" : "sales_order.invoices.type", "header" : "Invoice Type", style : {width : "80px"}},
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "sales_order.items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "sales_order.items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "sales_order.items", "header" : "DPSP Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "ttdpsp"},
        {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        {"key" : "order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        {"key" : "user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "generate_sales_return" : [
        {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        {"key" : "sales_order.invoices.invoice_number", "header" : "Invoice Number", style : {width : "120px"}},
        {"key" : "sales_order.invoices.type", "header" : "Invoice Type", style : {width : "80px"}},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "sales_order.items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "sales_order.items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "sales_order.items", "header" : "DPSP Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "ttdpsp"},
        {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        {"key" : "order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        {"key" : "user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "generate_sales_return_tarone" : [
        {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        {"key" : "sales_order.invoices.invoice_number", "header" : "Invoice Number", style : {width : "120px"}},
        {"key" : "sales_order.invoices.type", "header" : "Invoice Type", style : {width : "80px"}},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "sales_order.items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "sales_order.items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "sales_order.items", "header" : "DPSP Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "ttdpsp"},
        {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        {"key" : "order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        {"key" : "user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "sales_returns" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "quantity"},
        {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus"},
        {"key" : "items_rejected", "header" : "Rej. Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        {"key" : "items_rejected", "header" : "Rej. Qty", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "quantity"},
        {"key" : "items_rejected", "header" : "Rej. Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "credit_note_number", "header" : "Credit Note No.", style : {width : "120px"}},
        {"key" : "invoice_number", "header" : "Invoice Number", style : {width : "120px"}},
        {"key" : "order.sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        // {"key" : "order", "header" : "Order", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "order"},
        {"key" : "order.created_by", "header" : "Order Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "order.total", "header" : "Order Total", style : {width : "70px"}},
        {"key" : "order.order_lines", "header" : "Order Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "order.order_lines", "header" : "Order Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "order.user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "order.user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        {"key" : "order.created_by", "header" : "User ID", style : {width : "100px"}},
        //IMP
        {"key" : "order.sales_order.created_at", "header" : "Order Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},

        {"key" : "order.stockone_order_id", "header" : "Order ID", style : {width : "100px"}, "panel" : "right"},
        {"key" : "order.sales_order.status", "header" : "SO Status", style : {width : "180px"}, "panel" : "right", "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "order.sales_order.items", "header" : "BO Status", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "order.sales_order.items", "header" : "FO Status", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        // {"key" : "sales_order.warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "order.sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "panel" : "right", "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "order.payment_mode", "header" : "Payment Mode", style : {width : "180px"}, "panel" : "right"},
        {"key" : "order.sales_order.invoice_number", "header" : "Invoice No.", style : {width : "180px"}, "panel" : "right"},
        {"key" : "order.sales_order.items", "header" : "Order Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
      ],
      "purchase_returns" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "quantity"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus"},
        
        {"key" : "po.po_id", "header" : "PO ID", style : {width : "180px"}},
        {"key" : "po.items", "header" : "PO Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        {"key" : "po.items", "header" : "PO Quantity", style : {width : "70px"}, "type" : "list_count"},
        
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        // {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "po.items", "header" : "PO Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "po.rfq.id", "header" : "RFQ ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        // {"key" : "rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "po.vendor_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors"},
        {"key" : "po.vendor_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "order"},
        {"key" : "po.rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "po.rfq.orders_list", "header" : "RFQ Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        {"key" : "po.rfq.vendors", "header" : "Vendors", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "po.rfq.vendors_list", "header" : "RFQ Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors"},

        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "transfer_for_dpsp" : [
        {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        {"key" : "created_by", "header" : "User ID", style : {width : "100px"}},
        {"key" : "user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "user_details.shipping_city", "header" : "City", style : {width : "90px"}},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique_with_filter", "unique_key" : "product_id", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "sales_order.items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count_with_filter", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "sales_order.items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "sales_order.items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "back_order.order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        
        // {"key" : "back_order.order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        
        {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id", "filter_key" : "status", "filter_key_value" : "back"},
        
        {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "generate_rfq" : [
        {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        {"key" : "created_by", "header" : "User ID", style : {width : "100px"}},
        {"key" : "user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "user_details.shipping_city", "header" : "City", style : {width : "90px"}},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique_with_filter", "unique_key" : "product_id", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "sales_order.items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count_with_filter", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "sales_order.items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "sales_order.items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "back_order.order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        
        // {"key" : "back_order.order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        
        {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id", "filter_key" : "status", "filter_key_value" : "back"},
        
        {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "generate_rfq2" : [
        {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        {"key" : "created_by", "header" : "User ID", style : {width : "100px"}},
        {"key" : "user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "user_details.shipping_city", "header" : "City", style : {width : "90px"}},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique_with_filter", "unique_key" : "product_id", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "sales_order.items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count_with_filter", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "sales_order.items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "sales_order.items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "back_order.order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        
        // {"key" : "back_order.order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        
        {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id", "filter_key" : "status", "filter_key_value" : "back"},
        
        {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "generate_rfq_skus" : [
        {"key" : "sku_code", "header" : "SKU Code", style : {width : "120px"}},
        {"key" : "product_id", "header" : "Product ID", style : {width : "120px"}},
        {"key" : "sku_brand", "header" : "Brand", style : {width : "150px"}},
    ],
    "generate_rfq_vendors" : [
        {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "code", "header" : "Code", style : {width : "90px"}},
        {"key" : "name", "header" : "Name", style : {width : "180px"}},
        {"key" : "state", "header" : "State", style : {width : "90px"}},
        {"key" : "city", "header" : "City", style : {width : "90px"}},
        {"key" : "pincode", "header" : "PIN Code", style : {width : "70px"}},
        {"key" : "brands", "header" : "Brands", style : {width : "180px"}, "type" : "array_string"},
      ],
      "generate_rfq2_vendors" : [
        {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "name", "header" : "Name", style : {width : "180px"}},
        {"key" : "owner_name", "header" : "Owner", style : {width : "120px"}},
        {"key" : "state", "header" : "State", style : {width : "90px"}},
        {"key" : "city", "header" : "City", style : {width : "90px"}},
        {"key" : "pincode", "header" : "PIN Code", style : {width : "70px"}},
        {"key" : "brands", "header" : "Brands", style : {width : "180px"}, "type" : "array_string"},
      ],
      "generate_rfq_acc" : [
        {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "order_id", "header" : "Order ID", style : {width : "100px"}},
        // {"key" : "order_status", "header" : "Status", style : {width : "90px"}},

        {"key" : "sales_order.items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "sales_order.items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        
        // {"key" : "back_order.order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique_with_filter", "unique_key" : "product_id", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "back_order.order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "sales_order.items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count_with_filter", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "user_details.name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "user_details.mobile", "header" : "Phone", style : {width : "90px"}},
        {"key" : "user_details.user_id", "header" : "User ID", style : {width : "100px"}},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "generate_rfq_skus_acc" : [
        {"key" : "sku_code", "header" : "SKU Code", style : {width : "120px"}},
        {"key" : "product_id", "header" : "Product ID", style : {width : "120px"}},
        {"key" : "sku_brand", "header" : "Brand", style : {width : "150px"}},
    ],
    "generate_rfq_vendors_acc" : [
        {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "code", "header" : "Code", style : {width : "90px"}},
        {"key" : "name", "header" : "Name", style : {width : "180px"}},
        // {"key" : "state", "header" : "State", style : {width : "90px"}},
        // {"key" : "city", "header" : "City", style : {width : "90px"}},
        // {"key" : "pincode", "header" : "PIN Code", style : {width : "70px"}},
        {"key" : "brands", "header" : "Brands", style : {width : "450px"}, "type" : "array_string"},
      ],
      "create_vom_skus" : [
        {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "sku_code", "header" : "SKU Code", style : {width : "120px"}},
        {"key" : "product_id", "header" : "Product ID", style : {width : "120px"}},
        {"key" : "sku_brand", "header" : "Brand", style : {width : "150px"}},
        {"key" : "product_title", "header" : "Title", style : {width : "250px"}},
    ],
    "create_vom_vendors" : [
        {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "name", "header" : "Name", style : {width : "180px"}},
        {"key" : "owner_name", "header" : "Owner Name", style : {width : "120px"}},
        {"key" : "brands", "header" : "Brands", style : {width : "180px"}, "type" : "array_string"},
      ],
    "rfqs" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        {"key" : "rfq_type", "header" : "Type", style : {width : "70px"}, "type" : "custom", "type1" : "rfq_type"},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "pos", "header" : "POs", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "orders_list", "header" : "Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        {"key" : "vendors", "header" : "Vendors", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "vendors_list", "header" : "Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors_rfq", "extra_key" : "id"},
        // {"key" : "vendors2_list", "header" : "Vendors2", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors_rfq2", "extra_key" : "id"},
        {"key" : "vendor_responses", "header" : "Responses", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "vendor_responses", "header" : "Response Details", style : {width : "80px"}, "panel" : "right", "type" : "custom", "type1" : "rfq_vendor_response_details"},
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus_list"},
        {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},

        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
    "rfqs_acc" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "pos", "header" : "POs", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "orders_list", "header" : "Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders_acc"},
        {"key" : "vendors", "header" : "Vendors", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "vendors_list", "header" : "Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors_rfq_acc", "extra_key" : "id"},
        {"key" : "vendor_responses", "header" : "Responses", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "vendor_responses", "header" : "Response Details", style : {width : "80px"}, "panel" : "right", "type" : "custom", "type1" : "rfq_acc_vendor_response_details"},
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus_list"},
        {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
    "rfqs_acc_global" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "pos", "header" : "POs", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        // {"key" : "items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "vendor_details.name", "header" : "Vendor", style : {width : "180px"}},
        {"key" : "vendor_details", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor_rfq_acc_global", "extra_key" : "id"},
        // {"key" : "vendor_responses", "header" : "Responses", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "vendor_responses", "header" : "Response Details", style : {width : "80px"}, "panel" : "right", "type" : "custom", "type1" : "rfq_acc_vendor_response_details"},
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "sku_code"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus_list"},
        {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "sku_code"},
        
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
    "manufos_acc" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "mo_id", "header" : "MO ID", style : {width : "210px"}},
        // {"key" : "mo_type", "header" : "Type", style : {width : "70px"}, "type" : "custom", "type1" : "rfq_type"},
        {"key" : "mo_type", "header" : "Type", style : {width : "70px"}},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "vendor_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "acc_vendors"},
        {"key" : "vendor_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "acc_vendor"},
        
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "items", "header" : "Mfd. Qty", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity_mfd"},
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        // {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus5"},
        // {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        
        {"key" : "mo_invoices", "header" : "Uploaded Invoices Amount", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "invoice_amount"},
        {"key" : "mo_invoices", "header" : "Uploaded Invoices", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "mo_invoices", "header" : "Uploaded Invoices", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_tax_invoices"},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
        {"key" : "history", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_history_items"},
      ],
    "purchos" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "po_id", "header" : "PO ID", style : {width : "180px"}},
        {"key" : "po_type", "header" : "Type", style : {width : "70px"}, "type" : "custom", "type1" : "rfq_type"},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "rfq.id", "header" : "RFQ ID", style : {width : "90px"}, roles : ["admin"]},
        // {"key" : "grn.grn_id", "header" : "GRN ID", style : {width : "250px"}},
        {"key" : "grns", "header" : "GRNs", style : {width : "70px"}, "type" : "list_count"},
        
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "pickup_date", "header" : "PickUp Date", style : {width : "90px"}},
        // {"key" : "rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "vendor_id", "header" : "Vendor1", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors"},
        {"key" : "vendor_id", "header" : "Vendor1", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor"},
        // {"key" : "vendor_id", "header" : "Vendor2", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors2"},
        // {"key" : "vendor_id", "header" : "Vendor2", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor2"},
        
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "grns", "header" : "GRN Items", style : {width : "70px"}, "type" : "custom", "type1" : "po_grns_items"},
        {"key" : "grns", "header" : "GRN Quantity", style : {width : "70px"}, "type" : "custom", "type1" : "po_grns_quantity"},
        
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        // {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "rfq.orders", "header" : "RFQ Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "rfq.orders_list", "header" : "RFQ Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        // {"key" : "rfq.vendors", "header" : "RFQ Vendors", style : {width : "70px"}, "type" : "list_count"},        
        // {"key" : "rfq.vendors_list", "header" : "RFQ Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors"},
        // {"key" : "rfq.vendors2_list", "header" : "RFQ Vendors2", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors2"},
        {"key" : "po_invoices", "header" : "Uploaded Invoices Amount", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "invoice_amount"},
        {"key" : "po_invoices", "header" : "Uploaded Invoices", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "po_invoices", "header" : "Uploaded Invoices", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_tax_invoices"},
        {"key" : "po_receivings", "header" : "Receivings", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
        {"key" : "po_receivings", "header" : "Receivings", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "po_receivings"},
        {"key" : "history", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_history_items"},
      ],
    "purchos_acc" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "po_id", "header" : "PO ID", style : {width : "180px"}},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "rfq.id", "header" : "RFQ ID", style : {width : "90px"}, roles : ["admin"]},
        // {"key" : "grn.grn_id", "header" : "GRN ID", style : {width : "250px"}},
        // {"key" : "grns", "header" : "GRNs", style : {width : "70px"}, "type" : "list_count"},
        
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        // {"key" : "rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "vendor_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "acc_vendors"},
        {"key" : "vendor_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "acc_vendor"},
        
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},        
        
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        // {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "rfq.orders", "header" : "RFQ Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "rfq.orders_list", "header" : "RFQ Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders_acc"},
        {"key" : "rfq.vendors", "header" : "RFQ Vendors", style : {width : "70px"}, "type" : "list_count"},        
        {"key" : "rfq.vendors_list", "header" : "RFQ Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors_acc"},
        {"key" : "po_invoices", "header" : "Uploaded Invoices Amount", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "invoice_amount"},
        {"key" : "po_invoices", "header" : "Uploaded Invoices", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
        {"key" : "po_invoices", "header" : "Uploaded Invoices", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_tax_invoices"},
        {"key" : "history", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_history_items"},
      ],
    "purchos_acc_global" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "po_id", "header" : "PO ID", style : {width : "180px"}},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "rfq.id", "header" : "RFQ ID", style : {width : "90px"}, roles : ["admin"]},
        // {"key" : "grn.grn_id", "header" : "GRN ID", style : {width : "250px"}},
        // {"key" : "grns", "header" : "GRNs", style : {width : "70px"}, "type" : "list_count"},
        
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        // {"key" : "rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "vendor_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "acc_vendors"},
        {"key" : "vendor_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "acc_vendor"},
        
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "sku_code"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},        
        
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        // {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        // {"key" : "rfq.orders", "header" : "RFQ Orders", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "rfq.orders_list", "header" : "RFQ Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders_acc"},
        // {"key" : "rfq.vendors", "header" : "RFQ Vendors", style : {width : "70px"}, "type" : "list_count"},        
        // {"key" : "rfq.vendors_list", "header" : "RFQ Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors_acc"},
        {"key" : "po_invoices", "header" : "Uploaded Invoices Amount", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "invoice_amount"},
        {"key" : "po_invoices", "header" : "Uploaded Invoices", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
        {"key" : "po_invoices", "header" : "Uploaded Invoices", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_tax_invoices"},
        {"key" : "history", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_history_items"},
      ],
    "grns" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "grn_id", "header" : "GRN ID", style : {width : "150px"}},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "putaway_batches", "header" : "PA Batches", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "putaway_batches", "header" : "PA Batches", style : {width : "120px"}, "type" : "array_object_string", "key_display" : "id"},
        {"key" : "po_id", "header" : "PO ID", style : {width : "90px"}},
        {"key" : "po.po_id", "header" : "PO ID", style : {width : "180px"}},
        {"key" : "po.po_type", "header" : "PO Type", style : {width : "70px"}},
        {"key" : "po.rfq.id", "header" : "RFQ ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "started_at", "header" : "Started at", style : {width : "150px"}, "type" : "mongodate"},
        // {"key" : "rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "po.vendor_id", "header" : "Vendor1", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors"},
        {"key" : "po.vendor_id", "header" : "Vendor1", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor"},
        // {"key" : "po.vendor_id", "header" : "Vendor2", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors2"},
        // {"key" : "po.vendor_id", "header" : "Vendor2", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor2"},
        // {"key" : "po.rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "po.rfq.orders_list", "header" : "RFQ Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        // {"key" : "po.rfq.vendors", "header" : "Vendors", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "po.rfq.vendors_list", "header" : "RFQ Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors"},
        // {"key" : "po.rfq.vendors2_list", "header" : "RFQ Vendors2", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors2"},
        
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "items_rejected", "header" : "Rej Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        {"key" : "items_rejected", "header" : "Rej Quantity", style : {width : "70px"}, "type" : "list_count"},
        
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "items_rejected", "header" : "Rejected Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "started_by", "header" : "Started By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "operators", "header" : "Operators", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "operators", "header" : "Operators", style : {width : "80px"}, panel : "right", "type" : "custom", "type1" : "dashboard_roles_string2"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "grns_incomplete" : [
        // {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        // {"key" : "grn_id", "header" : "GRN ID", style : {width : "250px"}},
        // {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "po_id", "header" : "PO ID", style : {width : "90px"}},
        {"key" : "po_id_full", "header" : "PO ID", style : {width : "180px"}},
        // {"key" : "po.po_id", "header" : "PO ID", style : {width : "180px"}},
        // {"key" : "po.po_type", "header" : "PO Type", style : {width : "180px"}},
        // {"key" : "po.rfq.id", "header" : "RFQ ID", style : {width : "180px"}, roles : ["admin"]},
        // {"key" : "po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        {"key" : "created.created_warehouse", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "last_updated.updated_at", "header" : "Last Updated At", style : {width : "150px"}, "type" : "firebasedate"},
        {"key" : "last_updated.updated_by", "header" : "Last Updated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "users", "header" : "Users", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "users", "header" : "Users", style : {width : "80px"}, panel : "right", "type" : "custom", "type1" : "dashboard_roles_string1"},
        {"key" : "filled_items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "filled_items", "header" : "Qty", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "filled_items", "header" : "Rej Qty", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity_rejected"},
        {"key" : "filled_items", "header" : "Orig Qty", style : {width : "70px"}, "type" : "custom", "type1" : "item_quantity_inner"},
        {"key" : "created.created_at", "header" : "Created At", style : {width : "150px"}, "type" : "firebasedate"},
        {"key" : "created.created_by", "header" : "Created By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        // {"key" : "po.vendor_id", "header" : "Vendor1", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors"},
        // {"key" : "po.vendor_id", "header" : "Vendor1", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor"},
        // {"key" : "po.vendor_id", "header" : "Vendor2", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors2"},
        // {"key" : "po.vendor_id", "header" : "Vendor2", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor2"},
        // {"key" : "po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        // {"key" : "po.rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "po.rfq.orders_list", "header" : "RFQ Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        // {"key" : "po.rfq.vendors", "header" : "Vendors", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "po.rfq.vendors_list", "header" : "RFQ Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors"},
        // {"key" : "po.rfq.vendors2_list", "header" : "RFQ Vendors2", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors2"},
        
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        // {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "items_rejected", "header" : "Rej Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        // {"key" : "items_rejected", "header" : "Rej Quantity", style : {width : "70px"}, "type" : "list_count"},
        
        // {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        // {"key" : "items_rejected", "header" : "Rejected Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        // {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "picklists" : [
        {"key" : "picklist_id", "header" : "PickList ID", style : {width : "150px"}},
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "boxing_batches_list", "header" : "Status", style : {width : "90px"}, "type" : "custom", "type1" : "picklist_status"},
        {"key" : "orders_list", "header" : "Order IDs", style : {width : "180px"}, "type" : "custom", "type1" : "order_ids_array_string"},
        {"key" : "orders_list", "header" : "Order Created", style : {width : "180px"}, "type" : "custom", "type1" : "order_created_at_array_string"},
        {"key" : "orders_list", "header" : "Customers", style : {width : "180px"}, "type" : "custom", "type1" : "order_customer_firstnames_array_string"},
        {"key" : "boxing_batches_list", "header" : "Boxing Batches", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "list", "header" : "Items", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "list", "header" : "Quantity", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "quantity"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        
        {"key" : "orders_list", "header" : "Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        
        {"key" : "list", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus2"},
        // {"key" : "dsps", "header" : "DSPs", style : {width : "80px"}, "type" : "boolean"},
        // {"key" : "dsps", "header" : "DSPs", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "dsps"},
        {"key" : "inventory_snapshot", "header" : "Inventory Snapshot", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "inventory_items"},
        // {"key" : "rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "putaway_batches" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "items", "header" : "Locations", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "location"},
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "quantity"},
        // {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},        
        // {"key" : "items", "header" : "Locations", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "items", "header" : "Location & Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "locations_skus"},
        {"key" : "sr_id", "header" : "SR ID", style : {width : "90px"}},
        {"key" : "grn_id", "header" : "GRN ID", style : {width : "90px"}},
        {"key" : "grn.po_id", "header" : "PO ID", style : {width : "90px"}},
        {"key" : "grn.po.po_id", "header" : "PO ID", style : {width : "180px"}},
        {"key" : "grn.po.rfq.id", "header" : "RFQ ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "grn.po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        // {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "started_at", "header" : "Started at", style : {width : "150px"}, "type" : "mongodate"},
        // {"key" : "rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "grn.po.vendor_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors"},
        {"key" : "grn.po.vendor_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "order"},
        {"key" : "grn.po.rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "grn.po.rfq.orders_list", "header" : "RFQ Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        // {"key" : "grn.po.rfq.vendors", "header" : "Vendors", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "grn.po.rfq.vendors_list", "header" : "RFQ Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors"},        
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "started_by", "header" : "Started By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "operators", "header" : "Operators", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "operators", "header" : "Operators", style : {width : "80px"}, panel : "right", "type" : "custom", "type1" : "dashboard_roles_string2"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "putaways_incomplete" : [
        // {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        // {"key" : "grn_id", "header" : "GRN ID", style : {width : "250px"}},
        // {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "grn_id", "header" : "GRN ID", style : {width : "90px"}},
        {"key" : "grn_id_full", "header" : "GRN ID", style : {width : "150px"}},
        // {"key" : "po.po_id", "header" : "PO ID", style : {width : "180px"}},
        // {"key" : "po.po_type", "header" : "PO Type", style : {width : "180px"}},
        // {"key" : "po.rfq.id", "header" : "RFQ ID", style : {width : "180px"}, roles : ["admin"]},
        // {"key" : "po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        {"key" : "created.created_warehouse", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "last_updated.updated_at", "header" : "Last Updated At", style : {width : "150px"}, "type" : "firebasedate"},
        {"key" : "last_updated.updated_by", "header" : "Last Updated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "users", "header" : "Users", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "users", "header" : "Users", style : {width : "80px"}, panel : "right", "type" : "custom", "type1" : "dashboard_roles_string1"},
        {"key" : "filled_items", "header" : "Items", style : {width : "70px"}, "type" : "custom", "type1" : "putaways_incomplete_items_count"},
        {"key" : "filled_items", "header" : "Qty", style : {width : "70px"}, "type" : "custom", "type1" : "putaways_incomplete_items_quantity"},
        // {"key" : "filled_items", "header" : "Rej Qty", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity_rejected"},
        // {"key" : "filled_items", "header" : "Orig Qty", style : {width : "100px"}, "type" : "custom", "type1" : "item_quantity_inner"},
        {"key" : "created.created_at", "header" : "Created At", style : {width : "150px"}, "type" : "firebasedate"},
        {"key" : "created.created_by", "header" : "Created By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        // {"key" : "po.vendor_id", "header" : "Vendor1", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors"},
        // {"key" : "po.vendor_id", "header" : "Vendor1", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor"},
        // {"key" : "po.vendor_id", "header" : "Vendor2", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors2"},
        // {"key" : "po.vendor_id", "header" : "Vendor2", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor2"},
        // {"key" : "po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        // {"key" : "po.rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "po.rfq.orders_list", "header" : "RFQ Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        // {"key" : "po.rfq.vendors", "header" : "Vendors", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "po.rfq.vendors_list", "header" : "RFQ Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors"},
        // {"key" : "po.rfq.vendors2_list", "header" : "RFQ Vendors2", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors2"},
        
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        // {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "items_rejected", "header" : "Rej Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        // {"key" : "items_rejected", "header" : "Rej Quantity", style : {width : "70px"}, "type" : "list_count"},
        
        // {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        // {"key" : "items_rejected", "header" : "Rejected Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        // {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "putaways_incomplete_by_sales_return" : [
        // {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        // {"key" : "grn_id", "header" : "GRN ID", style : {width : "250px"}},
        // {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "sr_id", "header" : "Sales Return ID", style : {width : "90px"}},
        // {"key" : "sr_id_full", "header" : "Sales Return ID", style : {width : "150px"}},
        {"key" : "created.created_warehouse", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "last_updated.updated_at", "header" : "Last Updated At", style : {width : "150px"}, "type" : "firebasedate"},
        {"key" : "last_updated.updated_by", "header" : "Last Updated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "users", "header" : "Users", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "users", "header" : "Users", style : {width : "80px"}, panel : "right", "type" : "custom", "type1" : "dashboard_roles_string1"},
        {"key" : "filled_items", "header" : "Items", style : {width : "70px"}, "type" : "custom", "type1" : "putaways_incomplete_items_count"},
        {"key" : "filled_items", "header" : "Qty", style : {width : "70px"}, "type" : "custom", "type1" : "putaways_incomplete_items_quantity"},
        // {"key" : "filled_items", "header" : "Rej Qty", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity_rejected"},
        // {"key" : "filled_items", "header" : "Orig Qty", style : {width : "100px"}, "type" : "custom", "type1" : "item_quantity_inner"},
        {"key" : "created.created_at", "header" : "Created At", style : {width : "150px"}, "type" : "firebasedate"},
        {"key" : "created.created_by", "header" : "Created By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        // {"key" : "po.vendor_id", "header" : "Vendor1", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors"},
        // {"key" : "po.vendor_id", "header" : "Vendor1", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor"},
        // {"key" : "po.vendor_id", "header" : "Vendor2", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors2"},
        // {"key" : "po.vendor_id", "header" : "Vendor2", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor2"},
        // {"key" : "po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        // {"key" : "po.rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "po.rfq.orders_list", "header" : "RFQ Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        // {"key" : "po.rfq.vendors", "header" : "Vendors", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "po.rfq.vendors_list", "header" : "RFQ Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors"},
        // {"key" : "po.rfq.vendors2_list", "header" : "RFQ Vendors2", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors2"},
        
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        // {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "items_rejected", "header" : "Rej Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        // {"key" : "items_rejected", "header" : "Rej Quantity", style : {width : "70px"}, "type" : "list_count"},
        
        // {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        // {"key" : "items_rejected", "header" : "Rejected Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        // {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "orders_es" : [
        {"key" : "order_id", "header" : "Order ID", style : {width : "120px"}},
        {"key" : "type", "header" : "Type", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        // {"key" : "created_by", "header" : "User ID", style : {width : "100px"}},
        {"key" : "partner_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors,acc_vendors"},
        {"key" : "partner_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor_all"},

        {"key" : "client_customer_details.name", "header" : "Customer", style : {width : "120px"}},
        // {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "client_customer_details.shipping_address.city", "header" : "City", style : {width : "90px"}},
        {"key" : "line_items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "line_items", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        
        {"key" : "items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "items", "header" : "Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_item_statuses", "unique_key" : "status"},
        // {"key" : "sales_order.warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        // {"key" : "client_order.sales_order.items", "header" : "Client Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        
        // {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        // {"key" : "items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        // {"key" : "items", "header" : "DPSP Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "ttdpsp"},
        // {"key" : "sales_order.warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        // {"key" : "items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        
        // {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        {"key" : "sales_invoices", "header" : "Sales Invoices", style : {width : "120px"}, "type" : "custom", "type1" : "sales_invoices_numbers_es"},





        {"key" : "client_order.sales_order.so_order_id", "header" : "Client SO ID", style : {width : "120px"}},
        {"key" : "client_order.stockone_order_id", "header" : "Client Order ID", style : {width : "100px"}},
        // {"key" : "client_order.created_by", "header" : "User ID", style : {width : "100px"}},
        // {"key" : "client_order.user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "client_order.created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        // {"key" : "user_details.shipping_city", "header" : "City", style : {width : "90px"}},
        {"key" : "client_order.order_lines", "header" : "Client Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "client_order.order_lines", "header" : "Client Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        // {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        
        {"key" : "client_order.sales_order.status", "header" : "Client SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "client_order.sales_order.items", "header" : "Client Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "client_order.sales_order.items", "header" : "Client BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "client_order.sales_order.items", "header" : "Client FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "client_order.sales_order.items", "header" : "Client DPSP Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "ttdpsp"},
        // {"key" : "sales_order.warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "client_order.sales_order.items", "header" : "Client Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "client_order.total", "header" : "Client Total Price", style : {width : "70px"}},
        
        {"key" : "client_order.payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        // {"key" : "sales_order.invoice_number", "header" : "Invoice No.", style : {width : "120px"}},
        
        // {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        
        // {"key" : "_id.$oid", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "remote", "type1" : "sales_order_history"},
        // {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},





        
        // {"key" : "client_customer_details.mobile", "header" : "Phone", style : {width : "90px"}},
        
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "client_order.sales_order.items", "header" : "Client Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "_id.$oid", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "remote", "type1" : "dpsp_order_history"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],

      "sales_returns_es" : [
        // {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "credit_note_number", "header" : "Credit Note No.", style : {width : "120px"}},
        {"key" : "partner_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors"},
        {"key" : "partner_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor"},
        // {"key" : "warehouse_id", "header" : "Warehouse", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "sku_code"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "quantity"},
        {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus4"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        
        {"key" : "invoice_number", "header" : "Invoice Number", style : {width : "120px"}},
        {"key" : "invoice.invoice_date", "header" : "Invoice Date", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "invoice.invoice_type", "header" : "Inv. Type", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        // {"key" : "invoice.payment_status", "header" : "Payment Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "invoice.invoice_value", "header" : "Invoice Value", style : {width : "70px"}},
        {"key" : "invoice.balance_payable", "header" : "Balance Payable", style : {width : "70px"}},
        {"key" : "invoice.amount_received", "header" : "Amount Received", style : {width : "70px"}},
        {"key" : "invoice.payments", "header" : "Payments", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "sales_invoice_payments"},

        
        {"key" : "invoice.customer_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "invoice.customer_mobile", "header" : "Phone", style : {width : "90px"}},
        // {"key" : "order.created_by", "header" : "User ID", style : {width : "100px"}},
        //IMP
        {"key" : "invoice.created_at", "header" : "Invoice Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        // {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},

        {"key" : "invoice.line_items", "header" : "Items", style : {width : "70px"}, "panel" : "right", "type" : "object_count"},
        {"key" : "invoice.line_items", "header" : "Quantity", style : {width : "70px"}, "panel" : "right", "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "invoice.line_items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus4"},
        {"key" : "invoice.billing_address.city", "header" : "Billing City", style : {width : "90px"}},
        {"key" : "invoice.billing_address.state", "header" : "Billing State", style : {width : "90px"}},
        {"key" : "invoice.billing_address.pincode", "header" : "Billing PIN Code", style : {width : "70px"}},
        {"key" : "invoice.billing_address.address", "header" : "Billing Address", style : {width : "250px"}},
        {"key" : "invoice.shipping_address.city", "header" : "Shipping City", style : {width : "90px"}, "panel" : "right"},
        {"key" : "invoice.shipping_address.state", "header" : "Shipping State", style : {width : "90px"}, "panel" : "right"},
        {"key" : "invoice.shipping_address.pincode", "header" : "Shipping PIN Code", style : {width : "70px"}, "panel" : "right"},
        {"key" : "invoice.shipping_address.address", "header" : "Shipping Address", style : {width : "250px"}, "panel" : "right"},
        {"key" : "invoice.customer_gst", "header" : "Customer GSTIN", style : {width : "150px"}},
        {"key" : "invoice.notes", "header" : "Notes", style : {width : "250px"}},
        {"key" : "invoice.payment_mode", "header" : "Payment Mode", style : {width : "180px"}, "panel" : "right"},
        // {"key" : "invoice.operated_by", "header" : "Invoice Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"}
      ],

      "boxing_batches_es" : [
        // DSP CODE	DOCKET NO.	INVOICE NO.	INVOICE VALUE
        {"key" : "id", "header" : "ID", style : {width : "90px"}},
        // {"key" : "order.order_id", "header" : "Order ID", style : {width : "120px"}},
        {"key" : "order.client_order.stockone_order_id", "header" : "Order ID", style : {width : "120px"}},
        {"key" : "partner.name", "header" : "Vendor", style : {width : "180px"}},
        {"key" : "boxes", "header" : "Boxes", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "boxes", "header" : "Items", style : {width : "70px"}, "type" : "custom", "type1" : "boxing_items_count"},
        {"key" : "boxes", "header" : "Quantity", style : {width : "70px"}, "type" : "custom", "type1" : "boxing_items_quantity"},
        {"key" : "boxes", "header" : "Manifested", style : {width : "70px"}, "type" : "custom", "type1" : "boxing_manifest_count"},
        {"key" : "boxes", "header" : "Dispatched", style : {width : "70px"}, "type" : "custom", "type1" : "boxing_dispatched_count"},
        
        {"key" : "boxes", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "boxing_items_es", "extra_key" : "id"},
        {"key" : "boxes", "header" : "DSPs", style : {width : "70px"}, "type" : "custom", "type1" : "dsps_check"},
        // {"key" : "boxes", "header" : "DSPs", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "dsps_details"},
        
        
        
        // {"key" : "created_by", "header" : "User ID", style : {width : "100px"}},
        {"key" : "order.client_customer_details.name", "header" : "Customer", style : {width : "120px"}},
        // {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "order.client_customer_details.shipping_address.city", "header" : "City", style : {width : "90px"}},
        // {"key" : "line_items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        // {"key" : "line_items", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        
        //IMP
        {"key" : "order.created_at", "header" : "Order Created at", style : {width : "150px"}, "type" : "mongodate"},
        
        // {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "order.items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        // {"key" : "items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        // {"key" : "items", "header" : "DPSP Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "ttdpsp"},
        // {"key" : "sales_order.warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        // {"key" : "items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "order.total", "header" : "Order Total", style : {width : "70px"}},
        
        // {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        // {"key" : "order.sales_invoice.invoice_number", "header" : "Sales Invoice No.", style : {width : "120px"}},
        {"key" : "order.sales_invoices", "header" : "Sales Invoices", style : {width : "120px"}, "type" : "custom", "type1" : "sales_invoices_numbers_es"},
        
        {"key" : "order.client_customer_details.mobile", "header" : "Phone", style : {width : "90px"}},


        // {"key" : "grn.po_id", "header" : "PO ID", style : {width : "250px"}},
        // {"key" : "grn.po.po_id", "header" : "PO ID", style : {width : "180px"}},
        // {"key" : "grn.po.rfq.id", "header" : "RFQ ID", style : {width : "120px"}},
        // {"key" : "grn.po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        // // {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "warehouse_id", "header" : "Warehouse", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        // {"key" : "started_at", "header" : "Started at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        // {"key" : "started_by", "header" : "Started By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        // {"key" : "operators", "header" : "Operators", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "operators", "header" : "Operators", style : {width : "80px"}, panel : "right", "type" : "custom", "type1" : "dashboard_roles_string2"},
        // {"key" : "rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "grn.po.vendor_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors"},
        // {"key" : "grn.po.vendor_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor"},
        // {"key" : "grn.po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        // {"key" : "grn.po.rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "grn.po.rfq.orders_list", "header" : "RFQ Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        // {"key" : "grn.po.rfq.vendors", "header" : "Vendors", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "grn.po.rfq.vendors_list", "header" : "RFQ Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors"},        
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "reverse_pickups" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}},
        {"key" : "warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        
        {"key" : "invoice_number", "header" : "Invoice Number", style : {width : "120px"}},
        {"key" : "order.stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        {"key" : "boxing_batch_id", "header" : "Boxing Batch ID", style : {width : "90px"}},
        {"key" : "box_id", "header" : "Box ID", style : {width : "90px"}},
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        
        {"key" : "dispatch_data", "header" : "Tracking Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "tracking_details"},
        {"key" : "dispatch_data", "header" : "Tracking Status", style : {width : "70px"}, "type" : "custom", "type1" : "dsps_tracking_check1"},
        // {"key" : "boxes", "header" : "DSPs", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "dsps_details"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "boxing_batches" : [
        // DSP CODE	DOCKET NO.	INVOICE NO.	INVOICE VALUE
        {"key" : "id", "header" : "ID", style : {width : "90px"}},
        {"key" : "warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        
        {"key" : "picklist.orders_list", "header" : "Order IDs", style : {width : "180px"}, "type" : "custom", "type1" : "order_ids_array_string"},
        {"key" : "picklist.orders_list", "header" : "Order Created", style : {width : "180px"}, "type" : "custom", "type1" : "order_created_at_array_string"},
        // {"key" : "picklist.dsps", "header" : "DSPs", style : {width : "80px"}, "type" : "boolean"},

        {"key" : "picklist.orders_list", "header" : "Channels", style : {width : "90px"}, "type" : "custom", "type1" : "order_channel_array_string"},
        {"key" : "boxes", "header" : "Boxes", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "boxes", "header" : "Items", style : {width : "70px"}, "type" : "custom", "type1" : "boxing_items_count"},
        {"key" : "boxes", "header" : "Quantity", style : {width : "70px"}, "type" : "custom", "type1" : "boxing_items_quantity"},
        {"key" : "boxes", "header" : "Manifested", style : {width : "70px"}, "type" : "custom", "type1" : "boxing_manifest_count"},
        {"key" : "boxes", "header" : "Dispatched", style : {width : "70px"}, "type" : "custom", "type1" : "boxing_dispatched_count"},
        {"key" : "picklist.orders_list", "header" : "Customers", style : {width : "180px"}, "type" : "custom", "type1" : "order_customer_firstnames_array_string"},
        
        {"key" : "boxes", "header" : "Orders", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "so_order_id"},
        {"key" : "boxes", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "boxing_items", "extra_key" : "id", "extra_key1" : "picklist.orders_list"},
        {"key" : "boxes", "header" : "DSPs", style : {width : "70px"}, "type" : "custom", "type1" : "dsps_check"},
        {"key" : "boxes", "header" : "Tracking Status", style : {width : "70px"}, "type" : "custom", "type1" : "dsps_tracking_check"},
        // {"key" : "boxes", "header" : "DSPs", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "dsps_details"},
        {"key" : "picklist.id", "header" : "Picklist ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "picklist.picklist_id", "header" : "Picklist ID", style : {width : "150px"}},
        {"key" : "picklist.orders", "header" : "PickList Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "picklist.orders_list", "header" : "PickList Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        {"key" : "picklist.list", "header" : "PickList Items", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "picklist.list", "header" : "PickList Quantity", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "quantity"},
        {"key" : "picklist.list", "header" : "PickList Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus2"},
        {"key" : "picklist.inventory_snapshot", "header" : "PickList Inventory Snapshot", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "inventory_items"},
        // {"key" : "grn.po_id", "header" : "PO ID", style : {width : "250px"}},
        // {"key" : "grn.po.po_id", "header" : "PO ID", style : {width : "180px"}},
        // {"key" : "grn.po.rfq.id", "header" : "RFQ ID", style : {width : "120px"}},
        // {"key" : "grn.po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        // // {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "started_at", "header" : "Started at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "started_by", "header" : "Started By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "operators", "header" : "Operators", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "operators", "header" : "Operators", style : {width : "80px"}, panel : "right", "type" : "custom", "type1" : "dashboard_roles_string2"},
        // {"key" : "rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "grn.po.vendor_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors"},
        // {"key" : "grn.po.vendor_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor"},
        // {"key" : "grn.po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        // {"key" : "grn.po.rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "grn.po.rfq.orders_list", "header" : "RFQ Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        // {"key" : "grn.po.rfq.vendors", "header" : "Vendors", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "grn.po.rfq.vendors_list", "header" : "RFQ Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors"},        
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "boxings_incomplete" : [
        // {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        // {"key" : "grn_id", "header" : "GRN ID", style : {width : "250px"}},
        // {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "pl_id", "header" : "Picklist ID", style : {width : "90px"}},
        {"key" : "pl_id_full", "header" : "Picklist ID", style : {width : "150px"}},
        // {"key" : "po.po_id", "header" : "PO ID", style : {width : "180px"}},
        // {"key" : "po.po_type", "header" : "PO Type", style : {width : "180px"}},
        // {"key" : "po.rfq.id", "header" : "RFQ ID", style : {width : "180px"}, roles : ["admin"]},
        // {"key" : "po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        {"key" : "created.created_warehouse", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "last_updated.updated_at", "header" : "Last Updated At", style : {width : "150px"}, "type" : "firebasedate"},
        {"key" : "last_updated.updated_by", "header" : "Last Updated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "users", "header" : "Users", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "users", "header" : "Users", style : {width : "80px"}, panel : "right", "type" : "custom", "type1" : "dashboard_roles_string1"},
        {"key" : "skus", "header" : "Items", style : {width : "70px"}, "type" : "custom", "type1" : "boxings_incomplete_items_count"},
        {"key" : "skus", "header" : "Qty", style : {width : "70px"}, "type" : "custom", "type1" : "boxings_incomplete_items_quantity"},
        // {"key" : "filled_items", "header" : "Rej Qty", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity_rejected"},
        // {"key" : "filled_items", "header" : "Orig Qty", style : {width : "100px"}, "type" : "custom", "type1" : "item_quantity_inner"},
        {"key" : "created.created_at", "header" : "Created At", style : {width : "150px"}, "type" : "firebasedate"},
        {"key" : "created.created_by", "header" : "Created By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        // {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "items_rejected", "header" : "Rej Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        // {"key" : "items_rejected", "header" : "Rej Quantity", style : {width : "70px"}, "type" : "list_count"},
        
        // {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        // {"key" : "items_rejected", "header" : "Rejected Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        // {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      
      "debit_notes" : [
        {"key" : "dn_id", "header" : "Request ID", style : {width : "150px"}},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        // {"key" : "status", "header" : "Status", style : {width : "120px"}, "type" : "custom", "type1" : "vpr_status", "extra_key" : "payments"},
        {"key" : "type", "header" : "Type", style : {width : "90px"}},
        {"key" : "entity", "header" : "Entity", style : {width : "90px"}},
        {"key" : "entity_id", "header" : "Entity", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "distributors"},
        {"key" : "entity_id", "header" : "Entity", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "distributor"},
        {"key" : "amount", "header" : "Amount", style : {width : "70px"}},
        {"key" : "document_id", "header" : "Document ID", style : {width : "150px"}},
        // {"key" : "payments", "header" : "Paid Amount", style : {width : "120px"}, "type" : "array_key_sum_with_filter", "key_sum" : "amount", "filter_key" : "status", "filter_key_value" : "processed"},
        {"key" : "payments", "header" : "Paid Amount", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "amount"},
        // {"key" : "payments", "header" : "Payments Made", style : {width : "70px"}, "type" : "list_count_with_filter", "filter_key" : "status", "filter_key_value" : "processed"},
        {"key" : "notes", "header" : "Notes", style : {width : "150px"}},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
        // {"key" : "payments", "header" : "Payments", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_payments"},
        // {"key" : "tax_invoices", "header" : "Uploaded Invoices", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_tax_invoices"},
        {"key" : "history", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_history_items"},
      ],
      "vendor_payment_requests" : [
        {"key" : "vpr_id", "header" : "Request ID", style : {width : "150px"}},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        // {"key" : "status", "header" : "Status", style : {width : "120px"}, "type" : "custom", "type1" : "vpr_status", "extra_key" : "payments"},
        {"key" : "request_type", "header" : "Request Type", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "payment_type", "header" : "Payment Type", style : {width : "90px"}},
        {"key" : "vendor_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors"},
        {"key" : "vendor_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor"},
        {"key" : "invoice_amount", "header" : "Invoice Amount", style : {width : "70px"}},
        // {"key" : "payments", "header" : "Paid Amount", style : {width : "120px"}, "type" : "array_key_sum_with_filter", "key_sum" : "amount", "filter_key" : "status", "filter_key_value" : "processed"},
        {"key" : "payments", "header" : "Paid Amount", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "amount"},
        // {"key" : "payments", "header" : "Payments Made", style : {width : "70px"}, "type" : "list_count_with_filter", "filter_key" : "status", "filter_key_value" : "processed"},
        {"key" : "payments", "header" : "Payments Made", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "tax_invoices", "header" : "Uploaded Invoices Amount", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "invoice_amount"},
        {"key" : "tax_invoices", "header" : "Uploaded Invoices", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "invoice_number", "header" : "Invoice Number", style : {width : "120px"}},
        {"key" : "invoice_date", "header" : "Invoice Date", style : {width : "90px"}},
        {"key" : "notes", "header" : "Notes", style : {width : "150px"}},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "entity", "header" : "Entity", style : {width : "90px"}},
        {"key" : "company", "header" : "Company", style : {width : "90px"}},
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
        {"key" : "payments", "header" : "Payments", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_payments"},
        {"key" : "tax_invoices", "header" : "Uploaded Invoices", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_tax_invoices"},
        {"key" : "history", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_history_items"},
      ],
      "vendor_payment_requests_acc" : [
        {"key" : "vpr_id", "header" : "Request ID", style : {width : "150px"}},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        // {"key" : "status", "header" : "Status", style : {width : "120px"}, "type" : "custom", "type1" : "vpr_status", "extra_key" : "payments"},
        {"key" : "request_type", "header" : "Request Type", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "payment_type", "header" : "Payment Type", style : {width : "90px"}},
        {"key" : "vendor_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "acc_vendors"},
        {"key" : "vendor_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "acc_vendor"},
        {"key" : "invoice_amount", "header" : "Invoice Amount", style : {width : "70px"}},
        // {"key" : "payments", "header" : "Paid Amount", style : {width : "120px"}, "type" : "array_key_sum_with_filter", "key_sum" : "amount", "filter_key" : "status", "filter_key_value" : "processed"},
        {"key" : "payments", "header" : "Paid Amount", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "amount"},
        // {"key" : "payments", "header" : "Payments Made", style : {width : "70px"}, "type" : "list_count_with_filter", "filter_key" : "status", "filter_key_value" : "processed"},
        {"key" : "payments", "header" : "Payments Made", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "tax_invoices", "header" : "Uploaded Invoices Amount", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "invoice_amount"},
        {"key" : "tax_invoices", "header" : "Uploaded Invoices", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "invoice_number", "header" : "Invoice Number", style : {width : "120px"}},
        {"key" : "invoice_date", "header" : "Invoice Date", style : {width : "90px"}},
        {"key" : "notes", "header" : "Notes", style : {width : "150px"}},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "entity", "header" : "Entity", style : {width : "90px"}},
        {"key" : "company", "header" : "Company", style : {width : "90px"}},
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
        {"key" : "payments", "header" : "Payments", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_payments"},
        {"key" : "tax_invoices", "header" : "Uploaded Invoices", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_tax_invoices"},
        {"key" : "history", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_history_items"},
      ],
      "inventory_es" : [
        // {"key" : "part.product_id", "header" : "Product ID", style : {width : "120px"}},
        {"key" : "partner_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors,acc_vendors"},
        {"key" : "partner_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor_all"},
        {"key" : "sku_code", "header" : "SKU Code", style : {width : "120px"}, mobile_view_details : {isTitle : true, orderV : 1, orderH : 1}},
        {"key" : "qty", "header" : "Quantity", style : {width : "70px"}, mobile_view_details : {orderV : 1, orderH : 2}},
        // {"key" : "frozen_stock", "header" : "Frozen Qty", style : {width : "70px"}},
        {"key" : "location", "header" : "Location", style : {width : "90px"}, mobile_view_details : {orderV : 1, orderH : 3}},
        // {"key" : "warehouse_id", "header" : "Warehouse", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses", mobile_view_details : {orderV : 4, orderH : 1}},
        {"key" : "part.title", "header" : "Title", style : {width : "350px"}, "type" : "custom", "type1" : "sku_title", "extra_key" : "sku_code", mobile_view_details : {orderV : 2, orderH : 1}},
        // {"key" : "part.title", "header" : "Title", style : {width : "250px"}, mobile_view_details : {orderV : 2, orderH : 1}},
        // {"key" : "part.parts", "header" : "Category", style : {width : "120px"}, "type" : "array_object_string_unique", "key_display" : "sku_category", mobile_view_details : {orderV : 3, orderH : 1}},
        {"key" : "part.sku_brand", "header" : "Brand", style : {width : "150px"}, mobile_view_details : {orderV : 3, orderH : 2}},
        {"key" : "sku_code", "header" : "BarCode", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "barcode"},
        {"key" : "part.mrp", "header" : "Selling price", style : {width : "70px"}},
        // {"key" : "part.price", "header" : "Price", style : {width : "70px"}},
        // {"key" : "part.parts", "header" : "Alternative Parts", style : {width : "70px"}, "type" : "custom", "type1" : "alt_parts_count_es", "extra_key" : "product_id"},
        // {"key" : "part.parts", "header" : "Alternative Parts", style : {width : "70px"}, "panel" : "right", "type" : "custom", "type1" : "alt_parts_product_ids_es", "extra_key" : "sku_code"},
        // {"key" : "part.parts", "header" : "Image", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "image_es"},
    ],
  }









  const user_roles_es = {
    // sales: {title : "Sales Executive"},
    // procurement: {title : "Procurement Executive"},
    // procurement_admin: {title : "Procurement Admin"},
    // ops: {title : "Operations Executive"},
    // acc_ops: {title : "Accessories Operations Executive"},
    // warehouse_manager: {title : "Warehouse Manager"},
    // warehouse_inbound: {title : "Warehouse Inbound Executive"},
    // warehouse_outbound: {title : "Warehouse Outbound Executive"},
    // finance: {title : "Finance Executive"},
    admin: {title : "Admin"},
    // ops_admin: {title : "Operations Admin"},
    // acc_ops_admin: {title : "Accessories Operations Admin"},
};

  const tabGroupDataES = [
    // {tag :"global", title : "Global"}, 
    {tag :"data", title : "Data"}, 
    {tag :"sales", title : "Sales"}, 
    {tag :"finance", title : "Finance"}, 
    {tag :"warehouse", title : "Warehouse"}, 
    // {tag :"orders", title : "Orders"}, 
    // {tag : "procurement", title : "Procurement"}, 
    // {tag : "outbound", title : "OutBound"}, 
    {tag : "inbound", title : "InBound"}, 
];

const tabDataES = [
    {tag:"dashboard" , title : "Dashboard", middlePanelTitle : "Dashboard", type : "2", "group" : "data",
        roles : ["sales", "procurement", "procurement_admin", "warehouse_manager", "warehouse_inbound", "warehouse_outbound", "warehouse_admin", "admin", "finance", "admin", "ops_admin", "ops", "acc_ops", "acc_ops_admin"]}, 
    {tag:"orders" , title : "Orders", middlePanelTitle : "Orders", type : "3", "group" : "sales", 
        roles : ["admin"]},
    {tag:"sales_invoices" , title : "Invoices", middlePanelTitle : "Invoices", type : "3", "group" : "sales", 
        roles : ["admin"]},
    {tag:"customers" , title : "Customers", middlePanelTitle : "Customers", type : "3", "group" : "sales", 
        roles : ["admin"]},
    {tag:"manufos" , report : true, title : "Manufacturing Orders", tabTitle : "MOs", middlePanelTitle : "Manufacturing Order List", type : "3", "group" : "sales", 
        roles : ["admin"]}, 
    {tag:"skus" , title : "Catalogue", middlePanelTitle : "Catalogue", type : "3", "group" : "data", 
        roles : ["admin", "warehouse_manager"]}, 
    {tag:"periodic_tasks" , title : "Periodic Tasks", middlePanelTitle : "Periodic Tasks", type : "3", "group" : "data", 
        roles : ["admin", "warehouse_manager", "procurement_admin", "ops_admin", "acc_ops_admin"]}, 
    {tag:"employees" , title : "Employees", middlePanelTitle : "Employees", type : "3", "group" : "data", 
        roles : ["admin"]}, 
    // {tag:"actionables" , report : true, title : "Actionables", middlePanelTitle : "Actionables", type : "3", "group" : "global", 
    //     roles : ["admin", "warehouse_outbound"]}, 
    {tag:"warehouses" , title : "Warehouses", middlePanelTitle : "Warehouses", type : "3", "group" : "warehouse", 
        roles : ["admin", "warehouse_manager"]}, 
    {tag:"inventory" , report : true, title : "Inventory", middlePanelTitle : "Inventory", type : "3", "group" : "warehouse", 
        roles : ["admin", "warehouse_manager"]}, 
    {tag:"inventory_history" , title : "Inventory History", middlePanelTitle : "Inventory History", tabTitle : "INVY History", type : "3", "group" : "warehouse", 
        roles : ["admin", "warehouse_manager"]}, 
    {tag:"wh_layout" , title : "Warehouse Layout", middlePanelTitle : "WH Layout", tabTitle : "WH Layout", type : "3", "group" : "warehouse", 
        roles : ["admin", "warehouse_manager"]}, 
    // {tag:"wh_transfer_orders" , title : "Inventory Transfers", middlePanelTitle : "Warehouse Inventory Transfers", tabTitle : "INVY Transfers", type : "3", "group" : "warehouse", 
    //     roles : ["admin", "warehouse_manager"]}, 
    // {tag:"orders" , title : "Orders", middlePanelTitle : "Order List", type : "3", "group" : "orders", 
    //     roles : ["admin", "procurement_admin", "warehouse_inbound"]}, 
    // {tag:"sales_orders" , report : true, title : "Sales Orders", middlePanelTitle : "Sales Order List", type : "3", "group" : "orders", 
    //     roles : ["admin", "procurement_admin", "warehouse_inbound", "warehouse_outbound", "warehouse_manager"]}, 
    // {tag:"purchos" , report : true, title : "Purchase Orders", tabTitle : "PurchOs", middlePanelTitle : "Purchase Order List", type : "3", "group" : "procurement", 
    //     roles : ["admin", "procurement_admin", "warehouse_inbound"]}, 
    // {tag:"purchos_acc" , title : "Acc Purchase Orders", tabTitle : "Acc POs", middlePanelTitle : "Accessories Purchase Order List", type : "3", "group" : "procurement", 
    //     roles : ["admin", "procurement_admin", "warehouse_inbound"]}, 
    {tag:"boxing_batches" , report : true, title : "Boxing Batches", tabTitle : "Boxing Bs", middlePanelTitle : "Boxing Batch List", middlePanelTitle : "Boxing Batches", type : "3", "group" : "sales", 
        roles : ["admin", "warehouse_manager"]}, 
    // {tag:"generate_sales_return" , title : "Generate Sales Return", tabTitle : "Sales Return +", middlePanelTitle : "Generate Sales Return - Select Sales Orders", type : "3", "group" : "inbound", 
    //     roles : ["admin", "warehouse_manager"]}, 
    {tag:"dpsp_sales_returns" , report : true, title : "Sales Returns", tabTitle : "Sales Returns", middlePanelTitle : "Sales Return List", middlePanelMobileTitle : "Sales Returns", type : "3", "group" : "inbound", 
        roles : ["admin", "warehouse_manager"]}, 
];

const rowDataES = {
    "manufos" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "mo_id", "header" : "MO ID", style : {width : "210px"}},
        // {"key" : "mo_type", "header" : "Type", style : {width : "70px"}, "type" : "custom", "type1" : "rfq_type"},
        {"key" : "mo_type", "header" : "Type", style : {width : "70px"}},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        // {"key" : "vendor_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "acc_vendors"},
        // {"key" : "vendor_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "acc_vendor"},
        
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "items", "header" : "Mfd. Qty", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity_mfd"},
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        // {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus5"},
        // {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        
        {"key" : "mo_invoices", "header" : "Uploaded Invoices Amount", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "invoice_amount"},
        {"key" : "mo_invoices", "header" : "Uploaded Invoices", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "mo_invoices", "header" : "Uploaded Invoices", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_tax_invoices"},
        // {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
        // {"key" : "history", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_history_items"},
      ],
    "sales_invoices" : [
        // 'customer' : '$customer'
        //         'status' : 1,  
        //         
        // {"key" : "id", "header" : "ID", style : {width : "90px"}},
        {"key" : "warehouse_id", "header" : "Warehouse", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses", mobile_view_details : {orderV : 2, orderH : 2, flex : 2}},
        {"key" : "customer_name", "header" : "Customer", style : {width : "120px"}, mobile_view_details : {orderV : 1, orderH : 1}},
        {"key" : "customer_mobile", "header" : "Phone", style : {width : "90px"}, mobile_view_details : {orderV : 1, orderH : 2}},
        {"key" : "invoice_type", "header" : "Inv. Type", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses", mobile_view_details : {orderV : 3, orderH : 1}},
        {"key" : "invoice_number", "header" : "Invoice No.", style : {width : "120px"}, mobile_view_details : {isTitle : true, orderV : 2, orderH : 1}},
        {"key" : "invoice_date", "header" : "Invoice Date", style : {width : "150px"}, "type" : "mongodate", mobile_view_details : {orderV : 3, orderH : 2, flex : 2}},
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "sku_code"},
        // {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        // {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "line_items", "header" : "Items", style : {width : "70px"}, "type" : "object_count", mobile_view_details : {orderV : 4, orderH : 1, flex : 0.5}},
        {"key" : "line_items", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity", mobile_view_details : {orderV : 4, orderH : 2, flex : 0.5}},
        {"key" : "payment_status", "header" : "Payment Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses", mobile_view_details : {orderV : 4, orderH : 4}},
        {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}, mobile_view_details : {orderV : 4, orderH : 3}},
        {"key" : "invoice_value", "header" : "Invoice Value", style : {width : "70px"}, mobile_view_details : {isSubtitle : true, orderV : 5, orderH : 1}},
        {"key" : "balance_payable", "header" : "Balance Payable", style : {width : "70px"}, mobile_view_details : {orderV : 5, orderH : 3}},
        {"key" : "amount_received", "header" : "Amount Received", style : {width : "70px"}, mobile_view_details : {orderV : 5, orderH : 2}},
        {"key" : "payments", "header" : "Payments", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "sales_invoice_payments"},
        {"key" : "total_pre_extra_charges_amount", "header" : "Amount before Extra Charges", style : {width : "70px"}, "panel" : "right"},
        {"key" : "total_pre_extra_discount_amount", "header" : "Amount before Extra Discount", style : {width : "70px"}, "panel" : "right"},
        {"key" : "extra_charges", "header" : "Extra Charges", style : {width : "70px"}, "panel" : "right"},
        {"key" : "extra_charges_details", "header" : "Extra Charges Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "extra_amount_line"},
        {"key" : "extra_discount", "header" : "Extra Discount", style : {width : "70px"}, "panel" : "right"},
        {"key" : "extra_discount_details", "header" : "Extra Discount Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "extra_amount_line"},
        {"key" : "line_items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus4"},
        {"key" : "billing_address.city", "header" : "Billing City", style : {width : "90px"}, mobile_view_details : {orderV : 6, orderH : 1}},
        {"key" : "billing_address.state", "header" : "Billing State", style : {width : "90px"}, mobile_view_details : {orderV : 6, orderH : 2}},
        {"key" : "billing_address.pincode", "header" : "Billing PIN Code", style : {width : "70px"}, mobile_view_details : {orderV : 6, orderH : 3}},
        {"key" : "billing_address.address", "header" : "Billing Address", style : {width : "250px"}, mobile_view_details : {orderV : 7, orderH : 1}},
        {"key" : "shipping_address.city", "header" : "Shipping City", style : {width : "90px"}, "panel" : "right"},
        {"key" : "shipping_address.state", "header" : "Shipping State", style : {width : "90px"}, "panel" : "right"},
        {"key" : "shipping_address.pincode", "header" : "Shipping PIN Code", style : {width : "70px"}, "panel" : "right"},
        {"key" : "shipping_address.address", "header" : "Shipping Address", style : {width : "250px"}, "panel" : "right"},
        {"key" : "customer_gst", "header" : "Customer GSTIN", style : {width : "150px"}, mobile_view_details : {orderV : 1, orderH : 3}},
        {"key" : "notes", "header" : "Notes", style : {width : "250px"}},

        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "updated_at", "header" : "Updated at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles", mobile_view_details : {isFooter : true}},
        {"key" : "uploaded_invoices", "header" : "Uploaded Invoices", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_tax_invoices"},
        {"key" : "_id.$oid", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "remote", "type1" : "sales_invoice_history"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"], hideOnMobile : true},
      ],
    "customers" : [
        // {"key" : "id", "header" : "ID", style : {width : "90px"}},
        {"key" : "name", "header" : "Name", style : {width : "120px"}, mobile_view_details : {isTitle : true, orderV : 1, orderH : 1}},
        {"key" : "mobile", "header" : "Phone", style : {width : "90px"}, mobile_view_details : {orderV : 1, orderH : 2}},
        {"key" : "sales_invoices", "header" : "Balance Payable", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "balance_payable", mobile_view_details : {orderV : 2, orderH : 1}},
        {"key" : "sales_invoices", "header" : "Invoices", style : {width : "70px"}, "type" : "list_count", mobile_view_details : {orderV : 2, orderH : 2}},
        {"key" : "sales_invoices", "header" : "Invoices Amount", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "invoice_value", mobile_view_details : {orderV : 2, orderH : 3}},
        {"key" : "gstin", "header" : "GSTIN", style : {width : "150px"}, mobile_view_details : {orderV : 1, orderH : 3}},
        {"key" : "billing_address.city", "header" : "Billing City", style : {width : "90px"}, mobile_view_details : {orderV : 3, orderH : 1}},
        {"key" : "billing_address.state", "header" : "Billing State", style : {width : "90px"}, mobile_view_details : {orderV : 3, orderH : 2}},
        {"key" : "billing_address.pincode", "header" : "Billing PIN Code", style : {width : "70px"}, mobile_view_details : {orderV : 3, orderH : 3}},
        {"key" : "billing_address.address", "header" : "Billing Address", style : {width : "250px"}, mobile_view_details : {orderV : 4, orderH : 1}},
        {"key" : "shipping_address.city", "header" : "Shipping City", style : {width : "90px"}, mobile_view_details : {orderV : 5, orderH : 1}},
        {"key" : "shipping_address.state", "header" : "Shipping State", style : {width : "90px"}, mobile_view_details : {orderV : 5, orderH : 2}},
        {"key" : "shipping_address.pincode", "header" : "Shipping PIN Code", style : {width : "70px"}, mobile_view_details : {orderV : 5, orderH : 3}},
        {"key" : "shipping_address.address", "header" : "Shipping Address", style : {width : "250px"}, mobile_view_details : {orderV : 6, orderH : 1}},
        {"key" : "notes", "header" : "Notes", style : {width : "250px"}, mobile_view_details : {orderV : 7, orderH : 1}},
        
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate", panel : "right"},
        {"key" : "updated_at", "header" : "Updated at", style : {width : "150px"}, "type" : "mongodate", panel : "right"},
        // {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "employees" : [
        {"key" : "user_id", "header" : "ID", style : {width : "90px"}, mobile_view_details : {orderV : 2, orderH : 1}},
        {"key" : "user.name", "header" : "Name", style : {width : "120px"}, mobile_view_details : {isTitle : true, orderV : 2, orderH : 2, flex : 2}},
        {"key" : "user.mobile", "header" : "Phone", style : {width : "90px"}, mobile_view_details : {orderV : 1, orderH : 1}},
        {"key" : "role", "header" : "Role", style : {width : "70px"}, mobile_view_details : {orderV : 1, orderH : 2}},
        {"key" : "active", "header" : "Active", style : {width : "70px"}, type : "boolean", mobile_view_details : {orderV : 1, orderH : 3}},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate", mobile_view_details : {orderV : 3, orderH : 1}},
        {"key" : "updated_at", "header" : "Updated at", style : {width : "150px"}, "type" : "mongodate", mobile_view_details : {orderV : 3, orderH : 2}},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"], hideOnMobile : true},
      ],
    "periodic_tasks" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, mobile_view_details : {orderV : 1, orderH : 2, flex : 2}},
        {"key" : "type", "header" : "Type", style : {width : "200px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses", mobile_view_details : {isTitle : true, orderV : 3, orderH : 1, colorConditionKey : "status"}},
        // {"key" : "type", "header" : "Type", style : {width : "180px"}},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses", mobile_view_details : {orderV : 1, orderH : 1}},
        {"key" : "error_data", "header" : "Error Details", style : {width : "90px"}, "type" : "boolean", mobile_view_details : {orderV : 2, orderH : 1}},
        // {"key" : "ref_id", "header" : "Ref ID", style : {width : "120px"}},
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        // {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "error_data", "header" : "Error Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "periodic_task_error_details"},
        {"key" : "error_data.type", "header" : "Error Type", style : {width : "120px"}, mobile_view_details : {orderV : 2, orderH : 2}},
        {"key" : "error_data.text", "header" : "Error Desc", style : {width : "120px"}, mobile_view_details : {orderV : 2, orderH : 3}},
        // {"key" : "orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "orders_list", "header" : "Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate", mobile_view_details : {orderV : 4, orderH : 1}},
        {"key" : "updated_at", "header" : "Updated at", style : {width : "150px"}, "type" : "mongodate", mobile_view_details : {orderV : 4, orderH : 2}},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles", mobile_view_details : {isFooter : true}},
        {"key" : "history", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_history_items"},
    ],
    "skus" : [
        {"key" : "sku_code", "header" : "SKU Code", style : {width : "120px"}, mobile_view_details : {isTitle : true, orderV : 2, orderH : 1}},
        // {"key" : "product_id", "header" : "Product ID", style : {width : "120px"}},
        {"key" : "title", "header" : "Title", style : {width : "250px"}, mobile_view_details : {orderV : 1, orderH : 1}},
        {"key" : "parts", "header" : "Category", style : {width : "120px"}, "type" : "array_object_string_unique", "key_display" : "sku_category", mobile_view_details : {orderV : 3, orderH : 1}},
        {"key" : "sku_brand", "header" : "Brand", style : {width : "150px"}, mobile_view_details : {orderV : 3, orderH : 2}},
        {"key" : "inventory", "header" : "Stock", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "qty", panel : "right"},
        {"key" : "sku_code", "header" : "BarCode", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "barcode"},
        {"key" : "mrp", "header" : "Selling Price", style : {width : "70px"}, mobile_view_details : {orderV : 2, orderH : 2}},
        // {"key" : "price", "header" : "Price", style : {width : "70px"}},
        // {"key" : "parts", "header" : "Alternative Parts", style : {width : "70px"}, "type" : "custom", "type1" : "alt_parts_count_es", "extra_key" : "product_id"},
        // {"key" : "parts", "header" : "Alternative Parts", style : {width : "70px"}, "panel" : "right", "type" : "custom", "type1" : "alt_parts_product_ids_es", "extra_key" : "sku_code"},
        {"key" : "parts", "header" : "Image", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "image_es"},
    ],
    "warehouses" : [
        {"key" : "code", "header" : "Code", style : {width : "120px"}, mobile_view_details : {isTitle : true, orderV : 5, orderH : 2}},
        // {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "address.city", "header" : "City", style : {width : "90px"}, mobile_view_details : {orderV : 1, orderH : 1}},
        {"key" : "address.state", "header" : "State", style : {width : "90px"}, mobile_view_details : {orderV : 1, orderH : 2}},
        {"key" : "address.pincode", "header" : "PIN Code", style : {width : "70px"}, mobile_view_details : {orderV : 1, orderH : 3}},
        {"key" : "finance.bank_name", "header" : "Bank", style : {width : "150px"}, mobile_view_details : {orderV : 4, orderH : 1}},
        {"key" : "finance.bank_account", "header" : "Account No.", style : {width : "100px"}, mobile_view_details : {orderV : 4, orderH : 2}},
        {"key" : "finance.gstin", "header" : "GSTIN", style : {width : "150px"}, mobile_view_details : {orderV : 5, orderH : 1}},
        {"key" : "address.full_address", "header" : "Address", style : {width : "400px"}, mobile_view_details : {orderV : 3, orderH : 1}},
        // {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
    ],
    "inventory" : [
        // {"key" : "part.product_id", "header" : "Product ID", style : {width : "120px"}},
        {"key" : "sku_code", "header" : "SKU Code", style : {width : "120px"}, mobile_view_details : {isTitle : true, orderV : 1, orderH : 1}},
        {"key" : "qty", "header" : "Quantity", style : {width : "70px"}, mobile_view_details : {orderV : 1, orderH : 2}},
        // {"key" : "frozen_stock", "header" : "Frozen Qty", style : {width : "70px"}},
        {"key" : "location", "header" : "Location", style : {width : "90px"}, mobile_view_details : {orderV : 1, orderH : 3}},
        {"key" : "warehouse_id", "header" : "Warehouse", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses", mobile_view_details : {orderV : 4, orderH : 1}},
        {"key" : "part.title", "header" : "Title", style : {width : "350px"}, "type" : "custom", "type1" : "sku_title", "extra_key" : "sku_code", mobile_view_details : {orderV : 2, orderH : 1}},
        // {"key" : "part.title", "header" : "Title", style : {width : "250px"}, mobile_view_details : {orderV : 2, orderH : 1}},
        // {"key" : "part.parts", "header" : "Category", style : {width : "120px"}, "type" : "array_object_string_unique", "key_display" : "sku_category", mobile_view_details : {orderV : 3, orderH : 1}},
        {"key" : "part.sku_brand", "header" : "Brand", style : {width : "150px"}, mobile_view_details : {orderV : 3, orderH : 2}},
        {"key" : "sku_code", "header" : "BarCode", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "barcode"},
        {"key" : "part.mrp", "header" : "Selling price", style : {width : "70px"}},
        // {"key" : "part.price", "header" : "Price", style : {width : "70px"}},
        // {"key" : "part.parts", "header" : "Alternative Parts", style : {width : "70px"}, "type" : "custom", "type1" : "alt_parts_count_es", "extra_key" : "product_id"},
        // {"key" : "part.parts", "header" : "Alternative Parts", style : {width : "70px"}, "panel" : "right", "type" : "custom", "type1" : "alt_parts_product_ids_es", "extra_key" : "sku_code"},
        {"key" : "part.parts", "header" : "Image", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "image_es"},
    ],
    "inventory_history" : [
        // {"key" : "part.product_id", "header" : "Product ID", style : {width : "120px"}},
        {"key" : "part.sku_code", "header" : "SKU Code", style : {width : "120px"}, mobile_view_details : {isTitle : true, orderV : 1, orderH : 1, colorConditionKey : "txn_type"}},
        {"key" : "txn_type", "header" : "TXN Type", style : {width : "70px"}, mobile_view_details : {isSubtitle : true, orderV : 1, orderH : 2}},
        {"key" : "qty_original", "header" : "Original Qty", style : {width : "70px"}, mobile_view_details : {orderV : 2, orderH : 1}},
        {"key" : "qty_final", "header" : "Final Qty", style : {width : "70px"}, mobile_view_details : {orderV : 2, orderH : 2}},
        {"key" : "qty_change", "header" : "Qty Change", style : {width : "70px"}, mobile_view_details : {orderV : 2, orderH : 3}},
        {"key" : "location", "header" : "Location", style : {width : "90px"}, mobile_view_details : {orderV : 3, orderH : 2}},
        {"key" : "warehouse_id", "header" : "Warehouse", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses", mobile_view_details : {orderV : 3, orderH : 1}},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate", mobile_view_details : {orderV : 6, orderH : 1}},
        {"key" : "part.title", "header" : "Title", style : {width : "350px"}, "type" : "custom", "type1" : "sku_title", "extra_key" : "part.sku_code", mobile_view_details : {orderV : 4, orderH : 1}},
        // {"key" : "part.title", "header" : "Title", style : {width : "250px"}, mobile_view_details : {orderV : 4, orderH : 1}},
        // {"key" : "part.parts", "header" : "Category", style : {width : "120px"}, "type" : "array_object_string_unique", "key_display" : "sku_category", mobile_view_details : {orderV : 5, orderH : 1}},
        {"key" : "part.sku_brand", "header" : "Brand", style : {width : "150px"}, mobile_view_details : {orderV : 5, orderH : 2}},
        {"key" : "part.mrp", "header" : "Selling Price", style : {width : "70px"}},
        // {"key" : "part.price", "header" : "Price", style : {width : "70px"}},
        {"key" : "part.parts", "header" : "Image", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "image_es"},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles", mobile_view_details : {isFooter : true}},
    ],
    "wh_transfer_orders" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "to_id", "header" : "TO ID", style : {width : "150px"}},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "warehouse_from", "header" : "WH From", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "warehouse_to", "header" : "WH To", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},        
        {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"], hideOnMobile : true},
      ],
    "wh_layout" : [
        {"key" : "code", "header" : "Warehouse", style : {width : "120px"}, mobile_view_details : {orderV : 2, orderH : 2, flex : 2}},
        {"key" : "location", "header" : "Location", style : {width : "90px"}, mobile_view_details : {isTitle : true, orderV : 1, orderH : 1}},
        {"key" : "location_type", "header" : "Location type", style : {width : "90px"}, mobile_view_details : {orderV : 1, orderH : 2}},
        {"key" : "zone", "header" : "Zone", style : {width : "120px"}, mobile_view_details : {orderV : 1, orderH : 3}},
        // {"key" : "coordinates", "header" : "Coordinates", style : {width : "100px"}},
        {"key" : "is_available", "header" : "Availability", style : {width : "70px"}, "type" : "boolean", mobile_view_details : {orderV : 2, orderH : 1}},
        {"key" : "location", "header" : "BarCode", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "barcode"},
        // {"key" : "ffmt_constant", "header" : "BarCode", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "image", "constant_value" : "", "variable_values" : []},
    ],
    "orders1" : [
        {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        {"key" : "order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "order_status", "header" : "Status", style : {width : "90px"}},
        {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        {"key" : "user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        // {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        {"key" : "created_by", "header" : "User ID", style : {width : "100px"}},
        {"key" : "order_lines", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"], hideOnMobile : true},
      ],
      "orders" : [
        {"key" : "order_id", "header" : "Order ID", style : {width : "120px"}, mobile_view_details : {isTitle : true, orderV : 2, orderH : 1}},
        // {"key" : "created_by", "header" : "User ID", style : {width : "100px"}},
        {"key" : "client_order.stockone_order_id", "header" : "Client Order ID", style : {width : "100px"}, mobile_view_details : {orderV : 2, orderH : 2}},
        {"key" : "client_acc_order.order_id", "header" : "Client Acc Order ID", style : {width : "100px"}, mobile_view_details : {orderV : 2, orderH : 2}},
        {"key" : "client_customer_details.name", "header" : "Customer", style : {width : "120px"}, mobile_view_details : {orderV : 1, orderH : 1}},
        // {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "client_customer_details.shipping_address.city", "header" : "City", style : {width : "90px"}, mobile_view_details : {orderV : 1, orderH : 2}},
        {"key" : "line_items", "header" : "Items", style : {width : "70px"}, "type" : "object_count", mobile_view_details : {orderV : 3, orderH : 1}},
        {"key" : "line_items", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity", mobile_view_details : {orderV : 3, orderH : 2}},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate", mobile_view_details : {orderV : 5, orderH : 1}},
        
        // {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        // {"key" : "items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        // {"key" : "items", "header" : "DPSP Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "ttdpsp"},
        // {"key" : "sales_order.warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        // {"key" : "items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "total", "header" : "Total Price", style : {width : "70px"}, mobile_view_details : {orderV : 3, orderH : 3, flex : 2}},
        
        // {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        {"key" : "sales_invoices", "header" : "Sales Invoices", style : {width : "120px"}, "type" : "custom", "type1" : "sales_invoices_numbers_es", mobile_view_details : {orderV : 4, orderH : 1}},
        
        // {"key" : "client_customer_details.mobile", "header" : "Phone", style : {width : "90px"}},
        
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "_id.$oid", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "remote", "type1" : "dpsp_order_history"},
        // {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "generate_sales_return" : [
        {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        {"key" : "sales_order.invoices.invoice_number", "header" : "Invoice Number", style : {width : "120px"}},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "sales_order.items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "sales_order.items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        {"key" : "order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        {"key" : "user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"], hideOnMobile : true},
      ],
      "dpsp_sales_returns" : [
        {"key" : "credit_note_number", "header" : "Credit Note No.", style : {width : "120px"}, mobile_view_details : {isTitle : true, orderV : 2, orderH : 1}},
        {"key" : "warehouse_id", "header" : "Warehouse", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "sku_code", mobile_view_details : {orderV : 4, orderH : 1}},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "quantity", mobile_view_details : {orderV : 4, orderH : 2}},
        {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus4"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate", mobile_view_details : {orderV : 2, orderH : 2}},
        
        {"key" : "invoice_number", "header" : "Invoice Number", style : {width : "120px"}, mobile_view_details : {orderV : 3, orderH : 1}},
        {"key" : "invoice.invoice_date", "header" : "Invoice Date", style : {width : "150px"}, "type" : "mongodate", mobile_view_details : {orderV : 3, orderH : 2}},
        {"key" : "invoice.invoice_type", "header" : "Inv. Type", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        // {"key" : "invoice.payment_status", "header" : "Payment Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "invoice.invoice_value", "header" : "Invoice Value", style : {width : "70px"}},
        {"key" : "invoice.balance_payable", "header" : "Balance Payable", style : {width : "70px"}},
        {"key" : "invoice.amount_received", "header" : "Amount Received", style : {width : "70px"}},
        {"key" : "invoice.payments", "header" : "Payments", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "sales_invoice_payments"},

        
        {"key" : "invoice.customer_name", "header" : "Customer", style : {width : "120px"}, mobile_view_details : {orderV : 1, orderH : 1}},
        {"key" : "invoice.customer_mobile", "header" : "Phone", style : {width : "90px"}, mobile_view_details : {orderV : 1, orderH : 2}},
        // {"key" : "order.created_by", "header" : "User ID", style : {width : "100px"}},
        //IMP
        {"key" : "invoice.created_at", "header" : "Invoice Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles", mobile_view_details : {isFooter : true}},
        // {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},

        {"key" : "invoice.line_items", "header" : "Items", style : {width : "70px"}, "panel" : "right", "type" : "object_count"},
        {"key" : "invoice.line_items", "header" : "Quantity", style : {width : "70px"}, "panel" : "right", "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "invoice.line_items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus4"},
        {"key" : "invoice.billing_address.city", "header" : "Billing City", style : {width : "90px"}, mobile_view_details : {orderV : 5, orderH : 1}},
        {"key" : "invoice.billing_address.state", "header" : "Billing State", style : {width : "90px"}, mobile_view_details : {orderV : 5, orderH : 2}},
        {"key" : "invoice.billing_address.pincode", "header" : "Billing PIN Code", style : {width : "70px"}, mobile_view_details : {orderV : 5, orderH : 3}},
        {"key" : "invoice.billing_address.address", "header" : "Billing Address", style : {width : "250px"}, mobile_view_details : {orderV : 6, orderH : 1}},
        {"key" : "invoice.shipping_address.city", "header" : "Shipping City", style : {width : "90px"}, "panel" : "right"},
        {"key" : "invoice.shipping_address.state", "header" : "Shipping State", style : {width : "90px"}, "panel" : "right"},
        {"key" : "invoice.shipping_address.pincode", "header" : "Shipping PIN Code", style : {width : "70px"}, "panel" : "right"},
        {"key" : "invoice.shipping_address.address", "header" : "Shipping Address", style : {width : "250px"}, "panel" : "right"},
        {"key" : "invoice.customer_gst", "header" : "Customer GSTIN", style : {width : "150px"}, mobile_view_details : {orderV : 1, orderH : 3}},
        {"key" : "invoice.notes", "header" : "Notes", style : {width : "250px"}},
        {"key" : "invoice.payment_mode", "header" : "Payment Mode", style : {width : "180px"}, "panel" : "right"},
        {"key" : "invoice.operated_by", "header" : "Invoice Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"}
      ],
      "boxing_batches" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, mobile_view_details : {orderV : 2, orderH : 2}},
        {"key" : "order.order_id", "header" : "Order ID", style : {width : "120px"}, mobile_view_details : {isTitle : true, orderV : 2, orderH : 1}},
        {"key" : "boxes", "header" : "Boxes", style : {width : "70px"}, "type" : "list_count", mobile_view_details : {orderV : 4, orderH : 1}},
        {"key" : "boxes", "header" : "Items", style : {width : "70px"}, "type" : "custom", "type1" : "boxing_items_count", mobile_view_details : {orderV : 3, orderH : 1}},
        {"key" : "boxes", "header" : "Quantity", style : {width : "70px"}, "type" : "custom", "type1" : "boxing_items_quantity", mobile_view_details : {orderV : 3, orderH : 2}},
        {"key" : "boxes", "header" : "Manifested", style : {width : "70px"}, "type" : "custom", "type1" : "boxing_manifest_count", mobile_view_details : {orderV : 4, orderH : 2}},
        {"key" : "boxes", "header" : "Dispatched", style : {width : "70px"}, "type" : "custom", "type1" : "boxing_dispatched_count", mobile_view_details : {orderV : 4, orderH : 3}},
        
        {"key" : "boxes", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "boxing_items_es", "extra_key" : "id"},
        {"key" : "boxes", "header" : "DSPs", style : {width : "70px"}, "type" : "custom", "type1" : "dsps_check", mobile_view_details : {orderV : 4, orderH : 4}},
        // {"key" : "boxes", "header" : "DSPs", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "dsps_details"},
        
        
        
        // {"key" : "created_by", "header" : "User ID", style : {width : "100px"}},
        {"key" : "order.client_customer_details.name", "header" : "Customer", style : {width : "120px"}, mobile_view_details : {orderV : 1, orderH : 1}},
        // {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "order.client_customer_details.shipping_address.city", "header" : "City", style : {width : "90px"}, mobile_view_details : {orderV : 1, orderH : 2}},
        // {"key" : "line_items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        // {"key" : "line_items", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        
        //IMP
        {"key" : "order.created_at", "header" : "Order Created at", style : {width : "150px"}, "type" : "mongodate"},
        
        // {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "order.items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        // {"key" : "items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        // {"key" : "items", "header" : "DPSP Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "ttdpsp"},
        // {"key" : "sales_order.warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        // {"key" : "items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "order.total", "header" : "Order Total", style : {width : "70px"}},
        
        // {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        // {"key" : "order.sales_invoice.invoice_number", "header" : "Sales Invoice No.", style : {width : "120px"}},
        {"key" : "order.sales_invoices", "header" : "Sales Invoices", style : {width : "120px"}, "type" : "custom", "type1" : "sales_invoices_numbers_es", mobile_view_details : {orderV : 5, orderH : 2}},
        
        // {"key" : "order.client_customer_details.mobile", "header" : "Phone", style : {width : "90px"}},


        // {"key" : "grn.po_id", "header" : "PO ID", style : {width : "250px"}},
        // {"key" : "grn.po.po_id", "header" : "PO ID", style : {width : "180px"}},
        // {"key" : "grn.po.rfq.id", "header" : "RFQ ID", style : {width : "120px"}},
        // {"key" : "grn.po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        // // {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "warehouse_id", "header" : "Warehouse", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate", mobile_view_details : {orderV : 5, orderH : 1}},
        // {"key" : "started_at", "header" : "Started at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles", mobile_view_details : {isFooter : true}},
        // {"key" : "started_by", "header" : "Started By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        // {"key" : "operators", "header" : "Operators", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "operators", "header" : "Operators", style : {width : "80px"}, panel : "right", "type" : "custom", "type1" : "dashboard_roles_string2"},
        // {"key" : "rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "grn.po.vendor_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors"},
        // {"key" : "grn.po.vendor_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor"},
        // {"key" : "grn.po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        // {"key" : "grn.po.rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "grn.po.rfq.orders_list", "header" : "RFQ Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        // {"key" : "grn.po.rfq.vendors", "header" : "Vendors", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "grn.po.rfq.vendors_list", "header" : "RFQ Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors"},        
        // {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],

  }



  const linkTypeData = [
        {tag:"rfq_acc_response" , title : "RFQ Response", middlePanelTitle : "RFQ Response", type : "1"},
        {tag:"rfq_response" , title : "RFQ Response", middlePanelTitle : "RFQ Response", type : "1"}
        // {tag:"rfq2_response" , title : "RFQ Response", middlePanelTitle : "RFQ Response", type : "1"}
    ];

class Utils{

    getLocalRolesES(){
        return Object.keys(user_roles_es).map(o => ({title : user_roles_es[o].title, id : o}))
    }

    getFleetCategories(){
        return FLEET_CATEGORIES;
    }

    getStatesIndia(){
        return STATES_INDIA;
    }

    getFinalStatus(){
        return FINAL_STATUS1;
    }

    getFinalStatus1s(){
        return FINAL_STATUS1s;
    }

    getFinalStatus1sForReturn(){
        return FINAL_STATUS1s_FOR_RETURN;
    }

    getFinalStatus1sForReturn1(){
        return FINAL_STATUS1s_FOR_RETURN1;
    }

    getFinalStatusesES(){
        return FINAL_STATUSES_ES;
    }

    getNonCancellableStatus(){
        return NON_CANCELLABLE_STATUS;
    }

    getNonCancellableStatusES(){
        return NON_CANCELLABLE_STATUS_ES;
    }

    getYears(){
        return years;
    }

    getMonths(){
        return months;
    }

    getMonthNameByNumber(number){
        return months.find(o => o.number == number).titleShort;
    }

    getIDPatterns(){
        return id_patterns;
    }

    getLinkTypeByTag(tag){
        return linkTypeData.find(o => o.tag == tag);
    }

    getLinkTypesData(){
        return linkTypeData;
    }

    // getReportTabs(){
    //     return [{tag:"invoices" , title : "Invoices", middlePanelTitle : "Invoices", type : "3", "group" : "finance",
    //     roles : ["finance", "admin"]}].concat(tabData.filter(o => o.report));
    // }
    getReportTabs(){
        return [{tag:"sales_orders" , title : "Sales Orders", middlePanelTitle : "Sales Orders", type : "3", "group" : "finance",
            roles : ["admin"]},
            // {tag:"purchos" , title : "Purchase Orders", middlePanelTitle : "Purchase Orders", type : "3", "group" : "finance",
            // roles : ["admin"]},
            {tag:"inventory" , title : "Inventory", middlePanelTitle : "Inventory", type : "3", "group" : "finance",
            roles : ["admin"]},
            // {tag:"inventory1" , title : "Inventory Details - Jun", middlePanelTitle : "Inventory Details - Jun", type : "3", "group" : "finance",
            // roles : ["admin"]},
            // {tag:"inventory2" , title : "Inventory Details - Mar", middlePanelTitle : "Inventory Details - Mar", type : "3", "group" : "finance",
            // roles : ["admin"]},
            // {tag:"spares1" , title : "Spares - 3P - 22-23", middlePanelTitle : "Spares Dump - 3P", type : "3", "group" : "finance",
            // roles : ["admin"]},
            // {tag:"spares2" , title : "Spares - PL - 22-23", middlePanelTitle : "Spares Dump - PL", type : "3", "group" : "finance",
            // roles : ["admin"]},
            // {tag:"services1" , title : "Services 22", middlePanelTitle : "Services", type : "3", "group" : "finance",
            // roles : ["admin"]},
            // {tag:"services2" , title : "Services 23", middlePanelTitle : "Services", type : "3", "group" : "finance",
            // roles : ["admin"]},
            // {tag:"workshops1" , title : "Workshops 22-23", middlePanelTitle : "Workshops", type : "3", "group" : "finance",
            // roles : ["admin"]},
        ];
        // return [{tag:"invoices" , title : "Invoices", middlePanelTitle : "Invoices", type : "3", "group" : "finance",
        // roles : ["finance", "admin"]}].concat(tabData.filter(o => o.report));
    }

    getReportTabsES(){
        return [{tag:"invoices" , title : "Invoices", middlePanelTitle : "Invoices", type : "3", "group" : "finance",
        roles : ["finance", "admin"]}].concat(tabDataES.filter(o => o.report));
    }

    getTabByTag(tag){
        return tabData.find(o => o.tag == tag && o.roles.includes(localStorage.getItem("role")));
    }

    getTabByTagES(tag){
        return tabDataES.find(o => o.tag == tag && o.roles.includes(localStorage.getItem("role")));
    }

    getTabGroupByTag(tag){
        return tabGroupData.find(o => o.tag == tag && tabData.filter(o1 => o1.group == o.tag && o1.roles.includes(localStorage.getItem("role"))).length != 0);
    }

    getTabGroupByTagES(tag){
        return tabGroupDataES.find(o => o.tag == tag && tabDataES.filter(o1 => o1.group == o.tag && o1.roles.includes(localStorage.getItem("role"))).length != 0);
    }

    getWarehousesByWarehouseID(list){
        return this.getWarehouseID() == "all" ? list : list.filter(o => o.id == this.getWarehouseID());
    }

    getWHLayoutByWarehouseID(list){
        var that = this;
        console.log("liss4")
        console.log("liss", list, that.warehouses());
        // return list.filter(o => o.code == "GM-CHD");
        return this.getWarehouseID() == "all" ? list : list.filter(o => o.code == that.warehouses().find(o1 => o1.id == that.getWarehouseID()).code);
    }

    itemReplacementMapping(){
        if(localStorage.getItem("item_replacement_mapping") != null)
            return JSON.parse(localStorage.getItem("item_replacement_mapping"));
        else
            return null
    }

    subCategories(){
        if(localStorage.getItem("subcategories") != null)
            return JSON.parse(localStorage.getItem("subcategories"));
        else
            return null
    }

    carBrands(){
        if(localStorage.getItem("car_brands") != null)
            return JSON.parse(localStorage.getItem("car_brands"));
        else
            return null
    }

    carModels(){
        if(localStorage.getItem("car_models") != null)
            return JSON.parse(localStorage.getItem("car_models"));
        else
            return null
    }

    carVariants(){
        if(localStorage.getItem("car_variants") != null)
            return JSON.parse(localStorage.getItem("car_variants"));
        else
            return null
    }

    compatibility(){
        if(localStorage.getItem("compatibility") != null)
            return JSON.parse(localStorage.getItem("compatibility"));
        else
            return null
    }

    accCompatibility(){
        if(localStorage.getItem("compatibility_acc") != null)
            return JSON.parse(localStorage.getItem("compatibility_acc"));
        else
            return null
    }

    soOrderStatuses(){
        if(localStorage.getItem("so_order_statuses") != null)
            return JSON.parse(localStorage.getItem("so_order_statuses"));
        else
            return null
    }

    itemStatuses(){
        if(localStorage.getItem("item_statuses") != null)
            return JSON.parse(localStorage.getItem("item_statuses"));
        else
            return null
    }

    warehouses(){
        if(localStorage.getItem("warehouses") != null)
            return JSON.parse(localStorage.getItem("warehouses"));
        else
            return null
    }

    distributors(){
        if(localStorage.getItem("distributors") != null)
            return JSON.parse(localStorage.getItem("distributors"));
        else
            return null
    }


    accVendors(){
        if(localStorage.getItem("acc_vendors") != null)
            return JSON.parse(localStorage.getItem("acc_vendors"));
        else
            return null
    }

    vendors(){
        if(localStorage.getItem("vendors") != null)
            return JSON.parse(localStorage.getItem("vendors"));
        else
            return null
    }

    vendors2(){
        if(localStorage.getItem("vendors2") != null)
            return JSON.parse(localStorage.getItem("vendors2"));
        else
            return null
    }

    brands(){
        if(localStorage.getItem("brands") != null)
            return JSON.parse(localStorage.getItem("brands"));
        else
            return null
    }

    whLayout(){
        if(localStorage.getItem("wh_layout") != null)
            return JSON.parse(localStorage.getItem("wh_layout"));
        else
            return null
    }

    dashboardRoles(){
        if(localStorage.getItem("dashboard_roles") != null)
            return JSON.parse(localStorage.getItem("dashboard_roles"));
        else
            return null
    }

    b2bAgents(){
        if(localStorage.getItem("b2b_agents") != null)
            return JSON.parse(localStorage.getItem("b2b_agents"));
        else
            return null
    }

    dsps(){
        if(localStorage.getItem("dsps") != null)
            return JSON.parse(localStorage.getItem("dsps"));
        else
            return null
    }

    getSavedListItemByID(type, id){
        return JSON.parse(localStorage.getItem(type)).find(o => o._id == id);
    }

    getSavedList(type){
        return JSON.parse(localStorage.getItem(type));
    }

    setSavedList(type, data){
        localStorage.setItem(type, JSON.stringify(data));
    }

    setSubCategories(data){
        localStorage.setItem("subcategories", JSON.stringify(data));
    }

    setCarBrands(data){
        localStorage.setItem("car_brands", JSON.stringify(data));
    }

    setCarModels(data){
        localStorage.setItem("car_models", JSON.stringify(data));
    }

    setCarVariants(data){
        localStorage.setItem("car_variants", JSON.stringify(data));
    }

    setItemReplacementMapping(data){
        localStorage.setItem("item_replacement_mapping", JSON.stringify(data));
    }

    setCompatibility(data){
        localStorage.setItem("compatibility", JSON.stringify(data));
    }

    setAccCompatibility(data){
        localStorage.setItem("compatibility_acc", JSON.stringify(data));
    }

    setDashboardRoles(data){
        localStorage.setItem("dashboard_roles", JSON.stringify(data));
    }

    setB2BAgents(data){
        localStorage.setItem("b2b_agents", JSON.stringify(data));
    }

    setWarehouses(data){
        localStorage.setItem("warehouses", JSON.stringify(data));
    }

    setAccVendors(data){
        localStorage.setItem("acc_vendors", JSON.stringify(data));
    }

    setVendors(data){
        localStorage.setItem("vendors", JSON.stringify(data));
    }

    setVendors2(data){
        localStorage.setItem("vendors2", JSON.stringify(data));
    }

    setDistributors(data){
        localStorage.setItem("distributors", JSON.stringify(data));
    }

    setSoOrderStatuses(data){
        localStorage.setItem("so_order_statuses", JSON.stringify(data));
    }

    setItemStatuses(data){
        localStorage.setItem("item_statuses", JSON.stringify(data));
    }

    setBrands(data){
        localStorage.setItem("brands", JSON.stringify(data));
    }

    setWHLayout(data){
        localStorage.setItem("wh_layout", JSON.stringify(data));
    }

    setDSPs(data){
        localStorage.setItem("dsps", JSON.stringify(data));
    }


    getSOOrderStatuses(){
        return so_order_statuses;
    }
    getSOOrderStatusTitle(status){
        var found = so_order_statuses.find(o => o.status == status);
        return found ? found.title : null;
    }

    getUserNameByID(id){
        var role = this.dashboardRoles().find(o => o.user_id == id);
        return role && role.user ? role.user.name : id;
    }

    getItemStatuses(){
        return item_statuses;
    }
    getItemStatusTitle(status){
        var found = item_statuses.find(o => o.status == status);
        return found ? found.title : null;
    }

    getTabGroupData(){
        return tabGroupData.filter(o =>  tabData.filter(o1 => o1.group == o.tag && o1.roles.includes(localStorage.getItem("role"))).length != 0);
    }
    getTabGroupDataES(){
        return tabGroupDataES.filter(o =>  tabDataES.filter(o1 => o1.group == o.tag && o1.roles.includes(localStorage.getItem("role"))).length != 0);
    }
    getTabData(){
        return tabData.filter(o => o.roles.includes(localStorage.getItem("role")));
    }
    getTabDataES(){
        return tabDataES.filter(o => o.roles.includes(localStorage.getItem("role")));
    }
    getRowData(){
        return rowData;
    }
    getRowDataES(){
        return rowDataES;
    }
    getRowDataByTag(tag){
        console.log("roling", rowData[tag].map(o => !o.roles || o.roles.length == 0 || o.roles.indexOf(localStorage.getItem('role') != -1)));
        return rowData[tag].filter(o => (!o.panel || o.panel != "right") && (!o.roles || o.roles.length == 0 || o.roles.indexOf(localStorage.getItem('role')) != -1));
    }
    getRowDataByTagES(tag){
        return rowDataES[tag].filter(o => (!o.panel || o.panel != "right") && (!o.roles || o.roles.length == 0 || o.roles.indexOf(localStorage.getItem('role')) != -1));
    }
    getRightRowDataByTag(tag){
        return rowData[tag];
    }
    getRightRowDataByTagES(tag){
        return rowDataES[tag];
    }
    
    getValueFromKey(key, data){
        if(key.indexOf(".") != -1){
            var arr = key.split('.');
            var obj = data;
            for(var i = 0; i < arr.length; i++){
                obj = obj ? obj[arr[i]] : null;
            }
            return obj;
        }else{
            // console.log("fatt", key, data);
            return data ? data[key] : "";
        }
        
    }

    getRowDataValue(data, data1){
        if(data.extra_key1){
            return this.getRowDataValue1(data, this.getValueFromKey(data.key, data1), this.getValueFromKey(data.extra_key, data1), this.getValueFromKey(data.extra_key1, data1));
        }else if(data.extra_key){
            return this.getRowDataValue1(data, this.getValueFromKey(data.key, data1), this.getValueFromKey(data.extra_key, data1));
        }else{
            return this.getRowDataValue1(data, this.getValueFromKey(data.key, data1));
        }
    }
    // getRowDataValue(data, data1){
    //     var value = null;
    //     if(data.key.indexOf(".") != -1){
    //         var arr = data.key.split('.');
    //         var obj = data1;
    //         for(var i = 0; i < arr.length; i++){
    //             obj = obj ? obj[arr[i]] : null;
    //         }
    //         return this.getRowDataValue1(data, obj);
    //     }else{
    //         return this.getRowDataValue1(data, data1[data.key]);
    //     }
    // }

    getRowDataValue1(data, obj, extra_value = null, extra_value1 = null){
        console.log("dValue1X", data, obj);
        var value = null;
        if (data.type){
            if(obj){
                switch(data.type){
                    case "boolean":
                        value = obj ? "True" : "False";
                        break;
                    case "mongodate":
                        value = obj && obj["$date"] ? constants.get_DateStringX(obj["$date"]) : "";
                        break;
                    case "firebasedate":
                        value = constants.get_DateString2(obj);
                        break;
                    case "object_count":
                        value = Object.keys(obj).length;
                        break;
                    case "object_key_sum":
                        if (Object.values(obj).length == 0){
                            value = 0;
                        }else{
                            value = Object.values(obj).map(o => o[data.key_sum] ? parseInt(o[data.key_sum]) : 0).reduce((prev, next) => (parseInt(prev) + parseInt(next)));
                        }
                        break;
                    case "array_key_sum":
                        var value1 = obj.map(o => o[data.key_sum] ? parseInt(o[data.key_sum]) : 0);
                        value = value1.length == 0 ? 0 : value1.reduce((prev, next) => (parseInt(prev) + parseInt(next)));
                        break;
                    case "array_key_sum_with_filter":
                        var value1 = obj.filter(o => o[data.filter_key] == data.filter_key_value).map(o => o[data.key_sum] ? parseInt(o[data.key_sum]) : 0);
                        value = value1.length == 0 ? 0 : value1.reduce((prev, next) => (parseInt(prev) + parseInt(next)));
                        break;
                    case "list_count":
                        value = obj.length;
                        break;
                    case "list_count_with_filter":
                        value = obj.filter(o => o[data.filter_key] == data.filter_key_value).length;
                        break;
                    case "list_count_unique_with_filter":
                        // value = [...new Set(obj.filter(o => o[data.filter_key] == data.filter_key_value).map(item => item[data.unique_key]))].length;
                        value = this.getSKUDataByGrouping(obj, data.unique_key, data.filter_key, data.filter_key_value).length;
                        break;
                    case "list_count_unique":
                        // value = [...new Set(obj.map(item => item[data.unique_key]))].length;
                        value = this.getSKUDataByGrouping(obj, data.unique_key, null, null).length;
                        break;
                    case "array_string":
                        value = obj.join();
                        break;
                    case "array_object_string":
                        value = obj.map(o => o[data.key_display]).join();
                        break;
                    case "array_object_string_unique":
                        value = [... new Set(obj.map(o => o[data.key_display]))].join();
                        break;
                    case "localstorage_fetch":
                        if (data.localstorage_id.includes(",")){
                            var list = [];
                            var split = data.localstorage_id.split(",");
                            for(var i = 0; i < split.length; i++){
                                list = this.getSavedList(split[i].trim()).find(o => o[data.key_fetch] == obj);
                                value = this.getValueFromKey(data.key_display, list);
                                if(value && value.trim() != ""){
                                    break;
                                }
                            }
                        }else{
                            var list = this.getSavedList(data.localstorage_id).find(o => o[data.key_fetch] == obj);
                            // value = this.getSavedList(data.localstorage_id).find(o => o[data.key_fetch] == obj)[data.key_display]
                            value = this.getValueFromKey(data.key_display, list);
                        }
                        break;
                    case "custom":
                        switch(data.type1){
                            case "skus":
                                if(data.unique_key){
                                    if(data.filter_key){
                                        value = this.getSKUDataByGrouping(obj, data.unique_key, data.filter_key, data.filter_key_value);
                                    }else{
                                        value = this.getSKUDataByGrouping(obj, data.unique_key);
                                    }
                                }else{
                                    value = obj;
                                }
                                break;
                            case "skus1":
                                if(data.filter_key){
                                    value = obj.filter(o => o[data.filter_key] == data.filter_key_value);
                                }else{
                                    value = obj;
                                }
                                break;
                            case "order_channel":
                                value = this.b2bAgents().find(o => o.customer_id == obj) ? "B2B" : "B2C";
                                break;
                            case "dashboard_roles_string1":
                                value = this.dashboardRoles().filter(o => Object.keys(obj).map(o => parseInt(o)).indexOf(o.user_id) != -1).map(o => o.user.name).join(', ');
                                break;
                            case "dashboard_roles_string2":
                                value = this.dashboardRoles().filter(o => obj.map(o => parseInt(o)).indexOf(o.user_id) != -1).map(o => o.user.name).join(', ');
                                break;
                            case "dsps":
                                var dsps = this.dsps();
                                value = obj.map(o => ({...o, dsp_name : dsps.find(o1 => o1.id == o.dsp_id).name}));
                                break;
                            case "dsps_check":
                                // var dsps = this.dsps();
                                // value = obj.map(o => ({...o, dsp_name : dsps.find(o1 => o1.id == o.dsp_id).name}));
                                value = obj.filter(o => o.dispatch_data != null && o.dispatch_data.status == "success" && o.dispatch_data.dsp_id != null).length > 0 ? "True" : "False"
                                break;
                            // case "dsps_details":
                            //     // var dsps = this.dsps();
                            //     // value = obj.map(o => ({...o, dsp_name : dsps.find(o1 => o1.id == o.dsp_id).name}));
                            //     value = 2;
                            //     break;
                            case "dsps_tracking_check":
                                // var dsps = this.dsps();
                                // value = obj.map(o => ({...o, dsp_name : dsps.find(o1 => o1.id == o.dsp_id).name}));
                                value = obj.filter(o => o.dispatch_data != null && o.dispatch_data.status == "success" && o.dispatch_data.dsp_id != null && o.dispatch_data.data && o.dispatch_data.data.tracking_data).length > 0 ? "True" : "False"
                                break;
                            case "dsps_tracking_check1":
                                // var dsps = this.dsps();
                                // value = obj.map(o => ({...o, dsp_name : dsps.find(o1 => o1.id == o.dsp_id).name}));
                                value = obj != null && obj.status == "success" && obj.dsp_id != null && obj.data && obj.data.tracking_data ? "True" : "False"
                                break;
                            case "item_quantity_inner":
                                value = Object.values(obj).map(o => o.item.quantity ? parseInt(o.item.quantity) : 0).reduce((prev, next) => (parseInt(prev) + parseInt(next)));
                                break;
                            case "rfq_type":
                                value = obj ? obj : 1;
                                break;
                            case "vendors_rfq":
                                value = obj.map(o => ({...o, rfq_id : extra_value}));
                                break;
                            case "vendors_rfq2":
                                value = obj.map(o => ({...o, rfq_id : extra_value}));
                                break;
                            case "vendors_rfq_acc":
                                value = obj.map(o => ({...o, rfq_id : extra_value}));
                                break;
                            case "vendor_rfq_acc_global":
                                value = [{...obj, rfq_id : extra_value}];
                                break;
                            case "so_item_brands":
                                value = [...new Set(obj.map(o => o.sku_brand))].join(", ");
                                break;
                            case "order_ids_array_string":
                                value = obj.map(o => o.stockone_order_id).join(", ");
                                break;
                            case "order_customer_firstnames_array_string":
                                value = obj.map(o => o.user_details.first_name).join(", ");
                                break;
                            case "order_created_at_array_string":
                                //IMP
                                value = obj.map(o => constants.get_DateStringX(o.sales_order.created_at["$date"])).join(", ");
                                // value = ""
                                break;
                            case "dropship_vendor_status":
                                value = (obj && obj.status == "enabled") ? "Enabled" : "Disabled";
                                break;
                            case "dropship_bounce_time":
                                value = (obj && obj.bounce_time) ? obj.bounce_time+" days" : "0 days";
                                break;
                            case "dropship_daily_order_limit":
                                value = (obj && obj.daily_order_limit) ? obj.daily_order_limit+" orders" : "0 orders";
                                break;
                            case "dropship_payment_cycle":
                                value = (obj && obj.payment_cycle) ? obj.payment_cycle+" days" : "0 days";
                                break;
                            case "order_channel_array_string":
                                var thatCurrent = this;
                                value = obj.map(o => (thatCurrent.b2bAgents().find(o1 => o1.customer_id == o.created_by) ? "B2B" : "B2C")).join(", ");
                                break;
                            case "sales_invoices_numbers_es":
                                value = obj.map(o => (o.invoice_number)).join(", ");
                                break;
                            case "alt_parts_product_ids":
                                var list = obj ? obj.map(o => o.product_id_1 == extra_value ? o.product_id_2 : o.product_id_1) : [];
                                value = list.join(", ");
                                break;
                            case "alt_parts_product_ids_es":
                                var list = [].concat.apply([], obj.map(o => o.alt_parts ? o.alt_parts.map(o1 => o1.product_id_1 == o.product_id ? o1.product_id_2 : o1.product_id_1) : []));
                                // var list = obj ? obj.map(o => o.product_id_1 == extra_value ? o.product_id_2 : o.product_id_1) : [];
                                value = list.join(", ");
                                break;
                            case "alt_parts_count_es":
                                // var list = [].concat.apply([], obj.alt_parts.map())
                                var list = obj.map(o => o.alt_parts ? o.alt_parts.length : 0);
                                value = list.length == 0 ? 0 : list.reduce((prev, next) => (parseInt(prev) + parseInt(next)));
                                // var list = obj ? obj.map(o => o.product_id_1 == extra_value ? o.product_id_2 : o.product_id_1) : [];
                                // value = list.join(", ");
                                break;
                            case "image_es":
                                value =  [].concat.apply([], obj.map(o => o.image_url));
                                break;
                            case "picklist_status":
                                // var bbs = obj.map(ob => [].concat.apply([], ob.boxes.map(o => Object.values(o.items).map(o1 => o1.quantity))).length == 0 ? 0 : [].concat.apply([], ob.boxes.map(o => Object.values(o.items).map(o1 => o1.quantity))).reduce((prev, next) => (parseInt(prev) + parseInt(next))));
                                // // var list = [].concat.apply([], obj.map(o => Object.values(o.items).map(o1 => o1.quantity)));
                                // var quantity_boxed = bbs.length == 0 ? 0 : bbs.reduce((prev, next) => (parseInt(prev) + parseInt(next)))
                                // value = quantity_boxed == 0 ? "Open" : "Closed";
                                value = obj.length > 0 ? "Closed" : "Open";
                                break;
                            case "item_warehouses":
                                if(data.filter_key){
                                    value = this.getItemWarehousesByGrouping(obj, data.unique_key, data.filter_key, data.filter_key_value);
                                }else{
                                    value = this.getItemWarehousesByGrouping(obj, data.unique_key);
                                }
                                break;
                            case "dpsp_order_statuses":
                                if(data.filter_key){
                                    value = this.getItemStatusesByGrouping(obj, data.unique_key, data.filter_key, data.filter_key_value);
                                }else{
                                    value = this.getItemStatusesByGrouping(obj, data.unique_key);
                                }
                                break;
                            case "bo_order_statuses":
                                if(data.filter_key){
                                    value = this.getItemStatusesByGrouping(obj, data.unique_key, data.filter_key, data.filter_key_value);
                                }else{
                                    value = this.getItemStatusesByGrouping(obj, data.unique_key);
                                }
                                break;
                            case "fo_order_statuses":
                                if(data.filter_key){
                                    value = this.getItemStatusesByGrouping(obj, data.unique_key, data.filter_key, data.filter_key_value);
                                }else{
                                    value = this.getItemStatusesByGrouping(obj, data.unique_key);
                                }
                                break;
                            case "dpsp_order_item_statuses":
                                if(data.filter_key){
                                    value = this.getItemStatusesByGrouping(obj, data.unique_key, data.filter_key, data.filter_key_value);
                                }else{
                                    value = this.getItemStatusesByGrouping(obj, data.unique_key);
                                }
                                break;
                            case "vendor_all":
                                value = this.getSavedList("vendors").find(o => o["id"] == obj);
                                if(!value){
                                    value = this.getSavedList("acc_vendors").find(o => o["id"] == obj);
                                    if(value){
                                        value["vendor_type"] = "accessories";
                                    }
                                }else{
                                    value["vendor_type"] = "spares";
                                }
                                // value = [].concat.apply([], [this.getSavedList("vendors").find(o => o["id"] == obj), this.getSavedList("acc_vendors").find(o => o["id"] == obj)])
                                // value = [].concat.apply([], [this.getSavedList("vendors"), this.getSavedList("acc_vendors")]).find(o => o["id"] == obj)
                                break;
                            case "vendor":
                                value = this.getSavedList("vendors").find(o => o["id"] == obj)
                                break;
                            case "vendor2":
                                value = this.getSavedList("vendors2").find(o => o["id"] == obj)
                                break;
                            case "acc_vendor":
                                value = this.getSavedList("acc_vendors").find(o => o["id"] == obj)
                                break;
                            case "distributor":
                                value = this.getSavedList("distributors").find(o => o["id"] == obj)
                                break;
                            case "po_grns_items":
                                var list = obj ? [].concat.apply([], obj.map(o => o.items)) : [];
                                // console.log("obbb", obj, obj.grns, obj.grns ? obj.grns.map(o => o.items) : []);
                                value = this.getSKUDataByGrouping(list, "product_id", null, null).length;
                                break;
                            case "po_grns_quantity":
                                var list = obj ? [].concat.apply([], obj.map(o => o.items)) : [];
                                value = list.length;
                                break;
                            case "boxings_incomplete_items_count":
                                value = [].concat.apply([], Object.values(obj ? obj : {}).map(o => Object.values(o))).filter(o => o.quantity != 0).length;
                                break;
                            case "boxings_incomplete_items_quantity":
                                // var mItems = [].concat.apply([], Object.values(obj ? obj : {}).map(o => Object.values(o)));
                                // var fItems = mItems.filter(o => o.quantity != 0);
                                // var total_orders = [...new Set(fItems.map(o => o.item.so_order_id))].length
                                // var total_items = fItems.length;
                                // var total_items_orig = mItems.length;
                                // var total_quantity = mItems.map(o => o.quantity ? o.quantity : 0).reduce((prev, next) => (parseInt(prev) + parseInt(next)));
                                // var total_quantity_orig = mItems.map(o => o.item.quantity ? o.item.quantity : 0).reduce((prev, next) => (parseInt(prev) + parseInt(next)));
                                value = [].concat.apply([], Object.values(obj ? obj : {}).map(o => Object.values(o))).map(o => o.quantity ? o.quantity : 0).reduce((prev, next) => (parseInt(prev) + parseInt(next)));
                                break;
                            case "putaways_incomplete_items_count":
                                var list = [].concat.apply([], [].concat.apply([], Object.values(obj ? obj : {}).map(o => o.items)).filter(o => o != null).map(o => Object.values(o))).map(o => o.item.product_id);
                                console.log("listrr", list);
                                value = [...new Set(list)].length;
                                break;
                            case "putaways_incomplete_items_quantity":
                                var list = [].concat.apply([], [].concat.apply([], Object.values(obj ? obj : {}).map(o => o.items)).filter(o => o != null).map(o => Object.values(o))).map(o => o.quantity);
                                console.log("listrr", list);
                                value = list.length == 0 ? 0 : list.reduce((prev, next) => (parseInt(prev) + parseInt(next)))
                                break;
                            case "boxing_manifest_count":
                                // var value1 = obj.map(o => Object.values(o.items));
                                // value = [].concat.apply([], value1).length;
                                value = obj.filter(o => o.manifest && o.manifest != null).length;
                                break;
                            case "boxing_dispatched_count":
                                // var value1 = obj.map(o => Object.values(o.items));
                                // value = [].concat.apply([], value1).length;
                                value = obj.filter(o => o.dispatch_data && o.dispatch_data.is_dispatched).length;
                                break;
                            case "boxing_items_count":
                                // var value1 = obj.map(o => Object.values(o.items));
                                // value = [].concat.apply([], value1).length;
                                var list = [].concat.apply([], obj.map(o => Object.values(o.items)))
                                var value1 = [...new Map(list.map(item => [item["item"]["sku_code"], item])).values()].map(({_id, ...v}) => ({...v, quantity: list.filter(o => o["item"]["sku_code"] == v["item"]["sku_code"]).length}))
                                // value = this.getSKUDataByGrouping([].concat.apply([], obj.map(o => Object.values(o.items))), "sku_code", null, null).length;
                                value = value1.length;
                                break;
                            case "boxing_items_quantity":
                                // var value1 = obj.map(o => Object.values(o.items));
                                // value = [].concat.apply([], value1).length;
                                var list = [].concat.apply([], obj.map(o => Object.values(o.items).map(o1 => o1.quantity)));
                                // var value1 = [...new Map(list.map(item => [item["item"]["sku_code"], item])).values()].map(({_id, ...v}) => ({...v, quantity: list.filter(o => o["item"]["sku_code"] == v["item"]["sku_code"]).length}))
                                // value = this.getSKUDataByGrouping([].concat.apply([], obj.map(o => Object.values(o.items))), "sku_code", null, null).length;
                                // value = value1.length;
                                value = list.length == 0 ? 0 : list.reduce((prev, next) => (parseInt(prev) + parseInt(next)))
                                break;
                            case "boxing_items":
                                console.log("obb", data, obj)
                                var value1 = {};
                                for(var i = 0; i < obj.length; i++){
                                    var box = obj[i];
                                    box["boxing_batch_id"] = extra_value;                                 
                                    if(value1[box.so_order_id]){
                                        value1[box.so_order_id]["boxes"].push(box);
                                    }else{
                                        value1[box.so_order_id] = {boxes : [box], channel : this.b2bAgents().find(o => o.customer_id == extra_value1.find(o1 => o1.sales_order.so_order_id == box.so_order_id).created_by) ? "B2B" : "B2C", so_order_id : box.so_order_id, boxing_batch_id : extra_value};
                                    }
                                }
                                // value = this.getSKUDataByGrouping(obj, "so_order_id", null, null);
                                value = value1;
                                break;
                            case "boxing_items_es":
                                console.log("obb", data, obj)
                                var value1 = {};
                                value1 = {boxes : [], boxing_batch_id : extra_value};
                                for(var i = 0; i < obj.length; i++){
                                    var box = obj[i];
                                    box["boxing_batch_id"] = extra_value;                                 
                                    value1["boxes"].push(box);
                                }
                                // value = this.getSKUDataByGrouping(obj, "so_order_id", null, null);
                                value = value1;
                                break;
                            case "vpr_status":
                                console.log("obb", data, obj)
                                var value1 = {};
                                for(var i = 0; i < obj.length; i++){
                                    var box = obj[i];
                                    box["boxing_batch_id"] = extra_value;                                 
                                    if(value1[box.so_order_id]){
                                        value1[box.so_order_id]["boxes"].push(box);
                                    }else{
                                        value1[box.so_order_id] = {boxes : [box], so_order_id : box.so_order_id, boxing_batch_id : extra_value};
                                    }
                                }
                                // value = this.getSKUDataByGrouping(obj, "so_order_id", null, null);
                                value = value1;
                                break;
                            // case "rfq_vendor_response_details":
                            //     console.log("obb", data, obj)
                            //     var value1 = {};
                            //     for(var i = 0; i < obj.length; i++){
                            //         var box = obj[i];
                            //         box["boxing_batch_id"] = extra_value;                                 
                            //         if(value1[box.so_order_id]){
                            //             value1[box.so_order_id]["boxes"].push(box);
                            //         }else{
                            //             value1[box.so_order_id] = {boxes : [box], so_order_id : box.so_order_id, boxing_batch_id : extra_value};
                            //         }
                            //     }
                            //     value = value1;
                            //     break;
                            default:
                                value = obj;
                                break;
                        }
                        break;
                    default:
                        value = obj;
                        break;
                }
            }else{
                if(data.type == 'boolean'){
                    value = "False";
                }else if(data.type == "custom"){
                    switch(data.type1){
                        case "sku_title":
                            console.log("sku_titleX", extra_value);
                            if (obj)
                                value = obj;
                            else
                                value = this.getDynamicSKUTitle("seat_cover", extra_value);
                            break;
                    }
                }
            }
        }else{
            value = obj;
        }
        return value;
    }

    
    getItemWarehousesByGrouping(list, unique_key, filter_key=null, filter_key_value=null){
        var that = this;
        if(filter_key){
            return [...new Set(list.filter(o => o[filter_key] == filter_key_value).map((v) => ((that.warehouses().find(o => o.id == v["warehouse_id"]) ? that.warehouses().find(o => o.id == v["warehouse_id"]).code : "NA") + " - " + (list.filter(o => o[filter_key] == filter_key_value && v["warehouse_id"] == o["warehouse_id"]).length).toString())))].join(", ");
            return [...new Map(list.filter(o => o[filter_key] == filter_key_value).map(item => [item[unique_key], item])).values()].map(({_id, ...v}) => ({...v, quantity: list.filter(o => o.product_id == v.product_id).length}));
        }else{
            return [...new Set(list.map((v) => ((that.warehouses().find(o => o.id == v["warehouse_id"]) ? that.warehouses().find(o => o.id == v["warehouse_id"]).code : "NA") + " - " + (list.filter(o => v["warehouse_id"] == o["warehouse_id"]).length).toString())))].join(", ");
            return [...new Map(list.map(item => [item[unique_key], item])).values()].map(({_id, ...v}) => ({...v, quantity: list.filter(o => o.product_id == v.product_id).length}));
        }
    }

    getItemStatusesByGrouping(list, unique_key, filter_key=null, filter_key_value=null){
        var that = this;
        if(filter_key){
            return [...new Set(list.filter(o => o[filter_key] == filter_key_value).map((v) => (that.getItemStatusTitle(v["status1"] ? v["status1"] : v["status"]) + " - " + (v["status1"] ? list.filter(o => o[filter_key] == filter_key_value && v["status1"] == o["status1"]).length : list.filter(o => o[filter_key] == filter_key_value && v["status"] == o["status"] && !o["status1"]).length).toString())))].join(", ");
            return [...new Map(list.filter(o => o[filter_key] == filter_key_value).map(item => [item[unique_key], item])).values()].map(({_id, ...v}) => ({...v, quantity: list.filter(o => o.product_id == v.product_id).length}));
        }else{
            return [...new Set(list.map((v) => (that.getItemStatusTitle(v["status1"] ? v["status1"] : v["status"]) + " - " + (v["status1"] ? list.filter(o => v["status1"] == o["status1"]).length : list.filter(o => v["status"] == o["status"] && !o["status1"]).length).toString())))].join(", ");
            return [...new Map(list.map(item => [item[unique_key], item])).values()].map(({_id, ...v}) => ({...v, quantity: list.filter(o => o.product_id == v.product_id).length}));
        }
    }

    getSKUDataByGrouping(list, unique_key, filter_key=null, filter_key_value=null, equals=true){
        if(filter_key){
            if (equals){
                return [...new Map(list.filter(o => o[filter_key] == filter_key_value).map(item => [item[unique_key], item])).values()].map(({_id, ...v}) => ({...v, quantity: list.filter(o => o[unique_key] == v[unique_key] && o[filter_key] == filter_key_value).length}));
            }else{
                return [...new Map(list.filter(o => o[filter_key] != filter_key_value).map(item => [item[unique_key], item])).values()].map(({_id, ...v}) => ({...v, quantity: list.filter(o => o[unique_key] == v[unique_key] && o[filter_key] != filter_key_value).length}));
            }
        }else{
            return [...new Map(list.map(item => [item[unique_key], item])).values()].map(({_id, ...v}) => ({...v, quantity: list.filter(o => o[unique_key] == v[unique_key]).length}));
        }
    }

    getSKUDataByGroupingList(list, unique_key, filter_key=null, filter_key_values=[], equals=true){
        if(filter_key){
            if (equals){
                return [...new Map(list.filter(o => filter_key_values.indexOf(o[filter_key]) != -1).map(item => [item[unique_key], item])).values()].map(({_id, ...v}) => ({...v, quantity: list.filter(o => o[unique_key] == v[unique_key] && filter_key_values.indexOf(o[filter_key]) != -1).length}));
            }else{
                return [...new Map(list.filter(o => filter_key_values.indexOf(o[filter_key]) == -1).map(item => [item[unique_key], item])).values()].map(({_id, ...v}) => ({...v, quantity: list.filter(o => o[unique_key] == v[unique_key] && filter_key_values.indexOf(o[filter_key]) == -1).length}));
            }
        }else{
            return [...new Map(list.map(item => [item[unique_key], item])).values()].map(({_id, ...v}) => ({...v, quantity: list.filter(o => o[unique_key] == v[unique_key]).length}));
        }
    }

    getDashboardHeaders(type, data){
        console.log("dataTYPE", type, data);
        switch(type){
            case "tat_report":
                return [{text : "", style : {width : "250px"}}].concat(data.map(o => ({text : this.getMonths().find(m => m.number == o.month).titleShort, style : {width : "150px"}})));
            case "dispatched_data":
                return [{text : "", style : {width : "250px"}}].concat(data.line_items.list.map(o => ({text : o.title, style : {width : "150px"}})));
            case "pending_procurement":
                return [{text : "", style : {width : "250px"}}].concat(data.oem.list.map(o => ({text : o.title, style : {width : "150px"}})));
            case "pending_warehouse":
                return [{text : "", style : {width : "250px"}}].concat(data.oem.list.map(o => ({text : o.title, style : {width : "150px"}})));
            case "fulfilment_report":
                return [{text : "", style : {width : "250px"}},{text : "Orders Placed", style : {width : "150px"}},
                        {text : "Total Items", style : {width : "150px"}},{text : "At Warehouse", style : {width : "150px"}},
                        {text : "Pending GRN", style : {width : "150px"}},{text : "Dispatched", style : {width : "150px"}}];
            default:
                return [];
        }
    }

    getDashboardRows(type, data){
        console.log("dataTYPE3", type, data);
        switch(type){
            case "tat_report":
                return [
                    [{text : "Avg. Dispatch TAT", style : {width : "250px", background : "var(--colorFive300)", color: "#333333", fontFamily: "hurme1semibold"}}].concat(data.map(o => ({text : o.tat, style : {width : "150px"}}))),
                    [{text : "95% Avg. Dispatch TAT", style : {width : "250px", background : "var(--colorFive300)", color: "#333333", fontFamily: "hurme1semibold"}}].concat(data.map(o => ({text : o.tat95, style : {width : "150px"}})))
                        ];
            case "dispatched_data":
                return Object.values(data).map(o => ([{text : o.key, style : {width : "250px", background : "var(--colorFive300)", color: "#333333", fontFamily: "hurme1semibold"}}].concat(o.list.map(o1 => ({text : o1.value, style : {width : "150px"}})))));
            case "pending_procurement":
                return Object.values(data).map(o => ([{text : o.key, style : {width : "250px", background : "var(--colorFive300)", color: "#333333", fontFamily: "hurme1semibold"}}].concat(o.list.map(o1 => ({text : o1.value, style : {width : "150px"}})))));
            case "pending_warehouse":
                return Object.values(data).map(o => ([{text : o.key, style : {width : "250px", background : "var(--colorFive300)", color: "#333333", fontFamily: "hurme1semibold"}}].concat(o.list.map(o1 => ({text : o1.value, style : {width : "150px"}})))));
            case "fulfilment_report":
                return Object.values(data).map(o => ([{text : this.getMonths().find(m => m.number == o.month).titleShort, style : {width : "250px", background : "var(--colorFive300)", color: "#333333", fontFamily: "hurme1semibold"}}].concat(Object.values(o).slice(1).map(o1 => ({text : o1, style : {width : "150px"}})))));
            default:
                return [];
        }
    }

    getDashboardTitle(type){
        switch(type){
            case "tat_report":
                return "TAT Report";
            case "dispatched_data":
                return "Dispatch Data";
            case "pending_procurement":
                return "Pending Procurement";
            case "pending_warehouse":
                return "Pending At Warehouse";
            case "fulfilment_report":
                return "Fulfilment Report";
            default:
                return "";
        }
    }

    getDashboardRowsES(type, data){
        console.log("dataTYPE3", type, data);
        switch(type){
            case "tat_report":
                return [
                    [{text : "Avg. Dispatch TAT", style : {width : "250px", background : "var(--colorFive300)", color: "#333333", fontFamily: "hurme1semibold"}}].concat(data.map(o => ({text : o.tat, style : {width : "150px"}}))),
                    [{text : "95% Avg. Dispatch TAT", style : {width : "250px", background : "var(--colorFive300)", color: "#333333", fontFamily: "hurme1semibold"}}].concat(data.map(o => ({text : o.tat95, style : {width : "150px"}})))
                        ];
            case "dispatched_data":
                return Object.values(data).map(o => ([{text : o.key, style : {width : "250px", background : "var(--colorFive300)", color: "#333333", fontFamily: "hurme1semibold"}}].concat(o.list.map(o1 => ({text : o1.value, style : {width : "150px"}})))));
            case "pending_procurement":
                return Object.values(data).map(o => ([{text : o.key, style : {width : "250px", background : "var(--colorFive300)", color: "#333333", fontFamily: "hurme1semibold"}}].concat(o.list.map(o1 => ({text : o1.value, style : {width : "150px"}})))));
            case "pending_warehouse":
                return Object.values(data).map(o => ([{text : o.key, style : {width : "250px", background : "var(--colorFive300)", color: "#333333", fontFamily: "hurme1semibold"}}].concat(o.list.map(o1 => ({text : o1.value, style : {width : "150px"}})))));
            case "fulfilment_report":
                return Object.values(data).map(o => ([{text : this.getMonths().find(m => m.number == o.month).titleShort, style : {width : "250px", background : "var(--colorFive300)", color: "#333333", fontFamily: "hurme1semibold"}}].concat(Object.values(o).slice(1).map(o1 => ({text : o1, style : {width : "150px"}})))));
            default:
                return [];
        }
    }

    getDashboardTitleES(type){
        switch(type){
            case "tat_report":
                return "TAT Report";
            case "dispatched_data":
                return "Dispatch Data";
            case "pending_procurement":
                return "Pending Procurement";
            case "pending_warehouse":
                return "Pending At Warehouse";
            case "fulfilment_report":
                return "Fulfilment Report";
            default:
                return "";
        }
    }

    getFormattedSearchString(query, tag){
        console.log("dValue1", query, tag);
        query = query.toUpperCase();
        var objs = this.getIDPatterns()[tag];
        console.log("dValue2", obj, this.getIDPatterns());
        if(objs){
            for(var i = 0; i < objs.length; i++){
                var value = `${query}`
                var obj = objs[i];
                if(obj){
                    // var queries = [];
                    console.log("dValue2", obj.separator);
                    if(obj.separator){
                        console.log("dValue2", value);
                        // console.log("dValue2", obj.separator);
                        value = value.replace(/[^A-Za-z0-9]/g, '');
                        console.log("dValue2", value);
                        if(obj.keys.startsWith && !value.startsWith(obj.keys.startsWith.toUpperCase())){
                            continue;
                        }else{
                            for(var i = 0; i < obj.separator_indices.length; i++){
                                if(value.length >= obj.separator_indices[i]){
                                    value = [value.slice(0, obj.separator_indices[i]), obj.separator, value.slice(obj.separator_indices[i])].join('');
                                }
                            }
                            console.log("dValue2", value);
                            return value;
                        }
                    }
                    
                }
            }
        }
        
        return query;
    }

    clearCachedData(){
        localStorage.removeItem("warehouses");
        localStorage.removeItem("item_statuses");
        localStorage.removeItem("so_order_statuses");
        localStorage.removeItem("vendors");
        localStorage.removeItem("vendors2");
        localStorage.removeItem("acc_vendors");
        localStorage.removeItem("distributors");
        localStorage.removeItem("brands");
        localStorage.removeItem("wh_layout");
        localStorage.removeItem("dashboard_roles");
        localStorage.removeItem("dsps");
        localStorage.removeItem("item_replacement_mapping");
        localStorage.removeItem("compatibility_acc");
        localStorage.removeItem("car_brands");
        localStorage.removeItem("car_models");
        // localStorage.removeItem("car_variants");
    }

    getWarehouseIDFromCode(code){
        return this.warehouses().find(o => o.code == code).id;
    }

    getWarehouseID(){
        return localStorage.getItem("warehouse_id");
    }

    getWarehouseCode(){
        return localStorage.getItem("warehouse_id") != "all" ? this.warehouses().find(o => o.id == localStorage.getItem("warehouse_id")).code : "all";
    }

    getWarehouseTitle(){
        return this.getWarehouseCode().toUpperCase();
    }

    getWarehouseAddressData(){
        return localStorage.getItem("warehouse_id") != "all" ? this.warehouses().find(o => o.id == localStorage.getItem("warehouse_id")).address : {};
    }

    getPartnerWarehouseAddressData(partner_id){
        var vendor = this.accVendors().find(o => o.id == partner_id);
        return {full_address : vendor.address, state : vendor.state, city : vendor.city, pincode : vendor.pincode};
    }

    isWarehouseFixed(){
        return localStorage.getItem("is_warehouse_fixed");
    }

    isWareHouseSpecific(){
        // return localStorage.getItem("phone") == "9990903321" || localStorage.getItem("warehouse_id") != "all";
        return localStorage.getItem("warehouse_id") != "all";
    }

    getUserID(){
        return localStorage.getItem("user_id");
    }

    getCollabAppendObj(){
        return {last_updated : {updated_by : localStorage.getItem("user_id"), updated_at : (new Date()).getTime()}};
    }
    getCollabAppendObjInner(){
        return {updated_by : localStorage.getItem("user_id"), updated_at : (new Date()).getTime()};
    }

    getCollabAppendObjOriginal(){
        var time = (new Date()).getTime();
        return {last_updated : {updated_by : localStorage.getItem("user_id"), updated_at : time},
                created : {created_by : localStorage.getItem("user_id"), created_at : time, created_warehouse : this.getWarehouseID()}};
    }

    getCollabAppendObjFinal(){
        var time = (new Date()).getTime();
        return {finished : {finished_by : localStorage.getItem("user_id"), finished_at : time}};
    }

    getCollabAppendObjFinalInner(){
        var time = (new Date()).getTime();
        return {finished_by : localStorage.getItem("user_id"), finished_at : time};
    }

    getUserRole(){
        return localStorage.getItem('role');
    }

    getUserRoleTitle(){
        return user_roles[localStorage.getItem('role')].title;
    }

    getUserRoleTitleES(){
        return user_roles_es[localStorage.getItem('role')].title;
    }

    getPartner(){
        return JSON.parse(localStorage.getItem('partner'));
    }

    getPartnerID(){
        return localStorage.getItem("partner_id");
    }

    getLogoURL(){
        if(this.getPartner().logo_url){
            return this.getPartner().logo_url.replace('https://storage.cloud.google.com', 'https://storage.googleapis.com').replace(' ', '%20')
        }else{
            return null;
        }
    }

    setPartner(data){
        return localStorage.setItem("partner", JSON.stringify(data));
    }

    cacheCheck(){
        // return this.subCategories() == null;
        return this.subCategories() == null || this.accCompatibility() == null || this.carBrands() == null || this.carModels() == null || this.b2bAgents() == null || this.dsps() == null || this.warehouses() == null || this.itemStatuses() == null || this.soOrderStatuses() == null || this.accVendors() == null || this.vendors() == null || this.distributors() == null || this.brands() == null || this.whLayout() == null || this.dashboardRoles() == null
        // return this.subCategories() == null || this.accCompatibility() == null || this.carBrands() == null || this.carModels() == null || this.b2bAgents() == null || this.itemReplacementMapping() == null || this.dsps() == null || this.warehouses() == null || this.itemStatuses() == null || this.soOrderStatuses() == null || this.accVendors() == null || this.vendors() == null || this.vendors2() == null || this.brands() == null || this.whLayout() == null || this.dashboardRoles() == null
        // return this.subCategories() == null || this.accCompatibility() == null || this.carBrands() == null || this.b2bAgents() == null || this.itemReplacementMapping() == null || this.dsps() == null || this.warehouses() == null || this.itemStatuses() == null || this.soOrderStatuses() == null || this.accVendors() == null || this.vendors() == null || this.brands() == null || this.whLayout() == null || this.dashboardRoles() == null
    }

    cacheCheckES(){
        return this.warehouses() == null || this.itemStatuses() == null || this.dsps() == null || this.soOrderStatuses() == null || this.whLayout() == null || this.dashboardRoles() == null
    }

    getLandingData(){
        return landing_data;
    }

    getFAQs(){
        return faqs;
    }

    getUCStatusesOrder(status){
        return NS_UC_STATUSES_ORDER[status];
    }
    getUCStatuses(status){
        return NS_UC_STATUSES[status];
    }

    getNotifText(data){
        switch(data.type){
            case "ffmt_manufacturing_order":
                return "You have " + data.items.length + " new manufacturing order" + (data.items.length == 1 ? "" : "s") + " from GoMechanic."
            case "dpsp_order":
                return "You have been allocated " + data.items.length + " order" + (data.items.length == 1 ? "" : "s") + " by GoMechanic. Dispatch the orders within 24 hours to boost your earnings."
            case "periodic_tasks":
                switch(data.type1){
                    case "closed":
                        return data.items.length + " of your periodic tasks " + (data.items.length == 1 ? "is" : "are") + " now finished."
                    case "error":
                        return data.items.length + " of your periodic task" + (data.items.length == 1 ? "" : "s") + " have generated errors."
                    default:
                        return "You have a new notification."
                }
                
            default:
                return "You have a new notification."
        }
    }

    getDynamicSKUTypes(){
        return DYNAMIC_SKU_TYPEs;
    }

    getSeatCoverStyles(){
        return SEAT_COVER_STYLES;
    }
    getSeatCoverColors(){
        return SEAT_COVER_COLORS;
    }
    getSeatCoverArmrests(){
        return SEAT_COVER_ARMRESTS;
    }
    getSeatCoverHeadrests(){
        return SEAT_COVER_HEADRESTS;
    }
    getSeatCoverAttrs(){
        return SEAT_COVER_ATTRS;
    }

    getDynamicSKUTitle(type, sku_code){
        var title = "";
        switch(type){
            case "seat_cover":
                var split = sku_code.split("_");
                if(split.length != 3){
                    break;
                }
                title += this.getSeatCoverStyles().find(o => o.code == split[0]).title+"/";
                var split2 = split[2].match(/[A-Z]+|[0-9]+/g);
                var cgid = this.accCompatibility().find(o => o.compatibility_group_id == parseInt(split2[0]));
                title += cgid.make+"/"+cgid.model+"/"+cgid.year_range+"/";
                var split21 = split2[1].substr(0,1);
                var split22 = split2[1].substr(1,1);
                var split23 = split2[1].substr(2,1);
                var armrest = this.getSeatCoverArmrests().find(o => o.code == split21);
                var headrest = this.getSeatCoverHeadrests().find(o => o.code == split22);
                var attrs = this.getSeatCoverAttrs().find(o => o.code == split23);
                title += (armrest.front == "yes" ? "FWAR" : "FWOAR") + "_" + (armrest.rear == "yes" ? "RWAR" : "RWOAR") + "/";
                title += "F" + headrest.front + "_" + "R" + headrest.rear + "/";
                title += attrs.split_rear + "/";
                title += (attrs.airbags == "yes" ? "AB" : "NAB") + "/";
                var split1 = split[1].match(/[A-Z]+|[0-9]+/g);
                title += this.getSeatCoverColors().find(o => o.is_major && o.code_major == split1[0]).title+"/";
                title += this.getSeatCoverColors().find(o => o.is_minor && o.code_minor == split1[1]).title+"/";
                title += this.getSeatCoverColors().find(o => o.is_t1 && o.code_t1 == split1[2]).title+"/";
                title += this.getSeatCoverColors().find(o => o.is_t2 && o.code_t2 == split1[3]).title;
                // "FUSION_RODEO/SONET/2020-2022/FWAR_RWOAR/FNIH_RIH/SB/NAB/BLK_SILVER/SILVER_0"
                title = title.toUpperCase();
                break;
            default:
                break;
        }
        return title;
    }

    getDynamicSKUPricing(type, sku_code, price_type){
        switch(type){
            case "seat_cover":
                var split = sku_code.split("_");
                if(split.length != 3){
                    break;
                }
                var style = split[0];
                var split2 = split[2].match(/[A-Z]+|[0-9]+/g);
                var cg_id = parseInt(split2[0]);
                var obj = SEAT_COVER_PRICING.find(o => o.styles.indexOf(style) != -1 && o.compatibility_group_ids.indexOf(cg_id) != -1);
                return obj ? obj[price_type] : 0;
            default:
                return null;
        }
    }

    isDeviceMobile(){
        return constants.getDeviceTypeByWidth() == constants.getDeviceTypes().MOBILE;
    }


    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }

    makeRandomString(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    getInitialsFromName(name){
        let initials = [...name.matchAll(new RegExp(/(\p{L}{1})\p{L}+/, 'gu'))] || [];
        return ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();
    }


    validateEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(email && email != ""){
            console.log("email", email);
            return emailRegex.test(email);
        }
        console.log("error", email)
        return false;
    }

    validatePhoneNumber(phoneNumber) {
        if(!(/^[6-9]\d{9}$/.test(phoneNumber)) || phoneNumber.length != 10){
            return false;
        }
        return true;
    }
    validatePinCode(pinCode) {
        if(!(/^[1-9][0-9]{5}$/.test(pinCode)) || pinCode.length != 6){
            return false;
        }
        return true;
    }

    isLoggedIn(){
        return localStorage.getItem("access_token") != null && localStorage.getItem("access_token") != "";
    }

    getCurrentDateInput(date, type){
        const dateObj = new Date(date);
        const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
        const day = ("0" + dateObj.getDate()).slice(-2);
        const year = dateObj.getFullYear();
        
        let shortDate = ``;
        if(type == "type1")
            shortDate = `${day}/${month}/${year}`;
        else
            shortDate = `${year}-${month}-${day}`;
        return shortDate;
    };

}

export default new Utils();