import Home from "./Home";
import { Sidebar } from "./sidebar";
import constants from "../constants";
import {DashboardType1} from "./DashboardType1";
import {DashboardType2} from "./DashboardType2";
import { DashboardType3 } from "./DashboardType3";
import { DashboardType4 } from "./DashboardType4";
import { Navigate } from "react-router-dom";

export const Dashboard = (props) => {
    let content;
    console.log("hereXX", props);
    const subsection = constants.getSidebarSubsectionByKey("superadmin", props.subsection);
    console.log("hereXX1", subsection);
    const subsectionContent = constants.getSubsectionContent("superadmin", props.subsection);
    if(props.section == "home"){
        content = <Navigate to={"/dashboard/"+constants.getSidebarSubsectionFirst(constants.getRole()).key} />
    }else{
        if (props.dashboardType == "detail"){
            console.log("props.detail", props.detail);
            content = <DashboardType3 subsection={subsection} content={subsectionContent} />;
        }else if (props.dashboardType == "action"){
            console.log("props.actionType", props.actionType);
            content = <DashboardType4 subsection={subsection} content={subsectionContent} actionType={props.actionType} actionObject={props.actionObject} />;
            // switch(props.actionType) {
            //     case "add":
            //         content = <DashboardType4 subsection={subsection} content={subsectionContent} actionType={props.actionType} actionObject={props.actionObject} />;
            // }
        }else{
            switch(subsection.viewType) {
                case 1:
                    content = <DashboardType1 subsection={subsection} content={subsectionContent} />;
                    break;
                case 2:
                    content = <DashboardType2 subsection={subsection} content={subsectionContent} />;
                    break;
                default:
                    content = <DashboardType2 subsection={subsection} content={subsectionContent} />;
                    break;
            }
        }
    }

    return (
        <>
            <Sidebar {...props} />
            {content}
        </>
    );
};