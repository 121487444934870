import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import utils from '../utils';
import constants from '../constants';
import ChoiceBox from '../base/utils/choicebox';

export const RowItem = (props) =>  {
      let value = constants.getRowDataValue(props.data, props.data1);
      console.log("value", value, props.data);

      return(
        <div className="rowInner border-box leftalign" style={props.data.style}>
          {value ? value : ""}
        </div>
      );
  }