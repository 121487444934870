import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import utils from '../utils';
import constants from '../constants';
import ChoiceBox from '../base/utils/choicebox';

export const RowHeaderItem = (props) =>  {
      var that = this;
      var value = null;

      if(props.data.headerType == "checkbox"){
        var countState = -1;
        var countData = 0;

        if(props.dataState && props.dataState.selectedItems){
          countState = props.dataState.selectedItems.length;
        }
        if(props.dataData){
          countData = props.dataData.length;
        }

        // console.log("headerCheck", that.props.dataState, that.props.dataData);

        value = <ChoiceBox type="rfq_backorder" 
                  id="all" 
                  text={"\u2714"}
                  selected={countState == countData}
                  onClick={props.onChoiceBoxClick} />
      }else{
        value = props.data.header
      }

      return(
        <div className="border-box leftalign" style={props.data.style}>
            {value ? value : ""}
        </div>
      );
  }