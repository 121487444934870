import { Navigate, Outlet, useParams } from 'react-router-dom';
import Topbar from '../topbar/topbar';
import './dashboard.css';
import Home from './Home';
import utils from '../utils';

function AppDashboard(props) {

    return (
        <>
        {utils.isLoggedIn() ? 
            <>
                <Topbar type="auth" />
                <div className="dashboardbase border-box">
                    <Outlet />
                </div>
            </>
         : <><Navigate to="/login" /></>}
        </>
    );
}

export default AppDashboard;
