import { useEffect, useState } from 'react';
import utils from '../../utils';
import { redirect, useLocation, useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import "./action.css";
import FieldBox from '../../base/utils/fieldbox';
import Utils from '../../utils';
import api from '../../api';
import { Loader } from '../../base/utils/loader';

export const EditOEM = (props) => {
    const [pageStatus, setPageStatus] = useState("initial");
    const navigate = useNavigate();
    // const [currentTab, setCurrentTab] = useState(props.subsection.viewTabs ? props.subsection.viewTabs[0] : {});
    const [isLoading, setIsLoading] = useState(false);
    // const [fields, setFields] = useState({gender : "male"});
    const [error, setError] = useState({});
    const[errorGlobal, setErrorGlobal] = useState("");

    let { currentTab, id } = useParams();
    const {state} = useLocation();
    const [fields, setFields] = useState(state ? 
        {
            name : state.data.name,
            password : state.data.password,
            email : state.data.email,
            phoneNumber : state.data.user_info? state.data.user_info.phone_number : "",
            location : state.data.location,
            autoAssign: state.data.auto_assign ? "Yes" : "No",
        }
        : 
        {
            name : "", 
            email : "",
            password : "",
            phoneNumber : "",
            location : "",
            autoAssign: "No",
        });
    // const [data, setData] = useState(state.data);

    const errors = {
        name : "Please enter a valid name", 
        email : "Please enter a valid email address",
        phoneNumber : "Please enter a valid phone number",
        password : "Please enter a password of minimum 8 characters",
        location : "Please enter a HQ location",
    }

    // input_data = {
    //     "updated_at": now,
    //     "joining_date": datetime.datetime.strptime(str(request.data.get('joining_date')), "%d/%m/%Y"),
    //     "gender": str(request.data.get('gender')),
    //     "dob": str(request.data.get('dob')),
    //     "address": {
    //         "country": str(request.data.get('country')),
    //         "state": str(request.data.get('state')),
    //         "address_line1": str(request.data.get('address_line1')),
    //         "address_line2": str(request.data.get('address_line2')),
    //         "city": str(request.data.get('city')),
    //         "postal_code": str(request.data.get('postal_code'))
    //     },
    //     "location": str(request.data.get('city')),
    //     "city_id": int(request.data.get('city_id')),
    //     "employer": {
    //         "company_name": str(request.data.get('company_name')),
    //         "location": str(request.data.get('company_location')),
    //         "employee_id": str(request.data.get('employee_id'))
    //     },
    //     "service_center_id": int(request.data.get('service_center_id'))
    // }

    // console.log("idEditSB", id, currentTab, data, props.actionType);

    
    function handleFieldState(key, value) {
        switch (key) {
            case "test":
            case "aadhar_file1":
            case "aadhar_file2":
            case "license":
            case "photo":
                break;
            case "email":
                setFields({...fields, [key] : value.trim().toLowerCase()});
                validateField(key, value.trim().toLowerCase());
                break;
            default:
                setFields({...fields, [key] : value});
                validateField(key, value);
                break;
        }
    }

    function validateField(key, value){
        console.log("erroring", error);
        switch (key) {
            case "name":
                if ((/[^a-zA-Z]/.test(value)) || value.length > 70){
                    console.log("erroring1", key, (/[^a-zA-Z]/.test(value)), value.length > 70);
                    // error[key] = errors[key];
                    setError({...error, name : errors[key]});return false;
                }else{
                    delete error[key];setError(error);return true;
                }
                break;
            case "email":
                if(!Utils.validateEmail(value)) {
                    console.log("erroring1", key);
                    error[key] = errors[key];setError(error);return false;
                }else{
                    delete error[key];setError(error);return true;
                }
                break;
            case "phoneNumber":
                if(!Utils.validatePhoneNumber(value)) {
                    console.log("erroring3", key);
                    error[key] = errors[key];setError(error);return false;
                }else{
                    delete error[key];setError(error);return true;
                }
                break;
            case "password":
                if(fields && fields.name){
                    delete error["password"];
                    setError(error);
                    return true;
                    // setFields({...fields, [key] : value});
                    // validateField(key, value);
                }else{
                    // setFields({...fields, [key] : value});
                    // validateField(key, value);
                    if(!value || value.length < 8) {
                        error[key] = errors[key];
                        setError(error);
                        return false;
                    }else{
                        delete error["password"];
                        setError(error);
                        return true;
                    }
                }
              break;
            case "aadhar_file1":
            case "aadhar_file2":
            case "license":
            case "photo":
                return true;
                break;
            default:
                if(!value || value.length == 0) {
                    console.log("erroring", key);
                    error[key] = errors[key];setError(error);return false;
                }else{
                    delete error[key];setError(error);return true;
                }
                break;
        }
    }

    function validateAllFields(){
        let isValid = true;
        
        Object.keys(fields).forEach((key, index) => {
            console.log("validate", key);
            if(!validateField(key, fields[key])){
                console.log("invalid", key, fields[key]);
                isValid = false;
            }
        });
        return isValid;
    }


    function handleButtonClick(buttonType, data){
        if(buttonType == "save"){
            setErrorGlobal("");
            console.log("here");
            if (validateAllFields()){
                console.log("here1");
                let request_data = {
                    email : fields.email,
                    name : fields.name,
                    phone_number : fields.phoneNumber,
                    password : fields.password,
                    location : fields.location,
                    source : "vecmocon_superadmin_dashboard",
                    auto_assign : fields.autoAssign && fields.autoAssign == "Yes" ? true : false,
                }
                // var fieldData = Object.assign({}, that.state.fieldData);
                // delete fieldData.image_file;
                if(state && state.data.id){
                    request_data["oem_id"] = state.data.id;
                }
                setIsLoading(true);
                // let files = [fields.aadhar_file1, fields.aadhar_file2, fields.license, fields.photo];
                api.saveOEM(request_data)
                    .then(function (response) {console.log(response);setIsLoading(false);navigate("/dashboard/oems")})
                    .catch(function (error) {setIsLoading(false);
                        // setError({email : error.response.data.message});
                        setIsLoading(false);
                        setErrorGlobal(error.response.data.message);
                    }).finally(function () {});
            }
        }
    }

    return (<>
        {isLoading ? 
            <div className="loader spacer border-box flex_horizontal center_vertical center_horizontal"><Loader /></div> : 
            
        
        
        <div className="action full_width full_height border-box flex_vertical center_horizontal start_vertical">
            <div className="one full_width border-box flex_horizontal center_horizontal center_vertical">
                <div className="left border-box flex_horizontal center_horizontal center_vertical">
                    <div className="border-box flex_horizontal center_vertical center_horizontal shadow-box">
                        {props.actionType == "add" ? "" : utils.getInitialsFromName(props.data.name)}
                    </div>
                </div>
                <div className="right border-box flex_vertical start_horizontal end_vertical">
                    <div className="title weight600">{props.actionType == "add" ? "Add new OEM" : (props.data.name)}</div>
                    <div style={{height : "10px"}}/>
                    <div className="subtitle weight300">{props.actionType == "add" ? "" : props.data.email}</div>
                    <div style={{height : "30px"}}/>
                </div>
            </div>
            <div className="two full_width border-box flex_horizontal center_horizontal start_vertical bgcolorGray200">
                <div className="inner full_width border-box flex_horizontal center_horizontal start_vertical">
                    <div className="left border-box flex_vertical start_horizontal start_vertical">
                        <div className="title weight600">OEM details</div>
                        <div className="subtitle colorGray500span">Update OEM details</div>
                    </div>
                    <div className="right border-box flex_vertical start_horizontal start_vertical">
                        <div className="inner full_width border-box flex_vertical start_horizontal start_vertical bgcolorSix50 shadow-box">
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="name" title="Name" value={fields.name} size="small" mandatory={true}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.name} />  
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="location" title="Location" value={fields.location} size="small" mandatory={true}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.location} />  
                                </div>
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="email" title="Superadmin Email" value={fields.email} size="small" mandatory={true}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.email} />  
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="password" title="Superadmin Password" value={fields.password} size="small" mandatory={state ? false : true}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.password} />  
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="phoneNumber" title="Phone Number" value={fields.phoneNumber} size="small" mandatory={true}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.phoneNumber} />  
                                </div>
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="autoAssign" title="Auto Assign" value={fields.autoAssign} size="small" mandatory={true}
                                    onSetFieldState={handleFieldState} type="radio" choices={["Yes","No"]}/>
                                </div>
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <span className="error colorThree500span" style={{fontSize : "13px"}}>{errorGlobal}</span>
                                <div className="spacer"></div>
                                <div className="fieldbuttonbox border-box">
                                    <button className="bttn small solid one" onClick={(e) => handleButtonClick("save")}>Save</button>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </div>
        }
        </>
    );
}
