import { useEffect, useState } from 'react';
import utils from '../../utils';
import { redirect, useLocation, useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import "./action.css";
import FieldBox from '../../base/utils/fieldbox';
import Utils from '../../utils';
import api from '../../api';
import { Loader } from '../../base/utils/loader';
import constants from '../../constants';

export const EditVehicle = (props) => {
    const [pageStatus, setPageStatus] = useState("initial");
    const navigate = useNavigate();
    // const [currentTab, setCurrentTab] = useState(props.subsection.viewTabs ? props.subsection.viewTabs[0] : {});
    const [isLoading, setIsLoading] = useState(false);
    const [oems, setOems] = useState([]);
    // const [fields, setFields] = useState({gender : "male"});
    const [error, setError] = useState({});
    const[errorGlobal, setErrorGlobal] = useState("");

    const [citiesList, setCitiesList] = useState([]);
    const [brandsList, setBrandsList] = useState([]);
    const [modelsList, setModelsList] = useState([]);

    let { currentTab, id } = useParams();
    const {state} = useLocation();
    let vehicle = state && state.data.vehicles ? (state.data.vehicles.length == 0 ? null : state.data.vehicles[state.data.vehicles.length - 1]) : null;
    const [fields, setFields] = useState(state ? 
        {
            brand_name : vehicle ? vehicle.brand_name : "",
            model_name : vehicle ? vehicle.model_name : "",
            submodel_name : vehicle ? vehicle.submodel_name : "",
            oem_id : state.data.oem_id,
        }
        :
        {
            brand_name : "",
            model_name : "",
            submodel_name : "",
            oem_id : "",
        });
    // const [data, setData] = useState(state.data);

    const errors = {
        brand_name : "Please enter brand name",
        model_name : "Please enter model name",
        submodel_name : "Please enter submodel name",
        oem_id : "Please enter OEM",
    }

    let isChecked = false;

    useEffect(() => {
        if (isChecked) return;
        isChecked = true;
        
        fetchData();
        
        if(fields.brand_name && fields.brand_name.length > 0){
            fetchDataModelsList(fields.brand_name);
        }
        
      }, []);

    
    function handleFieldState(key, value) {
        switch (key) {
            case "test":
            case "aadhar_file1":
            case "aadhar_file2":
            case "license":
            case "photo":
                break;
            case "oem_id":
                var brand_name = oems.filter(o => o.id == value)[0].name;
                setFields({...fields, [key] : value, brand_name : brand_name, model_name : ""});
                validateField(key, value);
                fetchDataModelsList(brand_name);
                break;
            case "brand_name":
                setFields({...fields, [key] : value, model_name : ""});
                validateField(key, value);
                fetchDataModelsList(value);
                break;
            default:
                setFields({...fields, [key] : value});
                validateField(key, value);
                break;
        }
    }

    function validateField(key, value){
        switch (key) {
            case "submodel_name":
                return true;
                break;
            case "oem_id":
                if(constants.getRole() == "superadmin"){
                    if(!value || value.length == 0) {
                        console.log("erroring", key);
                        error[key] = errors[key];setError(error);return false;
                    }else{
                        delete error[key];setError(error);return true;
                    }    
                }else{
                    return true;
                }
                break;
            // case "brand_name":
            case "model_name":
                if(!value || value.length == 0) {
                    console.log("erroring", key);
                    error[key] = errors[key];setError(error);return false;
                }else{
                    delete error[key];setError(error);return true;
                }
                break;
            default:
                // if(!value || value.length == 0) {
                //     console.log("erroring", key);
                //     error[key] = errors[key];setError(error);return false;
                // }else{
                    delete error[key];setError(error);return true;
                // }
                break;
        }
    }

    function validateAllFields(){
        let isValid = true;
        
        Object.keys(fields).forEach((key, index) => {
            console.log("validate", key);
            if(!validateField(key, fields[key])){
                console.log("invalid", key, fields[key]);
                isValid = false;
            }
        });
        return isValid;
    }


    function handleButtonClick(buttonType, data){
        if(buttonType == "save"){
            setErrorGlobal("");
            console.log("here");
            if (validateAllFields()){
                console.log("here1");
                let request_data2 = {
                    // brand_name : fields.brand_name,
                    model_name : fields.model_name,
                    submodel_name : fields.submodel_name,
                }
                if(localStorage.getItem("oem_id")){
                    request_data2["oem_id"] = localStorage.getItem("oem_id");
                    request_data2["brand_name"] = oems.filter(o => o.id == localStorage.getItem("oem_id"))[0].name;
                }
                if (constants.getRole() == "superadmin"){
                    request_data2["oem_id"] = fields.oem_id;
                    request_data2["brand_name"] = oems.filter(o => o.id == fields.oem_id)[0].name;
                }
                setIsLoading(true);
                // let files = [fields.aadhar_file1, fields.aadhar_file2, fields.license, fields.photo];
                api.onboardVehicle(request_data2).then(function (response) {
                    console.log(response);setIsLoading(false);navigate("/dashboard/models");
                }).catch(function (error) {console.log(error);setIsLoading(false);setErrorGlobal(error.response.data.message);}).finally(function () {});
            }
        }
    }

    return (<>
        {isLoading ? 
            <div className="loader spacer border-box flex_horizontal center_vertical center_horizontal"><Loader /></div> : 
            
        
        
        <div className="action full_width full_height border-box flex_vertical center_horizontal start_vertical">
            <div className="one full_width border-box flex_horizontal center_horizontal center_vertical">
                <div className="left border-box flex_horizontal center_horizontal center_vertical">
                    <div className="border-box flex_horizontal center_vertical center_horizontal shadow-box">
                        {props.actionType == "add" ? "" : utils.getInitialsFromName(props.data.brandName)}
                    </div>
                </div>
                <div className="right border-box flex_vertical start_horizontal end_vertical">
                    <div className="title weight600">{props.actionType == "add" ? "Onboard new vehicle" : (props.data.first_name + " " + props.data.last_name)}</div>
                    <div style={{height : "10px"}}/>
                    <div className="subtitle weight300">{props.actionType == "add" ? "" : props.data.email}</div>
                    <div style={{height : "30px"}}/>
                </div>
            </div>
            <div className="two full_width border-box flex_horizontal center_horizontal start_vertical bgcolorGray200">
                <div className="inner full_width border-box flex_horizontal center_horizontal start_vertical">
                    <div className="left border-box flex_vertical start_horizontal start_vertical">
                        <div className="title weight600">Vehicle details</div>
                        {/* <div className="subtitle colorGray500span">Update customer details</div> */}
                    </div>
                    <div className="right border-box flex_vertical start_horizontal start_vertical">
                        <div className="inner full_width border-box flex_vertical start_horizontal start_vertical bgcolorSix50 shadow-box">
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                {constants.getRole() == "superadmin" ? 
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="oem_id" title="OEM" value={fields.oem_id}  mandatory={true}
                                        selectedValue={fields.oem_id} onSetFieldState={handleFieldState} 
                                        type="select" size="small" errorDisplay="yes" errorText={error.oem_id}
                                        choices={[...[{value:"", title:"Select an OEM"}], ...oems.map(o => ({value:o.id, title:o.name}))]
                                                .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
                                </div> : <></>}
                                {/* <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="brand_name" title="Vehicle brand name" value={fields.brand_name} 
                                        selectedValue={fields.brand_name} onSetFieldState={handleFieldState}  mandatory={true}
                                        type="select" size="small" errorDisplay="yes" errorText={error.brand_name}
                                        choices={[...[{value:"", title:""}], ...brandsList.map(o => ({value:o.brand_name, title:o.brand_name}))]
                                                .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
                                </div> */}
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    {/* <FieldBox objectKey="model_name" title="Vehicle model name" value={fields.model_name} 
                                        selectedValue={fields.model_name} onSetFieldState={handleFieldState}  mandatory={true}
                                        type="select" size="small" errorDisplay="yes" errorText={error.model_name}
                                        choices={[...[{value:"", title:""}], ...modelsList.map(o => ({value:o.model_name, title:o.model_name}))]
                                                .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/> */}
                                    <FieldBox objectKey="model_name" title="Vehicle model name" value={fields.model_name} size="small" mandatory={true}
                                        onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.model_name} />  
                                </div>
                                
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <span className="error colorThree500span" style={{fontSize : "13px"}}>{errorGlobal}</span>
                                <div className="spacer"></div>
                                <div className="fieldbuttonbox border-box">
                                    <button className="bttn small solid one" onClick={(e) => handleButtonClick("save")}>Save</button>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </div>
        }
        </>
    );



    function fetchData(){
        setIsLoading(true);
        api.getOEMs({offset : "0", limit : "200"}).then(function (response) {
            api.getBrands().then(function (response1) {
                console.log("responsedata", response1.data.data);
                setOems(response.data.data);setBrandsList(response1.data.data);
                console.log("oem_id", localStorage.getItem("oem_id"));
                console.log("oems", response.data.data);
                if(localStorage.getItem("oem_id")){
                    if(response.data.data.filter(o => o.id+"" == localStorage.getItem("oem_id")+"").length > 0){
                        var brand_name = response.data.data.filter(o => o.id+"" == localStorage.getItem("oem_id")+"")[0].name;
                        fetchDataModelsList(brand_name);
                    }
                }
                setIsLoading(false);
            }).catch(function (error1) {console.log(error1);}).finally(function () {});
        }).catch(function (error) {console.log(error);}).finally(function () {});
      }


    function fetchDataModelsList(brandName){
        if(brandName && brandName.length > 0){
            // setIsLoading(true);
            api.getModels(brandName).then(function (response) {console.log("responsedata", response.data.data); setModelsList(response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
        }else{
            setModelsList([]);
        }
    }
}
