import React from "react";
import ChoiceBoxMulti from './choiceboxmulti';
import ChoiceBox from './choicebox';
import Utils from '../../utils';

export default function FieldBox(props) {

    var className = "fieldd border-box";
    if (props.errorDisplay === "yes" && props.errorText){
        className += " error";
    }
    if (props.size){
        className += " " + props.size;
    }
    
    function handleChoiceBoxMultiClick(type, id, text){
        props.onSetFieldState(props.objectKey, id);
    }

    function handleChoiceBoxClick(type, id, text, selected){
        props.onSetFieldState(props.objectKey, id, "choicebox");
    }

    function handleChange(e){
      console.log("state1", e.target.value, props);
      props.onSetFieldState(props.objectKey, e.target.value);
    }

    function handleSearchChange(e){
      props.onSetFilter(props.choiceType, e.target.value);
    }

    function handleFileChange(e) {
        var file = e.target.files[0];
        props.onSetFieldState(props.objectKey, file);
        console.log("statefile", file);
    }

    const togglePasswordVisibility = () => {
      const passwordInput = document.getElementById('password');
      const togglePasswordButton = document.querySelector('.toggle-password');
    
      if (passwordInput.type === 'password') {
        passwordInput.type = 'text';
        togglePasswordButton.style.backgroundImage = 'url("/images/hide.png")';
      } else {
        passwordInput.type = 'password';
        togglePasswordButton.style.backgroundImage = 'url("/images/show.png")'; 
      }
    }

    
      return(
        <React.Fragment>
            {props.noTitle ? <React.Fragment /> : 
            <div className="fieldBoxInner1 border-box weight500">
                {/* {props.title} {props.mandatory ? 
                  <React.Fragment>&nbsp;&nbsp;<span style={{fontSize : "10px"}} className="weight700 colorThree500span">✺✵</span></React.Fragment> : 
                  ""} */}
                  {props.title} {props.mandatory ? 
                  <React.Fragment>&nbsp;&nbsp;<span style={{fontSize : "10px"}} className="weight700 colorThree500span">✵</span></React.Fragment> : 
                  ""}
            </div>
            }
            {props.type == "choiceboxes" && props.choice_search ?
              <input type="text" placeholder="Type to filter" className="fieldd tiny border-box" style={{width : "200px"}}
                value={props.filters && props.filters[props.choiceType] ? props.filters[props.choiceType] : ""}
                onChange={(e) => handleSearchChange(e)}
                // onKeyDown={_handleKeyDown} 
                />
             : <React.Fragment />}
             {props.disabled == "yes" ?
            <div className={props.type == "radio" ? `fieldRadioBoxInner border-box` : `fieldBoxInner2 border-box`} style={props.showPassword ? {position:'relative'} : {position:'initial'}}>
                {
                    {
                    'text': <input type={props.inputtype ? (props.inputtype == "number" ? "number" : "text") : "text"} 
                            placeholder="" disabled
                            className={className}
                            value={props.value} onChange={(e) => handleChange(e)} />,
                    'radio':  props.choices ? props.choices.map((obj, index) =>
                              <>
                                <input type="radio"
                                id={`${props.objectKey}-${index}`}
                                value={obj} disabled
                                checked={props.value == obj}
                                onChange={(e) => handleChange(e)} />
                                <label for={`${props.objectKey}-${index}`} className="radioInner">{obj}</label>
                              </>) : <React.Fragment />,
                    'password': <>
                            <input type="password" placeholder="" 
                            className={className}
                            id="password" disabled
                            value={props.value} onChange={(e) => handleChange(e)}/>
                            {props.showPassword ? <span className="toggle-password" onClick={() => togglePasswordVisibility()}></span> : null}
                            </>,
                    'textarea': <textarea type="text" placeholder="" 
                            className={className} disabled
                            onChange={(e) => handleChange(e)} 
                            style={{minHeight : props.minHeight ? props.minHeight : "100px"}}>{props.value}</textarea>,
                    'select': <select value={props.value} onChange={handleChange}  disabled
                                className={className}>
                                {props.choices}
                            </select>,
                    'date': <input type="date" placeholder="" 
                            className={className} disabled
                            // value={props.value}
                            defaultValue={props.value ? Utils.getCurrentDateInput(props.value) : ""}
                            onChange={(e) => handleChange(e)} />,
                    'time': <input type="time" placeholder="" 
                            className={className} disabled
                            value={props.value} onChange={(e) => handleChange(e)} />,
                    'file': <input type="file" placeholder="" 
                            className={className} disabled
                            onChange={(e) => handleFileChange(e)} />,
                    'choiceboxes' : props.choices ? props.choices.map((obj) =>
                            <ChoiceBox type={props.choiceType} id={obj.id} text={obj.title} 
                            selected={props.selectedValues ? props.selectedValues.indexOf(obj.id) != -1 : false} 
                            onClick={handleChoiceBoxClick} />) : <React.Fragment />,
                    'choiceboxmulti' : <ChoiceBoxMulti type={props.choiceType} 
                            objects={props.choices} 
                            selected={props.selectedValue}
                            onClick={handleChoiceBoxMultiClick} />,
                    }[props.type]
                }
            </div> : 
            <div className={props.type == "radio" ? `fieldRadioBoxInner border-box` : `fieldBoxInner2 border-box`} style={props.showPassword ? {position:'relative'} : {position:'initial'}}>
            {
                {
                'text': <input type={props.inputtype ? (props.inputtype == "number" ? "number" : "text") : "text"} 
                        placeholder="" 
                        className={className}
                        value={props.value} onChange={(e) => handleChange(e)} />,
                'radio':  props.choices ? props.choices.map((obj, index) =>
                          <>
                            <input type="radio"
                            id={`${props.objectKey}-${index}`}
                            value={obj}
                            checked={props.value == obj}
                            onChange={(e) => handleChange(e)} />
                            <label for={`${props.objectKey}-${index}`} className="radioInner">{obj}</label>
                          </>) : <React.Fragment />,
                'password': <>
                        <input type="password" placeholder="" 
                        className={className}
                        id="password"
                        value={props.value} onChange={(e) => handleChange(e)}/>
                        {props.showPassword ? <span className="toggle-password" onClick={() => togglePasswordVisibility()}></span> : null}
                        </>,
                'textarea': <textarea type="text" placeholder="" 
                        className={className}
                        onChange={(e) => handleChange(e)} 
                        style={{minHeight : props.minHeight ? props.minHeight : "100px"}}>{props.value}</textarea>,
                'select': <select value={props.value} onChange={handleChange} 
                            className={className}>
                            {props.choices}
                        </select>,
                'date': <input type="date" placeholder="" 
                        className={className}
                        // value={props.value}
                        defaultValue={props.value ? Utils.getCurrentDateInput(props.value) : ""}
                        onChange={(e) => handleChange(e)} />,
                'time': <input type="time" placeholder="" 
                        className={className}
                        value={props.value} onChange={(e) => handleChange(e)} />,
                'file': <input type="file" placeholder="" 
                        className={className}
                        onChange={(e) => handleFileChange(e)} />,
                'choiceboxes' : props.choices ? props.choices.map((obj) =>
                        <ChoiceBox type={props.choiceType} id={obj.id} text={obj.title} 
                        selected={props.selectedValues ? props.selectedValues.indexOf(obj.id) != -1 : false} 
                        onClick={handleChoiceBoxClick} />) : <React.Fragment />,
                'choiceboxmulti' : <ChoiceBoxMulti type={props.choiceType} 
                        objects={props.choices} 
                        selected={props.selectedValue}
                        onClick={handleChoiceBoxMultiClick} />,
                }[props.type]
            }
        </div>}
            {props.errorDisplay === "yes" ? 
            <div className="fieldBoxInner3 border-box weight500 colorThree500span">
                  {props.errorText}
            </div> : <React.Fragment />}
        </React.Fragment>
            /* <div className="styleVendorPaymentRequestProcess second border-box weight700" style={{fontSize : "16px"}}>
              {props.data.inventory}
            </div> */
            /* <div className="styleVendorPaymentRequestProcess second border-box">
              <input type="number" min="0" placeholder="" className="fieldd small border-box" style={{width : "100px"}}
                        value={props.data.quantity} onChange={(e) => handleChange(e)} />
            </div> */
            /* <div className="styleVendorPaymentRequestProcess third border-box">
              <ChoiceBoxMulti type="transfer_status" 
                objects={[
                    {title : "YES", id : "yes"}, {title : "NO", id : "no"}]} 
                selected={props.data.transfer_status}
                onClick={that.handleChoiceBoxMultiClick} />
            </div> */
      );
  }