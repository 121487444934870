export default function Linkbutton(props) {
    let className = "linkbuttonbase bttnlink";
    let style = {};
    if (props.button_type) {
        className += " " + props.button_type;
    }
    if (props.type) {
        if (props.type === "large1"){
            className += " " + props.type;
            style["fontFamily"] = "'hurme1medium', sans-serif";
            // style['color'] = "var(--colorGray500)";
            style['fontSize'] = "15px";
            style['padding'] = "10px 13px 10px";
        }
    }
    let content = 
        props.newTab == "yes" ? 
            (<a href={props.href} className={className} style={style} target="_blank">{props.text}</a>) : 
            (<a href={props.href} className={className} style={style}>{props.text}</a>);
    return (
        <>{content}</>
    );
  }  