import { useState } from 'react';
import api from '../api';
import { useNavigate } from "react-router-dom";
import utils from '../utils';

export const HeaderButtons = (props) => {
    const navigate = useNavigate(); 

    let content;

    switch(props.subsection.key) {
        case "customers":
            content = <>
                {/* <button className="bttn border one" onClick={(e) => handleButtonClick("download_csv")}>Download CSV</button>
                <button className="bttn solid one" onClick={(e) => handleButtonClick("add")}>+ Add</button> */}
            </>
            break;
        // case "users":
        //     content = <>
        //         <button className="bttn border one" onClick={(e) => handleButtonClick("sb_add")}>+ Add</button>
        //     </>;
        //     break;
        default:
            break;
    }

    function handleButtonClick(buttonType) {
        return
    }

    return (
      content ? <div className="border-box flex_horizontal center_vertical space_between_horizontal">{content}</div> : <></>
    );
}
