import React from 'react';
import { BrowserRouter, Link } from 'react-router-dom';

export default function Bottombarunauth() {
	return (
		<div className="bottombarinner border-box">
			<div className="bottombarinnerleft border-box">
                <a href="/terms-of-user" target="_blank">Terms of Use</a>
                <span style={{width: "20px", display: "inline-block"}}></span>
                <a href="/privacy-policy" target="_blank">Privacy Policy</a>
            </div>
            <div className="bottombarinnerright border-box">
                © 2023 Vecmocon Technologies
            </div>
		</div>
	);
}