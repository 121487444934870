import { useState } from 'react';
import api from '../api';
import { useNavigate } from "react-router-dom";
import utils from '../utils';
import constants from '../constants';

export const SubheaderButtons = (props) => {
    const navigate = useNavigate(); 

    let content = <></>;

    if(constants.isAdmin()){
        switch(props.subsection.key) {
            case "customers":
                content = <>
                    <button className="bttn border one" onClick={(e) => handleButtonClick("excel_upload_customers")}>Upload User Excel</button>
                    <button className="bttn border one" onClick={(e) => handleButtonClick("excel_upload_vehicles")}>Upload Vehicle Excel</button>
                    <button className="bttn border one" onClick={(e) => handleButtonClick("customer_add")}>+ Add</button>
                </>
                break;
            case "models":
                content = <>
                    <button className="bttn border one" onClick={(e) => handleButtonClick("vehicle_onboard")}>Onboard Vehicle</button>
                </>
                break;
            case "users":
                content = <>
                    <button className="bttn border one" onClick={(e) => handleButtonClick("excel_upload_users")}>Upload Excel</button>
                    <button className="bttn border one" onClick={(e) => handleButtonClick("sb_add")}>+ Add</button>
                </>
                break;
            case "managers":
                content = <>
                    <button className="bttn border one" onClick={(e) => handleButtonClick("excel_upload_users")}>Upload Excel</button>
                    <button className="bttn border one" onClick={(e) => handleButtonClick("manager_add")}>+ Add</button>
                </>
                break;
            case "oems":
                content = <>
                    <button className="bttn border one" onClick={(e) => handleButtonClick("oem_add")}>+ Add</button>
                </>
                break;
            case "fleets":
                content = <>
                    <button className="bttn border one" onClick={(e) => handleButtonClick("fleet_add")}>+ Add</button>
                </>
                break;
            case "warehouses":
                content = <>
                    <button className="bttn border one" onClick={(e) => handleButtonClick("excel_upload_warehouses")}>Upload Excel</button>
                    <button className="bttn border one" onClick={(e) => handleButtonClick("warehouse_add")}>+ Add</button>
                </>
                break;
            case "vehiclesb2b":
                content = <>
                    {props.isSelectionEnabled && props.selectedObjsCount > 0 ? <>
                        <button className="bttn small border three" onClick={(e) => handleButtonClick("out_of_service_vehicleb2b")}>Mark out of service</button>
                        <button className="bttn small border three" onClick={(e) => handleButtonClick("servicing_vehicleb2b")}>Mark under maintenance</button>
                        <button className="bttn small border three" onClick={(e) => handleButtonClick("active_vehicleb2b")}>Mark on road</button>
                        {/* <button className="bttn small solid one" onClick={(e) => handleButtonClick("delete_vehicleb2b")}>Delete</button> */}
                        </>
                    : <></>}
                    <button className="bttn border one" onClick={(e) => handleButtonClick("vehicles_select")}>{props.isSelectionEnabled ? "Deselect" : "Select"}</button>
                    <button className="bttn border one" onClick={(e) => handleButtonClick("excel_upload_vehicles")}>Upload Excel</button>
                    <button className="bttn border one" onClick={(e) => handleButtonClick("vehicle_add")}>+ Add</button>
                </>
                break;
            case "services_b2b":
                content = <>
                    <button className="bttn border one" onClick={(e) => handleButtonClick("excel_download_services_b2b")}>Download Excel</button>
                    {/* <button className="bttn border one" onClick={(e) => handleButtonClick("vehicle_add")}>+ Add</button> */}
                </>
                break;
            default:
                break;
        }
    }

    function handleButtonClick(buttonType) {
        switch(buttonType) {
            case "auto_assign_jobs":
                break;
            case "vehicle_onboard":
                navigate("/dashboard/action/customers/vehicles/onboard");
                break;
            case "customer_add":
                navigate("/dashboard/action/customers/customers/add");
                break;
            case "excel_upload_customers":
                navigate("/dashboard/action/excel/upload/customers");
                break;
            case "excel_upload_users":
                navigate("/dashboard/action/excel/upload/users");
                break;
            case "excel_upload_vehicles":
                navigate("/dashboard/action/excel/upload/vehicles");
                break;
            case "excel_upload_warehouses":
                navigate("/dashboard/action/excel/upload/warehouses");
                break;
            case "sb_add":
                navigate("/dashboard/action/users/users/add");
                break;
            case "manager_add":
                navigate("/dashboard/action/users/managers/add");
                break;
            case "oem_add":
                navigate("/dashboard/action/oems/oems/add");
                break;
            case "fleet_add":
                navigate("/dashboard/action/fleets/fleets/add");
                break;
            case "warehouse_add":
                navigate("/dashboard/action/warehouses/warehouses/add");
                break;
            case "vehicle_add":
                navigate("/dashboard/action/vehiclesb2b/vehiclesb2b/add");
                break;
            case "vehicles_select":
                props.handleSubHeaderButtonClick(buttonType);
                break;
            case "out_of_service_vehicleb2b":
            case "servicing_vehicleb2b":
            case "active_vehicleb2b":
            case "delete_vehicleb2b":
                props.handleSubHeaderButtonClick(buttonType);
                break;
            case "excel_download_services_b2b":
                window.location.href = constants.getBaseURL()+'api/v1/order/orders?offset=NaN&status_type=completed&user_type=super_admin&order_type=B2B&download_data=1';
                // https://vecmoconbe.thorintech.com/api/v1/order/orders?offset=NaN&status_type=completed&user_type=super_admin&order_type=B2B&download_data=1
                // api.getServicesB2BExcel({
                //     offset : (parseInt(props.page)-1)*parseInt(props.entriesperpage), limit : props.entriesperpage,
                //     status_type : "completed", user_type : "super_admin", order_type : "B2B", download_data : 1
                // }).then((response) => {
                //     console.log("response", response);
                // })
                break;
        }
    }

    return (
      content ? <div className="border-box flex_horizontal center_vertical space_between_horizontal">{content}</div> : <></>
    );
}
