import logo from './logo.svg';
import './App.css';
import './base/utils/dialog.css';
import { Routing } from './base/navigation/routing';

function App() {
  // const navigate = useNavigate();
  return (
      <Routing />
  );
}

export default App;
