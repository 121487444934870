import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import EntriesPerPage from '../base/utils/entriesperpage';

export const RowFooter = (props) => {
    const navigate = useNavigate();

    function handleButtonClick(buttonType) {
        switch(buttonType) {
            case "download_csv":
                break;
            default:
                break;
        }
    }

    function handleChoiceBoxMultiClick(type, id, text){
      console.log(type, id, text)
      if (type == "entriesperpage") {
          props.handleEntriesChange(id);
      }
    }

      return(
        <div className="rowfooter border-box flex_horizontal center_vertical space_between_horizontal">
            <button className="bttn border gray" onClick={(e) => props.handleNavigationButtonClick("previous")}>↤ Previous</button>
            <EntriesPerPage 
              selectedValue={props.entriesperpage ? props.entriesperpage.toLowerCase() : "20"}
              onClick={handleChoiceBoxMultiClick}
          />
            <button className="bttn border gray" onClick={(e) => props.handleNavigationButtonClick("next")}>Next ↦</button>
        </div>
      );
}
