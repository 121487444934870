import { useEffect, useState } from 'react';
import Utils from '../utils';
import FieldBox from '../base/utils/fieldbox';
import Linkbutton from '../base/utils/linkbutton';
import { redirect } from 'react-router-dom';
import api from '../api';
import { useNavigate } from "react-router-dom";
import PasswordSuccess from './PasswordSuccess';
import { useParams } from 'react-router-dom';
import { Loader } from '../base/utils/loader';

export const AppPasswordSet = (props) => {
    const [userID, setUserID] = useState("");
    const [password, setPassword] = useState("");
    const [passwordC, setPasswordC] = useState("");
    const [error, setError] = useState({});
    const [pageStatus, setPageStatus] = useState("initial");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    let isChecked = false;

    let { token } = useParams();

    useEffect(() => {
        if (isChecked) return;
        isChecked = true;
        console.log("dsdsd, dsdsdsds", token)
        api.passwordSetCheck(token).then(function (response) {
            if(response.data.status){
                console.log("response", response.data.data);
                setUserID(response.data.data.user_id);
            }else{
                error["password"] = response.data.message;
                setError(error);
            }
            })
            .catch(function (error) {
                error["password"] = error.response.data.message;
                setError(error);
                // setPageStatus("initial");
            })
            .finally(function () {
            });
        
    }, []);

    function handleFieldState(key, value) {
        switch (key) {
            case "password":
                setPassword(value.trim());
                break;
            case "passwordC":
                setPasswordC(value.trim());
                break;
            default:
                break;
        }
        validateField(key, value.trim());
    }

    function validateField(key, value){
        switch (key) {
            case "password":
                if(!value || value.length < 8) {
                  error["password"] = "Please enter a password of minimum 8 characters";
                  setError(error);
                  return false;
                }else{
                    delete error["password"];
                    setError(error);
                    return true;
                }
                break;
            case "passwordC":
                if(!value || value.length == 0) {
                  error["passwordC"] = "Please confirm the password";
                  setError(error);
                  return false;
                }else if(password !== value){
                    error["passwordC"] = "The passwords do not match";
                    error["password"] = "The passwords do not match";
                    setError(error);
                    return false;
                }else{
                    delete error["passwordC"];
                    delete error["password"];
                    setError(error);
                    return true;
                }
                break;
            default:
                break;
        }
    }

    function handleButtonClick(buttonType) {
        setIsLoading(true);
        if(validateField("password", password) && validateField("passwordC", passwordC)){
            api.passwordSet(userID, password).then(function (response) {
                if(response.data.status){
                    console.log("response", response.data);
                    setPageStatus("success")
                    // navigate("/account/password/set");
                    
                }else{
                    error["password"] = response.data.message;
                    setError(error);
                    setPageStatus("initial");
                }
                })
                .catch(function (error) {
                    error["password"] = error.response.data.message;
                    setError(error);
                    setPageStatus("initial");
                })
                .finally(function () {
                    setIsLoading(false);
                });
        }else{
            setIsLoading(false);
        }
    }

    return (
      <>
      {isLoading ?
        <div className="loader spacer border-box flex_horizontal center_vertical center_horizontal" style={{width : "100%", height : "100%"}}><Loader /></div>
        :
        <>
        {
            {'initial':
              <div className="accountbaseinner border-box password_set">
                  <div className="accountbaseinnerright border-box" style={{width : "30%"}}>
                  </div>
                  <div className="accountbaseinnerleft border-box" style={{width : "40%"}}>
                      <div className="inner border-box">
                          <h1>Change Password</h1>
                          <p className="centeralign" style={{fontSize : "12px"}}>Set your new password</p>
                          <div className="fieldouter border-box flex_vertical center_horizontal center_vertical">
                                <FieldBox objectKey="password" title="new password" value={password} size="small"
                                  onSetFieldState={handleFieldState} type="password" errorDisplay="yes" errorText={error.password}/>
                          </div>
                          <div className="fieldouter border-box flex_vertical center_horizontal center_vertical">
                                <FieldBox objectKey="passwordC" title="confirm password" value={passwordC} size="small"
                                  onSetFieldState={handleFieldState} type="password" errorDisplay="yes" errorText={error.passwordC}/>
                          </div>
                          <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                              <button className="bttn large solid one" onClick={(e) => handleButtonClick("set")}>Change</button>
                          </div>
                      </div>
                      
                  </div>
                  <div className="accountbaseinnerleft border-box" style={{width : "30%"}}>
                  </div>
              </div>,
              
              'success':
              <PasswordSuccess text='Your password has been changed.' />,
            }[pageStatus]
        }
        </>
        }
      </>
    );
}
