import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import utils from '../../utils';
import constants from '../../constants';
import { Row } from '../Row';

export const SBDetailsAbout = (props) => {
    const navigate = useNavigate();

    return(
        <>
            <div className="vehicle full_width border-box flex_vertical start_horizontal center_vertical">
                <div className="supertitle weight600 colorOne500span">Role</div>
                <div className="title">{props.data.roles.join()}</div>
                <div className="subtitle border-box flex_horizontal start_horizontal center_vertical">
                    <i className="ti ti-map-pin colorGray400span"></i>
                    <span className="colorGray500span">{props.data.sb_users.address ? props.data.sb_users.address.city : "NA"}</span>
                    <i className="ti ti-brand-speedtest colorGray400span"></i>
                    <span className="colorGray500span">Full-time</span>
                </div>
            </div>
            <div style={{height : "30px"}}/>
            <div className="contact full_width border-box flex_vertical start_horizontal center_vertical">
                <div className="supertitle weight600 colorGray500span">Contact Information</div>
                <div className="text full_width border-box flex_horizontal start_horizontal center_vertical">
                    <div className="left border-box colorGray500span">
                        Phone
                    </div>
                    <div className="right border-box">
                        {props.data.sb_users.phone_number}
                    </div>
                </div>
                <div className="text full_width border-box flex_horizontal start_horizontal center_vertical">
                    <div className="left border-box colorGray500span">
                        Email
                    </div>
                    <div className="right border-box">
                        {props.data.sb_users.email}
                    </div>
                </div>
                <div className="text full_width border-box flex_horizontal start_horizontal center_vertical">
                    <div className="left border-box colorGray500span">
                        Location
                    </div>
                    <div className="right border-box">
                        {props.data.sb_users.address ? props.data.sb_users.address.address_line1 + ", " + props.data.sb_users.address.address_line2 + ", " + props.data.sb_users.address.city + ", " + props.data.sb_users.address.state + ", " + props.data.sb_users.address.country + ", " + props.data.sb_users.address.postal_code : "NA"}
                    </div>
                </div>
            </div>
            <div style={{width : "100%", height : "1px", background : "var(--colorGray200)", marginTop : "20px"}} />
            <div style={{height : "30px"}}/>
            <div className="contact full_width border-box flex_vertical start_horizontal center_vertical">
                <div className="supertitle weight600 colorGray500span">Basic Information</div>
                <div className="text full_width border-box flex_horizontal start_horizontal center_vertical">
                    <div className="left border-box colorGray500span">
                        Date of birth
                    </div>
                    <div className="right border-box">
                        {props.data.sb_users.dob ? utils.getCurrentDateInput(props.data.sb_users.dob) : "NA"}
                    </div>
                </div>
                <div className="text full_width border-box flex_horizontal start_horizontal center_vertical">
                    <div className="left border-box colorGray500span">
                        Gender
                    </div>
                    <div className="right border-box">
                        {props.data.sb_users.gender ? props.data.sb_users.gender : "NA"}
                    </div>
                </div>
            </div>
        </>
    );
}
