import { useEffect, useState } from 'react';
import utils from '../../utils';
import { redirect, useLocation, useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import "./action.css";
import FieldBox from '../../base/utils/fieldbox';
import Utils from '../../utils';
import api from '../../api';
import { Loader } from '../../base/utils/loader';
import constants from '../../constants';
import Linkbutton from '../../base/utils/linkbutton';

export const ExcelUpload = (props) => {
    const [pageStatus, setPageStatus] = useState("initial");
    const navigate = useNavigate();
    // const [currentTab, setCurrentTab] = useState(props.subsection.viewTabs ? props.subsection.viewTabs[0] : {});
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({});
    const [success, setSuccess] = useState({});

    let { currentTab, id } = useParams();
    const {state} = useLocation();
    const [fields, setFields] = useState(state ? 
        {
            file1 : "",
        }
        : 
        {
            file1 : "", 
        });
    // const [data, setData] = useState(state.data);

    let actionTypeKey = "customer";
    let actionSampleLink = "";

    switch(props.actionType){
        case "customers":
            actionTypeKey = "customer";
            actionSampleLink = "https://vecmoconbe-app.s3.ap-south-1.amazonaws.com/bulkupload/Customer.xlsx";
            break;
        case "users":
            actionTypeKey = "user";
            actionSampleLink = "https://vecmoconbe-app.s3.ap-south-1.amazonaws.com/bulkupload/Users.xlsx";
            break;
        case "vehicles":
            actionTypeKey = "b2b_vehicle";
            actionSampleLink = "https://vecmoconbe-app.s3.ap-south-1.amazonaws.com/bulkupload/Vehicle_Upload_fleet.xlsx";
            break;
        case "warehouses":
            actionTypeKey = "b2b_warehouse";
            actionSampleLink = "https://vecmoconbe-app.s3.ap-south-1.amazonaws.com/bulkupload/fleet_warehouse_upload.xlsx";
            break;
    }

    const errors = {
        file1 : "Please upload file",
    }

    let isChecked = false;

    useEffect(() => {
        if (isChecked) return;
        isChecked = true;
        
      }, []);

    
    function handleFieldState(key, value) {
        switch (key) {
            // case "file1":
            //     break;
            default:
                setFields({...fields, [key] : value});
                validateField(key, value);
                break;
        }
    }

    function validateField(key, value){
        switch (key) {
            case "email":
                if(!Utils.validateEmail(value)) {
                    console.log("erroring1", key);
                    error[key] = errors[key];setError(error);return false;
                }else{
                    delete error[key];setError(error);return true;
                }
                break;
            case "file1":
                return true;
                break;
            case "oem_id":
                if(constants.getRole() == "superadmin"){
                    if(!value || value.length == 0) {
                        console.log("erroring", key);
                        error[key] = errors[key];setError(error);return false;
                    }else{
                        delete error[key];setError(error);return true;
                    }    
                }else{
                    return true;
                }
                break;
            default:
                if(!value || value.length == 0) {
                    console.log("erroring", key);
                    error[key] = errors[key];setError(error);return false;
                }else{
                    delete error[key];setError(error);return true;
                }
                break;
        }
    }

    function validateAllFields(){
        let isValid = true;
        
        Object.keys(fields).forEach((key, index) => {
            console.log("validate", key);
            if(!validateField(key, fields[key])){
                console.log("invalid", key, fields[key]);
                isValid = false;
            }
        });
        return isValid;
    }


    function handleButtonClick(buttonType, data){
        if(buttonType == "save"){
            if (validateAllFields()){
                console.log("here1".replaceAll("1", "2"));
                let request_data = {
                    user_type: actionTypeKey,
                    usertype : actionTypeKey,
                }

                // if(localStorage.getItem("oem_id")){
                //     request_data["oem_id"] = localStorage.getItem("oem_id");
                // }
                // if (constants.getRole() == "superadmin"){
                //     request_data["oem_id"] = fields.oem_id;
                // }
                // var fieldData = Object.assign({}, that.state.fieldData);
                // delete fieldData.image_file;
                setIsLoading(true);
                let files = [fields.file1];
                api.excelUpload(files, request_data)
                    .then(function (response) {
                        console.log(response.data.data);
                        setError({});
                        setSuccess({data_accurate : response.data.data.accurate_data, data_inaccurate : response.data.data.error_data, message : response.data.message});
                        setIsLoading(false);
                        // navigate("/dashboard/users");
                    })
                    .catch(function (error) {
                        console.log("error", error);
                        if(error.response.data.message){
                            setError({message : error.response.data.message});
                            setSuccess({});
                            setIsLoading(false);
                            setPageStatus("initial");
                        }
                    })
                    .finally(function () {
                        console.log("finally", success);
                    });
            }
        }else if(buttonType == "confirm"){
            setIsLoading(true);
            api.excelUploadConfirm({user_type : actionTypeKey, usertype : actionTypeKey, accurate_data : success.data_accurate})
                .then(function (response) {
                    console.log(response.data.data);
                    setError({});
                    setSuccess({});
                    setIsLoading(false);
                    setFields({file1 : ""});
                    // navigate("/dashboard/users");
                })
                .catch(function (error) {
                    console.log("error", error);
                    if(error.response.data.message){
                        setError({message : error.response.data.message});
                        setSuccess({});
                        setIsLoading(false);
                        setPageStatus("initial");
                    }
                })
                .finally(function () {
                    console.log("finally", success);
                });
        }
    }

    const getData = (type) => {
        let data = type == "accurate" ? success.data_accurate : success.data_inaccurate;
        // data.forEach((d, i) => {
        //     console.log("d", d, typeof(d));
        //     Object.entries(d).forEach((d1, i1) => {
        //         console.log("d1", d1, typeof(d1), d1[0], d1[1]);

        //     });
        // })
        let result = data.map((data, index) => <>
            <div className="border-box flex_horizontal start_horizontal start_vertical weight600">{actionTypeKey.toUpperCase()} {index+1}</div>
            {
                Object.entries(data).map(
                    (d, i) => <>
                        <div className="border-box flex_horizontal start_horizontal start_vertical">
                            <div className="title weight500">{d[0]}</div>
                            <div style={{width : "20px"}}/>
                            {d[0] == "vehicle" || d[0] == "address" || d[0] == "employer" ? 
                            <div className="subtitle weight300">{
                                Object.entries(d[1]).map(
                                    (d1, i1) => <>
                                        <div className="border-box flex_horizontal start_horizontal start_vertical">
                                            <div className="title weight500">{d1[0]}</div>
                                            <div style={{width : "20px"}}/>
                                            <div className="subtitle weight300">{d1[1]+""}</div>
                                        </div>
                                    </>
                                )
                            }</div>
                            : 
                            <div className="subtitle weight300">{d[1]+""}</div>}
                        </div>
                    </>
                )
            }
            <div style={{height : "20px"}}/>
        </>)

        console.log("result", result);

        return result;
    }

    return (<>
        {isLoading ? 
            <div className="loader spacer border-box flex_horizontal center_vertical center_horizontal"><Loader /></div> : 
            
        
        
        <div className="action full_width full_height border-box flex_vertical center_horizontal start_vertical">
            <div className="one full_width border-box flex_horizontal center_horizontal center_vertical">
                <div className="left border-box flex_horizontal center_horizontal center_vertical">
                    {/* <div className="border-box flex_horizontal center_vertical center_horizontal shadow-box">
                        
                    </div> */}
                </div>
                <div className="right border-box flex_vertical start_horizontal end_vertical">
                    <div className="title weight600">{props.actionType.toUpperCase()} Excel Upload</div>
                    <div style={{height : "10px"}}/>
                    <div className="subtitle">
                        <Linkbutton text="Download sample" href={actionSampleLink} newTab="yes" button_type="solid" />
                    </div>
                    {/* <div style={{height : "30px"}}/> */}
                </div>
            </div>
            <div className="two full_width border-box flex_horizontal center_horizontal start_vertical bgcolorGray200">
                <div className="inner full_width border-box flex_horizontal center_horizontal start_vertical">
                    <div className="left border-box flex_vertical start_horizontal start_vertical">
                        {/* <div className="title weight600">User details</div>
                        <div className="subtitle colorGray500span">Update user details</div> */}
                    </div>
                    <div className="right border-box flex_vertical start_horizontal start_vertical">
                        <div className="inner full_width border-box flex_vertical start_horizontal start_vertical bgcolorSix50 shadow-box">
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="file1" title="upload excel file" onSetFieldState={handleFieldState} 
                                        type="file" size="small"/>
                                </div>
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="spacer"></div>
                                <div className="fieldbuttonbox border-box">
                                    <button className="bttn small solid one" onClick={(e) => handleButtonClick("save")}>Save</button>
                                </div>
                            </div>
                        </div>
                        {error.message ? <>
                        <div style={{height : "30px"}}/>
                        <div className="inner full_width border-box flex_vertical start_horizontal start_vertical bgcolorSix50 shadow-box">
                            <div className="errorbox colorThree500span border-box flex_horizontal center_horizontal center_vertical">{error.message}</div>
                        </div>    
                        </> : <></>}
                        {success.message ? <>
                        <div style={{height : "30px"}}/>
                        <div className="inner full_width border-box flex_vertical start_horizontal start_vertical bgcolorSix50 shadow-box">
                            <div className="successbox border-box flex_horizontal start_horizontal center_vertical">{success.message}</div>
                        </div>
                        <div style={{height : "10px"}}/>
                        <div className="inner full_width border-box flex_vertical start_horizontal start_vertical bgcolorSix50 shadow-box">
                            <div className="successbox border-box flex_horizontal start_horizontal center_vertical">Accurate Data</div>
                            <div style={{height : "10px"}}/>
                            
                                {success.data_accurate && success.data_accurate.length != 0 ? 
                                <>
                                {getData("accurate")}
                                </>
                                     : <><span>No data</span></>}

                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="spacer"></div>
                                <div className="fieldbuttonbox border-box">
                                    <button className="bttn small solid one" onClick={(e) => handleButtonClick("confirm")}>Confirm</button>
                                </div>
                            </div>
                        </div>
                        <div style={{height : "10px"}}/>
                        <div className="inner full_width border-box flex_vertical start_horizontal start_vertical bgcolorSix50 shadow-box">
                            <div className="successbox border-box flex_horizontal start_horizontal center_vertical">Inaccurate Data</div>
                            <div style={{height : "10px"}}/>
                            
                                {success.data_inaccurate && success.data_inaccurate.length != 0 ? 
                                <>
                                {getData("inaccurate")}
                                </>
                                     : <><span>No data</span></>}
                        </div>
                        </> : <></>}
                    </div>
                </div>
            </div>
        </div>
        }
        </>
    );
}
