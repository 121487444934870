import { useEffect, useState } from 'react';
import utils from '../../utils';
import { redirect, useLocation, useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import "./action.css";
import FieldBox from '../../base/utils/fieldbox';
import Utils from '../../utils';
import api from '../../api';
import { Loader } from '../../base/utils/loader';

export const EditFleet = (props) => {
    const [pageStatus, setPageStatus] = useState("initial");
    const navigate = useNavigate();
    // const [currentTab, setCurrentTab] = useState(props.subsection.viewTabs ? props.subsection.viewTabs[0] : {});
    const [isLoading, setIsLoading] = useState(false);
    // const [fields, setFields] = useState({gender : "male"});
    const [error, setError] = useState({});

    const [citiesList, setCitiesList] = useState([]);
    const[errorGlobal, setErrorGlobal] = useState("");

    let { currentTab, id } = useParams();
    const {state} = useLocation();
    const [fields, setFields] = useState(state ? 
        {
            fleet_name : state.data.name,
            password : state.data.password,
            email : state.data.email,
            category : state.data.category,
            phoneNumber : state.data.user_info.phone_number,
            company_logo : "",
            fleet_image : "",
            address1 : state.data.user_info.address ? state.data.user_info.address.address_line1 : "",
            address2 : state.data.user_info.address ? state.data.user_info.address.address_line2 : "",
            city : state.data.user_info.address ? state.data.user_info.address.city : "",
            stateName : state.data.user_info.address ? state.data.user_info.address.state.toUpperCase() : "",
            pincode : state.data.user_info.address ? state.data.user_info.address.postal_code : "",
            
        }
        : 
        {
            fleet_name : "", 
            email : "",
            password : "",
            category : "",
            phoneNumber : "",
            company_logo : "",
            fleet_image : "",
            address1 : "",
            address2 : "",
            city : "",
            stateName : "",
            pincode : "",
        });
    // const [data, setData] = useState(state.data);

    const errors = {
        fleet_name : "Please enter a name", 
        email : "Please enter a valid email address",
        phoneNumber : "Please enter a valid phone number",
        password : "Please enter a password of minimum 8 characters",
        category : "Please enter a category",
        company_logo : "Please upload a company logo",
        fleet_image : "Please upload a fleet image",
        address1 : "Please enter address",
        address2 : "Please enter address",
        city : "Please enter a city",
        stateName : "Please enter a state",
        pincode : "Please enter a valid pincode",
    }

    let isChecked = false;

    useEffect(() => {
        if (isChecked) return;
        isChecked = true;
        
        if(fields.stateName && fields.stateName.length > 0){
            fetchDataCitiesList(fields.stateName);
        }
        
      }, []);

    // input_data = {
    //     "updated_at": now,
    //     "joining_date": datetime.datetime.strptime(str(request.data.get('joining_date')), "%d/%m/%Y"),
    //     "gender": str(request.data.get('gender')),
    //     "dob": str(request.data.get('dob')),
    //     "address": {
    //         "country": str(request.data.get('country')),
    //         "state": str(request.data.get('state')),
    //         "address_line1": str(request.data.get('address_line1')),
    //         "address_line2": str(request.data.get('address_line2')),
    //         "city": str(request.data.get('city')),
    //         "postal_code": str(request.data.get('postal_code'))
    //     },
    //     "location": str(request.data.get('city')),
    //     "city_id": int(request.data.get('city_id')),
    //     "employer": {
    //         "company_name": str(request.data.get('company_name')),
    //         "location": str(request.data.get('company_location')),
    //         "employee_id": str(request.data.get('employee_id'))
    //     },
    //     "service_center_id": int(request.data.get('service_center_id'))
    // }

    // console.log("idEditSB", id, currentTab, data, props.actionType);

    
    function handleFieldState(key, value) {
        switch (key) {
            case "test":
            case "company_logo":
            case "fleet_image":
            case "license":
            case "photo":
                break;
            case "stateName":
                setFields({...fields, [key] : value, city : ""});
                validateField(key, value);
                fetchDataCitiesList(value);
                break;
            case "email":
                setFields({...fields, [key] : value.trim().toLowerCase()});
                validateField(key, value.trim().toLowerCase());
                break;
            default:
                console.log("value", value)
                setFields({...fields, [key] : value});
                validateField(key, value);
                break;
        }
    }

    function validateField(key, value){
        switch (key) {
            case "email":
                if(!Utils.validateEmail(value)) {
                    console.log("erroring1", key);
                    error[key] = errors[key];setError(error);return false;
                }else{
                    delete error[key];setError(error);return true;
                }
                break;
            case "pincode":
                if(!value || value.length == 0) {
                    delete error[key];setError(error);return true;
                }
                else{
                    if(!Utils.validatePinCode(value)) {
                        console.log("erroring2", key);
                        error[key] = errors[key];setError(error);return false;
                    }else{
                        delete error[key];setError(error);return true;
                    }
                }
                break;
            case "phoneNumber":
                if(!value || value.length == 0) {
                    delete error[key];setError(error);return true;
                }
                else{
                    if(!Utils.validatePhoneNumber(value)) {
                        console.log("erroring3", key);
                        error[key] = errors[key];setError(error);return false;
                    }else{
                        delete error[key];setError(error);return true;
                    }
                }
                break;
            case "company_logo":
            case "fleet_image":
            case "license":
            case "photo":
                return true;
                break;
            case "password":
                if(state){
                    // setFields({...fields, [key] : value});
                    // validateField(key, value);
                    return true;
                }else{
                    // setFields({...fields, [key] : value});
                    // validateField(key, value);
                    if(!value || value.length < 8) {
                        error[key] = errors[key];
                        setError(error);
                        return false;
                    }else{
                        delete error["password"];
                        setError(error);
                        return true;
                    }
                }
              break;
            case "category":
            case "fleet_name":
                if(!value || value.length == 0) {
                    console.log("erroring", key);
                    error[key] = errors[key];setError(error);return false;
                }else{
                    delete error[key];setError(error);return true;
                }
                break;
            default:
                delete error[key];setError(error);return true;
                break;
        }
    }

    function validateAllFields(){
        let isValid = true;
        
        Object.keys(fields).forEach((key, index) => {
            console.log("validate", key);
            if(!validateField(key, fields[key])){
                console.log("invalid", key, fields[key]);
                isValid = false;
            }
        });
        return isValid;
    }


    function handleButtonClick(buttonType, data){
        if(buttonType == "save"){
            setErrorGlobal("");
            console.log("here");
            if (validateAllFields()){
                console.log("here1");
                let request_data = {
                    email : fields.email,
                    fleet_name : fields.fleet_name,
                    phone_number : fields.phoneNumber,
                    password : fields.password,
                    category : fields.category,
                    country : "India",
                    state : fields.stateName,
                    address_line1 : fields.address1,
                    address_line2 : fields.address2,
                    city : fields.city,
                    postal_code : fields.pincode,
                    source : "vecmocon_superadmin_dashboard",
                }
                // var fieldData = Object.assign({}, that.state.fieldData);
                // delete fieldData.image_file;
                if(state && state.data.id){
                    request_data["fleet_id"] = state.data.id;
                }
                setIsLoading(true);
                let files = [fields.company_logo, fields.fleet_image];
                api.saveFleet(files, request_data)
                    .then(function (response) {console.log(response);setIsLoading(false);navigate("/dashboard/fleets")})
                    .catch(function (error) {setIsLoading(false);
                        // setError({email : error.response.data.message});
                        setIsLoading(false);
                        setErrorGlobal(error.response.data.message);
                    }).finally(function () {});
            }
        }
    }

    return (<>
        {isLoading ? 
            <div className="loader spacer border-box flex_horizontal center_vertical center_horizontal"><Loader /></div> : 
            
        
        
        <div className="action full_width full_height border-box flex_vertical center_horizontal start_vertical">
            <div className="one full_width border-box flex_horizontal center_horizontal center_vertical">
                <div className="left border-box flex_horizontal center_horizontal center_vertical">
                    <div className="border-box flex_horizontal center_vertical center_horizontal shadow-box">
                        {props.actionType == "add" ? "" : utils.getInitialsFromName(props.data.name)}
                    </div>
                </div>
                <div className="right border-box flex_vertical start_horizontal end_vertical">
                    <div className="title weight600">{props.actionType == "add" ? "Add new Fleet" : (props.data.name)}</div>
                    <div style={{height : "10px"}}/>
                    <div className="subtitle weight300">{props.actionType == "add" ? "" : props.data.email}</div>
                    <div style={{height : "30px"}}/>
                </div>
            </div>
            <div className="two full_width border-box flex_horizontal center_horizontal start_vertical bgcolorGray200">
                <div className="inner full_width border-box flex_horizontal center_horizontal start_vertical">
                    <div className="left border-box flex_vertical start_horizontal start_vertical">
                        <div className="title weight600">Fleet details</div>
                        <div className="subtitle colorGray500span">Update Fleet details</div>
                    </div>
                    <div className="right border-box flex_vertical start_horizontal start_vertical">
                        <div className="inner full_width border-box flex_vertical start_horizontal start_vertical bgcolorSix50 shadow-box">
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="fleet_name" title="Fleet Name" value={fields.fleet_name} size="small" mandatory={true}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.fleet_name} />  
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical" style={{paddingBottom : "18px"}}>
                                    <FieldBox objectKey="company_logo" title="upload company logo" onSetFieldState={handleFieldState} 
                                        type="file" size="small"/>
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical" style={{paddingBottom : "18px"}}>
                                    <FieldBox objectKey="fleet_image" title="upload fleet image" onSetFieldState={handleFieldState} 
                                        type="file" size="small"/>
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="category" title="Category" value={fields.category}  mandatory={true}
                                        selectedValue={fields.category} onSetFieldState={handleFieldState} 
                                        type="select" size="small" errorDisplay="yes" errorText={error.category}
                                        choices={[...[{value:"", title:"Select a category"}], ...utils.getFleetCategories().map(o => ({value:o, title:o}))]
                                                .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
                                </div>
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="email" title="Superadmin Email" value={fields.email} size="small" mandatory={true}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.email} />  
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="password" title="Superadmin Password" value={fields.password} size="small" mandatory={state ? false : true}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.password} />  
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="phoneNumber" title="Phone Number" value={fields.phoneNumber} size="small" mandatory={false}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.phoneNumber} />  
                                </div>
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="address1" title="Head Office Address Line 1" value={fields.address1} size="small" mandatory={false}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.address1} />  
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="address2" title="Address Line 2" value={fields.address2} size="small" mandatory={false}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.address2} />  
                                </div>
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="stateName" title="State" value={fields.stateName}  mandatory={false}
                                        selectedValue={fields.stateName} onSetFieldState={handleFieldState} 
                                        type="select" size="small" errorDisplay="yes" errorText={error.stateName}
                                        choices={[...[{value:"", title:"Select a state"}], ...utils.getStatesIndia().map(o => ({value:o, title:o}))]
                                                .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="city" title="City" value={fields.city}  mandatory={false}
                                        selectedValue={fields.city} onSetFieldState={handleFieldState} 
                                        type="select" size="small" errorDisplay="yes" errorText={error.city}
                                        choices={[...[{value:"", title:"Select a city"}], ...citiesList.filter((item)=> item.state.toLowerCase() == fields.stateName.toLowerCase()).map(o => ({value:o.name, title:o.name}))]
                                                .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="pincode" title="Pin Code" value={fields.pincode} size="small" mandatory={false}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.pincode} />  
                                </div>
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <span className="error colorThree500span" style={{fontSize : "13px"}}>{errorGlobal}</span>
                                <div className="spacer"></div>
                                <div className="fieldbuttonbox border-box">
                                    <button className="bttn small solid one" onClick={(e) => handleButtonClick("save")}>Save</button>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </div>
        }
        </>
    );


    function fetchDataCitiesList(stateName){
        if(stateName && stateName.length > 0){
            // setIsLoading(true);
            api.getCities(stateName).then(function (response) {console.log("responsedata", response.data.data); setCitiesList(response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
        }else{
            setCitiesList([]);
        }
    }
}
