import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import utils from '../../utils';
import constants from '../../constants';
import { Row } from '../Row';
import ChoiceBoxMulti from '../../base/utils/choiceboxmulti';

export const UserDetailsServiceHistory = (props) => {
    const navigate = useNavigate();
    let orderData = props.extraData && props.extraData.length != 0 ? props.extraData[props.extraData.length - 1] : null;
    let vehicleData = orderData ? orderData.vehicle : null;
    let status = orderData ? orderData.status : "pending";

    const [selectedServiceTypeFilter, setSelectedServiceTypeFilter] = useState(status);
    const [order, setOrder] = useState(orderData);
    const [vehicle, setVehicle] = useState(vehicleData);

    console.log("order", order, props.extraData);
    
    function handleChoiceBoxMultiClick(type, id, text){
        if (type == "service_history") {
            let orderList = props.extraData.filter((item) => item.status == id);
            orderData = orderList && orderList.length != 0 ? orderList[orderList.length - 1] : null;
            vehicleData = orderData ? orderData.vehicle : null;
            setSelectedServiceTypeFilter(id);
            setOrder(orderData);
            setVehicle(vehicleData);
            console.log("orderData", orderData);
        }
    }

    return(
        <>
        
            <div className="servicehistory full_width full_height border-box">
                <div className="header border-box">
                    <ChoiceBoxMulti type="service_history" 
                        objects={[{title : "Ongoing", id : "ongoing"}, {title : "Past", id : "completed"}, 
                                    {title : "Pending", id : "pending"}]} 
                        selected={selectedServiceTypeFilter}
                        onClick={handleChoiceBoxMultiClick} />
                </div>
                {order ? 
                <>
                <div style={{height : "20px"}}/>
                {order.status == "completed" ? 
                <div className="details details3 border-box full_width flex_horizontal center_horizontal center_vertical shadow-box" >
                    <div className="inner border-box flex_horizontal center_horizontal center_vertical spacer">
                        <img src="/images/wallet.png" loading="lazy" height="40" />
                        <div className="text border-box flex_vertical start_horizontal center_vertical spacer">
                            <div className="title weight600">Payment</div>
                            <div className="subtitle colorGray400span">{order.payment_mode.toUpperCase()} {order.payment_details ? "₹"+order.payment_details.amount_paid+"/-" : ""}</div>
                        </div>
                    </div>
                    <div style={{width : "20px"}}/>
                    <div className="inner border-box flex_horizontal center_horizontal center_vertical spacer">
                        <img src="/images/service_buddy.png" loading="lazy" height="40" />
                        <div className="text border-box flex_vertical start_horizontal center_vertical spacer">
                            <div className="title weight600">Service Buddy</div>
                            <div className="subtitle colorGray400span">{order.service_buddy.name}</div>
                        </div>
                    </div>
                    <div style={{width : "20px"}}/>
                    <div className="inner border-box flex_horizontal center_horizontal center_vertical spacer">
                        <img src="/images/rating_star.png" loading="lazy" height="40" />
                        <div className="text border-box flex_vertical start_horizontal center_vertical spacer">
                            <div className="title weight600">Rating</div>
                            <div className="subtitle colorGray400span">{order.service_buddy.rating} stars</div>
                        </div>
                    </div>
                </div>
                :
                <div className="details details1 border-box full_width flex_vertical start_horizontal center_vertical shadow-box" >
                    <div className="supertitle weight600 colorGray400span">Service Buddy</div>
                    <div className="title">{order.service_buddy.name}</div>
                    <div className="subtitle border-box flex_horizontal start_horizontal center_vertical">
                        <i className="ti ti-map-pin colorGray400span"></i>
                        <span className="colorGray500span">Arrived</span>
                        <i className="ti ti-clock colorGray400span"></i>
                        <span className="colorOne500span">Servicing in progress</span>
                    </div>
                    <div style={{height : "20px"}}/>
                    <div className="vehicle full_width border-box flex_vertical start_horizontal center_vertical">
                        <div className="supertitle weight600 colorOne500span">Vehicle</div>
                        <div className="title">{vehicle.brand_name} {vehicle.model_name}</div>
                        <div className="subtitle border-box flex_horizontal start_horizontal center_vertical">
                            <i className="ti ti-brand-speedtest colorGray400span"></i>
                            <span className="colorGray500span">{props.extraData.odometer_reading}</span>
                            <i className="ti ti-license colorGray400span"></i>
                            <span className="colorGray500span">NA</span>
                            <i className="ti ti-scooter-electric colorGray400span"></i>
                            <span className="colorGray500span">NA</span>
                            <i className="ti ti-map-pin colorGray400span"></i>
                            <span className="colorGray500span">NA</span>
                        </div>
                    </div>
                </div>
                }
                <div style={{height : "20px"}}/>
                <div className="details details2 border-box full_width flex_horizontal center_horizontal center_vertical shadow-box" >
                    <div className="left border-box flex_vertical start_horizontal start_vertical">
                        <div className="info border-box flex_horizontal start_horizontal center_vertical">
                            <i className="ti ti-map-pin"></i>
                            <div className="border-box flex_vertical start_horizontal center_vertical">
                                <div className="title">Location {(order.status == "completed")+""}</div>
                                <div className="subtitle colorGray400span">NA</div>
                            </div>
                        </div>
                        <div className="info border-box flex_horizontal start_horizontal center_vertical">
                            <i className="ti ti-calendar"></i>
                            <div className="border-box flex_vertical start_horizontal center_vertical">
                                <div className="title">Date</div>
                                <div className="subtitle colorGray400span">{order.date}</div>
                            </div>
                        </div>
                        <div className="info border-box flex_horizontal start_horizontal center_vertical">
                            <i className="ti ti-clock"></i>
                            <div className="border-box flex_vertical start_horizontal center_vertical">
                                <div className="title">Time</div>
                                <div className="subtitle colorGray400span">{order.time} {order.ampm.toUpperCase()}</div>
                            </div>
                        </div>
                    </div>
                    <div className="right border-box flex_vertical start_horizontal center_vertical bgcolorGray200">
                        <div className="image full_width spacer">
                            <img src="/images/scooter_dummy.png" loading="lazy" />
                        </div>
                        <div className="text full_width">
                            <div className="title weight600">{vehicle.brand_name} {vehicle.model_name}</div>
                            <div className="subtitle bgcolorGray400span">{order.invoice_details.service_name}</div>
                            <a className="colorFour500span weight600" href="/dsd">Download Invoice</a>
                        </div>
                    </div>
                </div>
                <div style={{height : "20px"}}/>
                <p className="comments border-box full_width flex_horizontal start_horizontal start_vertical colorGray600span">
                    Comments by Service Buddy<br></br>
                    {order.comments}
                </p>
                </> : <></>}

            </div>
        </>
    );
}
