import { Navigate, Route, Routes, BrowserRouter } from "react-router-dom";
import AppAccount from "../../account/AppAccount";
import AppDashboard from "../../dashboard/AppDashboard";
import FourOFour from "./404";
import { Dashboard } from "../../dashboard/dashboard";
import utils from "../../utils";
import constants from "../../constants";

export const Routing = () => {
    console.log("isLoggedIn", utils.isLoggedIn())
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={ utils.isLoggedIn() ? <Navigate to="/dashboard/home"/> : <Navigate to="/login"/> } />
                <Route path="/login" element={ <AppAccount type="login"/> } />
                {/* <Route path="/signup" element={ <AppAccount type="signup"/> } /> */}
                <Route path="/account/password/reset" element={ <AppAccount type="password_reset"/> } />
                <Route path="/account/password/set/:token" element={ <AppAccount type="password_set"/> } />
                <Route path="/account/logout" element={ <AppAccount type="logout"/> } />
                <Route path="/dashboard/" element={<AppDashboard />}>
                    {/* <Route path="" element={<Navigate to={constants.getSidebarSubsectionFirst(constants.getRole()).key} />} /> */}
                    <Route path="" element={<Navigate to="home" />} />
                    <Route path="home" element={<Dashboard section="home" subsection="dashboard" />} />
                    <Route path="customers" element={<Dashboard section="pages" subsection="customers" />} />
                    <Route path="models" element={<Dashboard section="pages" subsection="models" />} />
                    <Route path="services" element={<Dashboard section="pages" subsection="services" />} />
                    <Route path="services_b2b" element={<Dashboard section="pages" subsection="services_b2b" />} />
                    <Route path="finance" element={<Dashboard section="pages" subsection="finance" />} />
                    <Route path="tickets" element={<Dashboard section="pages" subsection="tickets" />} />
                    <Route path="users" element={<Dashboard section="pages" subsection="users" />} />
                    <Route path="managers" element={<Dashboard section="pages" subsection="managers" />} />
                    <Route path="oems" element={<Dashboard section="pages" subsection="oems" />} />
                    <Route path="fleets" element={<Dashboard section="pages" subsection="fleets" />} />
                    <Route path="warehouses" element={<Dashboard section="pages" subsection="warehouses" />} />
                    <Route path="vehiclesb2b" element={<Dashboard section="pages" subsection="vehiclesb2b" />} />
                    {/* <Route path="customers/:currentTab/:id" element={<Dashboard section="pages" subsection="customers" dashboardType="detail" />} /> */}
                    <Route path="action/customers/customers/edit/:id" element={<Dashboard section="pages" subsection="users" dashboardType="action" actionObject="customer" actionType="edit" />} />
                    <Route path="action/excel/upload/customers" element={<Dashboard section="pages" subsection="users" dashboardType="action" actionObject="excel" actionType="customers" />} />
                    <Route path="action/excel/upload/users" element={<Dashboard section="pages" subsection="users" dashboardType="action" actionObject="excel" actionType="users" />} />
                    <Route path="action/excel/upload/vehicles" element={<Dashboard section="pages" subsection="vehicles" dashboardType="action" actionObject="excel" actionType="vehicles" />} />
                    <Route path="action/excel/upload/warehouses" element={<Dashboard section="pages" subsection="warehouses" dashboardType="action" actionObject="excel" actionType="warehouses" />} />
                    <Route path="customers/:currentTab/:id" element={<Dashboard section="pages" subsection="customers" dashboardType="detail" />} />
                    <Route path="services/:currentTab/:id" element={<Dashboard section="pages" subsection="services" dashboardType="detail" />} />
                    <Route path="services_b2b/:currentTab/:id" element={<Dashboard section="pages" subsection="services_b2b" dashboardType="detail" />} />
                    {/* <Route path="customers/services/:id" element={<Dashboard section="pages" subsection="services" dashboardType="detail" />} /> */}
                    <Route path="finance/:currentTab/:id" element={<Dashboard section="pages" subsection="finance" dashboardType="detail" />} />
                    
                    <Route path="action/vehiclesb2b/vehiclesb2b/add" element={<Dashboard section="pages" subsection="vehicles" dashboardType="action" actionObject="vehicleb2b" actionType="add" />} />
                    <Route path="action/customers/customers/add" element={<Dashboard section="pages" subsection="customers" dashboardType="action" actionObject="customer" actionType="add" />} />
                    <Route path="action/customers/vehicles/onboard" element={<Dashboard section="pages" subsection="customers" dashboardType="action" actionObject="vehicle" actionType="add" />} />
                    <Route path="action/warehouses/warehouses/add" element={<Dashboard section="pages" subsection="warehouses" dashboardType="action" actionObject="warehouse" actionType="add" />} />
                    <Route path="action/warehouses/warehouses/edit/:id" element={<Dashboard section="pages" subsection="warehouses" dashboardType="action" actionObject="warehouse" actionType="edit" />} />
                    <Route path="action/fleets/fleets/add" element={<Dashboard section="pages" subsection="fleets" dashboardType="action" actionObject="fleet" actionType="add" />} />
                    <Route path="action/fleets/fleets/edit/:id" element={<Dashboard section="pages" subsection="fleets" dashboardType="action" actionObject="fleet" actionType="edit" />} />
                    <Route path="action/oems/oems/add" element={<Dashboard section="pages" subsection="oems" dashboardType="action" actionObject="oem" actionType="add" />} />
                    <Route path="action/oems/oems/edit/:id" element={<Dashboard section="pages" subsection="oems" dashboardType="action" actionObject="oem" actionType="edit" />} />
                    <Route path="action/users/users/add" element={<Dashboard section="pages" subsection="users" dashboardType="action" actionObject="sb" actionType="add" />} />
                    <Route path="action/users/users/edit/:id" element={<Dashboard section="pages" subsection="users" dashboardType="action" actionObject="sb" actionType="edit" />} />
                    <Route path="action/users/managers/add" element={<Dashboard section="pages" subsection="users" dashboardType="action" actionObject="manager" actionType="add" />} />
                    <Route path="action/users/managers/edit/:id" element={<Dashboard section="pages" subsection="users" dashboardType="action" actionObject="manager" actionType="edit" />} />
                    <Route path="users/:currentTab/:id" element={<Dashboard section="pages" subsection="users" dashboardType="detail" />} />
                    <Route path="managers/:currentTab/:id" element={<Dashboard section="pages" subsection="managers" dashboardType="detail" />} />
                    <Route path="fleets/:currentTab/:id" element={<Dashboard section="pages" subsection="fleets" dashboardType="detail" />} />
                    <Route path="oems/:currentTab/:id" element={<Dashboard section="pages" subsection="oems" dashboardType="detail" />} />
                    <Route path="warehouses/:currentTab/:id" element={<Dashboard section="pages" subsection="warehouses" dashboardType="detail" />} />
                    <Route path="vehicles/:currentTab/:id" element={<Dashboard section="pages" subsection="vehicles" dashboardType="detail" />} />
                </Route>
                {/* The next line is very important for the Navigate component to work */}
                <Route path="/error-page" element={ <AppAccount type="login"/> } />
                <Route path="/redirect" element={ <Navigate to="/error-page" /> } />
                <Route path="/not-found" element={ <FourOFour /> } />
                <Route path="*" element={ <Navigate to="/not-found" /> } />
            </Routes>
        </BrowserRouter>
    );
}