import { useNavigate } from "react-router-dom";
import constants from "../constants";
import { RowItem } from "./RowItem";

export const Row = (props) => {
    
    function handleClick() {
        props.handleRowClick(props.data);
    }
    console.log("rowD", constants.getRowDataByTag(props.currentTab.key));

    // console.log("items", items);

    const getClass = () => {
        let className = "row border-box flex_horizontal center_vertical start_horizontal cursor-pointer weight300";
        if(props.selectedObjs && props.selectedObjs.length > 0){
            let selected = props.selectedObjs.find((obj) => obj == props.data.id);
            if(selected){
                className += " active";
            }
        }
        console.log("selectedObjsClass", props.selectedObjs, props.data.id, className, props.isSelected);
        return className;
    }

    return(
        <div className={getClass()} onClick={handleClick}>
            {constants.getRowDataByTag(props.currentTab.key).map((item) => 
        <RowItem {...props} key={item.key} data={item} data1={props.data} />
    )}
        </div>
    );
  }