import { useState } from 'react';
import Utils from '../utils';
import FieldBox from '../base/utils/fieldbox';
import Linkbutton from '../base/utils/linkbutton';
import { redirect } from 'react-router-dom';
import api from '../api';
import { useNavigate } from "react-router-dom";
import utils from '../utils';
import { Loader } from '../base/utils/loader';

export default function AppLogin(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState({});
    const [remember, setRemember] = useState(true);
    const [pageStatus, setPageStatus] = useState("initial");
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    function handleFieldState(key, value) {
      switch (key) {
          case "email":
              setEmail(value.trim().toLowerCase());
              break;
          case "password":
              setPassword(value.trim());
              break;
          default:
              break;
      }
      validateField(key, value.trim());
  }

  function validateField(key, value){
      switch (key) {
          case "email":
              if(!Utils.validateEmail(value)) {
                  error["email"] = "Please enter a valid email address";
                  setError(error);
                  return false;
              }else{
                  delete error["email"];
                  setError(error);
                  return true;
              }
              break;
          case "password":
              if(!value || value.length < 8) {
                error["password"] = "Please enter a password of minimum 8 characters";
                setError(error);
                return false;
              }else{
                  delete error["password"];
                  setError(error);
                  return true;
              }
              break;
          default:
              break;
      }
  }

    function handleButtonClick(buttonType) {
        setIsLoading(true);
      if(validateField("email", email) && validateField("password", password)){
          api.loginWithEmailPassword(email, password).then(function (response) {
            if(response.data.status){
                // localStorage.setItem("user_id", response.data.data.id);
                // localStorage.setItem("custom_token", response.data.data.custom_token);
                // localStorage.setItem("access_token", response.data.data.access_token.token);
                // localStorage.setItem("token_expiration_date", response.data.data.access_token.expiration_date);
                // localStorage.setItem("logged_in_at", new Date());
                // localStorage.setItem("email", email);
                // setPageStatus("redirect");
                api.getProfileRoles(response.data.data.access_token.token).then(function (response1) {
                  if(response1.data.status){
                    localStorage.setItem("user_id", response.data.data.id);
                    localStorage.setItem("custom_token", response.data.data.custom_token);
                    localStorage.setItem("access_token", response.data.data.access_token.token);
                    localStorage.setItem("token_expiration_date", response.data.data.access_token.expiration_date);
                    localStorage.setItem("logged_in_at", new Date());
                    localStorage.setItem("email", email);
                    
                    localStorage.setItem("name", response1.data.data.name);
                    // localStorage.setItem("phone", sessionStorage.getItem("phone"));
                    localStorage.setItem("first_name", response1.data.data.first_name);
                    localStorage.setItem("last_name", response1.data.data.last_name);
                    localStorage.setItem("phone_number", response1.data.data.phone_number);
                    localStorage.setItem("status", response1.data.data.status);
                    //   localStorage.setItem(utils.setSavedList("roles"), JSON.stringify(response1.data.data.user_role.roles));
                    utils.setSavedList("roles", response1.data.data.user_role.roles);
                    if(response1.data.data.user_role.fleet_id){
                        localStorage.setItem("fleet_id", response1.data.data.user_role.fleet_id);
                    }
                    if(response1.data.data.user_role.oem_id){
                        localStorage.setItem("oem_id", response1.data.data.user_role.oem_id);
                    }
                    if(response1.data.data.user_role.roles.includes("state_head")){
                        localStorage.setItem("stateName", response1.data.data.address.state);
                    }
                    if(response1.data.data.user_role.roles.includes("city_head")){
                        localStorage.setItem("cityName", response1.data.data.address.city);
                    }
                      // setPageStatus("redirect");
                      navigate("/dashboard/home");
                        
                  }else{
                      error["email"] = response.data.message;
                      setError(error);
                      setPageStatus("initial");
                  }
                  })
                  .catch(function (error) {
                      error["email"] = error.response.data.message;
                      error["password"] = error.response.data.message;
                      setError(error);
                      setPageStatus("initial");
                  })
                  .finally(function () {
                    setIsLoading(false);
                  });
                  
            }else{
                error["email"] = response.data.message;
                setError(error);
                setPageStatus("initial");
            }
            })
            .catch(function (error) {
                if(error.response.data.message == "No user"){
                    error["email"] = "Incorrect Email ID";
                    delete error["password"];    
                }
                if(error.response.data.message == "Password mismatch!"){
                    error["password"] = "Incorrect Password";
                    delete error["email"];    
                }
                setError(error);
                setPageStatus("initial");
            })
            .finally(function () {
                setIsLoading(false);
            });
      }else{
        setIsLoading(false);
      }
    }

    return (
      <>
      {isLoading ?
        <div className="loader spacer border-box flex_horizontal center_vertical center_horizontal" style={{width : "100%", height : "100%"}}><Loader /></div>
        :
              <div className="accountbaseinner border-box login">
                  <div className="accountbaseinnerleft border-box">
                      <div className="inner border-box">
                          <h1>Sign In</h1>
                          <p>Sign in to stay connected</p>
                          <div className="fieldouter border-box flex_vertical center_horizontal center_vertical">
                              <FieldBox objectKey="email" title="email" value={email} size="small"
                                  onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.email} />  
                          </div>
                          <div className="fieldouter border-box flex_vertical center_horizontal center_vertical">
                              <FieldBox objectKey="password" title="password" value={password} size="small"
                                  onSetFieldState={handleFieldState} type="password" errorDisplay="yes" errorText={error.password} showPassword={true}/>
                          </div>
                          <div className="fieldouter border-box flex_horizontal end_horizontal center_vertical">
                              <a href="/account/password/reset" className="colorOne500span" style={{fontSize : "12px"}}>Forgot password</a>
                          </div>
                          <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                              <button className="bttn large solid one" onClick={(e) => handleButtonClick("login")}>Sign in</button>
                          </div>
                      </div>
                      
                  </div>
                  <div className="accountbaseinnerright border-box">
                  </div>
              </div>
        }
      </>
    );
}
