import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import utils from '../utils';
import constants from '../constants';
import { RowHeaderItem } from './RowHeaderItem';

export const AttendanceHeader = (props) => {
    const navigate = useNavigate(); 


    let days = {};
    console.log("daysinmonthHEader", props.paramsFilter);
    if(props.paramsFilter.month && props.paramsFilter.year){
      days = constants.getAllDaysInMonth(parseInt(props.paramsFilter.month)-1, parseInt(props.paramsFilter.year));
    }else{
      let currentDate = new Date();
      days = constants.getAllDaysInMonth(currentDate.getMonth(), currentDate.getFullYear());
    }
    
    console.log("days", days, typeof(days));

      return(
        <div className="attendanceheader border-box flex_horizontal center_vertical start_horizontal">
            <div className="attendanceheaderinner border-box flex_horizontal center_vertical start_horizontal">
                <div className="column1 border-box flex_horizontal center_vertical start_horizontal weight600">
                  Name
                </div>
                {Object.keys(days).map((index, key) => 
                  <div className="column2 border-box flex_horizontal center_vertical center_horizontal weight600">
                        {constants.getShortestDayNameByDayOfWeek(days[key], days[key].getDay())}/{days[key].getDate()}
                  </div>)
                }
                
            </div>
        </div>
      );
}
