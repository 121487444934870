import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import utils from '../../utils';
import constants from '../../constants';
import { Row } from '../Row';

export const FleetDetailsAbout = (props) => {
    const navigate = useNavigate();

    let fleet = props.data;

    console.log("warehouse", props.extraData);
    
    return(
        <>
        {/* {fleet ? 
            <div className="vehicle full_width border-box flex_vertical start_horizontal center_vertical">
                <div className="supertitle weight600 colorOne500span">Vehicle</div>
                <div className="title">{vehicle.brand_name} {vehicle.model_name}</div>
                <div className="subtitle border-box flex_horizontal start_horizontal center_vertical">
                    <i className="ti ti-brand-speedtest colorGray400span"></i>
                    <span className="colorGray500span">{props.extraData.odometer_reading}</span>
                    <i className="ti ti-license colorGray400span"></i>
                    <span className="colorGray500span">NA</span>
                    <i className="ti ti-scooter-electric colorGray400span"></i>
                    <span className="colorGray500span">NA</span>
                    <i className="ti ti-map-pin colorGray400span"></i>
                    <span className="colorGray500span">NA</span>
                </div>
            </div> : <></>} */}
            {/* <div style={{height : "30px"}}/> */}
            <div className="contact full_width border-box flex_vertical start_horizontal center_vertical">
                <div className="supertitle weight600 colorGray500span">Warehouse Information</div>
                {props.extraData ? props.extraData.map((warehouse, index) => <>
                    <div className="text full_width border-box flex_horizontal start_horizontal center_vertical">
                        <div className="left border-box colorGray500span weight600">
                            {warehouse.warehouse_name}
                        </div>
                        <div className="right border-box">
                            {warehouse.location}
                        </div>
                    </div>    
                </>) : <></>}
            </div>
            <div style={{width : "100%", height : "1px", background : "var(--colorGray200)", marginTop : "20px"}} />
            <div style={{height : "30px"}}/>
            <div className="contact full_width border-box flex_vertical start_horizontal center_vertical">
                <div className="supertitle weight600 colorGray500span">Contact Information</div>
                <div className="text full_width border-box flex_horizontal start_horizontal center_vertical">
                    <div className="left border-box colorGray500span">
                        Phone
                    </div>
                    <div className="right border-box">
                        {props.data.user_info.phone_number}
                    </div>
                </div>
                <div className="text full_width border-box flex_horizontal start_horizontal center_vertical">
                    <div className="left border-box colorGray500span">
                        Email
                    </div>
                    <div className="right border-box">
                        {props.data.email}
                    </div>
                </div>
                <div className="text full_width border-box flex_horizontal start_horizontal center_vertical">
                    <div className="left border-box colorGray500span">
                        Location
                    </div>
                    <div className="right border-box">
                        {props.data.location ? props.data.location : "NA"}
                    </div>
                </div>
            </div>
        </>
    );
}
