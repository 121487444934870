import Linkbutton from "../base/utils/linkbutton";
import utils from "../utils";

export default function Topbarauth() {

    const name = localStorage.getItem("name");
    const roles = utils.getSavedList("roles");
    const role = roles ? roles.join().toUpperCase() : "";

  return (
    <div className="topbarinner border-box auth">
        <div className="topbarinnerleft full_height border-box flex_horizontal start_horizontal center_vertical">
            <img src="/images/logo_small.png" loading="lazy" height="20" />
        </div>
        <div className="topbarinnerright full_height border-box flex_horizontal start_horizontal center_vertical">
            <div className="text border-box full_height flex_vertical end_horizontal center_vertical">
                <span className="weight600" style={{fontSize : "14px"}}>{name}</span>
                <span className="colorGray500span" style={{fontSize : "11px"}}>{role}</span>
            </div>
            {/* <Linkbutton text="Log in" href="/" newTab="no" type="large1" button_type="transparent" />
            <Linkbutton text="Sign up" href="/signup" newTab="no" type="large1" button_type="solid" /> */}
        </div>
    </div>
  );
}