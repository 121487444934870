import Linkbutton from "../base/utils/linkbutton";

export default function Topbarunauth() {
  return (
    <div className="topbarinner border-box unauth">
        <div className="topbarinnerleft border-box">
        </div>
        <div className="topbarinnerright border-box">
            <Linkbutton text="Log in" href="/" newTab="no" type="large1" button_type="transparent" />
            <Linkbutton text="Sign up" href="/signup" newTab="no" type="large1" button_type="solid" />
        </div>
    </div>
  );
}