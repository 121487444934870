import { useEffect, useState } from 'react';
import utils from '../../utils';
import { redirect, useLocation, useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import "./action.css";
import FieldBox from '../../base/utils/fieldbox';
import Utils from '../../utils';
import api from '../../api';
import { Loader } from '../../base/utils/loader';
import constants from '../../constants';

export const EditSB = (props) => {
    const [pageStatus, setPageStatus] = useState("initial");
    const navigate = useNavigate();
    // const [currentTab, setCurrentTab] = useState(props.subsection.viewTabs ? props.subsection.viewTabs[0] : {});
    const [isLoading, setIsLoading] = useState(false);
    const [oems, setOems] = useState([]);
    // const [fields, setFields] = useState({gender : "male"});
    const [error, setError] = useState({});
    const[errorGlobal, setErrorGlobal] = useState("");

    const [citiesList, setCitiesList] = useState([]);

    let { currentTab, id } = useParams();
    const {state} = useLocation();
    const [fields, setFields] = useState(state ? 
        {
            firstName : state.data.sb_users.first_name,
            lastName : state.data.sb_users.last_name,
            email : state.data.sb_users.email,
            // password : "",
            phoneNumber : state.data.sb_users.phone_number,
            dob : state.data.sb_users.dob,
            gender : state.data.sb_users.gender,
            address1 : state.data.sb_users.address.address_line1,
            address2 : state.data.sb_users.address.address_line2,
            city : state.data.sb_users.address.city,
            stateName : state.data.sb_users.address.state.toUpperCase(),
            // pincode : state.data.sb_users.address.postal_code,
            aadhar_file1 : "",
            aadhar_file2 : "",
            license : "",
            photo : "",
            company : state.data.sb_users.employer.company_name,
            // location : state.data.sb_users.employer.location,
            role : state.data.roles && Array.isArray(state.data.roles) ? state.data.roles[0] : "",
            doj : state.data.sb_users.joining_date,
            employeeID : state.data.sb_users.employer.employee_id,
            status : state.data.sb_users.status,
            oem_id : state.data.oem_id,
            user_id : state.data.user_id,
        }
        : 
        {
            firstName : "", 
            lastName : "",
            email : "",
            // password : "",
            phoneNumber : "",
            dob : "",
            gender : "male",
            address1 : "",
            address2 : "",
            city : "",
            stateName : "",
            // pincode : "",
            aadhar_file1 : "",
            aadhar_file2 : "",
            license : "",
            photo : "",
            company : "",
            // location : "",
            role : "service_buddy",
            doj : "",
            employeeID : "",
            status : "active",
            oem_id : "",
            user_id : "",
        });
    // const [data, setData] = useState(state.data);

    const errors = {
        firstName : "Please enter a first name", 
        lastName : "Please enter a last name",
        // password : "Please enter a password of minimum 8 characters",
        email : "Please enter a valid email address",
        phoneNumber : "Please enter a valid phone number",
        dob : "Please enter a date of birth",
        gender : "Please enter a gender",
        address1 : "Please enter address",
        address2 : "Please enter address",
        city : "Please enter a city",
        stateName : "Please enter a state",
        pincode : "Please enter a valid pincode",
        aadhar_file1 : "Please upload aadhar front image",
        aadhar_file2 : "Please upload aadhar back image",
        license : "Please upload license image",
        photo : "Please upload profile photo",
        company : "Please enter company name",
        location : "Please enter location",
        role : "Please enter role",
        doj : "Please enter date of joining",
        employeeID : "Please enter employee ID",
        status : "Please enter status",
        oem_id : "Please enter OEM",
    }

    let isChecked = false;

    useEffect(() => {
        if (isChecked) return;
        isChecked = true;
        
        fetchData();

        if(fields.stateName && fields.stateName.length > 0){
            fetchDataCitiesList(fields.stateName);
        }
        
      }, []);

    // input_data = {
    //     "updated_at": now,
    //     "joining_date": datetime.datetime.strptime(str(request.data.get('joining_date')), "%d/%m/%Y"),
    //     "gender": str(request.data.get('gender')),
    //     "dob": str(request.data.get('dob')),
    //     "address": {
    //         "country": str(request.data.get('country')),
    //         "state": str(request.data.get('state')),
    //         "address_line1": str(request.data.get('address_line1')),
    //         "address_line2": str(request.data.get('address_line2')),
    //         "city": str(request.data.get('city')),
    //         "postal_code": str(request.data.get('postal_code'))
    //     },
    //     "location": str(request.data.get('city')),
    //     "city_id": int(request.data.get('city_id')),
    //     "employer": {
    //         "company_name": str(request.data.get('company_name')),
    //         "location": str(request.data.get('company_location')),
    //         "employee_id": str(request.data.get('employee_id'))
    //     },
    //     "service_center_id": int(request.data.get('service_center_id'))
    // }

    // console.log("idEditSB", id, currentTab, data, props.actionType);

    
    function handleFieldState(key, value) {
        switch (key) {
            case "test":
            case "aadhar_file1":
            case "aadhar_file2":
            case "license":
            case "photo":
                break;
            // case "role":
            //     switch (value) {
            //         case "State Head":
            //             setFields({...fields, [key] : value, city : "New Delhi"});
            //             validateField(key, value);
            //             break;
            //         default:
            //             setFields({...fields, [key] : value});
            //             validateField(key, value);
            //             break;
            //     }
                // break;
            case "city":
                if(fields.role !== "State Head"){
                    setFields({...fields, [key] : value});
                    validateField(key, value);
                }
                break;
            case "stateName":
                setFields({...fields, [key] : value, city : ""});
                validateField(key, value);
                fetchDataCitiesList(value);
                break;
            case "email":
                setFields({...fields, [key] : value.trim().toLowerCase()});
                validateField(key, value.trim().toLowerCase());
                break;
            default:
                setFields({...fields, [key] : value});
                validateField(key, value);
                break;
        }
    }

    function validateField(key, value){
        switch (key) {
            case "email":
                if(!value || value.length == 0) {
                    delete error[key];setError(error);return true;
                }else{
                    if(!Utils.validateEmail(value)) {
                        console.log("erroring1", key);
                        error[key] = errors[key];setError(error);return false;
                    }else{
                        delete error[key];setError(error);return true;
                    }
                }
                break;
            case "pincode":
                if(!value || value.length == 0) {
                    delete error[key];setError(error);return true;
                }else{
                    if(!Utils.validatePinCode(value)) {
                        console.log("erroring2", key);
                        error[key] = errors[key];setError(error);return false;
                    }else{
                        delete error[key];setError(error);return true;
                    }
                }
                break;
            case "phoneNumber":
                if(!Utils.validatePhoneNumber(value)) {
                    console.log("erroring3", key);
                    error[key] = errors[key];setError(error);return false;
                }else{
                    delete error[key];setError(error);return true;
                }
                break;
            // case "password":
            //     if(props.actionType == "add"){
            //         if(!value || value.length < 8) {
            //             console.log("erroring", key);
            //             error[key] = errors[key];setError(error);return false;
            //         }else{
            //             delete error[key];setError(error);return true;
            //         }
            //         break;
            //     }else{
            //         delete error[key];setError(error);return true;
            //         break;
            //     }
            case "aadhar_file1":
            case "aadhar_file2":
            case "license":
            case "photo":
            case "doj":
            case "employeeID":
            case "user_id":
                return true;
                break;
            case "city":
                if(fields.role === "State Head"){
                    delete error[key];setError(error);return true;
                }else{
                    if(!value || value.length == 0) {
                        console.log("erroring", key);
                        error[key] = errors[key];setError(error);return false;
                    }else{
                        delete error[key];setError(error);return true;
                    }
                }
                break;
            case "oem_id":
                if(constants.getRole() == "superadmin"){
                    if(!value || value.length == 0) {
                        console.log("erroring", key);
                        error[key] = errors[key];setError(error);return false;
                    }else{
                        delete error[key];setError(error);return true;
                    }    
                }else{
                    return true;
                }
                break;
            case "firstName":
            case "stateName":
            // case "role":
            //     if(!value || value.length == 0) {
            //         console.log("erroring", key);
            //         error[key] = errors[key];setError(error);return false;
            //     }else{
            //         delete error[key];setError(error);return true;
            //     }
            //     break;
            default:
                // if(!value || value.length == 0) {
                //     console.log("erroring", key);
                //     error[key] = errors[key];setError(error);return false;
                // }else{
                    delete error[key];setError(error);return true;
                // }
                break;
        }
    }

    function validateAllFields(){
        let isValid = true;
        
        Object.keys(fields).forEach((key, index) => {
            console.log("validate", key);
            if(!validateField(key, fields[key])){
                console.log("invalid", key, fields[key]);
                isValid = false;
            }
        });
        return isValid;
    }


    function handleButtonClick(buttonType, data){
        if(buttonType == "save"){
            setErrorGlobal("");
            if (validateAllFields()){
                console.log("here1".replaceAll("1", "2"));
                let request_data = {
                    joining_date: fields.doj ? Utils.getCurrentDateInput(fields.doj,"type1") : "",
                    email : fields.email,
                    // password : fields.password,
                    first_name : fields.firstName,
                    last_name : fields.lastName,
                    name : fields.firstName + " " + fields.lastName,
                    phone_number : fields.phoneNumber,
                    status : fields.status,
                    role : "service_buddy",
                    nickname : "",
                    gender : fields.gender,
                    dob : fields.dob ? Utils.getCurrentDateInput(fields.dob,"type1") : "",
                    // address : {
                        country : "India",
                        state : fields.stateName,
                        address_line1 : fields.address1,
                        address_line2 : fields.address2,
                        city : fields.city,
                        postal_code : fields.pincode,
                    // },
                    location : fields.city,
                    city_id : 1,
                    // employer : {
                        company_name : fields.company,
                        location : fields.location,
                        employee_id : fields.employeeID,
                    // },
                    service_center_id : 1,
                    source : "vecmocon_superadmin_dashboard",
                    user_id : fields.user_id,
                }

                if(localStorage.getItem("oem_id")){
                    request_data["oem_id"] = localStorage.getItem("oem_id");
                }
                if (constants.getRole() == "superadmin"){
                    request_data["oem_id"] = fields.oem_id;
                }
                // var fieldData = Object.assign({}, that.state.fieldData);
                // delete fieldData.image_file;
                setIsLoading(true);
                let files = [fields.aadhar_file1, fields.aadhar_file2, fields.license, fields.photo];
                api.saveServiceBuddies(files, request_data).then(function (response) {console.log(response);setIsLoading(false);navigate("/dashboard/users")}).catch(function (error) {console.log(error);setIsLoading(false);setErrorGlobal(error.response.data.message);}).finally(function () {});
            }
        }
    }

    return (<>
        {isLoading ? 
            <div className="loader spacer border-box flex_horizontal center_vertical center_horizontal"><Loader /></div> : 
            
        
        
        <div className="action full_width full_height border-box flex_vertical center_horizontal start_vertical">
            <div className="one full_width border-box flex_horizontal center_horizontal center_vertical">
                <div className="left border-box flex_horizontal center_horizontal center_vertical">
                    <div className="border-box flex_horizontal center_vertical center_horizontal shadow-box">
                        {props.actionType == "add" ? "" : utils.getInitialsFromName(props.data.sb_users.name)}
                    </div>
                </div>
                <div className="right border-box flex_vertical start_horizontal end_vertical">
                    <div className="title weight600">{props.actionType == "add" ? "Add new user" : (props.data.sb_users.first_name + " " + props.data.sb_users.last_name)}</div>
                    <div style={{height : "10px"}}/>
                    <div className="subtitle weight300">{props.actionType == "add" ? "" : props.data.sb_users.email}</div>
                    <div style={{height : "30px"}}/>
                </div>
            </div>
            <div className="two full_width border-box flex_horizontal center_horizontal start_vertical bgcolorGray200">
                <div className="inner full_width border-box flex_horizontal center_horizontal start_vertical">
                    <div className="left border-box flex_vertical start_horizontal start_vertical">
                        <div className="title weight600">User details</div>
                        <div className="subtitle colorGray500span">Update user details</div>
                    </div>
                    <div className="right border-box flex_vertical start_horizontal start_vertical">
                        <div className="inner full_width border-box flex_vertical start_horizontal start_vertical bgcolorSix50 shadow-box">
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="firstName" title="First Name" value={fields.firstName} size="small" mandatory={true}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.firstName} />  
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="lastName" title="Last Name" value={fields.lastName} size="small" mandatory={false}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.lastName} />  
                                </div>
                                {constants.getRole() == "superadmin" ? 
                                <>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="oem_id" title="OEM" value={fields.oem_id}  mandatory={true}
                                        selectedValue={fields.oem_id} onSetFieldState={handleFieldState} 
                                        type="select" size="small" errorDisplay="yes" errorText={error.oem_id}
                                        choices={[...[{value:"", title:"Select an OEM"}], ...oems.map(o => ({value:o.id, title:o.name}))]
                                                .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
                                </div>
                                </> : <></>}
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="email" title="Email" value={fields.email} size="small" mandatory={false}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.email} />  
                                </div>
                                {/* <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="password" title="Password" value={fields.password} size="small" mandatory={state ? false : true}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.password} />  
                                </div> */}
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="phoneNumber" title="Phone Number" value={fields.phoneNumber} size="small" mandatory={true}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.phoneNumber} />  
                                </div>
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox size="small" objectKey="dob" title="DOB" value={fields.dob}  mandatory={false}
                                    onSetFieldState={handleFieldState} type="date" errorDisplay="yes" errorText={error.dob}/>
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="gender" title="Gender" selectedValue={fields.gender} mandatory={false}
                                        onSetFieldState={handleFieldState} type="choiceboxmulti"
                                        choiceType="gender"
                                        choices={[{id : "male", title : "Male"},{id : "female", title : "Female"}]}
                                        errorDisplay="yes" errorText={error.gender}/>
                                </div>
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="photo" title="upload profile photo" onSetFieldState={handleFieldState} 
                                        type="file" size="small"/>
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="aadhar_file1" title="upload aadhar front" onSetFieldState={handleFieldState} 
                                        type="file" size="small"/>
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="aadhar_file2" title="upload aadhar back" onSetFieldState={handleFieldState} 
                                        type="file" size="small"/>
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="license" title="upload license" onSetFieldState={handleFieldState} 
                                        type="file" size="small"/>
                                </div>
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="address1" title="Address Line 1" value={fields.address1} size="small" mandatory={false}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.address1} />  
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="address2" title="Address Line 2" value={fields.address2} size="small" mandatory={false}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.address2} />  
                                </div>
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="stateName" title="State" value={fields.stateName}  mandatory={true}
                                        selectedValue={fields.stateName} onSetFieldState={handleFieldState} 
                                        type="select" size="small" errorDisplay="yes" errorText={error.stateName}
                                        choices={[...[{value:"", title:"Select a state"}], ...utils.getStatesIndia().map(o => ({value:o, title:o}))]
                                                .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
                                    {/* <FieldBox objectKey="stateName" title="State" value={fields.stateName} size="small"
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.stateName} />   */}
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="city" title="City" value={fields.city}  mandatory={true}
                                        selectedValue={fields.city} onSetFieldState={handleFieldState} 
                                        type="select" size="small" errorDisplay="yes" errorText={error.city}
                                        choices={[...[{value:"", title:"Select a city"}], ...citiesList.filter((item)=> item.state.toLowerCase() == fields.stateName.toLowerCase()).map(o => ({value:o.name, title:o.name}))]
                                                .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
                                </div>
                                {/* <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="pincode" title="Pin Code" value={fields.pincode} size="small"
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.pincode} />  
                                </div> */}
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="company" title="Company Name" value={fields.company} size="small" mandatory={false}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.company} />  
                                </div>
                                {/* <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="location" title="Location" value={fields.location} size="small"
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.location} />  
                                </div> */}
                                {/* <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="role" title="Role" value={fields.role} selectedValue={fields.role}
                                        onSetFieldState={handleFieldState} type="select" size="small" mandatory={true}
                                        choices={[...[
                                            {value:"", title:""},
                                            {value:"service_buddy", title:"Service Buddy"},
                                            {value:"city_head", title:"City Head"}, 
                                            {value:"state_head", title:"State Head"}]]
                                                    .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}
                                                    errorDisplay="yes" errorText={error.role}/>
                                </div> */}
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox size="small" objectKey="doj" title="Date of Joining" value={fields.doj} 
                                    onSetFieldState={handleFieldState} type="date" errorDisplay="yes" errorText={error.doj}/>
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="employeeID" title="Employee ID" value={fields.employeeID} size="small"
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.employeeID} />  
                                </div>
                                {/* <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="status" title="Status" value={fields.status} size="small" mandatory={true}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.status} />  
                                </div> */}
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <span className="error colorThree500span" style={{fontSize : "13px"}}>{errorGlobal}</span>
                                <div className="spacer"></div>
                                <div className="fieldbuttonbox border-box">
                                    <button className="bttn small solid one" onClick={(e) => handleButtonClick("save")}>Save</button>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </div>
        }
        </>
    );

    function fetchData(){
        setIsLoading(true);
        api.getOEMs({offset : "0", limit : "200"}).then(function (response) {setOems(response.data.data);setIsLoading(false);}).catch(function (error) {console.log(error);}).finally(function () {});
      }

    function fetchDataCitiesList(stateName){
        if(stateName && stateName.length > 0){
            // setIsLoading(true);
            api.getCities(stateName).then(function (response) {console.log("responsedata", response.data.data); setCitiesList(response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
        }else{
            setCitiesList([]);
        }
    }
}
