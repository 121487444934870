import { useState } from 'react';
import Utils from '../utils';
import FieldBox from '../base/utils/fieldbox';
import Linkbutton from '../base/utils/linkbutton';
import { redirect } from 'react-router-dom';
import api from '../api';
import { useNavigate } from "react-router-dom";

export default function AppSignUp(props) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [passwordC, setPasswordC] = useState("");
    const [error, setError] = useState({});
    const [remember, setRemember] = useState(true);
    const [pageStatus, setPageStatus] = useState("initial");
    const navigate = useNavigate();

    function handleFieldState(key, value) {
        switch (key) {
            case "firstName":
                setFirstName(value.trim());
                break;
            case "lastName":
                setLastName(value.trim());
                break;
            case "email":
                setEmail(value.trim().toLowerCase());
                break;
            case "phoneNumber":
                setPhoneNumber(value.trim());
                break;
            case "password":
                setPassword(value.trim());
                break;
            case "passwordC":
                setPasswordC(value.trim());
                break;
            default:
                break;
        }
        validateField(key, value.trim());
    }

    function validateField(key, value){
        switch (key) {
            case "firstName":
                if(!value || value.length == 0) {
                    error["firstName"] = "Please enter a first name";
                    setError(error);
                    return false;
                }else{
                    delete error["firstName"];
                    setError(error);
                    return true;
                }
                break;
            case "lastName":
                if(!value || value.length == 0) {
                  error["lastName"] = "Please enter a last name";
                  setError(error);
                  return false;
                }else{
                    delete error["lastName"];
                    setError(error);
                    return true;
                }
                break;
            case "email":
                if(!Utils.validateEmail(value)) {
                    error["email"] = "Please enter a valid email address";
                    setError(error);
                    return false;
                }else{
                    delete error["email"];
                    setError(error);
                    return true;
                }
                break;
            case "phoneNumber":
                if(!Utils.validatePhoneNumber(value)) {
                    error["phoneNumber"] = "Please enter a valid phone number";
                    setError(error);
                    return false;
                }else{
                    delete error["phoneNumber"];
                    setError(error);
                    return true;
                }
                break;
            case "password":
                if(!value || value.length < 8) {
                  error["password"] = "Please enter a password of minimum 8 characters";
                  setError(error);
                  return false;
                }else{
                    delete error["password"];
                    setError(error);
                    return true;
                }
                break;
            case "passwordC":
                if(!value || value.length == 0) {
                  error["passwordC"] = "Please confirm the password";
                  setError(error);
                  return false;
                }else if(password !== value){
                    error["passwordC"] = "The passwords do not match";
                    error["password"] = "The passwords do not match";
                    setError(error);
                    return false;
                }else{
                    delete error["passwordC"];
                    delete error["password"];
                    setError(error);
                    return true;
                }
                break;
            default:
                break;
        }
    }

    function handleButtonClick(buttonType) {
        if(validateField("firstName", firstName) && validateField("lastName", lastName) && validateField("email", email) && validateField("phoneNumber", phoneNumber) && validateField("password", password) && validateField("passwordC", passwordC)){
            api.signup(email, password, firstName, lastName, phoneNumber).then(function (response) {
                if(response.data.status){
                    // localStorage.setItem("user_id", response.data.data.id);
                    // // localStorage.setItem("phone", sessionStorage.getItem("phone"));
                    // localStorage.setItem("custom_token", response.data.data.custom_token);
                    // localStorage.setItem("access_token", response.data.data.access_token.token);
                    // localStorage.setItem("token_expiration_date", response.data.data.access_token.expiration_date);
                    // localStorage.setItem("role", "superadmin");
                    // localStorage.setItem("logged_in_at", new Date());
                    // localStorage.setItem("email", email);
                    // setPageStatus("redirect");
                    navigate("/login");
                       
                }else{
                    error["firstName"] = response.data.message;
                    setError(error);
                    setPageStatus("initial");
                }
                })
                .catch(function (error) {
                    error["firstName"] = error.response.data.message;
                    setError(error);
                    setPageStatus("initial");
                })
                .finally(function () {
                });
        }
    }

    return (
      <>
        {/* {
          {
          'initial':  */}
              <div className="accountbaseinner border-box signup">
                  <div className="accountbaseinnerleft border-box">
                      <div className="inner border-box" style={{width : "70%"}}>
                          <h1>Sign Up</h1>
                          <p>Create your Super Admin account</p>
                          <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                            <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                <FieldBox objectKey="firstName" title="First Name" value={firstName} size="small"
                                  onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.firstName} />  
                            </div>
                            <div style={{width : "20px"}}></div>
                            <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                <FieldBox objectKey="lastName" title="Last Name" value={lastName} size="small"
                                  onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.lastName} />  
                            </div>
                          </div>
                          <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                            <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                <FieldBox objectKey="email" title="email" value={email} size="small"
                                  onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.email} />  
                            </div>
                            <div style={{width : "20px"}}></div>
                            <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                <FieldBox objectKey="phoneNumber" title="Phone Number" value={phoneNumber} size="small"
                                  onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.phoneNumber} />  
                            </div>
                          </div>
                          <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                            <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                <FieldBox objectKey="password" title="password" value={password} size="small"
                                  onSetFieldState={handleFieldState} type="password" errorDisplay="yes" errorText={error.password}/>
                            </div>
                            <div style={{width : "20px"}}></div>
                            <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                <FieldBox objectKey="passwordC" title="confirm password" value={passwordC} size="small"
                                  onSetFieldState={handleFieldState} type="password" errorDisplay="yes" errorText={error.passwordC}/>
                            </div>
                          </div>
                          
                          <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                              <button className="bttn large solid one" onClick={(e) => handleButtonClick("signup")}>Sign up</button>
                          </div>
                      </div>
                      
                  </div>
                  <div className="accountbaseinnerright border-box">
                  </div>
              </div>
              {/* , */}
          {/* // 'redirect': <Navigate to="/dashboard" />,
          }[pageStatus]
      } */}
      </>
    );
}