import React from 'react';
import {Redirect, Link, useHistory, withRouter} from 'react-router-dom';

class ChoiceBoxMultiInner extends React.Component {
    constructor(props) {
      super(props);
      this.handleClick = this.handleClick.bind(this);
    }

    handleClick(){
        if(!this.props.selected){
            this.props.onClick(this.props.type, this.props.id+"", this.props.text);
        }
    }

    render(){
      return(
        <React.Fragment>
            <div className={this.props.selected ? "choiceboxmultiinner choiceboxmultiinnerselected border-box centeralign" : "choiceboxmultiinner border-box centeralign"} 
                onClick = {(e) => this.handleClick()}>{this.props.text}</div>
        </React.Fragment>
      );
    }
}

class ChoiceBoxMulti extends React.Component {
    constructor(props) {
      super(props);
    }

    render(){
        var that = this;
        const objects = that.props.objects.map((obj) =>
            <ChoiceBoxMultiInner type={this.props.type} id={obj.id} text={obj.title} selected={that.props.selected == obj.id} onClick={that.props.onClick} />
        );
      return(
        <React.Fragment>
            <div className="choiceboxmulti border-box uppercased weight700" style={{width : "fit-content"}}>
                {objects}
            </div>
        </React.Fragment>
      );
    }
}

export default ChoiceBoxMulti;