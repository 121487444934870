import constants from "../constants";
import { useNavigate } from "react-router-dom";

export const Sidebar = (props) => {
    const navigate = useNavigate();
    // constants.log("hereXX", constants.getRole());
    var sectionList = constants.getSidebarSections().filter(o => constants.getSidebarSubsectionsBySection(constants.getRole(), o.key).length > 0).map((section, index) => {
        return (
            <>
                <div className="header border-box flex_horizontal center_vertical start_horizontal weight600" key={section.key}>{section.section}</div>
                {constants.getSidebarSubsectionsBySection(constants.getRole(), section.key).map((subsection, index) => {
                    return (
                        <div 
                            className={section.key == props.section && subsection.key === props.subsection ? "active subheader cursor-pointer border-box flex_horizontal center_vertical start_horizontal weight500" : "subheader cursor-pointer border-box flex_horizontal center_vertical start_horizontal weight500"}
                            onClick={() => handleClick(subsection.key)} key={section.key+"_"+subsection.key} >
                            {subsection.icon}<span style={{width : "5px"}} />{subsection.subsection}
                        </div>
                    );
                })}
            </>
        );
    });

    function handleClick(type){
        console.log("clicking", "sidebar clicked", type);
        switch(type) {
            case "dashboard":
                navigate("/dashboard/home");
                break;
            default:
                navigate("/dashboard/" + type);
                break;
        }
    }

    return (
        <div className="sidebarbase border-box flex_vertical center_horizontal start_vertical">
            <div className="border-box full_width spacer flex_vertical center_horizontal start_vertical">
                {sectionList}
            </div>
            <div className="border-box full_width flex_vertical center_horizontal start_vertical">
                <div className="header border-box flex_horizontal center_vertical start_horizontal weight600">Account</div>
                <div 
                    className="subheader cursor-pointer border-box flex_horizontal center_vertical start_horizontal weight500"
                    onClick={() => {console.log("isLoggedIn", "logout clicked");navigate("/account/logout");}}>
                    <i className="ti ti-logout"></i><span style={{width : "5px"}} />Log out
                </div>
            </div>
        </div>
    );
}