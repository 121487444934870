import { useState } from "react";
import FieldBox from "./fieldbox";

export const Dialog = (props) => {

    let title = props.title;
    let text = props.text;
    let buttonText = props.buttonText;
    let buttonActionKey = props.buttonActionKey;
    let buttonClassNames = "bttn small "+props.buttonClassNames;

    console.log("buttonActionKey", buttonActionKey);

    console.log("dialog", props);

    const errors = {
        reason : "Please select a value"
    }

    const [fields, setFields] = useState(
        {
            reason : ""
        });

    const [error, setError] = useState({});

    function handleFieldState(key, value) {
            switch (key) {
                default:
                    setFields({...fields, [key] : value});
                    validateField(key, value);
                    break;
            }
        }
    
        function validateField(key, value){
            switch (key) {
                default:
                    if(!value || value.length == 0) {
                        console.log("erroring", key);
                        error[key] = errors[key];setError(error);return false;
                    }else{
                        delete error[key];setError(error);return true;
                    }
                    break;
            }
        }
    
        function validateAllFields(){
            let isValid = true;
            
            Object.keys(fields).forEach((key, index) => {
                console.log("validate", key);
                if(!validateField(key, fields[key])){
                    console.log("invalid", key, fields[key]);
                    isValid = false;
                }
            });
            return isValid;
        }

    const handleDialogActionButtonClick = (buttonActionKey) => {
        if(props.fields){
            if(validateAllFields()){
                props.handleDialogButtonClick(buttonActionKey, {reason : fields.reason});
            }
        }else{
            props.handleDialogButtonClick(buttonActionKey);
        }
    }
    
    return (
        <>
            <div className="dialogscreen border-box flex_vertical center_horizontal center_vertical">
                <div className="dialogbase border-box flex_vertical center_horizontal center_vertical shadow-box">
                    <div className="dialogtitle full_width border-box flex_horizontal center_vertical start_horizontal weight600">{title}</div>
                    <div className="dialogtext full_width border-box flex_horizontal center_vertical start_horizontal">{text}</div>
                    {props.fields ? 
                    <div className="dialogtext full_width border-box flex_vertical center_vertical center_horizontal">
                        <FieldBox objectKey="reason" title={props.fieldTitle} value={fields.reason}  mandatory={true}
                                        selectedValue={fields.reason} onSetFieldState={handleFieldState} 
                                        type="select" size="small" errorDisplay="yes" errorText={error.reason}
                                        choices={props.choices
                                                .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
                    </div> : <></>}
                    <div className="dialogbuttons full_width border-box flex_horizontal center_vertical space_between_horizontal">
                        <button className="bttn small border one" onClick={(e) => props.handleDialogButtonClick("cancel")}>Cancel</button>
                        <button className={buttonClassNames} onClick={(e) => handleDialogActionButtonClick(buttonActionKey)}>{buttonText}</button>
                    </div>
                </div>
            </div>
        </>
    );
  }  