import Linkbutton from "../base/utils/linkbutton";

export default function PasswordSuccess(props) {
    return (
        <div className="accountbaseinner border-box password_success">
                  <div className="accountbaseinnerleft border-box" style={{width : "30%"}}>
                  </div>
                  <div className="accountbaseinnerleft border-box" style={{width : "40%"}}>
                      <div className="inner border-box">
                          <h1 className="colorOne500span weight700">Success!</h1>
                          <p className="centeralign" style={{fontSize : "12px"}}>{props.text}</p>
                          <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <Linkbutton text="Back to Home" href="/" newTab="no" type="large1" button_type="solid" />
                          </div>
                      </div>
                      
                  </div>
                  <div className="accountbaseinnerright border-box" style={{width : "30%"}}>
                  </div>
              </div>
    );
}