import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import utils from '../utils';
import constants from '../constants';
import { RowHeaderItem } from './RowHeaderItem';

export const RowHeader = (props) => {
    const navigate = useNavigate(); 

    let headers = constants.getRowDataByTag(props.currentTab.key).map((header) => 
        <RowHeaderItem key={header.key} data={header} 
            onChoiceBoxClick={props.onChoiceBoxClick} dataState={props.dataState} dataData={props.dataData} />
    );

    console.log("currentTabHeader", props.currentTab.key, 
    constants.getRowDataByTag(props.currentTab.key).map((header) => 
        header.header
    ), headers);

      return(
        <div className="rowheader border-box flex_horizontal center_vertical start_horizontal bgcolorGray100 colorGray600span weight600">
          {headers}
        </div>
      );
}
