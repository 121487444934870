import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import utils from '../utils';
import constants from '../constants';
import { Row } from './Row';

export const RowData = (props) => {
    const navigate = useNavigate(); 

    console.log("rowDataSelected", props);

      return(
        <div className="rowdata border-box">
            {props.data.map((row) => 
                <Row key={row.key} data={row} currentTab={props.currentTab} selectedObjs={props.selectedObjs}
                    handleRowClick={props.handleRowClick} />
            )}
        </div>
      );
}
