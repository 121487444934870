import React from 'react';
import Bottombarunauth from './bottombar_unauth';
import "./bottombar.css"


export default function Bottombar(props) {
	let content;
	if (props.type === "unauth") {
		content = <Bottombarunauth />;
	}else{
		content = <></>;
	}
	return (
		<div className="bottombarbase border-box">
			{content}
		</div>
	);
}