import { Navigate, useParams } from 'react-router-dom';
import Bottombar from '../bottombar/bottombar';
import Topbar from '../topbar/topbar';
import AppLogin from './AppLogin';
import AppPasswordReset from './AppPasswordReset';
import { AppPasswordSet } from './AppPasswordSet';
import AppSignUp from './AppSignUp';
import './account.css';
import utils from '../utils';
import { AppLogout } from './AppLogout';

function AppAccount(props) {
    let content;
    console.log("isLoggedIn", "props", props.type)
    switch(props.type) {
        case "login":
            content = <AppLogin />;
            break;
        case "signup":
            content = <AppSignUp />;
            break;
        case "password_reset":
            content = <AppPasswordReset />;
            break;
        case "password_set":
            content = <AppPasswordSet />;
            break;
        case "logout":
            content = <AppLogout />;
            break;
        default:
            content = <AppLogin />;
            break;
    }

    let content_full = <></>;
    if(props.type === "logout"){
        content_full = <>
            {/* <Topbar type="unauth" /> */}
            <div className="accountbase border-box">
                {content}
            </div>
            <Bottombar type="unauth" />
        </>;
    }else{
        if(utils.isLoggedIn()){
            content_full = <Navigate to="/dashboard/home" />;
        }else{
            content_full = 
            <>
                {/* <Topbar type="unauth" /> */}
                <div className="accountbase border-box">
                    {content}
                </div>
                <Bottombar type="unauth" />
            </>
        }
    }
  
    return (
        <>
        {content_full}
        </>
    );
}

export default AppAccount;
