import React, {useEffect, useState } from 'react';
import FieldBox from '../base/utils/fieldbox';
import { Loader } from '../base/utils/loader';
import api from '../api';

export const Popup = ({popupConfig, closeClick}) => {
    const [fields, setFields] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [serviceBuddy, setServiceBuddy] = useState([]);
    function handleFieldState(key, value) {
        setFields(value);
        setErrorText("");
    }
    const handleFormSave = () => {
        if(!fields){
            setErrorText("Please select service buddy");
            return;
        }  
        setIsLoading(true);
        api.autoAssignBuddy({...popupConfig.data, service_buddy_id : parseInt(fields)}).then(function (response) {setIsLoading(false); closeClick()}).catch(function (error) {console.log(error);setIsLoading(false);})
    }
    const fetchServiceBuddyList = (stateName) =>{
        const params = {'oem_id':popupConfig.data.oem_info.id};
        api.getServiceBuddies({...params, is_online : 1}).then(function (response) {setServiceBuddy(response.data.data);}).catch(function (error) {console.log(error);})
    }
    useEffect(() => {
        if(popupConfig.show && popupConfig.data && popupConfig.data.oem_info && popupConfig.data.oem_info.id && popupConfig.data.oem_info.id && serviceBuddy.length == 0)
            fetchServiceBuddyList();
    },[popupConfig])

    return (
        <>
            {isLoading &&
            <div className="loader spacer border-box flex_horizontal center_vertical center_horizontal" style={{    'position': 'absolute',
                'zIndex': '999'}}><Loader /></div> 
            }
            <div className="modalComponent"/>
                <div className="modalBackground-nesc">
                    <div className='es-header es-flex-row'>
                        <div className='ml-auto'>{popupConfig && popupConfig.title ? popupConfig.title : 'Title'}</div>
                        <img onClick={()=>closeClick()} className='es-cross ml-auto' src='https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/chat/cross.png' width='16px' height='16px' />
                    </div>
                    <div className='modal-form'>
                    <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                        <FieldBox objectKey="stateName" title="Service Buddy" value={fields}  mandatory={true}
                        selectedValue={fields} onSetFieldState={handleFieldState} 
                        type="select" size="small" errorDisplay="yes" errorText={errorText} 
                        choices={[...[{value:"", title:"Select Service Buddy"}], ...serviceBuddy.map(o => ({value:o.user_id, title:o && o.sb_users && o.sb_users.name ? o.sb_users.name :""}))]
                                .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
                    </div>
                    <div className='es-flex-row es-lower'>
                        <button onClick={()=>closeClick()} className='es-cancel-button'>CANCEL</button>
                        <button onClick={()=>handleFormSave()} className='es-add-button'>Save</button>
                    </div>
                    </div>
            </div>
        </>
    )
}
