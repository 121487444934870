import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import utils from '../../utils';
import constants from '../../constants';
import { Row } from '../Row';

export const ServiceDetailsAbout = (props) => {
    const navigate = useNavigate();

    let vehicle = props.data.vehicle;
    
    return(
        <>
        {vehicle ? 
            <div className="vehicle full_width border-box flex_vertical start_horizontal center_vertical">
                <div className="supertitle weight600 colorOne500span">Vehicle</div>
                <div className="title">{vehicle.brand_name} {vehicle.model_name}</div>
                <div className="subtitle border-box flex_horizontal start_horizontal center_vertical">
                    {/* <i className="ti ti-brand-speedtest colorGray400span"></i>
                    <span className="colorGray500span">{props.extraData.odometer_reading}</span> */}
                    <i className="ti ti-license colorGray400span"></i>
                    <span className="colorGray500span">NA</span>
                    <i className="ti ti-scooter-electric colorGray400span"></i>
                    <span className="colorGray500span">NA</span>
                    <i className="ti ti-map-pin colorGray400span"></i>
                    <span className="colorGray500span">NA</span>
                </div>
            </div> : <></>}
            <div style={{height : "20px"}}/>
            <div className="contact full_width border-box flex_vertical start_horizontal center_vertical">
                <div className="supertitle weight600 colorGray500span">Customer Information</div>
                <div className="text full_width border-box flex_horizontal start_horizontal center_vertical">
                    <div className="left border-box colorGray500span">
                        Name
                    </div>
                    <div className="right border-box">
                        {props.data.user.name}
                    </div>
                </div>
                <div className="text full_width border-box flex_horizontal start_horizontal center_vertical">
                    <div className="left border-box colorGray500span">
                        Phone
                    </div>
                    <div className="right border-box">
                        {props.data.user.phone_number}
                    </div>
                </div>
                <div className="text full_width border-box flex_horizontal start_horizontal center_vertical">
                    <div className="left border-box colorGray500span">
                        Email
                    </div>
                    <div className="right border-box">
                        {props.data.user.email}
                    </div>
                </div>
                <div className="text full_width border-box flex_horizontal start_horizontal center_vertical">
                    <div className="left border-box colorGray500span">
                        Location
                    </div>
                    <div className="right border-box">
                        {props.data.city}, {props.data.state}
                    </div>
                </div>
                <div className="text full_width border-box flex_horizontal start_horizontal center_vertical">
                    <div className="left border-box colorGray500span">
                        Rating
                    </div>
                    <div className="right border-box">
                        {props.data.rating}
                    </div>
                </div>
            </div>
            <div style={{width : "100%", height : "1px", background : "var(--colorGray200)", marginTop : "10px"}} />
            <div style={{height : "20px"}}/>
            <div className="contact full_width border-box flex_vertical start_horizontal center_vertical">
                <div className="supertitle weight600 colorGray500span">Service Buddy Information</div>
                <div className="text full_width border-box flex_horizontal start_horizontal center_vertical">
                    <div className="left border-box colorGray500span">
                        Name
                    </div>
                    <div className="right border-box">
                        {props.data.service_buddy.name}
                    </div>
                </div>
                <div className="text full_width border-box flex_horizontal start_horizontal center_vertical">
                    <div className="left border-box colorGray500span">
                        Phone
                    </div>
                    <div className="right border-box">
                        {props.data.service_buddy.phone_number}
                    </div>
                </div>
                <div className="text full_width border-box flex_horizontal start_horizontal center_vertical">
                    <div className="left border-box colorGray500span">
                        Email
                    </div>
                    <div className="right border-box">
                        {props.data.service_buddy.email}
                    </div>
                </div>
                <div className="text full_width border-box flex_horizontal start_horizontal center_vertical">
                    <div className="left border-box colorGray500span">
                        OEM
                    </div>
                    <div className="right border-box">
                        {props.data.service_buddy.oem_name}
                    </div>
                </div>
                <div className="text full_width border-box flex_horizontal start_horizontal center_vertical">
                    <div className="left border-box colorGray500span">
                        Rating
                    </div>
                    <div className="right border-box">
                        {props.data.service_buddy_rating}
                    </div>
                </div>
            </div>
        </>
    );
}
