import { useEffect, useState } from 'react';
import Utils from '../utils';
import FieldBox from '../base/utils/fieldbox';
import Linkbutton from '../base/utils/linkbutton';
import { redirect, useLocation, useParams } from 'react-router-dom';
import api from '../api';
import { useNavigate } from "react-router-dom";
import utils from '../utils';
import { HeaderButtons } from './HeaderButtons';
import { SubheaderButtons } from './SubheaderButtons';
import { RowHeader } from './RowHeader';
import { RowFooter } from './RowFooter';
import { Loader } from '../base/utils/loader';
import { RowData } from './RowData';
import { UserDetails } from './userdetails/userdetails';
import { EditSB } from './action/editsb';
import { EditCustomer } from './action/editcustomer';
import { EditOEM } from './action/editoem';
import { EditFleet } from './action/editfleet';
import { EditWarehouse } from './action/editwarehouse';
import { EditVehicleB2B } from './action/editvehicle_b2b';
import { ExcelUpload } from './action/excelupload';
import { EditVehicle } from './action/editvehicle';
import { EditManager } from './action/editmanager';

export const DashboardType4 = (props) => {
    const [pageStatus, setPageStatus] = useState("initial");
    const navigate = useNavigate();
    // const [currentTab, setCurrentTab] = useState(props.subsection.viewTabs ? props.subsection.viewTabs[0] : {});
    const [isLoading, setIsLoading] = useState(true);

    let { currentTab, id } = useParams();
    const {state} = useLocation();
    let [data, setData] = useState(state ? state.data : {});
    // const [data, setData] = useState(state.data);

    console.log("idaction", id, currentTab, data, props, props.actionObject);

    
    let content = <></>;

    switch(props.actionObject){
        case "excel":
            content = <ExcelUpload actionType={props.actionType} data={data} />;
            break;
        case "vehicle":
            console.log("sb", "sb")
            content = <EditVehicle actionType={props.actionType} data={data} />;
            break;
        case "customer":
            console.log("sb", "sb")
            content = <EditCustomer actionType={props.actionType} data={data} />;
            break;
        case "sb":
            console.log("sb", "sb")
            content = <EditSB actionType={props.actionType} data={data} />;
            break;
        case "manager":
            console.log("sb", "sb")
            content = <EditManager actionType={props.actionType} data={data} />;
            break;
        case "oem":
            console.log("sb", "sb")
            content = <EditOEM actionType={props.actionType} data={data} />;
            break;
        case "fleet":
            console.log("sb", "sb")
            content = <EditFleet actionType={props.actionType} data={data} />;
            break;
        case "warehouse":
            console.log("sb", "sb")
            content = <EditWarehouse actionType={props.actionType} data={data} />;
            break;
        case "vehicleb2b":
            console.log("sb", "sb")
            content = <EditVehicleB2B actionType={props.actionType} data={data} />;
            break;
        default:
            console.log("22");
            break;
    }

    function handleButtonClick(buttonType, data){
        switch(buttonType) {
           case "tab":
              // setCurrentTab(data);
              setIsLoading(true);
              setData([]);
              console.log("new tab")
              fetchData(data.key);
              break;
        }
    }

    return (
      <>
          <div className="dashboardbaseinner border-box type4 flex_vertical start_vertical center_horizontal">
              {content}
          </div>
      </>
    );


    function fetchData(tag, id, isReload=true){
      console.log("tag", tag, id);
      switch(tag){
        
        case "customers":
          // API.getWHLayout(params).then(function (response) {that.props.setData(response.data.data, "wh_layout");}).catch(function (error) {console.log(error);}).finally(function () {});
          api.getCustomers({user_id : id}).then(function (response) {console.log(response);setFetchedData(tag, response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
          // if(isReload){
          //   api.getCustomers().then(function (response) {
          //     console.log(response.data);
          //       Utils.setWarehouses(response.data.data);
          //       that.fetchLocalData(tag, exists);
          //     }).catch(function (error) {}).finally(function () {});
          // }else{
          //   that.fetchLocalData(tag, exists);
          // }
          // API.getVendors1(params).then(function (response) {that.props.setData(response.data.data, "vendors");}).catch(function (error) {console.log(error);}).finally(function () {});
            break;
      }
    }

    function setFetchedData(tag, data){
      var that = this;
      switch(tag){
        case "actionables":
        case "periodic_tasks":
          setData(data);
            break;
        default:
          setData(data);
          break;
      }
      setIsLoading(false); 
      // that.setState({skeleton : false});
    }
}
