import { useEffect, useState } from 'react';
import utils from '../../utils';
import { redirect, useLocation, useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import "./action.css";
import FieldBox from '../../base/utils/fieldbox';
import Utils from '../../utils';
import api from '../../api';
import { Loader } from '../../base/utils/loader';
import constants from '../../constants';

export const EditCustomer = (props) => {
    const [pageStatus, setPageStatus] = useState("initial");
    const navigate = useNavigate();
    // const [currentTab, setCurrentTab] = useState(props.subsection.viewTabs ? props.subsection.viewTabs[0] : {});
    const [isLoading, setIsLoading] = useState(false);
    const [oems, setOems] = useState([]);
    // const [fields, setFields] = useState({gender : "male"});
    const [error, setError] = useState({});
    const[errorGlobal, setErrorGlobal] = useState("");

    const [citiesList, setCitiesList] = useState([]);
    const [brandsList, setBrandsList] = useState([]);
    const [modelsList, setModelsList] = useState([]);

    let { currentTab, id } = useParams();
    const {state} = useLocation();
    let vehicle = state && state.data.vehicles ? (state.data.vehicles.length == 0 ? null : state.data.vehicles[state.data.vehicles.length - 1]) : null;
    const [fields, setFields] = useState(state ? 
        {
            firstName : state.data.first_name,
            lastName : state.data.last_name,
            email : state.data.email,
            phoneNumber : state.data.phone_number,
            address1 : state.data.address ? state.data.address.address_line1 : "",
            address2 : state.data.address ? state.data.address.address_line2 : "",
            city : state.data.address ? state.data.address.city : "",
            stateName : state.data.address ? state.data.address.state.toUpperCase() : "",
            pincode : state.data.address ? state.data.address.postal_code : "",
            brand_name : vehicle ? vehicle.brand_name : "",
            model_name : vehicle ? vehicle.model_name : "",
            registration_number : vehicle ? vehicle.registration_number : "",
            odometer : vehicle ? vehicle.odometer : "",
            vin : vehicle ? vehicle.vin : "",
            oem_id : state.data.oem_id,
        }
        :
        {
            firstName : "",
            lastName : "",
            email : "",
            phoneNumber : "",
            address1 : "",
            address2 : "",
            city : "",
            stateName : "",
            pincode : "",
            brand_name : "",
            model_name : "",
            registration_number : "",
            odometer : "",
            vin : "",
            oem_id : "",
        });
    // const [data, setData] = useState(state.data);

    const errors = {
        firstName : "Please enter a first name", 
        lastName : "Please enter a last name",
        email : "Please enter a valid email address",
        phoneNumber : "Please enter a valid phone number",
        address1 : "Please enter address",
        address2 : "Please enter address",
        city : "Please enter a city",
        stateName : "Please enter a state",
        pincode : "Please enter a valid pincode",
        company : "Please enter company name",
        location : "Please enter location",
        role : "Please enter role",
        doj : "Please enter date of joining",
        employeeID : "Please enter employee ID",
        status : "Please enter status",
        brand_name : "Please enter brand name",
        model_name : "Please enter model name",
        registration_number : "Please enter registration number",
        odometer : "Please enter odometer reading",
        vin : "Please enter VIN",
        oem_id : "Please enter OEM",
    }

    let isChecked = false;

    useEffect(() => {
        if (isChecked) return;
        isChecked = true;
        
        fetchData();
        if(fields.stateName && fields.stateName.length > 0){
            fetchDataCitiesList(fields.stateName);
        }
        if(localStorage.getItem("oem_id")){
            fetchDataModelsList(localStorage.getItem("oem_id"));
        }
        if(fields.brand_name && fields.brand_name.length > 0){
            fetchDataModelsList(fields.brand_name);
        }
        
      }, []);

    // input_data = {
    //     "updated_at": now,
    //     "joining_date": datetime.datetime.strptime(str(request.data.get('joining_date')), "%d/%m/%Y"),
    //     "gender": str(request.data.get('gender')),
    //     "dob": str(request.data.get('dob')),
    //     "address": {
    //         "country": str(request.data.get('country')),
    //         "state": str(request.data.get('state')),
    //         "address_line1": str(request.data.get('address_line1')),
    //         "address_line2": str(request.data.get('address_line2')),
    //         "city": str(request.data.get('city')),
    //         "postal_code": str(request.data.get('postal_code'))
    //     },
    //     "location": str(request.data.get('city')),
    //     "city_id": int(request.data.get('city_id')),
    //     "employer": {
    //         "company_name": str(request.data.get('company_name')),
    //         "location": str(request.data.get('company_location')),
    //         "employee_id": str(request.data.get('employee_id'))
    //     },
    //     "service_center_id": int(request.data.get('service_center_id'))
    // }

    // console.log("idEditSB", id, currentTab, data, props.actionType);

    
    function handleFieldState(key, value) {
        switch (key) {
            case "test":
            case "aadhar_file1":
            case "aadhar_file2":
            case "license":
            case "photo":
                break;
            case "stateName":
                setFields({...fields, [key] : value, city : ""});
                validateField(key, value);
                fetchDataCitiesList(value);
                break;
            case "brand_name":
                setFields({...fields, [key] : value, model_name : ""});
                validateField(key, value);
                fetchDataModelsList(value);
                break;
            case "oem_id":
                setFields({...fields, [key] : value, model_name : ""});
                validateField(key, value);
                fetchDataModelsList(value);
                break;
            case "email":
                setFields({...fields, [key] : value.trim().toLowerCase()});
                validateField(key, value.trim().toLowerCase());
                break;
            default:
                setFields({...fields, [key] : value});
                validateField(key, value);
                break;
        }
    }

    function validateField(key, value){
        switch (key) {
            case "email":
                if(!value || value.length == 0) {
                    delete error[key];setError(error);return true;
                }else{
                    if(!Utils.validateEmail(value)) {
                        console.log("erroring1", key);
                        error[key] = errors[key];setError(error);return false;
                    }else{
                        delete error[key];setError(error);return true;
                    }
                }
                break;
            case "pincode":
                if(!value || value.length == 0) {
                    delete error[key];setError(error);return true;
                }else{
                    if(!Utils.validatePinCode(value)) {
                        console.log("erroring2", key);
                        error[key] = errors[key];setError(error);return false;
                    }else{
                        delete error[key];setError(error);return true;
                    }
                }
                break;
            case "phoneNumber":
                if(!Utils.validatePhoneNumber(value)) {
                    console.log("erroring3", key);
                    error[key] = errors[key];setError(error);return false;
                }else{
                    delete error[key];setError(error);return true;
                }
                break;
            case "aadhar_file1":
            case "aadhar_file2":
            case "license":
            case "photo":
                return true;
                break;
            case "oem_id":
                if(constants.getRole() == "superadmin"){
                    if(!value || value.length == 0) {
                        console.log("erroring", key);
                        error[key] = errors[key];setError(error);return false;
                    }else{
                        delete error[key];setError(error);return true;
                    }    
                }else{
                    return true;
                }
                break;
            case "firstName":
            case "registration_number":
            // case "brand_name":
            case "model_name":
                if(!value || value.length == 0) {
                    console.log("erroring", key);
                    error[key] = errors[key];setError(error);return false;
                }else{
                    delete error[key];setError(error);return true;
                }
                break;
            default:
                // if(!value || value.length == 0) {
                //     console.log("erroring", key);
                //     error[key] = errors[key];setError(error);return false;
                // }else{
                    delete error[key];setError(error);return true;
                // }
                break;
        }
    }

    function validateAllFields(){
        let isValid = true;
        
        Object.keys(fields).forEach((key, index) => {
            console.log("validate", key);
            if(!validateField(key, fields[key])){
                console.log("invalid", key, fields[key]);
                isValid = false;
            }
        });
        return isValid;
    }


    function handleButtonClick(buttonType, data){
        if(buttonType == "save"){
            console.log("here");
            setErrorGlobal("");
            if (validateAllFields()){
                console.log("here1");
                let request_data1 = {
                    email : fields.email,
                    first_name : fields.firstName,
                    last_name : fields.lastName,
                    name : fields.firstName + " " + fields.lastName,
                    phone_number : fields.phoneNumber,
                    // address : {
                        country : "India",
                        state : fields.stateName,
                        address_line1 : fields.address1,
                        address_line2 : fields.address2,
                        city : fields.city,
                        postal_code : fields.pincode,
                    // },
                    // location : fields.city,
                    city_id : 1,
                    // employer : {
                        // company_name : fields.company,
                        // location : fields.location,
                        // employee_id : fields.employeeID,
                    // },
                    // service_center_id : 1,
                }
                let request_data2 = {
                    brand_name : fields.brand_name,
                    model_name : fields.model_name,
                    registration_number : fields.registration_number,
                    odometer : fields.odometer,
                    vin : fields.vin,
                    type : "vehicle",
                }
                if(localStorage.getItem("oem_id")){
                    request_data1["oem_id"] = localStorage.getItem("oem_id");
                    request_data2["oem_id"] = localStorage.getItem("oem_id");
                }
                if (constants.getRole() == "superadmin"){
                    request_data1["oem_id"] = fields.oem_id;
                }
                // if(localStorage.getItem("oem_id")){
                //     request_data1["oem_id"] = localStorage.getItem("oem_id");
                // }
                // var fieldData = Object.assign({}, that.state.fieldData);
                // delete fieldData.image_file;
                setIsLoading(true);
                // let files = [fields.aadhar_file1, fields.aadhar_file2, fields.license, fields.photo];
                api.editCustomer(request_data1).then(function (response) {
                    request_data2["customer_id"] = response.data.data.id;
                    api.editVehicle(request_data2).then(function (response) {
                        console.log(response);setIsLoading(false);navigate("/dashboard/customers");
                    }).catch(function (error) {setErrorGlobal(error.response.data.message);setIsLoading(false);console.log(error);}).finally(function () {});
                }).catch(function (error) {setErrorGlobal(error.response.data.message);setIsLoading(false);console.log(error);}).finally(function () {});
            }
        }
    }

    return (<>
        {isLoading ? 
            <div className="loader spacer border-box flex_horizontal center_vertical center_horizontal"><Loader /></div> : 
            
        
        
        <div className="action full_width full_height border-box flex_vertical center_horizontal start_vertical">
            <div className="one full_width border-box flex_horizontal center_horizontal center_vertical">
                <div className="left border-box flex_horizontal center_horizontal center_vertical">
                    <div className="border-box flex_horizontal center_vertical center_horizontal shadow-box">
                        {props.actionType == "add" ? "" : utils.getInitialsFromName(props.data.name)}
                    </div>
                </div>
                <div className="right border-box flex_vertical start_horizontal end_vertical">
                    <div className="title weight600">{props.actionType == "add" ? "Add new customer" : (props.data.first_name + " " + props.data.last_name)}</div>
                    <div style={{height : "10px"}}/>
                    <div className="subtitle weight300">{props.actionType == "add" ? "" : props.data.email}</div>
                    <div style={{height : "30px"}}/>
                </div>
            </div>
            <div className="two full_width border-box flex_horizontal center_horizontal start_vertical bgcolorGray200">
                <div className="inner full_width border-box flex_horizontal center_horizontal start_vertical">
                    <div className="left border-box flex_vertical start_horizontal start_vertical">
                        <div className="title weight600">Customer details</div>
                        <div className="subtitle colorGray500span">Update customer details</div>
                    </div>
                    <div className="right border-box flex_vertical start_horizontal start_vertical">
                        <div className="inner full_width border-box flex_vertical start_horizontal start_vertical bgcolorSix50 shadow-box">
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="firstName" title="First Name" value={fields.firstName} size="small" mandatory={true}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.firstName} />  
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="lastName" title="Last Name" value={fields.lastName} size="small" mandatory={false}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.lastName} />  
                                </div>
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="email" title="Email" value={fields.email} size="small" mandatory={false}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.email} />  
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="phoneNumber" title="Phone Number" value={fields.phoneNumber} size="small" mandatory={true}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.phoneNumber} />  
                                </div>
                                {constants.getRole() == "superadmin" ? 
                                <>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="oem_id" title="OEM" value={fields.oem_id}  mandatory={true}
                                        selectedValue={fields.oem_id} onSetFieldState={handleFieldState} 
                                        type="select" size="small" errorDisplay="yes" errorText={error.oem_id}
                                        choices={[...[{value:"", title:"Select an OEM"}], ...oems.map(o => ({value:o.id, title:o.name}))]
                                                .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
                                </div>
                                </> : <></>}
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="address1" title="Address Line 1" value={fields.address1} size="small" mandatory={false}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.address1} />  
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="address2" title="Address Line 2" value={fields.address2} size="small" mandatory={false}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.address2} />  
                                </div>
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="stateName" title="State" value={fields.stateName}  mandatory={false}
                                        selectedValue={fields.stateName} onSetFieldState={handleFieldState} 
                                        type="select" size="small" errorDisplay="yes" errorText={error.stateName}
                                        choices={[...[{value:"", title:"Select a state"}], ...utils.getStatesIndia().map(o => ({value:o, title:o}))]
                                                .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="city" title="City" value={fields.city}  mandatory={false}
                                        selectedValue={fields.city} onSetFieldState={handleFieldState} 
                                        type="select" size="small" errorDisplay="yes" errorText={error.city}
                                        choices={[...[{value:"", title:"Select a city"}], ...citiesList.filter((item)=> item.state.toLowerCase() == fields.stateName.toLowerCase()).map(o => ({value:o.name, title:o.name}))]
                                                .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="pincode" title="Pin Code" value={fields.pincode} size="small" mandatory={false}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.pincode} />  
                                </div>
                            </div>
                            {/* <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="city" title="City" value={fields.city} size="small"
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.city} />  
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="stateName" title="State" value={fields.stateName} size="small"
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.stateName} />  
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="pincode" title="Pin Code" value={fields.pincode} size="small"
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.pincode} />  
                                </div>
                            </div> */}
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                {/* <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="brand_name" title="Vehicle brand name" value={fields.brand_name} 
                                        selectedValue={fields.brand_name} onSetFieldState={handleFieldState}  mandatory={true}
                                        type="select" size="small" errorDisplay="yes" errorText={error.brand_name}
                                        choices={[...[{value:"", title:""}], ...brandsList.map(o => ({value:o.brand_name, title:o.brand_name}))]
                                                .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
                                </div>
                                <div style={{width : "20px"}}></div> */}
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="model_name" title="Vehicle model name" value={fields.model_name} 
                                        selectedValue={fields.model_name} onSetFieldState={handleFieldState}  mandatory={true}
                                        type="select" size="small" errorDisplay="yes" errorText={error.model_name}
                                        choices={[...[{value:"", title:"Select a model name"}], ...modelsList.map(o => ({value:o.model_name, title:o.model_name}))]
                                                .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
                                </div>
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="registration_number" title="Registration number"
                                     mandatory={true} value={fields.registration_number} size="small"
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.registration_number} />  
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="vin" title="VIN" value={fields.vin} size="small" mandatory={false}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.vin} />  
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="odometer" title="Odometer reading" value={fields.odometer} size="small" mandatory={false}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.odometer} />  
                                </div>
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <span className="error colorThree500span" style={{fontSize : "13px"}}>{errorGlobal}</span>
                                <div className="spacer"></div>
                                <div className="fieldbuttonbox border-box">
                                    <button className="bttn small solid one" onClick={(e) => handleButtonClick("save")}>Save</button>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </div>
        }
        </>
    );



    function fetchData(){
        setIsLoading(true);
        api.getOEMs({offset : "0", limit : "200"}).then(function (response) {
            api.getBrands().then(function (response1) {
                console.log("responsedata", response1.data.data);
                setOems(response.data.data);setBrandsList(response1.data.data);setIsLoading(false);
            }).catch(function (error1) {console.log(error1);}).finally(function () {});
        }).catch(function (error) {console.log(error);}).finally(function () {});
      }

    function fetchDataCitiesList(stateName){
        if(stateName && stateName.length > 0){
            // setIsLoading(true);
            api.getCities(stateName).then(function (response) {console.log("responsedata", response.data.data); setCitiesList(response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
        }else{
            setCitiesList([]);
        }
    }

    function fetchDataModelsList(brandName){
        if(brandName && brandName.length > 0){
            // setIsLoading(true);
            api.getModelsByOEM(brandName).then(function (response) {console.log("responsedata", response.data.data); setModelsList(response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
        }else{
            setModelsList([]);
        }
    }
}
