import { useEffect, useState } from 'react';
import Utils from '../utils';
import FieldBox from '../base/utils/fieldbox';
import Linkbutton from '../base/utils/linkbutton';
import { redirect, useLocation, useParams } from 'react-router-dom';
import api from '../api';
import { useNavigate } from "react-router-dom";
import utils from '../utils';
import { HeaderButtons } from './HeaderButtons';
import { SubheaderButtons } from './SubheaderButtons';
import { RowHeader } from './RowHeader';
import { RowFooter } from './RowFooter';
import { Loader } from '../base/utils/loader';
import { RowData } from './RowData';
import { UserDetails } from './userdetails/userdetails';
import { SBDetails } from './userdetails/sbdetails';
import { InvoiceDetails } from './userdetails/invoicedetails';
import { FleetDetails } from './userdetails/fleetdetails';
import { VehicleDetails } from './userdetails/vehicledetails';
import { OemDetails } from './userdetails/oemdetails';
import { ServiceDetails } from './userdetails/servicedetails';
import { WarehouseDetails } from './userdetails/warehousedetails';
import { ServiceDetailsB2B } from './userdetails/servicedetailsb2b';

export const DashboardType3 = (props) => {
    const [pageStatus, setPageStatus] = useState("initial");
    const navigate = useNavigate();
    // const [currentTab, setCurrentTab] = useState(props.subsection.viewTabs ? props.subsection.viewTabs[0] : {});
    const [isLoading, setIsLoading] = useState(true);

    let isChecked = false;
    let shouldFetchData = true;

    let { currentTab, id } = useParams();
    console.log("paramsXXX", useParams());
    const {state} = useLocation();
    let [data, setData] = useState(state ? state.data : {});
    // const [data, setData] = useState(state.data);

    console.log("idDetail", id, currentTab, data);

    
    let content = <></>;

    switch(currentTab){
      case "services":
        console.log("11", data);
        if(data && data.id && data.id == id){
          console.log("shouldFetch");
          shouldFetchData = false;
        }else{
          console.log("shouldNotFetch");
          shouldFetchData = true;
        }
        content = <ServiceDetails data={data} />;
        break;
      case "services_b2b":
        console.log("11", data);
        if(data && data.id && data.id == id){
          console.log("shouldFetch");
          shouldFetchData = false;
        }else{
          console.log("shouldNotFetch");
          shouldFetchData = true;
        }
        content = <ServiceDetailsB2B data={data} />;
        break;
      case "customers":
        console.log("11", data);
        if(data && data.id && data.id == id){
          console.log("shouldFetch");
          shouldFetchData = false;
        }else{
          console.log("shouldNotFetch");
          shouldFetchData = true;
        }
        content = <UserDetails data={data} />;
        break;
      case "users":
        console.log("11", data);
        if(data && data.user_id && data.user_id == id){
          console.log("shouldFetch");
          shouldFetchData = false;
        }else{
          console.log("shouldNotFetch");
          shouldFetchData = true;
        }
        content = <SBDetails data={data} />;
        break;
      case "payments":
        console.log("11", data);
        if(data && data.order_id && data.order_id == id){
          console.log("shouldFetch");
          shouldFetchData = false;
        }else{
          console.log("shouldNotFetch");
          shouldFetchData = true;
        }
        content = <InvoiceDetails data={data} />;
        break;
      case "fleets":
        console.log("11", data);
        if(data && data.id && data.id == id){
          console.log("shouldFetch");
          shouldFetchData = false;
        }else{
          console.log("shouldNotFetch");
          shouldFetchData = true;
        }
        content = <FleetDetails data={data} />;
        break;
      case "oems":
        console.log("11", data);
        if(data && data.id && data.id == id){
          console.log("shouldFetch");
          shouldFetchData = false;
        }else{
          console.log("shouldNotFetch");
          shouldFetchData = true;
        }
        content = <OemDetails data={data} />;
        break;
      case "warehouses":
        console.log("11", data);
        if(data && data.id && data.id == id){
          console.log("shouldFetch");
          shouldFetchData = false;
        }else{
          console.log("shouldNotFetch");
          shouldFetchData = true;
        }
        content = <WarehouseDetails data={data} />;
        break;
      case "vehicles":
        console.log("11", data);
        if(data && data.id && data.id == id){
          console.log("shouldFetch");
          shouldFetchData = false;
        }else{
          console.log("shouldNotFetch");
          shouldFetchData = true;
        }
        content = <VehicleDetails data={data} />;
        break;
      default:
        console.log("22");
        break;
    }

    useEffect(() => {
      if (!shouldFetchData || isChecked) return;
      isChecked = true;
      console.log("fetching");
      fetchData(currentTab, id, false);
      
    }, []);

    function handleButtonClick(buttonType, data){
        switch(buttonType) {
           case "tab":
              // setCurrentTab(data);
              setIsLoading(true);
              setData([]);
              console.log("new tab")
              fetchData(data.key);
              break;
        }
    }


    function handleRowClick(data){

    }

    return (
      <>
          <div className="dashboardbaseinner border-box type3 flex_vertical start_vertical center_horizontal">
              {content}
          </div>
      </>
    );


    function fetchData(tag, id, isReload=true){
      console.log("tag", tag, id);
      switch(tag){
        
        case "customers":
          // API.getWHLayout(params).then(function (response) {that.props.setData(response.data.data, "wh_layout");}).catch(function (error) {console.log(error);}).finally(function () {});
          api.getCustomers({user_id : id}).then(function (response) {console.log(response);setFetchedData(tag, response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
          // if(isReload){
          //   api.getCustomers().then(function (response) {
          //     console.log(response.data);
          //       Utils.setWarehouses(response.data.data);
          //       that.fetchLocalData(tag, exists);
          //     }).catch(function (error) {}).finally(function () {});
          // }else{
          //   that.fetchLocalData(tag, exists);
          // }
          // API.getVendors1(params).then(function (response) {that.props.setData(response.data.data, "vendors");}).catch(function (error) {console.log(error);}).finally(function () {});
          break;
        case "users":
          api.getServiceBuddies({sb_user_id : id}).then(function (response) {console.log(response);setFetchedData(tag, response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
          break;
      }
    }

    function setFetchedData(tag, data){
      var that = this;
      switch(tag){
        case "actionables":
        case "periodic_tasks":
          setData(data);
            break;
        default:
          setData(data);
          break;
      }
      setIsLoading(false); 
      // that.setState({skeleton : false});
    }
}
