import { useState } from 'react';
import Utils from '../utils';
import FieldBox from '../base/utils/fieldbox';
import Linkbutton from '../base/utils/linkbutton';
import { redirect } from 'react-router-dom';
import api from '../api';
import { useNavigate } from "react-router-dom";
import PasswordSuccess from './PasswordSuccess';
import { Loader } from '../base/utils/loader';

export default function AppPasswordReset(props) {
    const [email, setEmail] = useState("");
    const [error, setError] = useState({});
    const [pageStatus, setPageStatus] = useState("initial");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    function handleFieldState(key, value) {
        switch (key) {
            case "email":
                setEmail(value.trim().toLowerCase());
                break;
            default:
                break;
        }
        validateField(key, value.trim());
    }

    function validateField(key, value){
        switch (key) {
            case "email":
                if(!Utils.validateEmail(value)) {
                    error["email"] = "Please enter a valid email address";
                    setError(error);
                    return false;
                }else{
                    delete error["email"];
                    setError(error);
                    return true;
                }
                break;
            default:
                break;
        }
    }

    function handleButtonClick(buttonType) {
        setIsLoading(true);
        if(validateField("email", email)){
            api.passwordReset(email).then(function (response) {
                if(response.data.status){
                    console.log("response", response.data);
                    setPageStatus("success")
                    // navigate("/account/password/set");
                    
                }else{
                    error["email"] = response.data.message;
                    setError(error);
                    setPageStatus("initial");
                }
                })
                .catch(function (error) {
                    error["email"] = error.response.data.message;
                    setError(error);
                    setPageStatus("initial");
                })
                .finally(function () {
                    setIsLoading(false);
                });
        }else{
            setIsLoading(false);
        }
    }

    const getText = () => {
        return "A email has been send to " + email + ". Please check for an email from Vecmocon and click on the included link to reset your password.";
    }

    return (
      <>
      {isLoading ?
        <div className="loader spacer border-box flex_horizontal center_vertical center_horizontal" style={{width : "100%", height : "100%"}}><Loader /></div>
        :
        <>
        {
            {'initial':
              <div className="accountbaseinner border-box password_reset">
                  <div className="accountbaseinnerright border-box" style={{width : "30%"}}>
                  </div>
                  <div className="accountbaseinnerleft border-box" style={{width : "40%"}}>
                      <div className="inner border-box">
                          <h1>Reset Password</h1>
                          <p className="centeralign" style={{fontSize : "12px"}}>Enter your email address and we’ll send you an email with instructions to reset your password</p>
                          <div className="fieldouter border-box flex_vertical center_horizontal center_vertical">
                              <FieldBox objectKey="email" title="email" value={email} size="small"
                                  onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.email} />  
                          </div>
                          <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                              <button className="bttn large solid one" onClick={(e) => handleButtonClick("reset")}>Reset</button>
                          </div>
                      </div>
                      
                  </div>
                  <div className="accountbaseinnerleft border-box" style={{width : "30%"}}>
                  </div>
              </div>,
              
              'success':
              <PasswordSuccess text={getText()} />,
            }[pageStatus]
        }
        </>
        }
      </>
    );
}
