import constants from "../../constants";
import { useState, useEffect, useMemo } from 'react';

export const CountdownText = (props) => {

    const [stopWatch, setStopWatch] = useState(0);
    const [countdown, setCountdown] = useState(props.duration);
    const [className, setClassName] = useState(props.className ? props.className : "colorGrayE11span");
    
    useEffect(() => {
        if(countdown != null){
            const timeoutId = setTimeout(() => {
            setStopWatch(stopWatch + 1000);
            }, 1000);
            return () => clearTimeout(timeoutId);
        }
    }, [stopWatch]);

    useEffect(() => {
        if(props.countdownIndex != null){
            setStopWatch(0);
            setCountdown(props.duration);
            // setIsBuffer(false);
            setClassName(props.className ? props.className : "colorGrayE11span");
        }
    }, [props.countdownIndex]);

    useEffect(() => {
        if(stopWatch < props.duration){
            setCountdown(constants.getTimeDifferenceFromMill1(props.duration - stopWatch));
            if(props.warnings){
                if((props.duration - stopWatch) <= 5000){
                    if(className != "colorThree700span"){
                        setClassName("colorThree700span");
                    }
                }else if((props.duration - stopWatch) <= 10000){
                    if(className != "colorTwo700span"){
                        setClassName("colorTwo700span");
                    }
                }
            }
        }else{
            if(countdown != null){
                setCountdown(null);
                props.handleCountdownFinished();
            }
        }
    }, [stopWatch, props.duration]);

    const renderCountdownText = useMemo(() => (
        <span className={className}>{countdown}</span>
    ), [countdown])


    return (
        // <>{renderCountdownText}</>
        <></>
    );
}