import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import utils from '../../utils';
import constants from '../../constants';
import { Row } from '../Row';
import ChoiceBoxMulti from '../../base/utils/choiceboxmulti';
import FieldBox from '../../base/utils/fieldbox';
import api from '../../api';
import Linkbutton from '../../base/utils/linkbutton';
import { Loader } from '../../base/utils/loader';

export const ServiceDetailsServiceDetails = (props) => {
    const navigate = useNavigate();
    let orderData = props.data;
    let vehicleData = orderData ? orderData.vehicle : null;
    let status = orderData ? orderData.status : "pending";
    const [isLoading, setIsLoading] = useState(false);

    // const [selectedServiceTypeFilter, setSelectedServiceTypeFilter] = useState(status);
    const [order, setOrder] = useState(orderData);
    const [vehicle, setVehicle] = useState(vehicleData);
    const [servicesList, setServicesList] = useState([]);

    const [zoomedImage, setZoomedImage] = useState(null);

    let servicehistory = orderData && orderData.order_info && orderData.order_info.history && orderData.order_info.history.length != 0 ? orderData.order_info.history : [];

    console.log("order", order, props.extraData);

    var labourDetails = [];
    if(orderData.invoice && orderData.invoice.labour_details){
        labourDetails = orderData.invoice.labour_details.map(item => ({id: item.id, type: item.type, name: item.name, cost: parseInt(item.cost)+""}));
    }
    
    // (orderData.invoice && orderData.invoice.labour_details) ? orderData.invoice.labour_details.map((item) => {item.id, item.type, item.name, item.cost});
    var partDetails = [];
    if(orderData.invoice && orderData.invoice.part_details){
        partDetails = orderData.invoice.part_details.map(item => ({id: item.id, type: item.type, name: item.name, cost: parseInt(item.cost)+""}));
    }
    // (orderData.invoice && orderData.invoice.part_details) ? orderData.invoice.part_details.map((item) => {item.id, item.type, item.name, item.cost})
    let invoiceData = [...labourDetails, ...partDetails];


    const [invoiceFieldData, setinvoiceFieldData] = useState(invoiceData);
    const [discount, setDiscount] = useState(orderData.invoice && orderData.invoice.discount ? parseInt(orderData.invoice.discount)+"" : "0");
    console.log("details", invoiceFieldData);

    let isChecked = false;

    useEffect(() => {
        if (isChecked) return;
        isChecked = true;
        
        fetchData();
        
      }, []);


      const escFunction = useCallback((event) => {
        console.log("event", event, event.key);
        if (event.key === "Escape") {
            setZoomedImage(null);
        }
      }, []);
    
      useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
    
        return () => {
          document.removeEventListener("keydown", escFunction, false);
        };
      }, [escFunction]);

    function handleFieldState(key, value) {
        console.log("handling", key, value);
        if(key == "discount"){
            if(parseInt(value) < 0){
                value = 0;
            }else if(parseInt(value) > parseInt(orderData.invoice.total_cost_wo_gst)){
                value = orderData.invoice.total_cost_wo_gst+"";
            }
            setDiscount(value);
            validateField(key, value);
        }else{
            var fields = [...invoiceFieldData];
            for(var i = 0; i < fields.length; i++){
                console.log("handling1", key, value, fields[i].id+"");
                if(key.includes("name")){
                    if ("name_"+fields[i].id+"" == key) {
                        console.log("handling2", key, value, fields[i].id+"");
                        let service = servicesList.filter(o => o.service_id+"" == value+"");
                        fields[i].name = service.length > 0 ? service[0].service_name : "";
                        fields[i].id = service.length > 0 ? service[0].service_id+"" : "";
                        fields[i].cost = service.length > 0 ? service[0].service_cost+"" : "";
                        fields[i].type = "Labour";
                        if(service.length >0 && service[0].required_parts && service[0].required_parts.length >0){
                            for(var j = 0; j < service[0].required_parts.length; j++){
                                fields.push({id : service[0].required_parts[j].part_id, type : "Part", name : service[0].required_parts[j].part_name, cost : service[0].required_parts[j].part_cost});
                            }
                        }
                        
                        break;
                        
                    }
                }else{
                    if (fields[i].id+"" == key) {
                        fields[i].cost = value;
                        break;
                    }    
                }
            }
            setinvoiceFieldData(fields);
            validateField(key, value);
        }
    }

    function validateField(key, value){
        switch (key) {
            case "discount":
                return true;
            default:
                if(!value || value.length == 0 || value == "0") {
                    console.log("erroring", key);
                    // error[key] = errors[key];setError(error);
                    return false;
                }else{
                    // delete error[key];setError(error);
                    return true;
                }
                break;
        }
    }

    function validateAllFields(){
        let isValid = true;
        var fields = [...invoiceFieldData];
        for(var i = 0; i < fields.length; i++){
            if(!validateField(fields[i].id+"", fields[i].cost)){
                isValid = false;
            }
        }
        // Object.keys(fields).forEach((key, index) => {
        //     console.log("validate", key);
        //     if(!validateField(key, fields[key])){
        //         console.log("invalid", key, fields[key]);
        //         isValid = false;
        //     }
        // });
        return isValid;
    }
    
    function handleButtonClick(buttonType, data){
        if(buttonType == "remove"){ 
            var fields = [...invoiceFieldData];
            var service_id = data;
            for(var i = 0; i < fields.length; i++){
                if (fields[i].id+"" == data+"") {
                    fields.splice(i, 1);
                    break;
                }
            }
            let service = servicesList.filter(o => o.service_id+"" == data+"");
            if(service.length > 0 && service[0].required_parts && service[0].required_parts.length >0){
                for(var j = 0; j < service[0].required_parts.length; j++){
                    for(var i = 0; i < fields.length; i++){
                        if (fields[i].id+"" == service[0].required_parts[j].part_id+"") {
                            fields.splice(i, 1);
                            break;
                        }
                    }
                }
            }
            setinvoiceFieldData(fields);
        }
        else if(buttonType == "add"){ 
            var fields = [...invoiceFieldData];
            fields.push({id : Math.random().toString(36).slice(2, 7), type : "", name : "", cost : ""});
            setinvoiceFieldData(fields);
        }
        else if(buttonType == "save"){
            console.log("here");
            if (validateAllFields()){
                setIsLoading(true);
                console.log("here1");
                let request_data = {
                    invoice :{
                        labour_details : invoiceFieldData.filter(o => o.type == "Labour"), 
                        part_details : invoiceFieldData.filter(o => o.type == "Part"),
                        total_cost_wo_gst : orderData.invoice.total_cost_wo_gst,
                        total_cost_w_gst : orderData.invoice.total_cost_w_gst,
                        gst : orderData.invoice.gst,
                        // discount : discount
                    },
                    order_id : orderData.order_id,
                    vehicle_id : orderData.vehicle_id,
                }
                // if(localStorage.getItem("oem_id")){
                //     request_data1["oem_id"] = localStorage.getItem("oem_id");
                // }
                // var fieldData = Object.assign({}, that.state.fieldData);
                // delete fieldData.image_file;
                // setIsLoading(true);
                // let files = [fields.aadhar_file1, fields.aadhar_file2, fields.license, fields.photo];
                api.updateOrder(request_data).then(function (response) {
                    console.log("responseXX", response);
                    // setIsLoading(false);navigate("/dashboard/customers");
                }).catch(function (error) {console.log("responseXXError", error);}).finally(function () {setIsLoading(false);});
            }
        }
    }

    // function handleChoiceBoxMultiClick(type, id, text){
    //     if (type == "service_history") {
    //         let orderList = props.extraData.filter((item) => item.status == id);
    //         orderData = orderList && orderList.length != 0 ? orderList[orderList.length - 1] : null;
    //         vehicleData = orderData ? orderData.vehicle : null;
    //         setSelectedServiceTypeFilter(id);
    //         setOrder(orderData);
    //         setVehicle(vehicleData);
    //         console.log("orderData", orderData);
    //     }
    // }

    const zoomImageClick = (image_url) => {
        setZoomedImage(image_url);
        console.log("zooming", image_url);
    }

    const closeZoomedImage = () => {
        setZoomedImage(null);
    }

    return(
        <>

            {zoomedImage ? 
                <div className="zoomedImage centeralign" style={{background : "#000000"}}>
                    <img src={zoomedImage} loading="lazy" style={{maxHeight : "100%", maxWidth : "100%"}} />
                    <button className="bttn small solid one zoomedImageBtn" onClick={(e) => closeZoomedImage()}>Close</button>
                </div> 
            : <></>}
        
            <div className="servicehistory full_width full_height border-box">
                {order ? 
                <>
                <div style={{height : "20px"}}/>
                {order.status == "completed" ? 
                <div className="details details3 border-box full_width flex_horizontal center_horizontal center_vertical shadow-box" >
                    <div className="inner border-box flex_horizontal center_horizontal center_vertical spacer">
                        <img src="/images/wallet.png" loading="lazy" height="40" />
                        <div className="text border-box flex_vertical start_horizontal center_vertical spacer">
                            <div className="title weight600">Payment</div>
                            <div className="subtitle colorGray400span">{order.payment_mode ? order.payment_mode.toUpperCase() : ""} {order.payment_details ? "₹"+order.payment_details.amount_paid+"/-" : ""}</div>
                        </div>
                    </div>
                    <div style={{width : "20px"}}/>
                    <div className="inner border-box flex_horizontal center_horizontal center_vertical spacer">
                        <img src="/images/service_buddy.png" loading="lazy" height="40" />
                        <div className="text border-box flex_vertical start_horizontal center_vertical spacer">
                            <div className="title weight600">Service Buddy</div>
                            <div className="subtitle colorGray400span">{order.service_buddy.name}</div>
                        </div>
                    </div>
                    <div style={{width : "20px"}}/>
                    <div className="inner border-box flex_horizontal center_horizontal center_vertical spacer">
                        <img src="/images/rating_star.png" loading="lazy" height="40" />
                        <div className="text border-box flex_vertical start_horizontal center_vertical spacer">
                            <div className="title weight600">Rating</div>
                            <div className="subtitle colorGray400span">{order.service_buddy_rating} stars</div>
                        </div>
                    </div>
                </div>
                :
                <div className="details details1 border-box full_width flex_vertical start_horizontal center_vertical shadow-box" >
                    <div className="supertitle weight600 colorGray400span">Service Buddy</div>
                    <div className="title">{order.service_buddy.name}</div>
                    <div className="subtitle border-box flex_horizontal start_horizontal center_vertical">
                        {/* <i className="ti ti-map-pin colorGray400span"></i>
                        <span className="colorGray500span">Arrived</span> */}
                        <i className="ti ti-clock colorGray400span"></i>
                        <span className="colorOne500span">{order.current_status}</span>
                    </div>
                    <div style={{height : "20px"}}/>
                    <div className="vehicle full_width border-box flex_vertical start_horizontal center_vertical">
                        <div className="supertitle weight600 colorOne500span">Vehicle</div>
                        <div className="title">{vehicle.brand_name} {vehicle.model_name}</div>
                        <div className="subtitle border-box flex_horizontal start_horizontal center_vertical">
                            <i className="ti ti-brand-speedtest colorGray400span"></i>
                            <span className="colorGray500span">{props.extraData ? props.extraData.odometer_reading : ""}</span>
                            <i className="ti ti-license colorGray400span"></i>
                            <span className="colorGray500span">NA</span>
                            <i className="ti ti-scooter-electric colorGray400span"></i>
                            <span className="colorGray500span">NA</span>
                            <i className="ti ti-map-pin colorGray400span"></i>
                            <span className="colorGray500span">NA</span>
                        </div>
                    </div>
                </div>
                }
                
                <div style={{height : "20px"}}/>
                {isLoading ? 
            <div className="loader spacer border-box flex_horizontal center_vertical center_horizontal"><Loader /></div> : 
                <div className="details detailsInvoice border-box full_width flex_vertical start_horizontal center_vertical shadow-box" >
                    <div className="supertitle weight600 colorGray400span">Invoice Details</div>
                    <div className="tableBox border-box full_width flex_vertical start_horizontal start_vertical">
                        <div className="header border-box full_width flex_horizontal start_horizontal center_vertical">
                            <div className="headeritem one border-box flex_horizontal start_horizontal center_vertical">
                                Type
                            </div>
                            <div className="headeritem two border-box flex_horizontal start_horizontal center_vertical">
                                Name
                            </div>
                            <div className="headeritem three border-box flex_horizontal start_horizontal center_vertical">
                                Pre-Tax Cost
                            </div>
                            <div className="headeritem four border-box flex_horizontal start_horizontal center_vertical">
                                Action
                            </div>
                        </div>
                        {invoiceFieldData.map((item, index) => 
                            <div key={item.id} className="body border-box full_width flex_horizontal start_horizontal center_vertical">
                                <div className="bodyitem one border-box flex_horizontal start_horizontal center_vertical">
                                    {item.type}
                                </div>
                                <div className="bodyitem two border-box flex_vertical start_horizontal center_vertical">
                                    {item.name && item.name.trim() != "" ? item.name : 
                                        <FieldBox objectKey={"name"+"_"+item.id} value={item.name} 
                                            // mandatory={true}
                                            disabled = {order.status == "completed" ? "yes" : "no"}
                                            selectedValue={item.name} onSetFieldState={handleFieldState}
                                            type="select" size="small" 
                                            // errorDisplay="yes" 
                                            // errorText={error.oem_id}
                                            choices={[...[{value:"", title:"Select a service"}], ...servicesList.filter(o => invoiceFieldData.filter(p => p.id+"" == o.service_id+"").length == 0).map(o => ({value:o.service_id, title:o.service_name}))]
                                                .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
                                    }
                                </div>
                                <div className="bodyitem three border-box flex_vertical start_horizontal center_vertical">
                                    <FieldBox objectKey={item.id+""} value={item.cost} size="small"
                                    // mandatory={true}
                                    disabled = {order.status == "completed" ? "yes" : "no"}
                                        onSetFieldState={handleFieldState} type="text" inputtype="number" 
                                        errorDisplay="no" 
                                        // errorText={error.lastName} 
                                        />
                                </div>
                                <div className="bodyitem four border-box flex_horizontal start_horizontal center_vertical">
                                    {order.status != "completed" ?
                                    <button className="bttn small solid one" onClick={(e) => handleButtonClick("remove", item.id)}>Remove</button>
                                    : <></>}
                                </div>
                            </div>        
                        )}
                        <></>
                        {order.status != "completed" && servicesList.filter(o => invoiceFieldData.filter(p => p.id+"" == o.service_id+"").length == 0).length > 0 ?
                        <div className="border-box full_width flex_horizontal start_horizontal center_vertical">
                            <button className="bttn small solid one" onClick={(e) => handleButtonClick("add")}>Add</button>
                        </div> : <></>}
                        <div className="border-box full_width flex_horizontal space_between_horizontal center_vertical">
                            {/* {orderData.invoice && orderData.invoice.total_cost_wo_gst ?
                            <div className="border-box flex_vertical center_horizontal center_vertical" style={{width : "100px"}}>
                                <FieldBox objectKey="discount" value={discount} size="small" title="discount"
                                    // mandatory={true}
                                    disabled = {order.status == "completed" ? "yes" : "no"}
                                        onSetFieldState={handleFieldState} type="text" inputtype="number" 
                                        errorDisplay="no" 
                                        // errorText={error.lastName} 
                                        />
                            </div> : <span></span>} */}
                            {order.status != "completed" ?
                            <button className="bttn small solid one" onClick={(e) => handleButtonClick("save")}>Save</button>
                            : <></>}
                        </div>
                    </div>
                </div>}
                
                <div style={{height : "20px"}}/>
                <div className="details details2 border-box full_width flex_horizontal center_horizontal center_vertical shadow-box" >
                    <div className="left border-box flex_vertical start_horizontal start_vertical">
                        <div className="info border-box flex_horizontal start_horizontal center_vertical">
                            <i className="ti ti-map-pin"></i>
                            <div className="border-box flex_vertical start_horizontal center_vertical">
                                <div className="title">Location {(order.status == "completed")+""}</div>
                                <div className="subtitle colorGray400span">NA</div>
                            </div>
                        </div>
                        <div className="info border-box flex_horizontal start_horizontal center_vertical">
                            <i className="ti ti-calendar"></i>
                            <div className="border-box flex_vertical start_horizontal center_vertical">
                                <div className="title">Date</div>
                                <div className="subtitle colorGray400span">{order.date}</div>
                            </div>
                        </div>
                        <div className="info border-box flex_horizontal start_horizontal center_vertical">
                            <i className="ti ti-clock"></i>
                            <div className="border-box flex_vertical start_horizontal center_vertical">
                                <div className="title">Time</div>
                                <div className="subtitle colorGray400span">{order.time} {order.ampm.toUpperCase()}</div>
                            </div>
                        </div>
                        <div className="info border-box flex_horizontal start_horizontal center_vertical">
                            <i className="ti ti-circle-dashed"></i>
                            <div className="border-box flex_vertical start_horizontal center_vertical">
                                <div className="title">Odometer Reading</div>
                                <div className="subtitle colorGray400span">{order.vehicle.odometer} km</div>
                            </div>
                        </div>
                    </div>
                    <div className="right border-box flex_vertical start_horizontal center_vertical bgcolorGray200">
                        <div className="image full_width spacer">
                            <img src="/images/scooter_dummy.png" loading="lazy" />
                        </div>
                        <div className="text full_width">
                            <div className="title weight600">{vehicle.brand_name} {vehicle.model_name}</div>
                            <div className="subtitle bgcolorGray400span">{order.invoice_details ? order.invoice_details.service_name : ""}</div>
                            <a className="colorFour500span weight600" href={order.invoice_path}>Download Invoice</a>
                        </div>
                    </div>
                </div>
                <div style={{height : "20px"}}/>
                <p className="comments border-box full_width flex_horizontal start_horizontal start_vertical colorGray600span">
                    Comments by Service Buddy<br></br>
                    {order.comments}
                </p>
                <div style={{height : "20px"}}/>
                <p className="comments border-box full_width flex_horizontal start_horizontal start_vertical colorGray600span">
                    Comments by Customer<br></br>
                    {order.customer_message}
                </p>
                <div style={{height : "20px"}}/>
                <div className="details detailsHistory border-box full_width flex_vertical start_horizontal center_vertical shadow-box" >
                    <div className="supertitle weight600 colorGray400span">History</div>
                    <div className="tableBox border-box full_width flex_vertical start_horizontal start_vertical">
                        <div className="header border-box full_width flex_horizontal start_horizontal center_vertical">
                            <div className="headeritem one border-box flex_horizontal start_horizontal center_vertical">
                                TimeStamp
                            </div>
                            <div className="headeritem two border-box flex_horizontal start_horizontal center_vertical">
                                Comment
                            </div>
                            <div className="headeritem three border-box flex_horizontal start_horizontal center_vertical">
                                Status
                            </div>
                        </div>
                        {servicehistory.map((item, index) => 
                            <div key={item.status_id} className="body border-box full_width flex_horizontal start_horizontal center_vertical">
                                <div className="bodyitem one border-box flex_horizontal start_horizontal center_vertical">
                                    {item.time}
                                </div>
                                <div className="bodyitem two border-box flex_vertical start_horizontal center_vertical">
                                    {item.text}
                                </div>
                                <div className="bodyitem three border-box flex_vertical start_horizontal center_vertical">
                                    {item.status}
                                </div>
                            </div>        
                        )}
                        
                    </div>
                </div>
                </> : <></>}


                <div style={{height : "20px"}}/>
                <div className="details imagesGrid border-box full_width flex_vertical start_horizontal center_vertical shadow-box" >
                    <div className="supertitle weight600 colorGray400span">Pre-Service Photos</div>
                    <div className="tableBox border-box full_width">
                        {order.order_info && order.order_info.service_image && order.order_info.service_image.length != 0 ?
                        order.order_info.service_image.map((item, index) => 
                            <div className="image border-box cursor-pointer" onClick={(e) => zoomImageClick(item)}>
                                <img src={item} loading="lazy" width="100%" height="auto" />
                            </div>
                        )
                        : <></>}
                    </div>
                </div>

                <div style={{height : "20px"}}/>
                <div className="details imagesGrid border-box full_width flex_vertical start_horizontal center_vertical shadow-box" >
                    <div className="supertitle weight600 colorGray400span">Post-Service Photos</div>
                    <div className="tableBox border-box full_width">
                        {order.order_info && order.order_info.service_post_image && order.order_info.service_image.length != 0 ?
                        order.order_info.service_post_image.map((item, index) => 
                            <div className="image border-box cursor-pointer" onClick={(e) => zoomImageClick(item)}>
                                <img src={item} loading="lazy" width="100%" height="auto" />
                            </div>
                        )
                        : <></>}
                    </div>
                </div>

                <div style={{height : "20px"}}/>
                <div className="details imagesGrid border-box full_width flex_vertical start_horizontal center_vertical shadow-box" >
                    <div className="supertitle weight600 colorGray400span">Videos</div>
                    <div className="border-box full_width flex_horizontal start_horizontal center_vertical">
                        {order.order_info && order.order_info.service_video ?
                        <Linkbutton text="Download Pre-Service Video" href={order.order_info.service_video} newTab="yes" type="small" button_type="solid" />
                        : <></>}
                        {order.order_info && order.order_info.service_post_video ?
                        <>
                        <div style={{width : "20px"}}/>
                        <Linkbutton text="Download Post-Service Video" href={order.order_info.service_post_video} newTab="yes" type="small" button_type="solid" />
                        </>
                        : <></>}
                    </div>
                </div>

            </div>
        </>
    );




    function fetchData(){
        api.getServiceData(orderData.order_id).then(function (response) {console.log("responsedata", response.data.data); setServicesList(response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
    }
}
