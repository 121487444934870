import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import utils from '../utils';
import constants from '../constants';
import { Row } from './Row';
import FieldBox from '../base/utils/fieldbox';
import api from '../api';

export const DashboardFilters = (props) => {
    const navigate = useNavigate(); 

    const [oemsList, setOemsList] = useState([]);
    const [fleetsList, setFleetsList] = useState([]);

    let isChecked = false;

    console.log("paramsFilter", props.paramsFilter);

    useEffect(() => {
        if (isChecked || constants.getRole() != "superadmin") return;
        isChecked = true;
        
        fetchData();
        
      }, []);

    let content_date = <>
    {constants.getRole() == "superadmin" && props.type != "oem_active" && props.type != "fleet_active" ? <>
        <div className="filterbasefieldouter border-box flex_vertical center_vertical start_horizontal">
            <FieldBox objectKey="oem_id" title="OEM" value={props.paramsFilter.oem_id ? props.paramsFilter.oem_id : ""}
                selectedValue={props.paramsFilter.oem_id} onSetFieldState={props.handleFilterChange} 
                type="select" size="small"
                choices={[...[{value:"", title:""}], ...oemsList.map(o => ({value:o.id, title:o.name}))]
                        .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
        </div>
        <div style={{width: "10px"}}></div>
        <div className="filterbasefieldouter border-box flex_vertical center_vertical start_horizontal">
            <FieldBox objectKey="fleet_id" title="Fleet" value={props.paramsFilter.fleet_id ? props.paramsFilter.fleet_id : ""}
                selectedValue={props.paramsFilter.fleet_id} onSetFieldState={props.handleFilterChange} 
                type="select" size="small"
                choices={[...[{value:"", title:""}], ...fleetsList.map(o => ({value:o.id, title:o.name}))]
                        .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
        </div>
        <div style={{width: "10px"}}></div>
        </> : <></>}
        {props.type != "sb_attendance" ? <>
        <div className="filterbasefieldouter border-box flex_vertical center_vertical start_horizontal">
        <FieldBox size="small" objectKey="date_from" title="From" value={props.paramsFilter.date_from ? props.paramsFilter.date_from : ""} 
            onSetFieldState={props.handleFilterChange} type="date"/>
        </div>
        <div style={{width: "10px"}}></div>
        <div className="filterbasefieldouter border-box flex_vertical center_vertical start_horizontal">
        <FieldBox size="small" objectKey="date_to" title="To" value={props.paramsFilter.date_to ? props.paramsFilter.date_to : ""}
            onSetFieldState={props.handleFilterChange} type="date"/>
        </div></> : <>
        <div className="filterbasefieldouter border-box flex_vertical center_vertical start_horizontal">
        <FieldBox size="small" objectKey="month" title="Month" value={props.paramsFilter.month ? props.paramsFilter.month : ""} 
            selectedValue={props.paramsFilter.month} onSetFieldState={props.handleFilterChange} 
            type="select"
            choices={[...constants.getMonths().map(o => ({value:o.month+"", title:o.monthName}))]
                    .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
        </div>
        <div style={{width: "10px"}}></div>
        <div className="filterbasefieldouter border-box flex_vertical center_vertical start_horizontal">
        <FieldBox size="small" objectKey="year" title="Year" value={props.paramsFilter.year ? props.paramsFilter.year : ""} 
            selectedValue={props.paramsFilter.year} onSetFieldState={props.handleFilterChange} 
            type="select"
            choices={[...constants.getYears().map(o => ({value:o+"", title:o+""}))]
                    .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
        </div>
        </>}
    </>;
    let content_location = <>
        {/* <FieldBox objectKey="stateName" title="State" value={fields.stateName} 
                                        selectedValue={fields.stateName} onSetFieldState={handleFieldState} 
                                        type="select" size="small" errorDisplay="yes" errorText={error.stateName}
                                        choices={[...[{value:"", title:""}], ...utils.getStatesIndia().map(o => ({value:o, title:o}))]
                                                .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/> */}
    </>;

    switch(props.type) {
        case "customers":
            break;
        case "sb_active":
        case "sb_available":
        case "sb_offline":
        // navigate("/dashboard/users/users/" + data.user_id, {state : {data : data}});
        // navigate("/dashboard/" + props.subsection.key + "/" + currentTab.key + "/" + data.id, {state : {data : data}});
        break;
        case "services_active":
        case "services_completed":
        case "services_pending":
        case "payments_all":
        case "payments_pending":
        // navigate("/dashboard/customers/customers/" + data.user.id);
            break;
    }

    let content = <>{content_location}{content_date}</>

    return(
    <div className="filterbase full_width border-box flex_horizontal center_vertical end_horizontal">
        {content}
    </div>
    );


    function fetchData(){
        api.getOEMs({offset : "0", limit : "50"}).then(function (response) {
            api.getFleets({offset : "0", limit : "50"}).then(function (response1) {
                console.log("responsedata", response1.data.data);
                setOemsList(response.data.data);setFleetsList(response1.data.data);
            }).catch(function (error1) {console.log(error1);}).finally(function () {});
        }).catch(function (error) {console.log(error);}).finally(function () {});
      }
}
