import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import utils from '../../utils';
import constants from '../../constants';
import { Row } from '../Row';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'

export const SBDetailsAttendance = (props) => {
    const navigate = useNavigate();

    // console.log("props.extraData", props.extraData)

    // {row.attendance.filter(o => o.date == constants.get_DateStringX3(days[key])).length != 0 ? 
    //     (row.attendance.filter(o => o.date == constants.get_DateStringX3(days[key]))[0].present != null ? (row.attendance.filter(o => o.date == constants.get_DateStringX3(days[key]))[0].present == null ? "-" : (row.attendance.filter(o => o.date == constants.get_DateStringX3(days[key]))[0].present == "NA" ? "NA" : (row.attendance.filter(o => o.date == constants.get_DateStringX3(days[key]))[0].present ? "P" : "A"))) : "-") : 
    //     "-"}

    const getEvents = () => {
        let events = {};
        console.log("props.extraData", props.extraData); 
        for(var i=0; i<props.extraData.length; i++){
            let data = props.extraData[i];
            if(data.date in events || data.present == null) continue;
            events[data.date] = 
                {
                    title : (data.present != null ? (data.present == "NA" ? "NA" : (data.present === false ? "Absent" : "Present")) : "-"), 
                    date : data.date, 
                    backgroundColor : (data.present == "NA" ? "var(--colorTwo500)" : (data.present === false ? "var(--colorThree500)" : "var(--colorOne500)"))
                };
            if(constants.get_DateStringX3(new Date()) == data.date){
                break;
            }
        }
        var values = Object.keys(events).map((key) => events[key]);
        console.log("extraDataValues", values);
        return Object.keys(events).map((key) => events[key]);
        // return props.extraData.filter(o => o.present != null).map((data) => 
        //     (
        //         {
        //             title : (data.present == "NA" ? "NA" : (data.present === false ? "Absent" : "Present")), 
        //             date : data.date, 
        //             backgroundColor : (data.present == "NA" ? "var(--colorTwo500)" : (data.present === false ? "var(--colorThree500)" : "var(--colorOne500)"))})
        // );
    }

    const onDatesSet = (dateInfo) => {
        var ms = dateInfo.start.getTime() + 15*86400000;
        var newdate = new Date(ms);
        console.log("month", newdate.getMonth());
        props.onDatesSet(newdate.getMonth()+1, newdate.getFullYear());
    }

    

    return(
        <>
            <div style={{height : "20px"}}/>
            <div className="calendar border-box full_width" >
                <FullCalendar
                    plugins={[ dayGridPlugin ]}
                    initialView="dayGridMonth"
                    events={getEvents()}
                    datesSet= {(dateInfo) => {
                        onDatesSet(dateInfo);
                        // console.log(dateInfo)
                        }}
                />
                </div>
            <div style={{height : "20px"}}/>
        </>
    );
}
