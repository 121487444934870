import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import utils from '../../utils';
import constants from '../../constants';
import { Row } from '../Row';
import './userdetails.css';
import { Rating1 } from '../../base/utils/rating1';
import { UserDetailsAbout } from './userdetailsabout';
import api from '../../api';
import { UserDetailsServiceHistory } from './userdetailsservicehistory';
import { Loader } from '../../base/utils/loader';
import { SBDetailsAbout } from './sbdetailsabout';
import { SBDetailsAttendance } from './sbdetailsattendance';
import { Dialog } from '../../base/utils/dialog';

const tabData = [{key : "about", title : "About"}, {key : "attendance", title : "Attendance"}, ];

export const VehicleDetails = (props) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isDialogShowing, setIsDialogShowing] = useState(false);
    const [dialogContent, setDialogContent] = useState(<></>);

    const [vehicleStatus, setVehicleStatus] = useState(props.data.status);

    let history = props.data && props.data.history && props.data.history.length != 0 ? props.data.history : [];
    
    // let dialogContent = <></>;

    let isChecked = false;

    // useEffect(() => {
    //     console.log("data_check1", Object.keys(props.data).length !== 0);
    //     if (Object.keys(props.data).length == 0 || isChecked) return;
    //     isChecked = true;
        
    //     fetchData();
        
    //   }, []);

    // useEffect(() => {
    //     if(isChecked) return;
    //     isChecked = true;

    //     fetchData();
    // }, [props.data]);

    console.log("props.data", props.data);

    function handleButtonClick(buttonType, data){
        switch(buttonType) {
        //    case "tab":
        //       setSelectedTab(data);
        //       break;
        //     case "edit":
        //         console.log("edit", "/action/users/users/edit/" + props.data.user_id);
        //         navigate("/dashboard/action/users/users/edit/" + props.data.user_id, {state : {data : props.data}});
        //         break;
            case "servicing":
                console.log("active");
                setDialogContent( 
                    <Dialog title = {vehicleStatus == "active" ? "Mark under maintenance?" : "Mark active?" }
                        text={vehicleStatus == "active" ? "Are you certain you want to mark the vehicle as under maintenance?" : "Are you certain that you want to mark the vehicle as on road?" }
                        buttonText = {vehicleStatus == "active" ? "Mark under maintenance" : "Mark active" }
                        buttonActionKey = "confirm_servicing" fields={vehicleStatus == "active" ? true : false} choices={[
                            {value:"", title:"Select a reason"}, 
                            {value:"Insurance Claim Pending", title:"Insurance Claim Pending"},
                            {value:"No Drivers Available", title:"No Drivers Available"},
                            {value:"OEM Parts not available", title:"OEM Parts not available"}
                        ]} fieldTitle = "Reason"
                        buttonClassNames = {props.data.active ? "solid three" : "solid one" }
                        handleDialogButtonClick={handleDialogButtonClick} />);
                    setIsDialogShowing(true);
                    break;    
            case "active":
                console.log("active");
                setDialogContent( 
                    <Dialog title = {vehicleStatus == "active" ? "Mark inactive?" : "Mark active?" }
                        text={vehicleStatus == "active" ? "Are you certain you want to mark the vehicle as out of service?" : "Are you certain that you want to mark the vehicle as on road?" }
                        buttonText = {vehicleStatus == "active" ? "Mark inactive" : "Mark active" }
                        buttonActionKey = "confirm_active"
                        buttonClassNames = {props.data.active ? "solid three" : "solid one" }
                        handleDialogButtonClick={handleDialogButtonClick} />);
                    setIsDialogShowing(true);
                    break;
            case "delete":
                console.log("delete");
                setDialogContent( 
                    <Dialog title = "Delete Vehicle" 
                        text="Are you certain that you want to delete the vehicle?"
                        buttonText = "Delete"
                        buttonActionKey = "confirm_delete"
                        buttonClassNames = "solid three"
                        handleDialogButtonClick={handleDialogButtonClick} />);
                    setIsDialogShowing(true);
                console.log("delete1", isDialogShowing, dialogContent);
                    break;

        }
    }

    function handleDialogButtonClick(buttonType, data){
        switch(buttonType) {
            case "cancel":
                // setDialogContent(<></>);
                // setIsDialogShowing(false);
                break;
            case "confirm_servicing":
                    console.log("confirm_servicing");
                    api.saveVehicleB2B({warehouse_id : props.data.warehouse_id, fleet_id : props.data.fleet_id, vehicle_id : props.data.id, status : vehicleStatus == "active" ? "servicing" : "active", reason : vehicleStatus == "active" ? data.reason : null}).then(function (response) {
                        if(response.data.status){
                            // fetchData();
                            setVehicleStatus(vehicleStatus == "active" ? "servicing" : "active");
                        }else{
                            console.log("error", response.data);
                        }
                    }).catch(function (error) {console.log(error);}).finally(function () {});
                    break;
            case "confirm_active":
                console.log("confirm_access");
                api.saveVehicleB2B({warehouse_id : props.data.warehouse_id, fleet_id : props.data.fleet_id, vehicle_id : props.data.id, status : vehicleStatus == "active" ? "inactive" : "active"}).then(function (response) {
                    if(response.data.status){
                        // fetchData();
                        setVehicleStatus(vehicleStatus == "active" ? "inactive" : "active");
                    }else{
                        console.log("error", response.data);
                    }
                }).catch(function (error) {console.log(error);}).finally(function () {});
                break;
            case "confirm_delete":
                console.log("confirm_delete");
                api.deleteVehicleB2B({vehicle_id : props.data.id}).then(function (response) {
                    if(response.data.status){
                        navigate("/dashboard/vehiclesb2b");
                    }else{
                        console.log("error", response.data);
                    }
                }).catch(function (error) {console.log(error);}).finally(function () {});
                break;
        }
        setDialogContent(<></>);
        setIsDialogShowing(false);
    }

    
      return(
        <div className="userdetails full_width full_height border-box flex_vertical center_horizontal start_vertical">
            {Object.keys(props.data).length !== 0 ? 
            <>
            <div className="one full_width border-box flex_horizontal center_horizontal center_vertical">
                <div className="left border-box flex_horizontal center_horizontal center_vertical">
                    <div className="border-box flex_horizontal center_vertical center_horizontal shadow-box">
                        {utils.getInitialsFromName(props.data.brand_name + " " + props.data.model_name)}
                    </div>
                </div>
                <div className="right border-box flex_vertical start_horizontal end_vertical">
                    <div className="full_width border-box flex_horizontal center_vertical space_between_horizontal">
                        <div className="title weight600 flex_horizontal start_horizontal center_vertical">{props.data.brand_name + " " + props.data.model_name} <span style={{width : "20px"}} /> <span style={{width : "max-content", padding : "3px 5px", borderRadius : 5, fontSize : "11px", textTransform : "capitalize", backgroundColor : "var(--colorOne100)", color : "var(--colorOne900)"}}>• {vehicleStatus}</span></div>
                        <div className="flex_horizontal end_horizontal center_vertical">
                            {constants.isAdmin() ? <>
                            {/* <button className="bttn small solid one" onClick={(e) => handleButtonClick("edit")}>Edit</button> */}
                            {vehicleStatus != "servicing" ? <button className="bttn small border three" onClick={(e) => handleButtonClick("active")}>{vehicleStatus == "active" ? "Mark out of service" : "Mark on road"}</button> : <></>}
                            {vehicleStatus != "inactive" ? <button className="bttn small border three" onClick={(e) => handleButtonClick("servicing")}>{vehicleStatus == "active" ? "Mark under maintenance" : "Mark on road"}</button> : <></>}
                            <button className="bttn small solid one" onClick={(e) => handleButtonClick("delete")}>Delete</button>
                            {/* <button className="bttn small solid three" onClick={(e) => handleButtonClick("delete")}>Delete</button> */}
                            </> : <></>}
                        </div>
                    </div>
                    <div className="supertitle weight300">Reg No : <span className="weight600">{props.data.registration_number}</span></div>
                    <div style={{height : "30px"}}/>
                    <div className="subtitle weight300">Odometer : <span className="weight600">{props.data.odometer}</span></div>
                    <div style={{height : "10px"}}/>
                    <div className="subtitle weight300">VIN : <span className="weight600">{props.data.vin}</span></div>
                    
                </div>
            </div>

            {history.length != 0 ? <>
            <div style={{height : "20px"}}/>
            <div className="servicehistory full_width full_height border-box">
                <div className="details detailsHistory border-box full_width flex_vertical start_horizontal center_vertical shadow-box" >
                    <div className="supertitle weight600 colorGray400span">History</div>
                    <div className="tableBox border-box full_width flex_vertical start_horizontal start_vertical">
                        <div className="header border-box full_width flex_horizontal start_horizontal center_vertical">
                            <div className="headeritem one border-box flex_horizontal start_horizontal center_vertical">
                                TimeStamp
                            </div>
                            <div className="headeritem two border-box flex_horizontal start_horizontal center_vertical">
                                Comment
                            </div>
                            <div className="headeritem three border-box flex_horizontal start_horizontal center_vertical">
                                Status
                            </div>
                        </div>
                        {history.map((item, index) => 
                            <div key={item.status_id} className="body border-box full_width flex_horizontal start_horizontal center_vertical">
                                <div className="bodyitem one border-box flex_horizontal start_horizontal center_vertical">
                                    {item.time}
                                </div>
                                <div className="bodyitem two border-box flex_vertical start_horizontal center_vertical">
                                    {item.text}
                                </div>
                                <div className="bodyitem three border-box flex_vertical start_horizontal center_vertical">
                                    {item.status}
                                </div>
                            </div>        
                        )}
                        
                    </div>
                </div>
            </div>
            </> : <></>}
            {/* <div className="two full_width border-box flex_horizontal center_horizontal center_vertical">
                <div className="left border-box flex_vertical start_horizontal start_vertical">
                    <div className="supertitle border-box flex_horizontal center_vertical start_horizontal">
                        <i className="ti ti-building-skyscraper"></i> <div style={{width : "10px"}} /> <span className="weight600">{props.data.sb_users.employer ? props.data.sb_users.employer.company_name : "NA"}</span>
                    </div>
                    <div className="supertitle border-box flex_horizontal center_vertical start_horizontal colorGray500span">
                        {props.data.sb_users.employer ? props.data.sb_users.employer.location : "NA"}
                    </div>
                </div>
                <div className="right border-box flex_vertical start_horizontal start_vertical">
                    {
                        {
                            "about" : <SBDetailsAbout data={props.data} extraData={aboutData} />,
                            "attendance" : <SBDetailsAttendance data={props.data} extraData={attendanceData} />,
                        }[selectedTab.key]
                    }
                </div>
            </div> */}
            {isDialogShowing ? dialogContent : <></>}
            </>
            : <div className="loader spacer border-box flex_horizontal center_vertical center_horizontal"><Loader /></div>}
        </div>
      );


    //   function fetchData(){
    //     api.getVehicles({vehicle_id : props.data.id}).then(function (response1) {
    //         console.log("response1", response1.data.data)
    //         setprops.data(response1.data.data);
            
    //     }).catch(function (error) {console.log(error);}).finally(function () {});
    //     }
}
