import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import utils from '../../utils';
import constants from '../../constants';
import { Row } from '../Row';
import './userdetails.css';
import { Rating1 } from '../../base/utils/rating1';
import { UserDetailsAbout } from './userdetailsabout';
import api from '../../api';
import { UserDetailsServiceHistory } from './userdetailsservicehistory';
import { Loader } from '../../base/utils/loader';
import { SBDetailsAbout } from './sbdetailsabout';
import { SBDetailsAttendance } from './sbdetailsattendance';
import { Dialog } from '../../base/utils/dialog';

// const tabData = [{key : "about", title : "About"}, {key : "attendance", title : "Attendance"}, ];

export const InvoiceDetails = (props) => {
    const navigate = useNavigate();
    // const [selectedTab, setSelectedTab] = useState(tabData[0]);
    const [isLoading, setIsLoading] = useState(true);
    const [attendanceData, setAttendanceData] = useState([]);
    const [aboutData, setAboutData] = useState({});
    const [isDialogShowing, setIsDialogShowing] = useState(false);
    const [dialogContent, setDialogContent] = useState(<></>);
    
    // let dialogContent = <></>;

    let isChecked = false;

    useEffect(() => {
        console.log("data_check1", Object.keys(props.data).length !== 0);
        if (Object.keys(props.data).length == 0 || isChecked) return;
        console.log("data_check2", Object.keys(props.data).length !== 0);
        isChecked = true;
        
        fetchData();
        
      }, []);

    // useEffect(() => {
    //     if(isChecked) return;
    //     isChecked = true;

    //     fetchData();
    // }, [props.data]);

    console.log("props.data", props.data);

    function handleButtonClick(buttonType, data){
        switch(buttonType) {
        //    case "tab":
        //       setSelectedTab(data);
        //       break;
            case "edit":
                console.log("edit", "/action/users/users/edit/" + props.data.user_id);
                navigate("/dashboard/action/users/users/edit/" + props.data.user_id, {state : {data : props.data}});
                break;
            case "delete":
                console.log("delete");
                setDialogContent( 
                    <Dialog title = "Delete Service Buddy" 
                        text="Are you certain that you want to delete the service buddy? This action will permanently remove the service buddy from the system, and all associated data and access rights will be irreversibly lost."
                        buttonText = "Delete"
                        buttonActionKey = "confirm_delete"
                        buttonClassNames = "solid three"
                        handleDialogButtonClick={handleDialogButtonClick} />);
                    setIsDialogShowing(true);
                    break;
            case "access":
                console.log("access");
                setDialogContent( 
                    <Dialog title = {props.data.active ? "Revoke access?" : "Restore access?" }
                        text={props.data.active ? "Are you certain you want to revoke access? This action will remove the granted permissions or access for the service buddy, preventing them from performing tasks and accessing the profile." : "Are you certain that you want to proceed with restoring access? This action will reinstate the previously revoked permissions and access for the service buddy, allowing them to perform tasks and access their profile once again." }
                        buttonText = {props.data.active ? "Revoke access" : "Restore access" }
                        buttonActionKey = "confirm_access"
                        buttonClassNames = {props.data.active ? "solid three" : "solid one" }
                        handleDialogButtonClick={handleDialogButtonClick} />);
                    setIsDialogShowing(true);
                    break;

        }
    }

    function handleDialogButtonClick(buttonType, data){
        switch(buttonType) {
            case "cancel":
                // setDialogContent(<></>);
                // setIsDialogShowing(false);
                break;
            case "confirm_delete":
                console.log("confirm_delete");
                api.deleteServiceBuddy({user_id : props.data.user_id}).then(function (response) {
                    if(response.data.status){
                        navigate("/dashboard/action/users/users");
                    }else{
                        console.log("error", response.data);
                    }
                }).catch(function (error) {console.log(error);}).finally(function () {});
                break;
            case "confirm_access":
                console.log("confirm_access");
                api.updateServiceBuddy({user_id : props.data.user_id}).then(function (response) {
                    if(response.data.status){
                        fetchData();
                    }else{
                        console.log("error", response.data);
                    }
                }).catch(function (error) {console.log(error);}).finally(function () {});
                break;
        }
        setDialogContent(<></>);
        setIsDialogShowing(false);
    }

    // let tabs = tabData.length > 0 ? tabData.map((tab, index) => {
    //     return (
    //         <div 
    //             className={tab.key === selectedTab.key ? "tab active border-box flex_horizontal center_vertical center_horizontal cursor-pointer" : "tab border-box flex_horizontal center_vertical center_horizontal cursor-pointer"} 
    //             key={index} onClick={(e) => handleButtonClick("tab", tab)}>{tab.title}</div>
    //     );
    //   }) : <></>;
    
      return(
        <div className="userdetails invoice full_width full_height border-box flex_vertical center_horizontal start_vertical">
            {Object.keys(props.data).length !== 0 ? 
            <>
            <div className="one full_width border-box flex_horizontal center_horizontal center_vertical">
                <div className="left border-box flex_horizontal center_horizontal center_vertical">
                    <div className="border-box flex_horizontal center_vertical center_horizontal shadow-box">
                        {utils.getInitialsFromName(props.data.user.name)}
                    </div>
                </div>
                <div className="right border-box flex_vertical start_horizontal end_vertical">
                    <div className="full_width border-box flex_horizontal center_vertical space_between_horizontal">
                        <div className="title weight600 flex_horizontal start_horizontal center_vertical">{props.data.user.name} <span style={{width : "20px"}} /> <span style={{width : "max-content", padding : "3px 5px", borderRadius : 5, fontSize : "11px", textTransform : "capitalize", backgroundColor : "var(--colorOne100)", color : "var(--colorOne900)"}}>• {props.data.payment_details.payment_status}</span></div>
                        {/* <div className="flex_horizontal end_horizontal center_vertical">
                            {constants.isAdmin() ? <>
                            <button className="bttn small solid one" onClick={(e) => handleButtonClick("edit")}>Edit</button>
                            <button className="bttn small border three" onClick={(e) => handleButtonClick("access")}>{props.data.active == true ? "Revoke access" : "Restore access"}</button>
                            <button className="bttn small solid three" onClick={(e) => handleButtonClick("delete")}>Delete</button>
                            </> : <></>}
                        </div> */}
                    </div>
                    {/* <div className="supertitle weight300">@Nickname : <span className="weight600">{props.data.sb_users.nickname}</span></div>
                    <div style={{height : "30px"}}/>
                    <div className="subtitle weight300">Rating : <span className="weight600">{props.data.rating ? props.data.rating : "NA"}</span></div>
                    <div style={{height : "10px"}}/>
                    <div className="rating"><Rating1 value={props.data.rating ? props.data.rating : 0} /></div>
                    <div style={{height : "50px"}}/> */}
                    {/* <div className="tabs border-box flex_horizontal center_vertical start_horizontal">
                        {tabs}
                    </div> */}
                </div>
            </div>
            <div className="four full_width border-box flex_horizontal center_horizontal start_vertical bgcolorGray200">
                <div className="inner border-box flex_vertical start_horizontal start_vertical bgcolorSix50">
                    <div className="one border-box full_width flex_horizontal space_between_horizontal start_vertical">
                        <div className="left border-box flex_vertical start_horizontal start_vertical">
                            <div className="border-box flex_horizontal center_vertical start_horizontal colorGray500span">
                                Billed to
                            </div>
                            <div className="border-box flex_horizontal center_vertical start_horizontal weight600">
                                {props.data.user.name}
                            </div>
                            <div className="border-box flex_horizontal center_vertical start_horizontal colorGray500span">
                                {props.data.primary_address}
                            </div>
                            <div className="border-box flex_horizontal center_vertical start_horizontal colorGray500span">
                                {props.data.city}, {props.data.state} - {props.data.pincode}
                            </div>
                            <div className="border-box flex_horizontal center_vertical start_horizontal colorGray500span">
                                {props.data.user.phone_number}
                            </div>
                        </div>
                        <div className="left border-box flex_vertical end_horizontal start_vertical">

                        </div>
                    </div>
                    <div className="two border-box full_width flex_vertical start_horizontal center_vertical shadow-box">
                        <div className="supertitle weight600 colorGray400span">Service Buddy</div>
                        <div className="title">{props.data.service_buddy.name}</div>
                        <div className="subtitle border-box flex_horizontal start_horizontal center_vertical">
                            <i className="ti ti-clock colorGray400span"></i>
                            <span className="colorOne500span">Completed</span>
                        </div>
                        <div style={{height : "20px"}}/>
                        <div className="vehicle full_width border-box flex_vertical start_horizontal center_vertical">
                            <div className="supertitle weight600 colorOne500span">Vehicle</div>
                            <div className="title">{props.data.vehicle.brand_name} {props.data.vehicle.model_name}</div>
                            <div className="subtitle border-box flex_horizontal start_horizontal center_vertical">
                                {/* <i className="ti ti-brand-speedtest colorGray400span"></i>
                                <span className="colorGray500span">{props.extraData.odometer_reading}</span>
                                <i className="ti ti-license colorGray400span"></i>
                                <span className="colorGray500span">NA</span> */}
                                <i className="ti ti-scooter-electric colorGray400span"></i>
                                <span className="colorGray500span">{props.data.vehicle.vin}</span>
                                <i className="ti ti-map-pin colorGray400span"></i>
                                <span className="colorGray500span">{props.data.city}, {props.data.state} - {props.data.pincode}</span>
                            </div>
                        </div>
                    </div>
                    <div className="three border-box full_width flex_vertical start_horizontal center_vertical">
                        <div className="inner border-box full_width flex_horizontal space_between_horizontal center_vertical">
                            <div className="border-box flex_vertical start_horizontal center_vertical">
                                <div className="supertitle colorGray500span">Invoice Date</div>
                                <div className="text weight600">{props.data.date}</div>
                            </div>
                            {/* <div className="border-box spacer flex_vertical end_horizontal center_vertical">

                            </div>
                            <div className="border-box spacer flex_vertical start_horizontal center_vertical">

                            </div> */}
                            <div className="border-box flex_vertical end_horizontal center_vertical">
                                <div className="supertitle colorGray500span">Invoice No.</div>
                                <div className="text weight600">{props.data.order_id}</div>
                            </div>
                        </div>
                    </div>
                    <div className="four border-box full_width flex_vertical start_horizontal center_vertical">
                        <div className="inner border-box full_width flex_horizontal center_horizontal center_vertical">
                            <div className="text border-box full_width flex_horizontal start_horizontal center_vertical colorGray600span" style={{width : "50%"}}>
                                Item description
                            </div>
                            <div className="text spacer border-box full_width flex_horizontal start_horizontal center_vertical colorGray600span">
                                Qty
                            </div>
                            <div className="text spacer border-box full_width flex_horizontal end_horizontal center_vertical colorGray600span">
                                Rate
                            </div>
                            <div className="text spacer border-box full_width flex_horizontal end_horizontal center_vertical colorGray600span">
                                Amount
                            </div>
                        </div>
                        <div className="inner border-box full_width flex_horizontal center_horizontal center_vertical">
                            <div className="text border-box full_width flex_horizontal center_horizontal center_vertical" style={{width : "50%"}}>
                                
                            </div>
                            <div className="text spacer border-box full_width flex_horizontal center_horizontal center_vertical">

                            </div>
                            <div className="text spacer border-box full_width flex_horizontal center_horizontal center_vertical">

                            </div>
                            <div className="text spacer border-box full_width flex_horizontal center_horizontal center_vertical">

                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="left border-box flex_vertical start_horizontal start_vertical">
                    <div className="supertitle border-box flex_horizontal center_vertical start_horizontal">
                        <i className="ti ti-building-skyscraper"></i> <div style={{width : "10px"}} /> <span className="weight600">{props.data.sb_users.employer ? props.data.sb_users.employer.company_name : "NA"}</span>
                    </div>
                    <div className="supertitle border-box flex_horizontal center_vertical start_horizontal colorGray500span">
                        {props.data.sb_users.employer ? props.data.sb_users.employer.location : "NA"}
                    </div>
                </div> */}
                {/* <div className="right border-box flex_vertical start_horizontal start_vertical">
                    {
                        {
                            "about" : <SBDetailsAbout data={props.data} extraData={aboutData} />,
                            "attendance" : <SBDetailsAttendance data={props.data} extraData={attendanceData} />,
                        }[selectedTab.key]
                    }
                </div> */}
            </div>
            {/* {isDialogShowing ? dialogContent : <></>} */}
            </>
            : <div className="loader spacer border-box flex_horizontal center_vertical center_horizontal"><Loader /></div>}
        </div>
      );


      function fetchData(){
        api.getServiceBuddies({sb_user_id : props.data.user_id}).then(function (response1) {
            console.log("response1", response1.data.data)
            setAttendanceData(response1.data.data.attendance);
            
        }).catch(function (error) {console.log(error);}).finally(function () {});
        }
}
