import constants from './constants';
import axios from 'axios';

class api{

    getConfig(token=localStorage.getItem("access_token")+""){
        return {headers: {'Authorization': "Bearer " + token}};
    }
    
    getConfigWithParams(params, token=localStorage.getItem("access_token")+"", ){
        console.log("params3", params);
        return {headers: {'Authorization': "Bearer " + token}, "params" : params};
    }
    
    getParams(params){
        return {params : params};
    }
    
    getConfigMultiPartFormData(token=localStorage.getItem("access_token").toString()) {
        if(token){
            return { headers: { "content-type": "multipart/form-data", 'Authorization': "Bearer " + token } };
        }else{
            return { headers: { "content-type": "multipart/form-data" } };
        }
    }
    
    getConfigJSON(token=localStorage.getItem("access_token").toString()) {
        if(token){
            return { headers: { "content-type": "application/json", 'Authorization': "Bearer " + token } };
        }else{
            return { headers: { "content-type": "application/json" } };
        }
    }


    loginWithEmailPassword(email, password){
        return axios.post(constants.getBaseURL()+'api/v1/verify_login_with_password', {
            email : email, password : password,
            // session_key : sessionStorage.getItem("session_key"),
            source : "vecmocon_superadmin_dashboard"
          })
    }
    signup(email, password, first_name, last_name, phone_number){
        return axios.post(constants.getBaseURL()+'api/v1/signup', {
            email : email, password : password,
            first_name : first_name, last_name : last_name, phone_number : phone_number,
            // session_key : sessionStorage.getItem("session_key"),
            source : "vecmocon_superadmin_dashboard"
          })
    }
    logout(phone, otp){
        return axios.post(constants.getBaseURL()+'api/logout', {
            
          }, this.getConfig())
    }
    passwordReset(email, password){
        return axios.post(constants.getBaseURL()+'api/v1/account/password/reset', {
            email : email,
            // session_key : sessionStorage.getItem("session_key"),
            source : "vecmocon_superadmin_dashboard"
          })
    }
    passwordSetCheck(token){
        return axios.post(constants.getBaseURL()+'api/v1/account/password/set/check', {
            token : token,
            // session_key : sessionStorage.getItem("session_key"),
            source : "vecmocon_superadmin_dashboard"
          })
    }
    passwordSet(user_id, password){
        return axios.post(constants.getBaseURL()+'api/v1/account/password/set', {
            user_id : user_id, password : password,
            // session_key : sessionStorage.getItem("session_key"),
            source : "vecmocon_superadmin_dashboard"
          })
    }

    getProfileRoles(token){
        return axios.get(constants.getBaseURL()+'api/v1/uprofile/profile_roles', this.getConfig(token));
    }

    getServiceData(orderID){
        let params = {order_id : orderID, order_type : "B2C"};
        return axios.get(constants.getBaseURL()+'api/v1/order/available_services', this.getConfigWithParams(params));
    }
    
    getCities(stateName){
        let params = {filter : "city", state : stateName}
        return axios.get(constants.getBaseURL()+'api/v1/uprofile/get_car_city_list', this.getConfigWithParams(params));
    }

    getBrands(){
        let params = {filter : "vehicle_brand"}
        return axios.get(constants.getBaseURL()+'api/v1/uprofile/get_car_city_list', this.getConfigWithParams(params));
        // https://vecmoconbe.thorintech.com/api/v1/uprofile/get_car_city_list?filter=vehicle_model&brand_name=TVS&brand_id=35
    }
    getModels(brand){
        let params = {filter : "vehicle_model", brand_name : brand}
        return axios.get(constants.getBaseURL()+'api/v1/uprofile/get_car_city_list', this.getConfigWithParams(params));
    }
    getAllModels(data){
        let params = {...data, filter : "vehicle_model"}
        return axios.get(constants.getBaseURL()+'api/v1/uprofile/get_car_city_list', this.getConfigWithParams(params));
    }

    getModelsByOEM(oem_id){
        let params = {filter : "vehicle_model", oem_id : oem_id}
        return axios.get(constants.getBaseURL()+'api/v1/uprofile/get_car_city_list', this.getConfigWithParams(params));
    }

    getServicesB2BExcel(params){
        return axios.get(constants.getBaseURL()+'api/v1/order/orders', this.getConfigWithParams(params));
    }

    getOEMs(params){
        return axios.get(constants.getBaseURL()+'api/v1/uprofile/edit_oem', this.getConfigWithParams(params));
    }
    getFleets(params){
        return axios.get(constants.getBaseURL()+'api/v1/uprofile/edit_fleet', this.getConfigWithParams(params));
    }
    getCustomers(params){
        if(localStorage.getItem("oem_id")){
            params["oem_id"] = localStorage.getItem("oem_id");
        }
        if(constants.isStateHead()){
            params["state"] = localStorage.getItem("stateName");
        }
        if(constants.isCityHead()){
            params["city"] = localStorage.getItem("cityName");
        }
        return axios.get(constants.getBaseURL()+'api/v1/uprofile/customer_details', this.getConfigWithParams(params));
    }
    getOrders(params){
        if(localStorage.getItem("oem_id")){
            params["oem_id"] = localStorage.getItem("oem_id");
        }
        if(constants.isStateHead()){
            params["state"] = localStorage.getItem("stateName");
        }
        if(constants.isCityHead()){
            params["city"] = localStorage.getItem("cityName");
        }
        if(localStorage.getItem("fleet_id")){
            params["fleet_id"] = localStorage.getItem("fleet_id");
        }
        return axios.get(constants.getBaseURL()+'api/v1/order/orders', this.getConfigWithParams(params));
    }
    getServiceBuddies(params){
        if(localStorage.getItem("oem_id")){
            params["oem_id"] = localStorage.getItem("oem_id");
        }
        if(constants.isStateHead()){
            params["state"] = localStorage.getItem("stateName");
        }
        if(constants.isCityHead()){
            params["city"] = localStorage.getItem("cityName");
        }
        return axios.get(constants.getBaseURL()+'api/v1/service_buddy/service_buddy_info', this.getConfigWithParams(params));
    }
    getWarehouses(params){
        if(localStorage.getItem("fleet_id")){
            params["fleet_id"] = localStorage.getItem("fleet_id");
        }
        return axios.get(constants.getBaseURL()+'api/v1/b2b_servicebuddy/onboard_warehouse', this.getConfigWithParams(params));
    }
    getVehicles(params){
        if(localStorage.getItem("fleet_id")){
            params["fleet_id"] = localStorage.getItem("fleet_id");
        }
        return axios.get(constants.getBaseURL()+'api/v1/b2b_servicebuddy/onboard_vehicle', this.getConfigWithParams(params));
    }
    saveServiceBuddies(files, data){
        const formData = new FormData();
            // formData.append("name", data.name);
        formData.append("file", files[0]);
        for (const [key, value] of Object.entries(data)) {
            formData.append(key, value);
            }

        return axios.post(
            constants.getBaseURL() + "api/v1/service_buddy/service_buddy_info",
            formData,
            this.getConfigMultiPartFormData()
        );
    }
    saveFleet(files, data){
        const formData = new FormData();
            // formData.append("name", data.name);
        formData.append("file", files[0]);
        for (const [key, value] of Object.entries(data)) {
            formData.append(key, value);
            }

        console.log("formData", formData, data, files);
        for (var pair of formData.entries()) {
            console.log("formData1", pair[0]+ ', ' + pair[1]); 
        }

        return axios.post(
            constants.getBaseURL() + "api/v1/uprofile/edit_fleet",
            formData,
            this.getConfigMultiPartFormData()
        );
    }

    excelUpload(files, data){
        const formData = new FormData();
            // formData.append("name", data.name);
            console.log("files", files);
        formData.append("file", files[0]);
        for (const [key, value] of Object.entries(data)) {
            formData.append(key, value);
            }

        return axios.post(
            constants.getBaseURL() + "api/v1/uprofile/bulk_add_user",
            formData,
            this.getConfigMultiPartFormData()
        );
    }
    excelUploadConfirm(data){
        return axios.put(constants.getBaseURL()+'api/v1/uprofile/bulk_add_user', data, this.getConfig());
    }

    saveOEM(data){
        return axios.post(constants.getBaseURL()+'api/v1/uprofile/edit_oem', data, this.getConfig());
    }
    saveWarehouse(data){
        return axios.post(constants.getBaseURL()+'api/v1/b2b_servicebuddy/onboard_warehouse', data, this.getConfig());
    }
    saveVehicleB2B(data){
        return axios.post(constants.getBaseURL()+'api/v1/b2b_servicebuddy/onboard_vehicle', data, this.getConfig());
    }
    onboardVehicle(data){
        return axios.post(constants.getBaseURL()+'api/v1/vehicle/add_vehicle', data, this.getConfig());
    }
    deleteVehicleB2B(data){
        return axios.delete(constants.getBaseURL()+'api/v1/vehicle/vehicle', {...this.getConfig(), data : data});
    }
    deleteServiceBuddy(data){
        return axios.delete(constants.getBaseURL()+'api/v1/service_buddy/service_buddy_info', {...this.getConfig(), data : data});
    }
    deleteOrder(data){
        return axios.delete(constants.getBaseURL()+'api/v1/order/order', {...this.getConfig(), data : data});
    }
    updateServiceBuddy(data){
        return axios.put(constants.getBaseURL()+'api/v1/service_buddy/service_buddy_info', data, this.getConfig());
    }
    editCustomer(data){
        return axios.post(constants.getBaseURL()+'api/v1/uprofile/edit_customer', {...data, type : "customer"}, this.getConfig());
    }
    editVehicle(data){
        return axios.post(constants.getBaseURL()+'api/v1/uprofile/edit_customer', {...data, type : "vehicle"}, this.getConfig());
    }
    getBMS(params){
        return axios.get(constants.getBaseURL()+'api/v1/vehicle/bms', this.getConfigWithParams(params));
    }
    autoAssignBuddy(data){
        return axios.post(constants.getBaseURL()+'api/v1/order/order', data, this.getConfig());
    }
    updateOrder(data){
        return axios.put(constants.getBaseURL()+'api/v1/order/order', data, this.getConfig());
    }

    // getProfileRoles(params){
    //     return axios.get(constants.getBaseURL()+'api/v1/uprofile/profile_roles', this.getConfigWithParams(params));
    // }
}

export default new api();