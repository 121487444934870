import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#12B76A',
    },
    '& .MuiRating-iconHover': {
      color: '#12B76A',
    },
  });

export const Rating1 = (props) => {
    let size = props.size ? props.size : "large";
    let spacing = props.spacing ? props.spacing : 1;
    let precision = props.precision ? props.precision : 0.5;
    let max = props.max ? props.max : 5;
    let value = props.value ? props.value : 0;
    return(
        <Stack spacing={spacing}>
            <StyledRating precision={precision} name="rating1" defaultValue={value} size={size} max={max} readOnly />
        </Stack>
    );
};